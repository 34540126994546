<div class="password-checker-component">
    <div *ngIf="showValidation && !isRegistration">
        <div *ngIf="passwordValidations?.PASSWORD_LENGTH?.value">
            <div class="valid-label c-mtm-green" *ngIf="passwordValidations.PASSWORD_LENGTH.valid">
                <label>&#9745; Password length valid</label>
            </div>
            <div class="error-label c-mtm-red" *ngIf="!passwordValidations.PASSWORD_LENGTH.valid">
                <label>Minimum password length is {{passwordValidations.PASSWORD_LENGTH.value}}</label>
            </div>
        </div>
        <div *ngIf="passwordValidations?.PASSWORD_COMPLEXITY?.NUMBER?.value">
            <div class="valid-label c-mtm-green" *ngIf="passwordValidations.PASSWORD_COMPLEXITY.NUMBER.valid">
                <label>&#9745; Password number requirement is valid</label>
            </div>
            <div class="error-label c-mtm-red" *ngIf="!passwordValidations.PASSWORD_COMPLEXITY.NUMBER.valid">
                <label>Password number requirement is invalid</label>
            </div>
        </div>
        <div *ngIf="passwordValidations?.PASSWORD_COMPLEXITY?.SYMBOL?.value">
            <div class="valid-label c-mtm-green" *ngIf="passwordValidations.PASSWORD_COMPLEXITY.SYMBOL.valid">
                <label>&#9745; Password symbol requirement is valid</label>
            </div>
            <div class="error-label c-mtm-red" *ngIf="!passwordValidations.PASSWORD_COMPLEXITY.SYMBOL.valid">
                <label>Password symbol requirement is invalid</label>
            </div>
        </div>
        <div *ngIf="passwordValidations?.PASSWORD_COMPLEXITY?.CASES?.value">
            <div class="valid-label c-mtm-green" *ngIf="passwordValidations.PASSWORD_COMPLEXITY.CASES.valid">
                <label>&#9745; Password case requirement is valid</label>
            </div>
            <div class="error-label c-mtm-red" *ngIf="!passwordValidations.PASSWORD_COMPLEXITY.CASES.valid">
                <label>Password case requirement is invalid</label>
            </div>
        </div>
    </div>

    <div class="password-generator-section">
        <div *ngIf="!!samplePassword" class="d-flex align-items-center sample-password flex-fill py-2 w-100">
            <label class="w-100 d-flex flex-column">
                <span class="mb-1" [style.color]="isRegistration ? '#637EA3' : '#000'">{{'randomPassword' | translate}}:</span>
                <div class="d-flex align-items-center info flex-wrap">
                    <div class="col-lg-9 col-md-12 col-sm-12 d-flex flex-wrap p-0 w-100">
                        <span class="d-block w-100 p-2 mr-3"
                            [ngClass]="isRegistration ? 'registration-sample-password-label' : 'sample-password-label'"
                            [style.line-break]="'anywhere'">{{samplePassword}}
                        </span>
                        <i *ngIf="!isRegistration" class="fa fa-copy pointer mr-3" (click)="copyPassword($event)"
                            [style.font-size]="'1rem'" [attr.title]="'Password copied &#10003;'"></i>
                        <i *ngIf="!isRegistration" class="fa fa-refresh pointer" [style.font-size]="'1rem'"
                            (click)="generateSamplePassword({ length: passwordGeneratorModel.length.value, numbers: passwordGeneratorModel.numbers.value, lowercase: true, uppercase: passwordGeneratorModel.cases.value, symbols: passwordGeneratorModel.symbols.value }, true)"></i>
                    </div>
                    <div class="col-lg-3 col-md-12 col-sm-12 mt-lg-0 mt-3 mt-sm-3 d-flex flex-wrap justify-content-center justify-content-lg-end justify-content-md-center justify-content-sm-center p-0 w-100" [style.gap]="'15px'">
                        <div>
                            <select *ngIf="isRegistration" class="form-select w-auto ms-md-2 mb-2 mb-md-0"
                                [(ngModel)]="selectedLength" (change)="updatePasswordStrength()">
                                <option *ngFor="let option of generatedOptions" [value]="option">
                                    {{ option }} car.
                                </option>
                            </select>
                        </div>                        
                        <div>
                            <button  *ngIf="isRegistration" type="button" class="reload-btn btn btn-outline-none ms-md-2"
                                (click)="generateSamplePassword({ length: passwordGeneratorModel.length.value, numbers: passwordGeneratorModel.numbers.value, lowercase: true, uppercase: passwordGeneratorModel.cases.value, symbols: passwordGeneratorModel.symbols.value }, true)">
                                <img src="../../../assets/svg/redo.svg" width="21" height="21" alt="">
                            </button>
                        </div>
                        <div>
                            <button [attr.title]="'Password copied &#10003;'" *ngIf="isRegistration" type="button" class="reload-btn btn btn-outline-none ms-md-2"
                                (click)="copyPassword($event)">
                                <img src="../../../assets/svg/copy.svg" width="21" height="21" alt="" (click)="copyPassword($event)">
                            </button>
                        </div>
                    </div>
                </div>
            
            </label>
        </div>

        <div class=" group-item" *ngIf="passwordGeneratorModel && !isRegistration">
            <div class="group-content security-policy">
                <div class="form-group d-flex align-items-center" [ngClass]="{'min-same-floor': minLength === 128}">
                    <label class="text-nowrap flex-fill" for="passwordLength">Password Length</label>
                    <ng5-slider [(ngModel)]="passwordGeneratorModel.length.value"
                        [options]="{ floor: minLength || 0, step:1, ceil: 128 }"
                        (click)="generateSamplePassword({ length: passwordGeneratorModel.length.value, numbers: passwordGeneratorModel.numbers.value, lowercase: true, uppercase: passwordGeneratorModel.cases.value, symbols: passwordGeneratorModel.symbols.value }, true)"></ng5-slider>
                </div>
                <div class="form-group d-flex align-items-center">
                    <label class="text-nowrap flex-fill" for="numbers">Numbers</label>
                    <div class="switch-btn">
                        <ui-switch [disabled]="!passwordGeneratorModel.numbers.enable"
                            [(ngModel)]="passwordGeneratorModel.numbers.value" defaultBgColor="black" size="small"
                            switchColor="#efefef"
                            (valueChange)="generateSamplePassword({ length: passwordGeneratorModel.length.value, numbers: passwordGeneratorModel.numbers.value, lowercase: true, uppercase: passwordGeneratorModel.cases.value, symbols: passwordGeneratorModel.symbols.value }, true)"></ui-switch>
                    </div>
                </div>
                <div class="form-group d-flex align-items-center">
                    <label class="text-nowrap flex-fill" for="specialCharacters">Special Characters</label>
                    <div class="switch-btn">
                        <ui-switch [disabled]="!passwordGeneratorModel.symbols.enable"
                            [(ngModel)]="passwordGeneratorModel.symbols.value" defaultBgColor="black" size="small"
                            switchColor="#efefef"
                            (valueChange)="generateSamplePassword({ length: passwordGeneratorModel.length.value, numbers: passwordGeneratorModel.numbers.value, lowercase: true, uppercase: passwordGeneratorModel.cases.value, symbols: passwordGeneratorModel.symbols.value }, true)"></ui-switch>
                    </div>
                </div>
                <div class="form-group d-flex align-items-center">
                    <label class="text-nowrap flex-fill" for="cases">Upper Case / Lower Case mix</label>
                    <div class="switch-btn">
                        <ui-switch [disabled]="!passwordGeneratorModel.cases.enable"
                            [(ngModel)]="passwordGeneratorModel.cases.value" defaultBgColor="black" size="small"
                            switchColor="#efefef"
                            (valueChange)="generateSamplePassword({ length: passwordGeneratorModel.length.value, numbers: passwordGeneratorModel.numbers.value, lowercase: true, uppercase: passwordGeneratorModel.cases.value, symbols: passwordGeneratorModel.symbols.value }, true)"></ui-switch>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>