import * as _ from 'lodash';
import { Component, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormGroup, FormBuilder, Validators } from "@angular/forms";

import * as google from 'google-libphonenumber';

import { CountryCodes } from './models/country-codes.model';
import { PLUS_ONE_AREA_CODE } from './models/plus-one-area-code';

@Component({
    selector: 'selector-mtm-tel-input',
    templateUrl: './mtm-tel-input.component.html',
    styleUrls: ['./mtm-tel-input.component.scss']
})
export class MTMTelInputComponent {
    @Input() cardForm: UntypedFormGroup;
    @Input() cardFormKey: string;
    //------- OR
    @Input() phoneNumberInInternationalFormat: string;

    /* --- * * --- */
    @Input() trim: boolean = false;
    @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() focus: EventEmitter<any> = new EventEmitter<any>();
    @Input() isSignup: boolean = false;
    
    isRequired: boolean = false;

    /* ------- * * ------- */

    @ViewChild('flag', { static: true }) flag: ElementRef;

    googlePhoneUtil = google.PhoneNumberUtil.getInstance();
    PNF = google.PhoneNumberFormat;
    PNT = google.PhoneNumberType;

    listCountryCodes: any[] = new CountryCodes().allCountries;
    selectedCountry: any = {
        'countryName': "France",
        'flagClass': {
            'flag-icon-fr': true
        },
        'internationalCode': "33",
        'regionCode': "FR"
    };

    flagcssOfSelectedCountry: String;

    phoneNumber: string = '';
    phoneFormatInternationalForPlaceholder: string = '';
    isAnError: boolean = false;

    private searchValue = '';
    isLoadingDropdown: boolean = false;
    isVisibleDropdown: boolean = false;

    //[1-9]{1}[0-9]{10}
    constructor() { }

    ngOnInit() {
        this.listCountryCodes.map((k: any) => {
            let code = 'flag-icon-' + k.regionCode.toLowerCase();
            k.flagClass = {};
            k.flagClass[code] = true;
        });
        this.selectedCountry.selected = true;
        this.onSelectedCountry(this.selectedCountry);

        this.init();
    }

    ngOnChanges() {
        this.init();
    }

    restrictToNumbers(event: KeyboardEvent) {
        const charCode = event.charCode || event.keyCode;
        const inputElement = event.target as HTMLInputElement;
        const inputValue = inputElement.value;
    
        // Allow '+' only if it’s the first character and not already present
        if (charCode === 43) { // 43 is the char code for '+'
            if (inputValue.indexOf('+') !== 0) {
                inputElement.value = `+${inputValue.replace('+', '')}`;
            }
            event.preventDefault(); // Prevent further processing of '+'
            return;
        }
    
        // Allow numbers (0-9) only
        if (/[0-9]/.test(String.fromCharCode(charCode))) {
            return; // Allow numbers
        }
    
        // Prevent all other characters
        event.preventDefault();
    }    
    
    private isEmpty(value) { return (!value || value == '') ? true : false; }

    private init() {
        if (this.isEmpty(this.cardForm) && this.isEmpty(this.cardFormKey) && this.isEmpty(this.phoneNumberInInternationalFormat))
            return;

        if (this.phoneNumberInInternationalFormat)
            this.phoneNumber = this.phoneNumberInInternationalFormat;
        else if (this.cardForm && this.cardFormKey)
            this.phoneNumber = _.get(this.cardForm, 'value.' + this.cardFormKey);
        else {
            this.setErrorState(true);
            return;
        }

        try{
            let abstractControl = this.cardForm.controls[this.cardFormKey];
            this.isRequired = abstractControl.hasValidator(Validators.required);
        } catch {

        }


        if (this.isEmpty(this.phoneNumber)){
            
             if(this.isRequired){
                 this.onSelectedCountry(this.selectedCountry);
             }
            
            return;
        }
            

        this.selectedCountry = this.listCountryCodes.find(k => this.phoneNumber.indexOf('+' + k.internationalCode) >= 0);
        if (!this.selectedCountry)
            return;

        this.onSelectedCountry(this.selectedCountry);
        this.phoneNumber = _.get(this.cardForm, 'value.' + this.cardFormKey) ? _.get(this.cardForm, 'value.' + this.cardFormKey) : this.phoneNumber;

        if (this.trim) {
            this.phoneNumber = this.phoneNumber.replace(/\s/g, '');
        }

        this.isValidPhoneNumber();

        if (this.cardForm && this.cardFormKey && this.isAnError)
            this.setErrorToPhoneControlOfFrom();
    }

    private parsePhoneNumber(phoneNumber: string = '1234567890') {
        return this.googlePhoneUtil.parse(phoneNumber, this.selectedCountry.regionCode);
    }

    private getPhoneNumberPlaceHolder(phoneNumber: string = '1234567890'): string {
        try {
            return this.googlePhoneUtil.format(this.parsePhoneNumber(phoneNumber), this.PNF.E164);
        }
        catch (e) {
            return 'Error';
        }
    }

    private onSelectedCountry(selectedCountry) {
        this.listCountryCodes
             .filter(k => k.selected == true)
            .map(k => k.selected = false);
        this.selectedCountry = selectedCountry;
        this.selectedCountry.selected = true;
        this.flag.nativeElement.className = "";
        this.flag.nativeElement.className += " flag-icon";
        this.flag.nativeElement.className += " flag-icon-" + this.selectedCountry.regionCode.toLowerCase();
        //I9- doesnt support classList
        // this.flag.nativeElement.classList.add('flag-icon');
        // this.flag.nativeElement.classList.add('flag-icon-' + this.selectedCountry.regionCode.toLowerCase());
        this.phoneNumber = '+' + this.selectedCountry.internationalCode;
        this.phoneFormatInternationalForPlaceholder = this.getPhoneNumberPlaceHolder();
    }

    private getIsValidNumber(): boolean {
        try {
            let func = () => {
                let phoneType = this.googlePhoneUtil.getNumberType(this.parsePhoneNumber(this.phoneNumber));

                return phoneType == this.PNT.MOBILE || phoneType == this.PNT.FIXED_LINE_OR_MOBILE || phoneType == this.PNT.FIXED_LINE;
            }
            return this.phoneNumber.startsWith("+") && this.googlePhoneUtil.isValidNumber(this.parsePhoneNumber(this.phoneNumber))
                &&
                func();//is mobile phone number?
        } catch (error) {
            return false;
        }
    }
    
    isValidPhoneNumber() {
        try {
            this.getIsValidNumber() == true ? this.setErrorState(false) : this.setErrorState(true);
        } catch (error) {
            this.setErrorState(true);
        }
    }

    modelChangeForPhoneInput(event) {
        this.detectPhoneCountryChange();
        let updateKey = {}, keyControls = '';
        const arrKeys = this.cardFormKey.split('.');
        for (let i = 0; i < arrKeys.length; i++) {
            keyControls += (i > 0 ? '.' : '') + arrKeys[i];
        }

        if (this.trim) {
            this.phoneNumber = this.phoneNumber.replace(/\s/g, '');
        }

        _.set(updateKey, keyControls, this.phoneNumber);
        this.cardForm.patchValue(updateKey);
        this.setErrorToPhoneControlOfFrom();
        this.isValidPhoneNumber();
        if (this.getIsValidNumber()) {
            this.valueChange.emit({ value: this.phoneNumber });
        }
    }

    private detectPhoneCountryChange() {
        let phoneNo: string = this.phoneNumber;
        if (phoneNo.startsWith('+'))
            phoneNo = phoneNo.slice(1);
        const plusOneArea = phoneNo.startsWith('1') ? PLUS_ONE_AREA_CODE[phoneNo.slice(1, 4)] : void 0;
        if (plusOneArea) {
            this.selectedCountry = this.listCountryCodes.find(c => c.regionCode.toLowerCase() === plusOneArea.country.toLowerCase());
        } else {
            this.selectedCountry = this.listCountryCodes.find(c => phoneNo.startsWith(c.internationalCode));
        }
        if (this.selectedCountry) {
            this.selectedCountry.selected = true;
            this.flag.nativeElement.className = "";
            this.flag.nativeElement.className += " flag-icon";
            this.flag.nativeElement.className += " flag-icon-" + this.selectedCountry.regionCode.toLowerCase();
        }
    }

    private setErrorToPhoneControlOfFrom() {
        if (this.cardForm && this.cardFormKey) {
            const arrKeys = this.cardFormKey.split('.');
            let keyControls = '';
            for (let i = 0; i < arrKeys.length; i++) {
                keyControls += (i > 0 ? '.' : '') + 'controls.' + arrKeys[i];
            }
            let control = _.get(this.cardForm, keyControls);
            if (this.getIsValidNumber()) {
                control.setErrors(null);
            }
            else {
                control.setErrors({ 'incorrect': true });
            }
        }
    }

    setErrorState(state: boolean) {
        this.isAnError = state;
    }


    setValueIsVisibleDropdown(value) {
        this.isLoadingDropdown = true;
        setTimeout(() => this.isLoadingDropdown = false, 249);
        setTimeout(() => this.isVisibleDropdown = value, 251);
    }

    elementFocus() {
        this.focus.emit();
    }

    blur(){
        if(!this.phoneNumber && this.isRequired){
            this.setErrorState(true);
        }
    }

    /*
    private checkPhoneNumber() {
        let PNF = google.PhoneNumberFormat;
        let phoneNumberUtil = google.PhoneNumberUtil.getInstance();
        var phoneNumber = phoneNumberUtil.parse(this.number, this.selectedCountry.regionCode);

        this.createResultItem("Country Code", phoneNumber.getCountryCode());
        this.createResultItem("National Number", phoneNumber.getNationalNumber());
        this.createResultItem("Extension", phoneNumber.getExtension());
        this.createResultItem("Country Code Source", phoneNumber.getCountryCodeSource());
        this.createResultItem("Italian Leading Zero", phoneNumber.getItalianLeadingZero());
        this.createResultItem("Raw Input", phoneNumber.getRawInput());

        this.createResultItem("Is Possible Number", phoneNumberUtil.isPossibleNumber(phoneNumber));
        this.createResultItem("Is Valid Number", phoneNumberUtil.isValidNumber(phoneNumber));
        this.createResultItem("Is Valid Number For Region", phoneNumberUtil.isValidNumberForRegion(phoneNumber));
        this.createResultItem("Region Code For Number", phoneNumberUtil.getRegionCodeForNumber(phoneNumber));
        this.createResultItem("NumberType", phoneNumberUtil.getNumberType(phoneNumber));
        this.createResultItem("Format PNF.E164", phoneNumberUtil.getNumberType(phoneNumber));
        this.createResultItem("Format In Original Format 'TR'", phoneNumberUtil.getNumberType(phoneNumber));
        this.createResultItem("Format PNF.NATIONAL", phoneNumberUtil.format(phoneNumber, PNF.NATIONAL));
        this.createResultItem("Format PNF.INTERNATIONAL", phoneNumberUtil.format(phoneNumber, PNF.INTERNATIONAL));
        this.createResultItem("Format PNF.INTERNATIONAL", phoneNumberUtil.format(phoneNumber, PNF.INTERNATIONAL));
    }
    onSelectedCountry(selectedCountry) {
        this.listCountryCodes.filter(k => k.selected == true).map(k => k.selected = false);
        this.selectedCountry = selectedCountry;
        this.selectedCountry.selected = true;

        this.formatPhoneNumber = this.getPhoneNumberPlaceHolder(this.selectedCountry.regionCode);
    }
    */
}
