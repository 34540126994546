<div class="subsection-option-edit-container">
  <div class="modal-header">
    <div
      class="d-flex flex-column flex-md-row w-100 justify-content-between align-items-start align-items-md-center title-row">
      <div class="text-uppercase modal-title">
        <span class="d-inline-flex justify-content-center align-items-center mr-2 icon-container">
          <i class="fa" aria-hidden="true" [class.fa-plus]="!optionId" [class.fa-pencil]="optionId"></i>
        </span>
        <span class="title">{{
          (optionId ? currentOption?.title :
          'versioning_edit_newOption') | translate
          }}
        </span>
      </div>


      <div class="version-dropdown" ngbDropdown *ngIf="currentOption?.simpleVersions && currentVersion">
        <button ngbDropdownToggle [disabled]="isLoading" class="btn p-2 dropdown-toggle">
          <span class="d-flex flex-column align-items-start flex-fill version-column">
            <div class="d-flex align-item-center justify-content-between w-100">
              <span class="version">{{ 'V' + currentVersion.versionNumber }}</span>
              <span class="d-flex align-items-center justify-content-center arrow-wrapper">
                <i class="fa fa-chevron-down" aria-hidden="true"></i>
              </span>
            </div>
            <span class="date" *ngIf="currentVersion.createdAt" [title]="currentVersion.createdAt | mtmDateTime">{{
              currentVersion.createdAt | mtmDateTime
              }}</span>
          </span>
        </button>
        <div ngbDropdownMenu class="p-1 dropdown-menu">
          <button *ngFor="let version of currentOption.simpleVersions"
            class="d-flex align-items-center  justify-content-between btn dropdown-item version-selector-dropdown"
            [disabled]="isLoading" (click)="switchVersion(version.versionNumber)">
            <span class="version">{{ 'V' + version.versionNumber }}</span>
            <span class="date" *ngIf="version.createdAt" [title]="version.createdAt | mtmDateTime">{{ version.createdAt
              |
              mtmDateTime }}</span>
          </button>
          <button class="d-flex align-items-center justify-content-center btn dropdown-item
          version-selector-dropdown btn-manage-versions" [disabled]="isLoading" (click)="showManageVersions()">
            <span class="text-uppercase">{{'versioning_edit_manageVersions' | translate}}</span>
          </button>
          <button class="d-flex align-items-center justify-content-center btn  dropdown-item
           version-selector-dropdown btn-add-version" [disabled]="isLoading" (click)="createNewVersion()"
            *ngIf="!isNewVersion">
            <span class="d-flex align-items-center justify-content-center icon-wrapper mr-2">
              <i class="fa fa-plus" aria-hidden="true"></i></span>
            <span class="text-uppercase">{{'versioning_edit_createNewVersion' | translate}}</span>
          </button>
        </div>
      </div>


    </div>

  </div>
  <div class="p-0 modal-body">

    <div class="d-flex flex-column flex-lg-row">

      <form [formGroup]="optionForm" class="d-flex flex-column option-form" (ngSubmit)="submit()">
        <div class="d-flex flex-column flex-lg-row align-items-lg-center justify-content-lg-center p-3 buttons">
          <button type="button" class="text-uppercase btn btn-cancel" (click)="cancel()">
            {{ 'cancel' | translate }}
          </button>
          <button type="submit" class="text-uppercase btn btn-save">
            {{ 'save' | translate }}
          </button>
        </div>

        <div class="d-flex flex-column p-3 fields">

          <div class="form-group">
            <label for="title">{{ 'title' | translate }}</label>
            <input type="text" class="form-control" id="title" trimOnBlur maxlength="100" formControlName="title">
          </div>

          <div class="form-group">
            <label for="description">{{ 'description' | translate }}</label>
            <textarea class="form-control" id="description" trimOnBlur maxlength="300"
              formControlName="description"></textarea>
          </div>

        </div>

        <div class="p-2 file-upload-container">
          <mtm-upload-file (files)="handleFileUpload($event)">
          </mtm-upload-file>
        </div>
      </form>


      <div class="p-3  d-flex flex-column files">
        <div class="new-files-container" *ngIf="newFiles?.length">
          <div *ngFor="let file of newFiles | newFilesFilter: versionFilterParam; index as idx"
            class="d-flex align-items-center mb-3 new-file-row">

            <span class="d-inline-flex align-items-center justify-content-center mr-2 drag-icon-container">
              <i class="fa fa-arrows" aria-hidden="true"></i>
            </span>

            <div class="d-flex flex-column flex-lg-row align-items-start align-items-lg-center new-file-container">
              <!--
              <div class="d-flex flex-column align-items-center justify-content-center spinner-container">
                <i class="fa fa-spinner fa-pulse"></i>
              </div>
              -->
              <div class="position-relative new-file-wrapper">
                <selector-newupload-files-preview-thumpnail-component [item]="file">
                </selector-newupload-files-preview-thumpnail-component>
                <div class="d-flex flex-column align-items-center justify-content-center spinner-container">
                  <i class="fa fa-spinner fa-pulse"></i>
                </div>
                <button class="text-danger d-inline-flex align-items-center justify-content-center btn btn-delete-new"
                  [disabled]="isLoading" (click)="deleteNewFile(file)">
                  <i class="fa fa-trash" aria-hidden="true"></i>
                </button>
              </div>

              <div class="d-flex flex-column flex-lg-row align-items-start align-items-lg-center">
                <span class="filename">{{ 'uploading' | translate }}</span>
              </div>
            </div>
          </div>
        </div>


        <div class="files-container">
          <div *ngFor="let file of currentFiles; index as idx" class="d-flex align-items-center mb-3 file-row"
            [draggable]="true" (dragover)="dragOver($event)" (dragstart)="dragFile($event, file)"
            (drop)="dropFile($event, file)">

            <span class="d-inline-flex align-items-center justify-content-center mr-2 drag-icon-container">
              <i class="fa fa-arrows" aria-hidden="true"></i>
            </span>

            <div draggable="false" class="d-flex flex-column flex-lg-row align-items-start align-items-lg-center">

              <div class="position-relative file-container">
                <!--
                <mtm-media-player [item]="file" [enableGeneralComments]="false"
                  [showDownloadButtonIfNotPreviewAble]="true" [hideAnnotationToolbar]="true"
                  [hideTopMediaInfo]="true"></mtm-media-player>
                -->
                <div class="asset-item" [assetPreviewThumbnailPosition]="'horizontal'" [assetPreviewDirective]="file">
                </div>

                <div class="d-flex align-items-center file-action-buttons">
                  <button class="d-inline-flex align-items-center justify-content-center mr-2 btn btn-download"
                    [disabled]="isLoading" (click)="downloadFile(file)">
                    <i class="fa fa-download" aria-hidden="true"></i>
                  </button>
                  <button class="text-danger d-inline-flex align-items-center justify-content-center btn btn-delete"
                    [disabled]="isLoading" (click)="deleteFile(file)">
                    <i class="fa fa-trash" aria-hidden="true"></i>
                  </button>
                </div>
              </div>

              <div class="d-flex flex-column ml-lg-2">
                <label for="fileName-{{idx}}">{{ 'title' | translate }}</label>
                <input [id]="'fileName'+idx" type="text" class="form-control name-input" trimOnBlur maxlength="80"
                  required [(ngModel)]="fileDisplayRecord[file.id].caption">
              </div>
            </div>


          </div>

        </div>
      </div>
    </div>
  </div>

</div>