export const environment = {

	production: true,
	isProductionSide: false,
	logging: true,

	web: {
		baseUrl: '//dev2.mtm.video',
		avatarUrl: 'https://storage.googleapis.com/mtmvideo-dev-v2/%COMPANYID%/%USERNAME%/avatar?alt=media',
		langJsonFiles: 'https://storage.googleapis.com/mtmvideo-dev-v2/language/%lang%.json',
		termsAndConditionsPdfFiles: 'https://storage.googleapis.com/mtmvideo-local/terms-conditions/'

	},

	api: {
		baseUrl: 'https://api.dev2.mtm.video',
		mockapiUrl: '//58c14598e8215412002f7cf5.mockapi.io'
	},

	jitsi: {
		baseUrl: 'jitsiv5.dev.mtm.video',
		localIframeUrl: 'assets/jitsi/index.html'
	},

	websocket: {
		chatBaseUrl: 'https://api.dev2.mtm.video/chat'
	},

	stripe: {
		publishableKey: 'pk_test_6b1YLLJGwISQPjyFWQTi6vop',
		publishableKeyUs: 'pk_test_VJEmOjZYRrqSG9e3xq8kOXOZ00Yd15UJna'
	},


	ltg: {
		luxCompanyId: '81f87c32-ed1f-45fa-accb-927fd3c42052',
		cpdCompanyId: '24325734-fdc8-434c-bb74-ccebd5ccec49'
	},

	google: {
		apiKey: 'AIzaSyD8vCL1Qyj3JAqq1j1RUD_GbXvbMvMhSnE'
	},
	aIFramingBaseAPIUrl: 'https://ai.api.mtm.video',
	aiReframingUserAccount: {
		username: 'elie.ohayon@mtm.video',
		password: 'admin123'
	},
	helpHeroId: 'cAEe2kn5fW',
	subtitleGenerationApiUrl: 'https://subtitles.dev.mtm.video/v1',
	versionCheckURL: 'https://dev2.mtm.video/version.json',
	healthCheckURL: 'https://api.dev2.mtm.video/mtm-manage/health',
	trainer:{
		url:'https://training.dev.mtm.video',
		apiurl:'https://training.api.dev.mtm.video'
	},
	captchaSiteKey: '6LfnEnsqAAAAAGQRdizpY2qt-OgSISWYqyE8PsdL'
};
