import { Routes, RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { LoginComponent } from "app/login/login.component";
import { MaintenanceComponent } from "app/maintenance/maintenance.component";
import { RequestInvitationComponent } from "app/login/request-invitation/request-invitation.component";
import { ProfileInfoComponent } from "app/on-boarding/profile-info/profile-info.component";
import { PhoneVerificationComponent } from "app/on-boarding/phone-verification/phone-verification.component";
import { ProfileAvatarComponent } from "app/on-boarding/profile-avatar/profile-avatar.component";
import { AuthGuard } from "app/shared/guards/auth.guard";
import { NoAuthGuard } from "app/shared/guards/no-auth.guard";
import { ResetPasswordComponent } from 'app/reset-password/reset-password.component';
import { RateClientComponent } from "app/on-boarding/rate-client/rate-client.component";
import { BuildRequestComponent } from "app/layouts/build-request/build-request.component";
import { SubscriptionComponent } from "app/layouts/subscription/subscription.component";
import { AuthRedirectComponent } from "app/login/auth-redirect/auth-redirect.component";
import { OneShotGuard } from "app/shared/guards/one-shot.guard";
import { RegistrationLandingComponent } from "app/on-boarding/registration-landing/registration-landing.component";
import { OfflineComponent } from "../../maintenance/offline/offline.component";


export const routes: Routes = [
	{
		path: '',
		redirectTo: 'login',
		pathMatch: 'full'
	},
	{
		path: 'maintenance',
		component: MaintenanceComponent,
		pathMatch: 'full',
		canActivate: [NoAuthGuard]
	},
	{
		path: 'offline',
		component: OfflineComponent,
		pathMatch: 'full',
	},
	{
		path: 'login',
		component: LoginComponent,
		pathMatch: 'full',
		canActivate: [NoAuthGuard]
	},
	{
		path: 'login/:lang',
		component: LoginComponent,
		pathMatch: 'full',
		canActivate: [NoAuthGuard]
	},
	{
		path: 'login/:projectId/:projectType/:companyType',
		component: LoginComponent,
		pathMatch: 'full',
		canActivate: [NoAuthGuard]
	},
	{
		path: 'login/fromOneShot/:projectId/:projectType/:companyType/:redirectLoginFormOneShot',
		component: LoginComponent,
		pathMatch: 'full',
		canActivate: [NoAuthGuard]
	},
	{
		path: 'rateClient',
		component: RateClientComponent,
	},
	{
		path: 'user-password/reset',
		component: ResetPasswordComponent,
		canActivate: [NoAuthGuard]
	},
	{
		path: 'request-invitation',
		component: RequestInvitationComponent,
		canActivate: [NoAuthGuard]
	},
	{
		path: 'on-boarding/profile-info',
		component: ProfileInfoComponent,
		canActivate: [NoAuthGuard]
	},
	{
		path: 'on-boarding/landing',
		component: RegistrationLandingComponent,
		canActivate: [NoAuthGuard]
	},
	{
		path: 'on-boarding/phone-verification',
		component: PhoneVerificationComponent,
		canActivate: [AuthGuard]
	},
	{
		path: 'on-boarding/profile-avatar',
		component: ProfileAvatarComponent,
		canActivate: [AuthGuard]
	},
	{
		path: 'auth/:service',
		component: AuthRedirectComponent,
		canActivate: [NoAuthGuard]
	},
	{
		path: 'marketplace/build-request/:projectType/:companyType',
		component: BuildRequestComponent,
		canActivate: [OneShotGuard],
	},
	{
		path: 'marketplace/build-request/:projectType/:companyType/:lang',
		component: BuildRequestComponent,
		canActivate: [NoAuthGuard],
	},
	{
		path: 'subscription',
		component: SubscriptionComponent,
		canActivate: [NoAuthGuard],
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class EntryRoutingModule {
}
