import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { ErrorMessageService } from './../../shared/services/error-message.service';
import { UserService } from './../../shared/services/user.service';
import { NotificationService } from './../../shared/services/notification.service';
import { TranslatePipe } from "../../shared/pipes/translate.pipe";
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuthService } from 'app/shared/services/auth.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EmitterService } from 'app/shared/services/emitter.service';
import { BroadcastService } from 'app/shared/services/broadcast.service';
import { Router } from '@angular/router';

@Component({
	selector: 'mtm-account-change-password',
	templateUrl: 'account-change-password.component.html',
	styleUrls: ['./account-change-password.component.scss', '../custom.scss']
})
export class AccountChangePasswordComponent implements OnInit, OnDestroy {
	@Input() asModal: boolean = false;

	strongPassword: boolean = false;
	formGroup: UntypedFormGroup;
	submitted: boolean = false;
	formErrors: any = null;
	isPasswordValid: boolean = false;
	ngUnsubscribe = new Subject();
	authUser: any;

	minPassLength: any;

	constructor(private fb: UntypedFormBuilder,
		private activeModal: NgbActiveModal,
		private broadcastService: BroadcastService,
		public userService: UserService,
		private authService: AuthService,
		private router: Router,
		private notificationService: NotificationService,
		private translatePipe: TranslatePipe) { }

	@HostListener('window:beforeunload')
	doSomething() {
		if (this.asModal) {
			this.authService.logout();
			EmitterService.get('user.logout').emit();
			this.broadcastService.send({ action: 'user.logout' });
		}
	}

	ngOnInit() {
		this.authUser = this.authService.getAuthUser();
		// Load form group
		this.loadFormGroup();
	}

	ngOnDestroy(): void {
		if (this.asModal) {
			this.authService.logout();
			EmitterService.get('user.logout').emit();
			this.broadcastService.send({ action: 'user.logout' });
		}
		this.ngUnsubscribe.next(undefined);
		this.ngUnsubscribe.complete();
	}

	/**
	 * On change password submit
	 */
	onSubmit() {
		if (!this.isPasswordValid) {
			return;
		}

		this.submitted = true;

		// Validate both password
		this.validatePasswordMatch();

		if (this.formGroup.valid) {

			// Call API to change password
			this.userService.changePassword(this.formGroup.controls['currentPassword'].value,
				this.formGroup.controls['newPassword'].value,
				this.formGroup.controls['reNewPassword'].value).pipe(
					takeUntil(this.ngUnsubscribe)
				).subscribe(
					(data: any) => {
						this.notificationService.open({
							title: 'Change password',
							description: this.translatePipe.transform('overlayChangeSave'),
							confirmBtn: 'Accept'
						}).pipe(
							takeUntil(this.ngUnsubscribe)
						).subscribe(res => {
							if (this.asModal) {
								setTimeout(() => {
									this.activeModal.close();
								});
							}
						});

					},
					(err: any) => {
						console.error('Change password error', err);

						this.notificationService.open({
							title: 'Change password',
							description: `Error trying to change password.\n${err.errorCode}\nPlease, try again.`,
							confirmBtn: 'Accept'
						});
					});
		}
	}


	// **********************************************************************

	/**
	 * Load form group form
	 */
	private loadFormGroup() {

		// Build FormGroup
		this.formGroup = this.fb.group({
			currentPassword: ['', [Validators.required]],
			newPassword: ['', [Validators.required, Validators.minLength(6)]],
			reNewPassword: ['']
		});

		// Subscribe to changes to get validation errors
		this.formGroup.valueChanges.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(data => this.onValueChanged(data));

		// Just run an empty validation
		this.onValueChanged();
	}


	/**
	 * Get validation error messages from the form group
	 * @param data
	 */
	private onValueChanged(data?) {
		if (this.formGroup)
			this.formErrors = ErrorMessageService.getFormErrors(this.formGroup);
	}




	/**
	 * Check if both passwords match
	 */
	private validatePasswordMatch() {
		let newPassword = this.formGroup.controls['newPassword'].value;
		let reNewPassword = this.formGroup.controls['reNewPassword'].value;

		if (newPassword == '') {
			this.formGroup.controls['reNewPassword'].setErrors({
				error: true
			});
		}
		else if (newPassword !== reNewPassword) {
			this.formGroup.controls['reNewPassword'].setErrors({
				mismatchedPasswords: true
			});
		}
		else {
			this.formGroup.controls['reNewPassword'].setErrors(null);
		}

	}

	onPasswordStrengthChanged(event: boolean) {
		this.strongPassword = event;
	}

	passwordValidated(val) {
		this.isPasswordValid = val;
	}

	closeModal() {
		this.activeModal.close();
	}

}
