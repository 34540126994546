import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ASSET_BET_LIST, ASSET_CATEGORIES, ASSET_FOCUS, ASSET_FORMAT, ASSET_FUNNEL, ASSET_LENGTH, ASSET_TYPE } from '../asset.model';
import { AuthService } from 'app/shared/services/auth.service';

@Component({
    selector: 'mtm-edit-asset-modal',
    templateUrl: './edit-asset-modal.component.html',
    styleUrls: ['./edit-asset-modal.component.scss']
})
export class MtmEditAssetModalComponent implements OnInit, OnChanges, OnDestroy {
    @Input() funnel: any;
    @Input() asset: any;

    betList = ASSET_BET_LIST;

    availableLength = ASSET_LENGTH;

    availableFormat = ASSET_FORMAT;

    availableCategories = ASSET_CATEGORIES;

    selectedAssetType: any = null;

    funnels: any = ASSET_FUNNEL;

    focuses: any[] = ASSET_FOCUS;

    focus: any = null;

    types: any = ASSET_TYPE;

    type: any = null;

    details: string;

    detail: any = null;

    isNew: boolean = true;

    selectedCategories = [];

    selectedBets = [];

    selectedLengths = [];

    selectedFormats = [];

    submitted: boolean = false;

    disableFunnelSelection: boolean = false;

    otherBet: string;

    constructor(private activeModal: NgbActiveModal, private authService: AuthService) { }

    ngOnInit() {
        if (this.asset?.id) {
            this.isNew = false;
            this.mapValues()
        }
        if (this.funnel) {
            this.disableFunnelSelection = true;
        }
    }

    ngOnChanges(changes: SimpleChanges) { }

    ngOnDestroy() { }

    mapValues() {
        this.type = this.asset.type;
        this.focus = this.asset.focus;
        this.detail = this.asset.detail;
        const bets = JSON.parse(JSON.stringify(this.asset.bets));
        this.selectedBets = bets.filter(bet => this.betList.indexOf(bet) != -1);
        this.otherBet = bets.find(bet => this.betList.indexOf(bet) == -1);
        this.selectedFormats = JSON.parse(JSON.stringify(this.asset.format));
        this.selectedLengths = JSON.parse(JSON.stringify(this.asset.length));
        this.selectedCategories = JSON.parse(JSON.stringify(this.asset.categories));
    }

    onFunnelSelect(val) {
        this.funnel = val;
    }

    onFocusSelect(val) {
        this.focus = val;
    }

    onTypeSelect(val) {
        this.type = val;
    }

    onDetailSelect(val) {
        this.detail = val;
    }

    selectAsset(asset) {
        this.selectedAssetType = asset;
    }

    selectCategory(category) {
        const idx = this.selectedCategories.indexOf(category);
        if (idx < 0) {
            this.selectedCategories.push(category)
        } else {
            this.selectedCategories = this.selectedCategories.filter(l => l != category);
        }
    }

    selectBet(bet) {
        const idx = this.selectedBets.indexOf(bet);
        if (idx < 0) {
            this.selectedBets.push(bet)
        } else {
            this.selectedBets = this.selectedBets.filter(l => l != bet);
        }
    }

    selectLength(length) {
        const idx = this.selectedLengths.indexOf(length);
        if (idx < 0) {
            this.selectedLengths.push(length)
        } else {
            this.selectedLengths = this.selectedLengths.filter(l => l != length);
        }
    }

    selectFormat(format) {
        const idx = this.selectedFormats.indexOf(format);
        if (idx < 0) {
            this.selectedFormats.push(format)
        } else {
            this.selectedFormats = this.selectedFormats.filter(l => l != format);
        }
    }

    save() {
        if (!this.funnel || !this.type) {
            this.submitted = true;
            return;
        }
        const authUser = this.authService.getAuthUser();
        this.activeModal.close({
            funnel: this.funnel,
            focus: this.focus,
            type: this.type,
            detail: this.detail,
            bets: !this.otherBet ? this.selectedBets : [...this.selectedBets, this.otherBet],
            format: this.selectedFormats,
            length: this.selectedLengths,
            categories: this.selectedCategories,
            companyId: authUser.companyId
        });
    }

    cancel() {
        this.activeModal.close();
    }
}