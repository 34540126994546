import * as _ from 'lodash';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { onLoadProject, ProjectService } from "app/shared/services/project.service";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from 'moment';
import 'moment-timezone';
import { LoadingBase } from 'app/shared/bases/loading.base';
import { Authority, PermissionService } from "../../../../shared/services/permissions.service";
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuthService } from 'app/shared/services/auth.service';
import { ProjectV2ServiceService } from 'app/shared/services/project-v2-service.service';
import { TimelineService } from 'app/shared/services/timeline.service';
import { CryptoService } from 'app/shared/services/crypto.service';
import { MtmBreadcrumbService } from 'app/shared/services/mtm-breadcrumb.service';
import { WorkspaceService } from 'app/shared/services/workspace.service';
import { environment } from 'environments/environment';
@Component({
	selector: 'mtm-project-assets',
	templateUrl: './project-assets.component.html',
	styleUrls: ['./project-assets.component.scss']
})

export class ProjectAssetsComponent extends LoadingBase implements OnInit, OnDestroy {
	hrFiles: any;
	ngUnsubscribe = new Subject();
	activeRequest: any = 0;
	workspaceId: string;
	workspaceName: string;
	campaignId: string;
	project: any;
	driveCampaign: any;

    isCpd: boolean = false;
    authUser: any;
	
	constructor(
		public service: ProjectService,
		private permissionService: PermissionService,
		private authService: AuthService,
		private projectV2ServiceService: ProjectV2ServiceService,
		private timelineService: TimelineService,
		private cryptoService: CryptoService,
		private breadcrumbService: MtmBreadcrumbService,
		private workspaceService: WorkspaceService,
		private router: Router
	) {
		super();
		onLoadProject.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe((project) => {
			this.project = project;
			this.loadFiles();
			this.retrieveExistingWorkspaceName();
		})
	}

	ngOnDestroy(): void {
		this.breadcrumbService.clearLinks();
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	async getFiles(projectId: any, sectionId: any) {
		let response = await this.service.getFilesBySectionId(projectId, sectionId);
		this.activeRequest--;
		return _.get((response as any).children, '[0].children', []);
	}

	ngOnInit() {
        this.authUser = this.authService.getAuthUser();
        this.isCpd = this.authUser.companyId == environment.ltg.cpdCompanyId;
		if (this.service.project.id) {
			this.project = this.service.project;
			// Check if user has PM access
			if (!this.permissionService.hasAuthority(Authority.U)) {
				this.router.navigate(['projects', this.service.project.id]);
			}
			this.loadFiles();
			this.retrieveExistingWorkspaceName();
		}
	}

	ngAfterViewInit() {
		this.initLoadingParameters(undefined, undefined, ['w-100']);
		this.retrieveExistingWorkspaceName();
	}

	loadFiles() {
		let sections = _.uniqBy(this.service.sections, 'id') || [];
		this.hrFiles = {
			id: this.service.project.projectId,
			name: this.service.project.name,
			files: [],
			children: []
		}
		setTimeout(async () => {
			for (let i = 0; i < sections.length; i++) {
				if (sections[i].id) {
					this.activeRequest++;
					this.hrFiles.children.push({
						id: sections[i].id,
						name: sections[i].name,
						files: [],
						children: await this.getFiles(this.service.project.id, sections[i].id)
					});
				}
			}
		}, 0);
	}

	private async handleCampaignEvents(): Promise<void> {
		if (this.project?.campaignId) {
			this.driveCampaign = await this.timelineService.getTimeLine(this.project.workspaceId, this.project.campaignId);
		}
	}
	/**
	 * When save project, redirect to project dashboard page
	 */
	onSaveProject(): void {
		this.router.navigate(['projects/' + this.service.project.id + '/dashboard']);
	}


	getUploadedDate(time: number): Date {
		return moment(time).toDate();
	}

	returnToProject() {
		this.router.navigate(['projects', this.service.project.id]);
	}

	retrieveExistingWorkspaceName() {
		if (this.project && this.project.company && this.project.workspaceId) {
			this.workspaceService.getWorkspaces(this.project.company.id)
				.subscribe(async workspaces => {
					const existing = workspaces.find(w => w.id == this.project.workspaceId);
					const hasLtgPermission = this.authService.checkLtgPermission();
					if (!existing && !hasLtgPermission) {
						this.router.navigate(['/projects']);
						return;
					}
					if (existing && existing.name) {
						this.workspaceName = existing.name;
					}
					await this.handleCampaignEvents();
					this.setBreadcrumb();
				});
		}
	}

	setBreadcrumb() {
		if (this.project.campaignId) {
			if (!this.driveCampaign)  this.handleCampaignEvents();
		}

		let links = [];
		const hasLtgPermission = this.authService.checkLtgPermission();
		if (hasLtgPermission) {
			const brand = this.authService.getMyBrand();
			if (brand) {
				links.push({
					label: brand.name
				})
			}
			if (this.project) {
				links.push({
					label: 'Workspaces',
					url: ['/workspaces']
				});
				links.push({
					label: this.workspaceName,
					url: ['/workspaces', 'campaign-drive'],
					queryParams: {
						wid: this.cryptoService.encryptString(this.project?.workspaceId)
					}
				});

				if (this.project && this.project.campaignId && this.project.isDriveCampaign) {
					links.push({
						label: this.driveCampaign?.name,
						url: ['/workspaces', 'campaigndrive', 'dashboard'],
						queryParams: {
							wid: this.cryptoService.encryptString(this.workspaceId),
							dc: this.cryptoService.encryptString(this.project?.campaignId)
						}
					})
				}

			} else {
				links.push({
					label: this.isCpd ? 'Campaigns & Projects' : 'Drive Campaigns & Projects',
					url: ['/workspaces', 'campaign-drive'],
					queryParams: {
						wid: this.cryptoService.encryptString(this.workspaceId)
					}
				});
			}

		} else {
			const activeWorkspace = this.workspaceService.getActiveWorkspace();
			links.push({
				label: 'Workspaces',
				url: ['/workspaces']
			});
			links.push({
				label: activeWorkspace.name,
				url: ['/projects']
			});
		}
		links.push({
			label: !this.project?.id ? 'teamFiles' : this.project.name,
			url: ['projects', 'edit', this.project.id, 'company'],
			queryParams: {
				workspaceId: this.project.workspaceId,
				dcId: this.project.campaignId,
				isdc: true
			}
		})
		if (this.router.url.startsWith('/projects/edit')) {
			links.push({
				label: 'Setting',
			});
		}
		links.push({
			label: 'teamFiles'
		})
		this.breadcrumbService.setLinks(links);
	}
}
