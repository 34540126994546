import { Injectable } from '@angular/core';
import { ApiService } from "app/shared/services/api.service";
import { AuthService } from "app/shared/services/auth.service";
import { Subject, Observable } from "rxjs";
import { BatchEditParams } from '../interfaces';

@Injectable({
	providedIn: 'root'
})
export class ActorService {
	private tempFiles: any[] = null;

	constructor(private api: ApiService, public auth: AuthService) { }

	/**
	 * Retrieve subsection's Actors
	 * @param projectId
	 * @param sectionId
	 * @param actorId
	 */
	public getActors(projectId: string, sectionId: string, subsectionId: string): Observable<any> {
		return this.api.setProfileAndImagesProbForItems(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-actors`);
	}

	public getActorsSimple(projectId: string, sectionId: string, subsectionId: string): Observable<any> {
		return this.api.httpGet(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-actors-simple`);
	}

	public getActorSimple(projectId: string, sectionId: string, subsectionId: string, actorId: string): Observable<any> {
		return this.api.httpGet(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-actors-simple/${actorId}`);
	}


	/**
	 * Create a new Actor
	 * @param projectId
	 * @param sectionId
	 * @param subsectionId
	 * @param actor
	 */
	public saveActor(projectId: string, sectionId: string, subsectionId: string, actor: any): Observable<any> {
		return this.api.httpPost(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-actors?topic=null&topicId=null`, actor);
	}

	public renameActor(projectId: string, sectionId: string, subsectionId: string, actorId: string, newName: string): Observable<any> {
		return this.api.httpPut(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-actors/${actorId}?newName=${newName}`, {});
	}


	/**
	 * Delete a Actor given by ID
	 * @param projectId
	 * @param sectionId
	 * @param subsectionId
	 * @param actorId
	 */
	public deleteActor(projectId: string, sectionId: string, subsectionId: string, actorId: string): Observable<any> {
		return this.api.httpDelete(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-actors/${actorId}`);
	}


	/**
	 * Retrieve Actor by ID
	 * @param projectId
	 * @param sectionId
	 * @param subsectionId
	 * @param actorId
	 */
	public getActor(projectId: string, sectionId: string, subsectionId: string, actorId: string): Observable<any> {
		return this.api.httpGet(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-actors/${actorId}`);
	}


	/**
	 * Retrieve actor's files
	 * @param projectId
	 * @param sectionId
	 * @param subsectionId
	 * @param actorId
	 */
	public getActorFiles(projectId: string, sectionId: string, subsectionId: string, actorId: string): Observable<any> {
		return this.api.httpGet(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/items/${actorId}/files`);
	}


	/**
	 * Return eye color options
	 */
	getEyeColors() {
		return [
			{ id: 'brown', name: 'Brown', img: '/assets/img/eyes/brownEYE.png' },
			{ id: 'hazel', name: 'Hazel', img: '/assets/img/eyes/hazelEYE.jpeg' },
			{ id: 'blue', name: 'Blue', img: '/assets/img/eyes/blueEYE.jpg' },
			{ id: 'green', name: 'Green', img: '/assets/img/eyes/greenEYE.png' },
			{ id: 'silver', name: 'Silver', img: '/assets/img/eyes/silverEYE.png' },
			{ id: 'amber', name: 'Amber', img: '/assets/img/eyes/amberEYE.jpg' },
		]
	}


	/**
	 * Return hair color options
	 */
	getHairColors() {
		return [
			{ id: 'sunflower_blonde', name: 'Sunflower Blonde', colorCode: '#ebc693' },
			{ id: 'pure_diamond', name: 'Pure-Diamond', colorCode: '#bbb09a' },
			{ id: 'caramel', name: 'Caramel', colorCode: '#8f6756' },
			{ id: 'hot_toffee', name: 'Hot-Toffee', colorCode: '#503627' },
			{ id: 'sparkling_amber', name: 'Sparkling Amber', colorCode: '#985943' },
			{ id: 'havana_brown', name: 'Havana Brown', colorCode: '#5e3e38' },
			{ id: 'espresso', name: 'Espresso', colorCode: '#452a20' },
			{ id: 'french_roast', name: 'French Roast', colorCode: '#6f5f6a' },
			{ id: 'copper_shimmer', name: 'Copper Shimmer', colorCode: '#944b29' },
			{ id: 'ruby_fusion', name: 'Ruby Fusion', colorCode: '#83474d' },
			{ id: 'crushed_garnet', name: 'Crushed Garnet', colorCode: '#331a15' },
			{ id: 'blowout_burgundy', name: 'Blowout Burgundy', colorCode: '#431f33' },
			{ id: 'chocolate_cherry', name: 'Chocolate Cherry', colorCode: '#452d31' },
			{ id: 'midnight_ruby', name: 'Midnight Ruby', colorCode: '#231620' },
			{ id: 'leather_black', name: 'Leather Black', colorCode: '#4a474e' }
		]
	}

	public storeTempFiles(data: any[]) {
		this.tempFiles = data;
	}

}
