import * as moment from 'moment';
import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { TranslateService } from "app/shared/services/translate.service";
import { AuthService } from 'app/shared/services/auth.service';


@Pipe({
	name: 'commentDate',
	pure: false
})
@Injectable({
	providedIn: 'root'
})
export class CommentDatePipe implements PipeTransform {
	constructor(private authService: AuthService, private translateService: TranslateService) { }

	transform(value: string) {
		const { timeFormat = '12hs' } = this.authService.getAuthUserSettings();
		let lang = this.translateService.getLang();
		let dateFormat = this.authService.getUserSettings('dateFormat');
		if (!dateFormat) {
			dateFormat = 'DD/MM/YYYY';
		}
		/*
		let formattedTime = 'hh:mm a';
		if (timeFormat === '24hs') {
			formattedTime = 'HH:mm';
		}
		if (!dateFormat)
			dateFormat = 'YYYY-MM-DD';
		let isSameDay = moment().isSame(moment(value), 'day');
		if (lang === 'fr')
			return moment(value).format(isSameDay ? `[Aujourd\'hui à] ${formattedTime}` : `${dateFormat} [à] ${formattedTime}`);
		else
			return moment(value).format(isSameDay ? `[Today at] ${formattedTime}` : `${dateFormat} [at]  ${formattedTime}`);
		*/
		return moment(value).format(dateFormat);
	}
}
