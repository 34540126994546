import { Injectable } from '@angular/core';
import { ApiService } from "app/shared/services/api.service";
import { AuthService } from "app/shared/services/auth.service";
import { Subject, Observable } from "rxjs";

@Injectable({
	providedIn: 'root'
})
export class CastingService {

	constructor(private api: ApiService, public auth: AuthService) { }

	public getRoleList(projectId: string, sectionId: string, subsectionId: string): Observable<any> {
		let endpoint = `/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/castings`;
		return this.api.httpGet(endpoint);
	}

	public renameCasting(projectId: string, sectionId: string, subsectionId: string, castingId: string, newName: string): Observable<any> {
		return this.api.httpPut(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/castings/${castingId}?newName=${newName}`, {});
	}

	/**
	 * Retrieve subsection's Castings
	 * @param projectId
	 * @param sectionId
	 */
	public getCastings(projectId: string, sectionId: string, subsectionId: string): Observable<any> {
		let endpoint = `/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/castings`;
		return this.api.setProfileAndImagesProbForItems(endpoint);
	}


	/**
	 * Create a new Casting
	 * @param projectId
	 * @param sectionId
	 * @param subsectionId
	 * @param casting
	 */
	public saveCasting(projectId: string, sectionId: string, subsectionId: string, casting: any): Observable<any> {
		return this.api.httpPost(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/castings`, casting);
	}


	/**
	 * Delete a Casting given by ID
	 * @param projectId
	 * @param sectionId
	 * @param subsectionId
	 * @param castingId
	 */
	public deleteCasting(projectId: string, sectionId: string, subsectionId: string, castingId: string): Observable<any> {
		return this.api.httpDelete(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/castings/${castingId}`);
	}


	/**
	 * Retrieve Casting by ID
	 * @param projectId
	 * @param sectionId
	 * @param subsectionId
	 * @param castingId
	 */
	public getCasting(projectId: string, sectionId: string, subsectionId: string, castingId: string): Observable<any> {
		return this.api.httpGet(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/castings/${castingId}`);
	}


	/**
	 * Save list of actors in a Casting
	 * @param projectId
	 * @param sectionId
	 * @param subsectionId
	 * @param castingId
	 * @param actors
	 */
	public saveActors(projectId: string, sectionId: string, subsectionId: string, castingId: string, actors: any[]): Observable<any> {
		return this.api.httpPut(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/castings/${castingId}/actors`, actors);
	}

}
