import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { BatchEditParams } from '../interfaces';

import { ApiService } from './api.service';
import { onLoadProject, ProjectService } from './project.service';



@Injectable({
	providedIn: 'root'
})
export class ShootAndTakeService {

	private shotSelected: any;
	private tempFiles: any = [];

	constructor(private serviceApi: ApiService,
		private serviceProject: ProjectService) { }


	public setShotSelected(shotSelected: any) {
		this.shotSelected = shotSelected;
	}

	public getShotSelected() {
		return this.shotSelected;
	}

	public getpreProdShootingBoardId(projectId): Observable<any> {
		return new Observable(observe => {

			let findSubSectionId = () => {
				try {
					let sectionIndex = this.serviceProject.sections.findIndex(k => k.section == "PRE_PRODUCTION");
					if (sectionIndex < 0) {
						observe.error();
						return;
					}

					let index = this.serviceProject.sections[sectionIndex].projectSubSections.findIndex(k => k.subSection == "SHOOTING_BOARD");
					if (index < 0) {
						observe.error();
						return;
					}

					observe.next(this.serviceProject.sections[sectionIndex].projectSubSections[index]);
					observe.complete();
				} catch (err) {
					console.log(err);
					observe.error();
				}
			}

			try {
				if (this.serviceProject.projectOnloaded)
					findSubSectionId();
				else {
					onLoadProject.subscribe(() => {
						findSubSectionId();
					});
				}
			} catch (error) {
				observe.error();
			}
		});
	}

	public getAllShotsForShooting(projectId, sectionId, subsectionId): Observable<any> {
		let endpoint = `/api/projects/${projectId}/sections/${sectionId}/subsections/${projectId}/shootingShots/scene/${subsectionId}`;
		return this.serviceApi.setProfileAndImagesProbForItems(endpoint);
	}

	public getShotforShooting(projectId, sectionId, subsectionId, id): Observable<any> {
		return this.serviceApi.httpGet(`/api/projects/${projectId}/sections/${sectionId}/subsections/${projectId}/scene/${subsectionId}/shootingShot/${id}`)
	}

	public saveShotforShooting(projectId, sectionId, subsectionId, data): Observable<any> {
		return this.serviceApi.httpPost(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/shootingShot`, data);
	}

	public updateShotforShooting(projectId, sectionId, subsectionId, data): Observable<any> {
		return this.serviceApi.httpPost(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/shootingShot`, data);
	}

	public deleteShot(projectId: string, sectionId: string, subsectionId: string, shotId: string): Observable<any> {
		return this.serviceApi.httpDelete(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/shootingShot/${shotId}`);
	}

	public renameShootingShot(projectId: string, sectionId: string, subsectionId: string, shootingShotId: string, newName: string): Observable<any> {
		return this.serviceApi.httpPut(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/shootingShot/${shootingShotId}?newName=${newName}`, {});
	}

	public getShotTakes(projectId: string, sectionId: string, subsectionId: string): Observable<any> {
		let endpoint = `/api/projects/${projectId}/sections/${sectionId}/subsections/${projectId}/shootingShots/scene/${subsectionId}`;
		return this.serviceApi.setProfileAndImagesProbForItems(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-shotTakes`);
	}

	public renameShotTakes(projectId: string, sectionId: string, subsectionId: string, shotTakeId: string, newName: string): Observable<any> {
		return this.serviceApi.httpPut(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-shotTakes/${shotTakeId}?newName=${newName}`, {});
	}

	public getShotTakesForShotId(projectId: string, sectionId: string, subsectionId: string, shotId: string): Observable<any> {
		return this.serviceApi.setProfileAndImagesProbForItems(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/shotTakesForShotId/${shotId}`);
	}

	public saveShotTake(projectId: string, sectionId: string, subsectionId: string, shotTake: any): Observable<any> {
		return this.serviceApi.httpPost(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-shotTakes?topic=null&topicId=null`, shotTake);
	}

	public deleteShotTake(projectId: string, sectionId: string, subsectionId: string, shotTakeId: string): Observable<any> {
		return this.serviceApi.httpDelete(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-shotTakes/${shotTakeId}`);
	}

	public getShotTake(projectId: string, sectionId: string, subsectionId: string, shotTakeId: string): Observable<any> {
		return this.serviceApi.httpGet(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-shotTakes/${shotTakeId}`);
	}

	public getShotTakeSimple(projectId: string, sectionId: string, subsectionId: string, shotTakeId: string): Observable<any> {
		return this.serviceApi.httpGet(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-shotTakes-simple/${shotTakeId}`);
	}

	public storeTempFiles(data: any[]) {
		this.tempFiles = data;
	}

}
