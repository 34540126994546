import {
  SURLFUManagerService, MTMCustomFile, TransferType, FileTransferService,
  DirectUploadRequest, SignedURLUploadRequest, TransferMonitorService, TransferStatus
} from 'app/shared/services/signed-url';
import { Subject } from 'rxjs';
import { IHaveVersions } from "../interfaces";
import { HelperService } from "../services/helper.service";
import { AuthService } from "../services/auth.service";

export interface SignedURLUploadInfo {
  preUploadPathGetter?: () => string;
  resumablePreUploadPathGetter?: () => string;
  postUploadPathGetter?: () => string;
  uploadPathGetter?: () => string; //upload path will still need to be added with the (pre upload / post upload) slug
  labelGetter?: () => string;
  entityGetter?: () => any; //refers to the option which contains this uploaded file
  projectIdGetter?: () => string;
  postUploadParamsGetter?: () => string[];
  preUploadSlug?: string;
  resumablePreUploadSlug?: string;
  postUploadSlug?: string;
  parentUrlGetter?: () => string;
  postUploadAttributes?: () => any;
  hideProgress?: boolean;
  previewUrlGetter?: (value: any) => string;
}

export interface VersioningUploadInfo {
  hasVersions?: boolean;
  currentVersionGetter: () => number;
}

type SignedURLOptions = (param: SignedURL_FU_Base) => void;

export function WithSignedURLUploadInfo(uploadInfo: SignedURLUploadInfo): SignedURLOptions {
  return (base: SignedURL_FU_Base) => {
    if (uploadInfo.uploadPathGetter) {
      base.defaultUploadPathGetter = uploadInfo.uploadPathGetter;
    }
    if (uploadInfo.preUploadPathGetter) {
      base.preUploadPathGetter = uploadInfo.preUploadPathGetter;
    }
    if (uploadInfo.resumablePreUploadPathGetter) {
      base.resumablePreUploadPathGetter = uploadInfo.resumablePreUploadPathGetter;
    }
    if (uploadInfo.postUploadPathGetter) {
      base.postUploadPathGetter = uploadInfo.postUploadPathGetter;
    }
    if (uploadInfo.labelGetter) {
      base.labelGetter = uploadInfo.labelGetter;
    }
    if (uploadInfo.entityGetter) {
      base.entityGetter = uploadInfo.entityGetter;
    }
    if (uploadInfo.projectIdGetter) {
      base.projectIdGetter = uploadInfo.projectIdGetter;
    }
    if (uploadInfo.postUploadParamsGetter) {
      base.postUploadParamsGetter = uploadInfo.postUploadParamsGetter;
    }
    if (uploadInfo.preUploadSlug) {
      base.preUploadSlug = uploadInfo.preUploadSlug;
    }

    if (uploadInfo.resumablePreUploadSlug) {
      base.resumablePreUploadSlug = uploadInfo.resumablePreUploadSlug;
    }

    if (uploadInfo.postUploadSlug) {
      base.postUploadSlug = uploadInfo.postUploadSlug;
    }
    if (uploadInfo.parentUrlGetter) {
      base.parentUrlGetter = uploadInfo.parentUrlGetter;
    }
    if (uploadInfo.postUploadAttributes) {
      base.postUploadAttributes = uploadInfo.postUploadAttributes;
    }
    if (uploadInfo.hideProgress) {
      base.hideProgress = uploadInfo.hideProgress;
    }

    if (uploadInfo.previewUrlGetter) {
      base.previewUrlGetter = uploadInfo.previewUrlGetter;
    }
  }
}

export function WithVersioningUploadInfo(uploadInfo: VersioningUploadInfo): SignedURLOptions {
  return (base: SignedURL_FU_Base) => {
    base.hasVersions = uploadInfo.hasVersions;
    if (uploadInfo.currentVersionGetter) {
      base.currentVersionGetter = uploadInfo.currentVersionGetter;
    }
  }
}

export function WithVersioningUploadEnabled(): SignedURLOptions {
  return (base: SignedURL_FU_Base) => {
    base.hasVersions = true;
    const page = (base as any) as IHaveVersions;
    if (page) {
      base.currentVersionGetter = () => HelperService.getCurrentPageVersion(page);
      base.previewUrlGetter = (file) => HelperService.getOptionPreviewUrl(page, file);
    }

  }
}


export function WithUploadBaseDependencies(authService: AuthService, transferService: FileTransferService): SignedURLOptions {
  return (base: SignedURL_FU_Base) => {
    base.transferService = transferService;
    base.authService = authService;
  }
}

const emptyStringGetter = () => '';
const nullGetter = () => null;

export class SignedURL_FU_Base {
  public files: any[] = [];
  public newFiles: MTMCustomFile[] = [];
  preUploadPathGetter: () => string = emptyStringGetter;
  resumablePreUploadPathGetter: () => string = emptyStringGetter;
  postUploadPathGetter: () => string = emptyStringGetter;
  defaultUploadPathGetter: () => string = emptyStringGetter;
  preUploadSlug: string = 'preUploadFiles';
  resumablePreUploadSlug: string = 'preUploadFilesVR';
  postUploadSlug: string = 'postUploadFiles';
  labelGetter: () => string = emptyStringGetter;
  entityGetter: () => any = nullGetter; //refers to the entity associated with this uploaded file
  projectIdGetter: () => string = emptyStringGetter;
  postUploadParamsGetter: () => string[] = () => [];
  parentUrlGetter: () => string = emptyStringGetter; //refers to the url of the entity associated with this uploaded file
  previewUrlGetter: (value: any) => string = null;
  hasVersions: boolean = false;
  currentVersionGetter: () => number = () => 1;
  transferService: FileTransferService = null;
  authService: AuthService = null;
  postUploadAttributes: () => any;
  hideProgress: boolean = false;
  constructor(...options: SignedURLOptions[]) {
    if (options) {
      options.forEach(option => option(this));
    }
  }

  protected getPreUploadURL() {
    let isResumable = this.isResumableUploadActivated();
    let url = !isResumable ? this.preUploadPathGetter() : this.resumablePreUploadPathGetter();
    let preUploadSlug = !isResumable ? this.preUploadSlug : this.resumablePreUploadSlug;

    if (!url) {
      let defaultPath = this.defaultUploadPathGetter();
      if (defaultPath) {
        url = !defaultPath.endsWith('/') ? defaultPath + '/' : defaultPath;
        url += preUploadSlug;
      }
    }

    if (this.hasVersions) {
      const currentVersion = this.currentVersionGetter();
      url += `?versionNumber=${currentVersion}`;
    }

    return url;
  }

  protected getParentId(): any {
    return null;
  }

  protected getPostUploadURL() {
    let url = this.postUploadPathGetter();
    let slugParamsArray = this.postUploadParamsGetter();

    if (!url) {
      let defaultPath = this.defaultUploadPathGetter();
      if (defaultPath) {
        url = !defaultPath.endsWith('/') ? defaultPath + '/' : defaultPath;
        url += this.postUploadSlug;
      }
    }

    //use post uploads endpoint without subsection part name for now
    /*
    let slugParam = '';
    if (slugParamsArray?.length) {
      slugParam = slugParamsArray.map(e => e = e.replace(/,/g, "").replace(/\//g, '')).join('/').trimEnd();
      if(slugParam){
        url += `/${slugParam}`;
      }
    }
    */
    if (this.hasVersions) {
      url += url.includes('?') ? '&' : '?';
      const currentVersion = this.currentVersionGetter();
      url += `versionNumber=${currentVersion}`;
    }

    return url;
  }

  private getLabel(): string {
    return this.labelGetter();
  }

  private getCard(): any {
    return this.entityGetter();
  }

  private getProjectId(): string {
    return this.projectIdGetter();
  }

  public syncBetweenPageAndUploadingFiles() {
    this.newFiles = SURLFUManagerService.syncBetweenPageAndService(this.files, this.getPreUploadURL());
  }

  //make sure preUploadUrl is properly set before calling this
  monitorFiles(entity: any) {
    this.newFiles = this.transferService.transferMonitor.monitor({
      newFiles: this.newFiles,
      currentFiles: this.files,
      origin: this.getPreUploadURL(),
      entity
    });
  }

  protected prepareSignedURLUpload() {
    this.newFiles.forEach(file => {
      const entity = this.entityGetter();
      if (entity) {
        file.entity = { id: entity.id };
      }
      if (this.hasVersions) {
        file.entity.version = this.currentVersionGetter();
      }
    });
  }

  protected isResumableUploadActivated(): boolean {
    const userSettings = this.authService.getAuthUserSettings();
    return userSettings?.uploadResumable || false;
  }

  public startUpload() {
    if (this.isResumableUploadActivated()) {
      this.startResumableUpload();
      return;
    }

    this.startBasicSignedUrlUpload();
  }

  public startResumableUpload() {
    this.prepareSignedURLUpload();
    const request: SignedURLUploadRequest = {
      files: this.newFiles.filter(file => file.uploadStatusCode === TransferStatus.NEW),
      uploadType: TransferType.ResumableSignedURL,
      preUploadUrl: this.getPreUploadURL(),
      postUploadUrl: this.getPostUploadURL(),
      parentId: this.getParentId()
    };
    this.transferService.uploadFiles(request);
  }

  public startBasicSignedUrlUpload() {
    this.prepareSignedURLUpload();
    const request: SignedURLUploadRequest = {
      files: this.newFiles.filter(file => file.uploadStatusCode === TransferStatus.NEW),
      uploadType: TransferType.SignedURL,
      preUploadUrl: this.getPreUploadURL(),
      postUploadUrl: this.getPostUploadURL(),
      parentId: this.getParentId()
    };
    this.transferService.uploadFiles(request, this.hideProgress);
  }

  public startReferenceUpload(refFiles: any[]) {
    if (refFiles.length == 0) return;

    refFiles.forEach(file => {
      if (!file.fileName) {
        file.fileName = file.name;
      }

      if (!file.fileSize) {
        file.fileSize = file.size;
      }

      if (!file.progress$) {
        file.progress$ = new Subject<number>();
      }
    });

    const request: SignedURLUploadRequest = {
      files: refFiles,
      uploadType: TransferType.Reference,
      preUploadUrl: this.getPreUploadURL(),
      postUploadUrl: this.getPostUploadURL(),
      parentId: this.getParentId()
    };
    this.transferService.uploadFiles(request);
  }

  public startDirectUpload(uploadUrl: string) {
    const request: DirectUploadRequest = {
      files: this.newFiles,
      uploadType: TransferType.DirectUpload,
      uploadUrl: uploadUrl
    };
    this.transferService.uploadFiles(request);
  }

  public startUploadFiles(silentUpload = false, retryErrorUpload = false, asReference?: boolean, refFiles?: any, nativeUploadMethod?: any) {
    if (asReference) {
      SURLFUManagerService.uploadNewFiles(this.files, this.newFiles, this.getPreUploadURL(), this.getPostUploadURL(), this.getLabel(), this.getCard(), this.getProjectId(), silentUpload, asReference, refFiles, this.getParentId(), nativeUploadMethod);
    } else {
      const postUploadURL = this.getPostUploadURL();
      const preUploadURL = this.getPreUploadURL();
      if (retryErrorUpload) {
        SURLFUManagerService.serviceSURL.listFilesUploadStruct = [];
      }
      SURLFUManagerService.uploadNewFiles(this.files, this.newFiles, this.getPreUploadURL(), this.getPostUploadURL(), this.getLabel(), this.getCard(), this.getProjectId(), silentUpload, void 0, void 0, this.getParentId(), nativeUploadMethod);
    }
  }

  private createMTMCustomFile(file, index, fileAttachForChat = null): MTMCustomFile {
    let newMTMCustomFile = new MTMCustomFile(file, index);
    newMTMCustomFile.postUploadPayload = fileAttachForChat;
    if (this.postUploadAttributes) {
      newMTMCustomFile.postUploadAttributes = this.postUploadAttributes();
    }
    newMTMCustomFile.parentUrl = this.parentUrlGetter();
    if (this.previewUrlGetter) {
      newMTMCustomFile.extraHandlers['previewUrl'] = this.previewUrlGetter;
    }
    return newMTMCustomFile;
  }

  public prepareFilesForUpload(selectedFiles: any[], fileAttachForChat = null) {
    const entity = this.entityGetter();
    let currentVersion = 0;
    if (this.hasVersions) {
      currentVersion = this.currentVersionGetter();
    }

    const waitingFiles = this.newFiles.filter(f => f.uploadStatusCode === TransferStatus.WAITING);
    waitingFiles.forEach(file => {
      const newIndex = this.newFiles.findIndex(f => f == file);
      if (newIndex > -1) {
        this.newFiles.splice(newIndex, 1);
      }
    });

    for (let i = 0; i < selectedFiles.length; i++) {
      let file = new File([selectedFiles[i]], selectedFiles[i].name, { type: selectedFiles[i].type });
      if (this.newFiles.findIndex(k => k.fileName == selectedFiles[i].name) < 0) {
        let newMTMCustomFile = this.createMTMCustomFile(file, i, fileAttachForChat);
        if (entity) {
          newMTMCustomFile.entity.id = entity.id;
          if (currentVersion) {
            newMTMCustomFile.entity.version = entity.version;
          }
        }
        this.newFiles.push(newMTMCustomFile);
      }
    }
  }

  public prepareFilesForChatUpload(selectedFiles: File[]) {
    this.prepareFilesForUpload(selectedFiles, JSON.stringify({ message: ['%ID%'] }));
  }

  public getCardUploadFileStructID(): string {
    return this.getPreUploadURL();
  }

  monitorActiveVersion(activeVersion) {
    this.newFiles = [];
    this.monitorFiles(this.entityGetter());
  }
}

export class BatchSignedURLUpload extends SignedURL_FU_Base {

  constructor(...options: SignedURLOptions[]) {
    super(...options);
    this.hasVersions = true;
    this.currentVersionGetter = () => 1;
  }

}
