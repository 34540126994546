import { Injectable } from '@angular/core';
import { ApiService } from "app/shared/services/api.service";
import { AuthService } from "app/shared/services/auth.service";
import { Subject, Observable } from "rxjs";
import { onLoadProject, ProjectService } from "./project.service";
import { tap } from "rxjs/operators";

@Injectable({
	providedIn: 'root'
})
export class StylismService {

	constructor(private api: ApiService, public auth: AuthService, private serviceProject: ProjectService) { }

	/**
	 * Retrieve subsection's StylismRoles
	 * @param projectId
	 * @param sectionId
	 * @param subsectionId
	 */
	public getStylismRoles(projectId: string, sectionId: string, subsectionId: string): Observable<any> {
		return this.api.setProfileAndImagesProbForItems(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/stylismRoles`);
	}

	/**
	 * Retrieve subsection's Wardrobes
	 * @param projectId
	 * @param sectionId
	 * @param subsectionId
	 */
	public getWardrobes(projectId: string, sectionId: string, subsectionId: string): Observable<any> {
		return this.api.setProfileAndImagesProbForItems(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/wardrobes`);
	}


	/**
	 * Create a new Wardrobe
	 * @param projectId
	 * @param sectionId
	 * @param subsectionId
	 * @param wardrobe
	 */
	public saveWardrobe(projectId: string, sectionId: string, subsectionId: string, wardrobe: any): Observable<any> {
		return this.api.httpPost(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/wardrobes`, wardrobe);
	}

	/**
	 * Create a new StylismRole
	 * @param projectId
	 * @param sectionId
	 * @param subsectionId
	 * @param location
	 */
	public saveStylismRole(projectId: string, sectionId: string, subsectionId: string, stylismRole: any): Observable<any> {
		return this.api.httpPost(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/stylismRoles`, stylismRole);
	}

	public renameWardrobe(projectId: string, sectionId: string, subsectionId: string, wardrobeId: string, newName: string): Observable<any> {
		return this.api.httpPut(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/wardrobes/${wardrobeId}?newName=${newName}`, {});
	}

	/**
	 * Delete a Wardrobe given by ID
	 * @param projectId
	 * @param sectionId
	 * @param subsectionId
	 * @param styleId
	 */
	public deleteWardrobe(projectId: string, sectionId: string, subsectionId: string, wardrobeId: string): Observable<any> {
		return this.api.httpDelete(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/wardrobes/${wardrobeId}`);
	}

	/**
	 * Delete a StylismRole given by ID
	 * @param projectId
	 * @param sectionId
	 * @param subsectionId
	 * @param styleId
	 */
	public deleteStylismRole(projectId: string, sectionId: string, subsectionId: string, stylismRoleId: string): Observable<any> {
		return this.api.httpDelete(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/stylismRoles/${stylismRoleId}`);
	}


	/**
	 * Retrieve wardrobe by ID
	 * @param projectId
	 * @param sectionId
	 * @param subsectionId
	 * @param wardrobeId
	 */
	public getWardrobe(projectId: string, sectionId: string, subsectionId: string, wardrobeId: string): Observable<any> {
		return this.api.httpGet(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/wardrobes/${wardrobeId}`);
	}

	/**
	 * Retrieve stylismRole by ID
	 * @param projectId
	 * @param sectionId
	 * @param subsectionId
	 * @param stylismRoleId
	 */
	public getStylismRole(projectId: string, sectionId: string, subsectionId: string, stylismRoleId: string): Observable<any> {
		return this.api.httpGet(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/stylismRoles/${stylismRoleId}`);
	}

	/**
	 * Retrieve stylismRole's files
	 * @param projectId
	 * @param sectionId
	 * @param subsectionId
	 * @param stylismRoleId
	 */
	public getStylismRoleFiles(projectId: string, sectionId: string, subsectionId: string, stylismRoleId: string): Observable<any> {
		return this.api.httpGet(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/items/${stylismRoleId}/files`);
	}

	/**
	 * Retrieve wardrobe's files
	 * @param projectId
	 * @param sectionId
	 * @param subsectionId
	 * @param wardrobeId
	 */
	public getWardrobeFiles(projectId: string, sectionId: string, subsectionId: string, wardrobeId: string): Observable<any> {
		return this.api.httpGet(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/items/${wardrobeId}/files`);
	}


	/**
	 * Retrieve subsection's Articles
	 * @param projectId
	 * @param sectionId
	 * @param subsectionId
	 */
	public getArticles(projectId: string, sectionId: string, subsectionId: string): Observable<any> {
		return this.api.httpGet(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-articles`);
	}

	/**
	 * Retrieve subsection's Articles
	 * @param projectId
	 * @param sectionId
	 * @param subsectionId
	 */
	public getSimpleArticles(projectId: string, sectionId: string, subsectionId: string): Observable<any> {
		return this.api.httpGet(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-articles-simple`)
			.pipe(tap((data: any) => {
				data.forEach((option: any) => {
					option.title = option.articleOfCostumeDesign;
					if (option.simpleVersions) {
						option.simpleVersions.sort((e1, e2) => e1.order < e2.order ? -1 : 1);
					}
				});
				data.sort((a1, a2) => a1.title.localeCompare(a2.title));
			}));
	}


	/**
	 * Create a new Article
	 * @param projectId
	 * @param sectionId
	 * @param subsectionId
	 * @param article
	 */
	public saveArticle(projectId: string, sectionId: string, subsectionId: string, article: any): Observable<any> {
		return this.api.httpPost(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-articles?topic=null&topicId=null`, article);
	}


	/**
	 * Delete a Article given by ID
	 * @param projectId
	 * @param sectionId
	 * @param subsectionId
	 * @param styleId
	 */
	public deleteArticle(projectId: string, sectionId: string, subsectionId: string, articleId: string): Observable<any> {
		return this.api.httpDelete(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-articles/${articleId}`);
	}

	public renameArticle(projectId: string, sectionId: string, subsectionId: string, articleId: string, newName: string): Observable<any> {
		return this.api.httpPut(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-articles/${articleId}?newName=${newName}`, {});
	}


	/**
	 * Retrieve article by ID
	 * @param projectId
	 * @param sectionId
	 * @param subsectionId
	 * @param articleId
	 */
	public getArticle(projectId: string, sectionId: string, subsectionId: string, articleId: string): Observable<any> {
		return this.api.httpGet(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-articles/${articleId}`);
	}

	/**
	 * Retrieve article by ID
	 * @param projectId
	 * @param sectionId
	 * @param subsectionId
	 * @param articleId
	 */
	public getArticleSimpleInfo(projectId: string, sectionId: string, subsectionId: string, articleId: string): Observable<any> {
		return this.api.httpGet(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-articles-simple/${articleId}`);
	}

	public getpreProdCastingId(projectId): Observable<any> {
		return new Observable(observe => {

			let findSubSectionId = () => {
				try {
					let sectionIndex = this.serviceProject.sections.findIndex(k => k.section == "PRE_PRODUCTION");
					if (sectionIndex < 0) {
						observe.error();
						return;
					}

					let index = this.serviceProject.sections[sectionIndex].projectSubSections.findIndex(k => k.subSection == "CASTING");
					if (index < 0) {
						observe.error();
						return;
					}

					observe.next(this.serviceProject.sections[sectionIndex].projectSubSections[index]);
					observe.complete();
				} catch (err) {
					console.log(err);
					observe.error();
				}
			}

			try {
				if (this.serviceProject.projectOnloaded)
					findSubSectionId();
				else {
					onLoadProject.subscribe(() => {
						findSubSectionId();
					});
				}
			} catch (error) {
				observe.error();
			}

		});
	}

}
