import { AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/shared/services/auth.service';
import { ProjectV2ServiceService } from 'app/shared/services/project-v2-service.service';
import { WorkspaceService } from 'app/shared/services/workspace.service';
import { AnalyticsService } from 'app/shared/services/analytics.service';
import { CryptoService } from 'app/shared/services/crypto.service';
import { TimelineService } from 'app/shared/services/timeline.service';
import { BreadcrumbService } from 'ng5-breadcrumb';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Authority, PermissionService } from 'app/shared/services/permissions.service';
import { OverlayService } from 'app/shared/services/overlayService';
import { EmitterService } from 'app/shared/services/emitter.service';
import { Brand, WorkspaceUpdatedEvent } from 'app/shared/interfaces';
import { NotificationService } from "app/shared/services/notification.service";
import { TranslatePipe } from "app/shared/pipes/translate.pipe";
import { environment } from 'environments/environment';
import { MtmBreadcrumbLink, MtmBreadcrumbService } from 'app/shared/services/mtm-breadcrumb.service';
import { ThemeService } from "app/shared/services/theme.service";
import { GoogleAnalyticsService } from "app/kanban/core/services/google-analytics.service"
@Component({
  selector: 'mtm-workspace-dashboard',
  templateUrl: './workspace-dashboard.component.html',
  styleUrls: ['./workspace-dashboard.component.scss']
})
export class WorkspaceDashboardComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('searchPanel', { static: false }) searchPanel: TemplateRef<any>;
  worskspaceSearch: string;
  backupworkSpaces = [];
  workSpaces = [];
  isscenario2: boolean = false;
  workspaceImage: any;
  authUser: any;
  isLoaded: boolean = false;
  ngUnsubscribe = new Subject();
  canCreateWorkspace: boolean = false;
  brandName: string;
  isError: boolean = false;
  headerImage: string = '../../../assets/img/project-v2/workspace-image.png';
  imageVersion: string = new Date().getTime().toString();
  calendarViewMode = 'Latest';
  calendarViewLabel = 'latest';
  isAll: boolean = true;
  isFav: boolean = false;
  isArchived: boolean = false;
  favbackupworkSpaces = [];
  favworkSpaces = [];
  isPending: boolean = false;
  isCpd = false;
  cacheBuster: string = Date.now().toString();

  constructor(
    private router: Router,
    private workspaceService: WorkspaceService,
    private projectV2ServiceService: ProjectV2ServiceService,
    private authService: AuthService,
    private analyticService: AnalyticsService,
    private cryptoService: CryptoService,
    private timelineService: TimelineService,
    private permissionService: PermissionService,
    private breadcrumbService: BreadcrumbService,
    private overlayService: OverlayService,
    private themeService: ThemeService,
    private translatePipe: TranslatePipe,
    private notificationService: NotificationService,
    private mtmBreadcrumbService: MtmBreadcrumbService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {
    this.setBreadcrumb();
    EmitterService.get('BRAND_UPDATED').pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe({
      next: (brand) => {
        this.setBreadcrumb();
      }
    });
  }

  ngOnInit(): void {
    // resetTab
    this.timelineService.resetTabsEmmiter.emit(true);

    this.timelineService.workspaceDashboardEmitter.next(true);
    this.timelineService.isDriveNav.emit(true);

    this.authUser = this.authService.getAuthUser();
    this.isCpd = this.authUser.companyId == environment.ltg.cpdCompanyId;
    // this.getWorkspaces();
    this.getBrands();

    // get workspace from side bar nav
    this.getSideNavWorkspace();

    // trigger search emmiter
    this.searchWorkSpace();

    // trigger tabs
    this.getTabsInputs();
    const isAgencyUser = this.authUser.userPermissionType === 'AGENCY';
    if (!isAgencyUser) {
      this.canCreateWorkspace = (this.permissionService.hasAuthority(Authority.Z, null) || this.permissionService.hasAuthority(Authority.Z)) || (this.authService.hasPaidSubscription() && this.permissionService.hasAuthority(Authority.S, null));
    }
    const activeWs = this.workspaceService.getActiveWorkspace();
    if (activeWs) {
      this.workspaceService.unsetActiveWorkspace();
    }
  }

  ngAfterViewInit() {
    this.themeService.updateContent({
      searchPanel: this.searchPanel,
      fullWidthContent: true
    });
  }

  ngOnDestroy(): void {
    this.mtmBreadcrumbService.setLinks([]);
    this.themeService.updateContent({
      searchPanel: null,
      fullWidthContent: false
    })
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
  //get brands and set id in local storage
  // getBrands() {
  //   this.workSpaces = [];
  //   this.backupworkSpaces = [];
  //   this.isError = false;
  //   this.isLoaded = false;
  //   this.analyticService.getBrands().pipe(takeUntil(this.ngUnsubscribe)).
  //     subscribe({
  //       next: (brands: any) => {
  //         this.isLoaded = true;
  //         let allBrands = brands;
  //         allBrands.forEach(brand => {
  //           // set header banner
  //           this.timelineService.componentName.next({
  //             componentName: 'workspaceComponent',
  //             bannerUrl: brand.banner,
  //           });
  //           this.refreshImageVersion();

  //           if (brand.workspaces) {
  //             brand.workspaces.forEach(workspace => {
  //               this.workSpaces.push(workspace);
  //               this.backupworkSpaces.push(workspace);
  //               this.workSpaces.forEach(workspace => {
  //                 workspace['brandName'] = brand.name;
  //                 workspace['isFavorite'] = false;
  //                 // Remove name space from start
  //                 workspace.name = workspace.name.trim();
  //               });
  //               // sort workpsaces
  //               this.backupworkSpaces.sort((w1, w2) => w1.name.localeCompare(w2.name));
  //               this.workSpaces.sort((w1, w2) => w1.name.localeCompare(w2.name));
  //             });
  //             this.getFavoriteWorkspace();
  //           }
  //           if (brand.usernamesRoles) {
  //             brand.usernamesRoles.forEach(user => {
  //               if (user.username == this.authUser.email) {
  //                 localStorage.setItem('brandId', brand.id);
  //                 this.authService.setMyBrand(brands[0]);
  //               }
  //             });
  //           }
  //         });
  //       },
  //       error: (err) => {
  //         this.isError = true;
  //         console.error('An error occurred:', err);
  //       },
  //       complete: () => {
  //         console.info('complete')
  //       }
  //     })
  // }
  getBrands() {
    this.workSpaces = [];
    this.backupworkSpaces = [];
    this.isError = false;
    this.isLoaded = false;
    this.analyticService.getBrandsWithFilteredWorkspaces().pipe(takeUntil(this.ngUnsubscribe)).
      subscribe({
        next: (brands: any) => {
          let allBrands = brands;
          allBrands.forEach(brand => {
            // set header banner
            this.timelineService.componentName.next({
              componentName: 'workspaceComponent',
              bannerUrl: brand.banner,
            });
            this.refreshImageVersion();

            if (brand.workspaces) {
              this.workSpaces.push(...brand.workspaces);
              this.backupworkSpaces.push(...brand.workspaces);
              this.workSpaces.forEach(workspace => {
                workspace['brandName'] = brand.name;
                workspace['isFavorite'] = false;
                // Remove name space from start
                workspace.name = workspace.name.trim();
              });
              this.backupworkSpaces.forEach(workspace => {
                workspace['brandName'] = brand.name;
                workspace['isFavorite'] = false;
                // Remove name space from start
                workspace.name = workspace.name.trim();
              });
              // sort workspaces
              this.backupworkSpaces.sort((w1, w2) => w1.name.localeCompare(w2.name));
              this.workSpaces.sort((w1, w2) => w1.name.localeCompare(w2.name));
              this.favbackupworkSpaces = this.backupworkSpaces;
              this.favworkSpaces = this.workSpaces;
            }

            /*
            if (brand.usernamesRoles) {
              brand.usernamesRoles.forEach(user => {
                if (user.username == this.authUser.email) {
                  localStorage.setItem('brandId', brand.id);
                  this.authService.setMyBrand(brands[0]);
                  EmitterService.get('BRAND_UPDATED').emit(brands[0]);
                }
              });
            }
            */
            // try to always populate 
            localStorage.setItem('brandId', brand.id);
            this.authService.setMyBrand(brands[0]);
            EmitterService.get('BRAND_UPDATED').emit(brands[0]);

          });
          this.isLoaded = true;
          if (brands) {
            this.getFavoriteWorkspace();
          }
        },
        error: (err) => {
          this.isError = true;
          console.error('An error occurred:', err);
        },
        complete: () => {
          console.info('complete');
        }
      })
  }

  prepareBreadcrumbs(): void {
    let url = 'workspaces'
    if (url) {
      const route = url.split('?')[0];
      const searchParams = new URLSearchParams(url.split('?')[1]);
      const queryParams = {};
      for (const [key, value] of searchParams.entries()) {
        queryParams[key] = value;
      }
      this.breadcrumbService.hideRoute(route);
      const newRoute = `${route}?${Object.entries(queryParams).map(([key, value]) => `${key}=${value}`).join('&')}`;
      this.breadcrumbService.addFriendlyNameForRoute(newRoute, 'workspaces');
    }
  }

  searchWorkSpace() {
    // this.timelineService.searchEventEmitter.subscribe(searchString => {
    //   this.worskspaceSearch = searchString
    if (this.worskspaceSearch) {
      this.workSpaces = this.backupworkSpaces.filter(workSpace => {
        let isMatched = true;
        if (this.worskspaceSearch) {
          if (!workSpace.name) {
            isMatched = false;
          } else {
            isMatched = workSpace.name.toLowerCase().indexOf(this.worskspaceSearch.toLowerCase()) > -1;
          }
          if (!isMatched) {
            this.workSpaces = this.backupworkSpaces;
          }
        }
        return isMatched;
      });
    } else {
      this.workSpaces = this.backupworkSpaces;
    }
    // })
  }

  // get selected workspace from side bar
  getSideNavWorkspace() {
    this.timelineService.selectedWorkspace.subscribe((wk: any) => {
      if (wk && wk.id) {
        this.goToCampaignDrive(wk);
      }
    })
  }

  goToCampaignDrive(workspace: any) {
    const authUser = this.authService.getAuthUser();
    const showCampaign = authUser.campaign;
    const showDriveCampaign = authUser.drivecampaign;
    // set active workspace
    this.workspaceService.setActiveWorkspace(workspace);
    localStorage.setItem('workspace', workspace.id);
    localStorage.setItem('workspaceName', workspace.name);
    this.workspaceService.changeStatus(true);
    this.workspaceService.changeStatus(true);
    // this.timelineService.selectedWorkspace.next(workspace);
    this.timelineService.componentName.next({
      componentName: 'campaignDriveComponent',
      bannerUrl: workspace.coverUrl
    });
    // don't open workspace if user don't have any permission
    if (!showCampaign && !showDriveCampaign) {
      this.overlayService.showError('You don\'t have permission to access campaigns or drive campaigns.', 'Error');
      return false;
    }
    if (showCampaign && showDriveCampaign) {
      this.router.navigate(['workspaces/campaign-drive'], {
        queryParams: {
          wid: this.cryptoService.encryptString(workspace.id)
        }
      });
    } else if (showCampaign) {
      this.router.navigate(['workspaces/campaigns'], {
        queryParams: {
          wid: this.cryptoService.encryptString(workspace.id)
        }
      });
    } else if (showDriveCampaign) {
      this.router.navigate(['workspaces/campaign-drive'], {
        queryParams: {
          wid: this.cryptoService.encryptString(workspace.id)
        }
      });
    }

    this.timelineService.workspaceBannerEmitter.emit(workspace.coverUrl);
    this.timelineService.componentName.next({
      componentName: 'campaignDriveComponent',
      bannerUrl: workspace.coverUrl
    });
    this.timelineService.workspaceName = workspace.name;
    let encryptedWId = this.cryptoService.encryptString(workspace.id)
    localStorage.setItem('workspaceV2Id', encryptedWId);
    this.projectV2ServiceService.workspaceIdSubject.next(workspace.id);

    //update selected milestone to null
    this.timelineService.updateMilestone({});

    this.timelineService.isDriveNav.emit(false);
  }

  createWorkspace() {
    this.router.navigate(['/workspace/new'])
  }

  editWorkspace(item) {
    this.router.navigate(['/workspace/edit', item.id]);
  }

  private refreshImageVersion() {
    this.imageVersion = new Date().getTime().toString();
  }

  markAsFavorite(workspace) {
    workspace.isFavorite = !workspace.isFavorite;
    this.analyticService.markAsFavorite(workspace, 'WORKSPACE').pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe({
      next: (res: any) => {
        this.workSpaces = this.workSpaces.map(workspace => ({
          ...workspace,
          isFavorite: res.items.includes(workspace.id)
        }));
        // this.getFavoriteWorkspace();
      },
      error: (err) => {
        console.error(err)
      },
      complete: () => {

      }
    });
  }

  removeFavorite(workspace) {
    this.analyticService.removeAsFavorite(workspace, 'WORKSPACE').pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe({
      next: (res: any) => {
        // this.workSpaces = this.workSpaces.map(workspace => ({
        //   ...workspace,
        //   isFavorite: res.items.includes(workspace.id)
        // }));
        this.getBrands()
      },
      error: (err) => {
        console.error(err)
      },
      complete: () => {
      }
    });
  }

  getFavoriteWorkspace() {
    this.workSpaces = [];
    this.backupworkSpaces = [];
    this.isError = false;
    this.isLoaded = false;
    this.analyticService.getFavoriteItem('WORKSPACE').pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe({
      next: (res: any) => {
        // set response direct when fav tab is triggered
        if (this.isFav) {
          this.workSpaces = res;
          this.backupworkSpaces = res;
          this.workSpaces.forEach(workspace => {
            workspace.isFavorite = res.some(w => w.id === workspace.id);
          });
          this.backupworkSpaces.forEach(workspace => {
            workspace.isFavorite = res.some(w => w.id === workspace.id);
          });
          this.isLoaded = true;
        } else if (this.isAll) { // if All is triggered then filter fav data and highlight fav items
          this.favworkSpaces.forEach(workspace => {
            workspace.isFavorite = res.some(w => w.id === workspace.id);
          });
          this.favbackupworkSpaces.forEach(workspace => {
            //   const foundWorkspace = res.find(w => w.id === workspace.id);
            //   workspace.isFavorite = foundWorkspace !== undefined;
            workspace.isFavorite = res.some(w => w.id === workspace.id);
          });
          // remove duplicate items
          this.workSpaces = this.favworkSpaces.filter((value, index, self) =>
            index === self.findIndex((t) => (
              t.id === value.id
            )))
          this.backupworkSpaces = this.favbackupworkSpaces.filter((value, index, self) =>
            index === self.findIndex((t) => (
              t.id === value.id
            )))
          this.isLoaded = true;
        }
      },

      error: (err) => {
        console.error(err);
        this.isError = true;
      },
      complete: () => {
        // Handle completion if needed
      }
    });
  }

  // Helper method to set isFavorite status based on a set of favorite workspace IDs
  setFavoriteStatus(array: any[], favoriteWorkspaces: any[]): void {
    array.forEach(workspace => {
      workspace.isFavorite = favoriteWorkspaces.some(w => w.id === workspace.id);
    });
  }

  shouldShowEllipsis(name: string): boolean {
    return name.length > 12;
  }

  getTabsInputs() {
    this.timelineService.isAll.pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (data: boolean) => {
        if (data) {
          this.isAll = true;
          this.isFav = false;
          this.isArchived = false;
          this.getBrands();
          this.timelineService.emitIsAll(false);
        }
      },
      error: (err) => {
        console.error(err);
      },
      complete: () => {
        // Handle completion if needed
      }
    });

    this.timelineService.isFavorites.pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (data: boolean) => {
        if (data) {
          this.isAll = false;
          this.isFav = true;
          this.isArchived = false;
          this.getFavoriteWorkspace();
          this.timelineService.emitIsFavorites(false);
        }
      },
      error: (err) => {
        console.error(err);
      },
      complete: () => {
        // Handle completion if needed
      }
    });

    this.timelineService.isArchived.pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (data: boolean) => {
        if (data) {
          this.isAll = false;
          this.isFav = false;
          this.isArchived = true;
          this.timelineService.emitIsArchived(false);
        }
      },
      error: (err) => {
        console.error(err);
      },
      complete: () => {
        // Handle completion if needed
      }
    });
  }

  selectCalendarMode(mode) {
    console.log('mode', mode)
    let selectedViewDate = this.projectV2ServiceService.calculateDateRange(mode);
    console.log('selectedViewDate', selectedViewDate)
    switch (mode) {
      case '3_months':
        this.calendarViewMode = '3 Months';
        this.calendarViewLabel = 'loreal_dashboard_3months';
        break;
      case '6_months':
        this.calendarViewMode = '6 Months';
        this.calendarViewLabel = 'loreal_dashboard_6months';
        break;
      case 'week':
        this.calendarViewMode = 'Week';
        this.calendarViewLabel = 'week';
        break;
      default:
        this.calendarViewMode = 'Latest';
        this.calendarViewLabel = 'latest';
        break;
    }
  }

  deleteWorkspace(ws: any) {
    if (this.isPending) {
      return;
    }

    this.notificationService.open({
      title: this.translatePipe.transform('workspace_delete_title'),
      description: this.translatePipe.transform('workspace_delete_confirm', { name: ws.name }),
      confirmBtn: this.translatePipe.transform('yes'),
      cancelBtn: this.translatePipe.transform('no')
    }).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((confirm: boolean) => {
        if (!confirm) {
          return;
        }
        this.isPending = true;
        this.workspaceService.deleteWorkspace(ws.companyId, ws.id)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: () => {
              this.isPending = false;
              this.getBrands();
            },
            error: err => {
              this.isPending = false;
              this.overlayService.showError(this.translatePipe.transform('workspace_delete_error'), 'Error');
            }
          });

      })
  }

  setBreadcrumb() {
    const brand = JSON.parse(localStorage.getItem('brand'));
    if (brand) {
      const pageLinks: MtmBreadcrumbLink[] = [{
        label: brand.name
      }, {
        label: 'Workspaces',
        url: ['/workspaces']
      }]
      this.mtmBreadcrumbService.setLinks(pageLinks);
      this.googleAnalyticsService.sendUserAnalyticsConfig('', '','');
    }
  }

}
