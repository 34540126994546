import { MTMCustomFile } from "../../shared/services/signed-url";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'newFilesFilter',
  pure: false,
})
export class NewFilesFilterPipe implements PipeTransform {
  transform(values: MTMCustomFile[], version: number): MTMCustomFile[] {
    return values.filter((file: MTMCustomFile) => file.entity.version == version);
  }
}
