import { AuthService } from '../services/auth.service';
import * as moment from 'moment';
import 'moment-timezone';
import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "../services/translate.service";
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

@Pipe({
	name: 'ngbDate',
	pure: false
})
export class MtmNgbDate implements PipeTransform {

	constructor(private authService: AuthService, private translateService: TranslateService) {
	}
	transform(value: NgbDate): string {
		const { dateFormat = 'DD/MM/YYYY' } = this.authService.getAuthUserSettings() || {};
		const lang = this.translateService.getLang() as string;
		let dateValue: any = new Date(value.year, value.month - 1, value.day);

        const dateTimeFormat = `${dateFormat}`;
		return moment(dateValue).locale(lang).format(dateTimeFormat);
	}
}
