import { UUID } from 'angular2-uuid';
import * as moment from 'moment';
import { EmitterService } from '../emitter.service';
import { TransferStatus, listenerUploadStatusChanged } from './index';
import { Subject, Subscription } from "rxjs";
import { TransferProgress } from "./transfer-progress";
import { UploadRecord } from "./idx-schema";
import { BasicFunction } from "../../models";


export enum TransferType {
  SignedURL = 'signedURL',
  ResumableSignedURL = 'resumableSignedURL',
  Reference = 'reference',
  DirectUpload = 'directUpload',
  TranscodingFailed = 'transcodingFailed',

}

export interface MTMCustomFileExtraInfo {
  previewUrl?: string;
  remainingTime?: number;
  failedTranscodingFileId?: string;
  failedTranscodingReplacementType?: string;
}

export class MTMCustomFile {
  file: File;
  fileName: string = '';
  fileSize: number = 0.0;
  fileType: string = '';
  parentId?: string;

  /* ----------- * OVIRRADE * ----------- */
  name: string = '';
  size: number = 0.0;
  type: string = '';
  /* ----------- * OVIRRADE * ----------- */

  fileLocalId: string = '';
  localPlayPath: string = '';
  fileSizeMB: number = 0.00;
  dbFileObject: any = {};
  progress: number = 0.0;
  uploadStatusCode: string = TransferStatus.NEW;
  postUploadPayload: any = null; //payload to be sent during postUploadUrl, can be string which will be evaluated after preUpload
  postUploadAttributes: any;

  /* ----------- * - * ----------- */
  timeToStartUploading: number;
  /* ----------- * - * ----------- */

  refFileUploadWithSignedURL: any;//SignedURLFileUpload;

  /* ----------- * - * ----------- */
  createTime: number;

  //new props
  owner: string = '';
  pipelines: any[] = [];
  progress$: Subject<TransferProgress> = new Subject<TransferProgress>();
  step: string = '';
  asReference: boolean = false;
  preUploadUrl: string = '';
  postUploadUrl: string = '';
  sessionUrl: string = '';
  startIndex: number = 0;
  transferType: string = '';
  entity: any = {}; //associated entity info, contains at least id (and version if applicable)
  retryCount: number = 0;
  maxRetry: number = 3;
  currentRequest: Subscription = null;
  parentUrl: string = '';
  notificationId: string = '';
  iconUrl?: string = '';
  extraInfo: MTMCustomFileExtraInfo = {};
  extraHandlers: { [key: string]: BasicFunction } = {};

  constructor(file: File, index) {
    this.file = file;
    if (file) {
      this.fileName = file.name;
      this.fileSize = file.size;
      this.fileType = file.type;
    }

    this.name = this.fileName;
    this.size = this.fileSize;
    this.type = this.fileType;

    this.uploadStatusCode = TransferStatus.NEW;
    this.fileLocalId = UUID.UUID() + '_' + Date.now() + '_' + index;

    if (file) {
      this.localPlayPath = URL.createObjectURL(file);
      let sizeMB = parseInt(file.size.toFixed(2)) / (1024 * 1024);
      this.fileSizeMB = parseFloat(sizeMB.toFixed(2));
    }

    this.createTime = moment().valueOf();
  }

  /* ----------- * - * ----------- */

  removeFromUploadingFiles(that: this) {
    console.log('removeFromUploadingFiles');
  }

  cancelUpload(that: this) {
    if (that.refFileUploadWithSignedURL && that.refFileUploadWithSignedURL.isUploading)
      that.refFileUploadWithSignedURL.abourtUploading();

    that.setUploadStatusCode(TransferStatus.ERROR);
  }

  onRefreshUploading = (that) => {
  }

  /* ----------- * - * ----------- */

  public setProgress = (progress: number): void => {
    this.progress = progress;
    EmitterService.get('upload-file-progress-' + this.dbFileObject.sourceId).emit({
      file: this.file,
      progress: this.progress,
      dbFileObject: this.dbFileObject
    });
  }

  public setUploadStatusCode(uploadStatusCode: string): void {
    this.uploadStatusCode = uploadStatusCode;
    listenerUploadStatusChanged.emit();
  }

  public setUploadStatusCode_and_Progress(progress: number, uploadStatusCode: any) {
    this.setProgress(progress);
    this.uploadStatusCode = uploadStatusCode;
  }

  /* ----------- * - * ----------- */

  public setDbFileObject(dbFileObject): void {
    this.dbFileObject = dbFileObject;
    if (typeof this.postUploadPayload == 'string') {
      this.postUploadPayload = JSON.parse(this.postUploadPayload.replace('%ID%', dbFileObject.id));
    }
  }

  public setPostUploadAttributes(response): void {
    for (const [key, value] of Object.entries(this.postUploadAttributes)) {
      this.postUploadAttributes[key] = response[key];
    }
  }

  /* ----------- * - * ----------- */
  public setTimeToStartUploading() {
    this.timeToStartUploading = moment.now();
  }

  /* ----------- * - * ----------- */

  toUploadRecord(): UploadRecord {
    const obj: UploadRecord = {
      id: this.dbFileObject.id,
      name: this.name,
      owner: this.owner,
      contentType: this.fileType || this.dbFileObject?.contentType,
      status: this.uploadStatusCode,
      startDate: this.createTime,
      percentage: this.progress,
      startIndex: this.startIndex,
      totalSize: this.fileSize,
      preUploadUrl: this.preUploadUrl,
      postUploadUrl: this.postUploadUrl,
      step: this.step,
      pipelines: JSON.stringify(this.pipelines),
      sessionUrl: this.sessionUrl,
      additionalData: '',
      entity: JSON.stringify(this.entity),
      parentUrl: this.parentUrl,
      extraInfo: JSON.stringify(this.extraInfo),
    };

    const fileObj = { ...this.dbFileObject }
    delete fileObj.participants;
    obj.additionalData = JSON.stringify(fileObj);

    if (this.postUploadPayload) {
      if (typeof this.postUploadPayload != 'string') {
        obj.postUploadPayload = JSON.stringify(this.postUploadPayload);
      } else {
        obj.postUploadPayload = this.postUploadPayload;
      }
    }

    return obj;
  }

  updatePercentage() {
    let percentage = (this.startIndex * 1.0 / this.fileSize) * 100;
    if (percentage > 100) {
      percentage = 100;
    }
    this.progress = percentage;
  }

  isResumable(): boolean {
    return this.transferType === TransferType.ResumableSignedURL;
  }

  cleanUp() {
    this.progress$.complete();
    if (this.currentRequest) {
      this.currentRequest.unsubscribe();
      this.currentRequest = null;
    }
  }

  static FromUploadRecord(record: UploadRecord): MTMCustomFile {
    const obj = new MTMCustomFile(null, record.startIndex);
    obj.name = record.name;
    obj.fileName = record.name;
    obj.fileType = record.contentType;
    obj.owner = record.owner;
    obj.uploadStatusCode = record.status;
    obj.createTime = record.startDate;
    obj.progress = record.percentage;
    obj.startIndex = record.startIndex;
    obj.fileSize = record.totalSize;
    obj.size = obj.fileSize;
    let sizeMB = parseInt(record.totalSize.toFixed(2)) / (1024 * 1024);
    obj.fileSizeMB = parseFloat(sizeMB.toFixed(2));
    obj.preUploadUrl = record.preUploadUrl;
    obj.postUploadUrl = record.postUploadUrl;
    obj.step = record.step;
    obj.pipelines = JSON.parse(record.pipelines);
    obj.sessionUrl = record.sessionUrl;
    obj.transferType = TransferType.ResumableSignedURL;
    obj.parentUrl = record.parentUrl;
    if (record.additionalData) {
      obj.dbFileObject = JSON.parse(record.additionalData);
    }
    if (record.entity) {
      obj.entity = JSON.parse(record.entity);
    }
    if (record.postUploadPayload) {
      if (record.postUploadPayload.indexOf('%ID%') > -1) {
        obj.postUploadPayload = JSON.parse(record.postUploadPayload);
      } else {
        obj.postUploadPayload = record.postUploadPayload;
      }
    }
    if (record.extraInfo) {
      obj.extraInfo = JSON.parse(record.extraInfo);
    }
    return obj;
  };

  static MakeMTMCustomFileAdapter(file: any) {
    if (!file.setUploadStatusCode) {
      file.setUploadStatusCode = (uploadStatusCode: string) => {
        file.uploadStatusCode = uploadStatusCode;
      }
    }

    if (!file.cleanUp && file.progress$) {
      file.cleanUp = () => {
        file.progress$.complete();
      }
    }

    if (!file.setDbFileObject) {
      file.setDbFileObject = (dbFileObject) => {
        file.dbFileObject = dbFileObject;
      }
    }

    if (!file.setTimeToStartUploading) {
      file.setTimeToStartUploading = () => file.timeToStartUploading = moment.now();
    }

    file.isResumable = () => false;
  }
}


