import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ProjectThumbnailListComponent } from "app/layouts/my-projects/thumbnail-list/thumbnail-list.component";
import { TranslatePipe } from "app/shared/pipes/translate.pipe";
import { OverlayService } from "app/shared/services/overlayService";
import { UserService } from "app/shared/services/user.service";
import { UserWorkspaceRole } from "app/workspace/models/workspace.model";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { RoleInfo } from "app/shared/interfaces";


@Component({
    selector: 'mtm-new-workspace-user-modal',
    templateUrl: './new-workspace-user.component.html',
    styleUrls: ['./new-workspace-user.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NewWorkspaceUserComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() workspaceRoles: RoleInfo[];
    @Input() projectRoles: RoleInfo[];
    addForm: UntypedFormGroup = null;
    companyUsers: any[] = [];
    currentUsers: UserWorkspaceRole[] = [];
    nameChanged: boolean = false;
    isReadOnlyRole: boolean = false;
    roleLabel: string = '';
    companies: any[] = [];
    workspaces: any[] = [];
    invitedUsers: any;
    isNewUser: boolean = false;
    sending: boolean = false;
    private ngUnsubscribe = new Subject<any>();

    constructor(private fb: UntypedFormBuilder,
        private modal: NgbActiveModal,
        private translatePipe: TranslatePipe,
        private overlayService: OverlayService,
        private userService: UserService) {

    }

    ngOnInit(): void {
        this.initForm();
    }

    ngAfterViewInit(): void {
        const searchBox = document.querySelector('.mtm-inviter-dropdown-component .search-box');
        if (searchBox)
            searchBox.setAttribute('autocomplete', 'mtm');
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next(undefined);;
        this.ngUnsubscribe.complete();
    }

    initForm() {
        this.addForm = this.fb.group({
            username: ['', [Validators.required, this.validateName()]],
            role: ['', Validators.required],
            defaultProjectRoles: [[]]
        });
        this.addForm.valueChanges.subscribe((formValue) => {
            this.validate(formValue);
        });
    }

    validate(values: any) {

    }

    validateName() {
        return (control: UntypedFormControl): ValidationErrors | null => {
            const username = control.value.trim();

            const existingUser = this.currentUsers.find(u => u.username == username);
            if (existingUser) {
                return {
                    duplicate: {
                        message: 'workspace_userExists'
                    }
                };
            }
            return null;
        }
    }

    cancel() {
        this.modal.dismiss();
    }

    nameChange(e) {
        if (e.invalid || e.value.length == 0)
            return;

        const username = e.value[0];
        const existingUser = this.companyUsers.find(u => u.username == username);
        const fixedRoles = ['ADMIN', 'COMPANY_PRINCIPAL','COMPANY_REGULAR','COMPANY_PROJECT_OWNER','COMPANY_PRODUCTION_OWNER'];

        if (existingUser) {
            this.isReadOnlyRole = fixedRoles.indexOf(existingUser.globalRole) > -1;
            this.isNewUser = false;
            this.addForm.patchValue({
                defaultProjectRoles: []
            });

            //if selected user's role is admin/company principal then display as read only
            if (this.isReadOnlyRole) {
                this.addForm.patchValue({
                    role: existingUser.globalRole,
                });
                this.roleLabel = this.translatePipe.transform(`role${existingUser.globalRole}`);
            } else {
                //if it has existing workspace global role then check if he/she is part of multiple workspace,
                //if yes, show role as read only
                //else, set it as default
                if (this.workspaceRoles.some(r => r.code == existingUser.globalRole)) {
                    const involvedWorkspaces = this.workspaces.filter(w => Array.isArray(w.usernames) &&
                        w.usernames.indexOf(existingUser.username) > -1);
                    if (involvedWorkspaces.length > 1) {
                        this.roleLabel = this.translatePipe.transform(`role${existingUser.globalRole}`);
                        this.isReadOnlyRole = true;
                        this.addForm.patchValue({
                            role: existingUser.globalRole,
                        });
                    }
                    else {
                        this.roleLabel = '';
                        this.addForm.patchValue({
                            role: existingUser.globalRole,
                        });
                    }
                }
                else {
                    //user needs to select role
                    this.roleLabel = '';
                    this.addForm.patchValue({
                        role: '',
                    });
                }
            }
        } else {
            //otherwise user need to select role
            this.isReadOnlyRole = false;
            this.isNewUser = true;
            this.addForm.patchValue({
                role: '',
            });

        }

        this.addForm.patchValue({
            username,
        });
        this.nameChanged = true;
    }

    save() {
        if (!this.addForm.valid || this.sending)
            return;

        const usernameToAdd = this.addForm.value.username;

        if (this.companyUsers.some(u => u.username == usernameToAdd)) {
            const user = this.companyUsers.find(u => u.username == usernameToAdd);
            this.modal.close({formData:this.addForm.value,userData:user});
        } else {
            this.sending = true;
            this.userService.findUserByUsername(usernameToAdd, false, false)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: (user) => {
                        this.sending = false;
                        if (!user) {
                            this.modal.close(this.addForm.value);
                        } else {
                            this.overlayService.showError(`Cannot add a workspace user from another company.\nPlease try adding directly to the project.`,
                                'Error');
                        }

                    }, error: () => {
                        this.sending = false;
                        this.modal.close(this.addForm.value);
                    }
                })
        }
    }
}
