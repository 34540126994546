<div class="review-link-settings-component-container">
	<div [hidden]="!isLoading" class="card d-block">
		<loading-data class="d-block" [ngClass]="{'p-4': isLoading}" #loadingRef></loading-data>
	</div>
	<div *ngIf="!isLoading">
		<div class="modal-header pb-0">
			<label class="m-0 _600">{{ 'shareAReviewLink' | translate }}</label>
			<i class="fa fa-times pointer" (click)="closeModal()"></i>
		</div>
		<div class="modal-body pt-0">
			<div class="tab-menu-container d-flex flex-column flex-md-row align-items-center mb-3">
				<div [ngClass]="{ 'selected': activeTab==='share' }"
					class="tab-menu text-nowrap pointer py-2 text-center px-4" (click)="settingsTabSelect('share')">
					{{
					'sharing' | translate }}</div>
				<div [ngClass]="{ 'selected': activeTab==='optional' }"
					class="tab-menu text-nowrap pointer py-2 text-center px-4" (click)="settingsTabSelect('optional')">
					{{ 'optionalSettings' | translate }}
				</div>
				<div [ngClass]="{ 'selected': activeTab==='manage-user' }"
					class="tab-menu text-nowrap pointer py-2 text-center px-4"
					(click)="settingsTabSelect('manage-user')">
					{{ 'manageUser' | translate }}
				</div>
			</div>
			<div class="modal-content-detail">
				<div [ngClass]="{ 'd-block': activeTab==='share' }"
					[style.display]="activeTab==='share' ? 'block' : 'none'">
					<div class="assets-dropdown d-flex flex-md-row flex-column mb-md-3">
						<label class="info-label mb-0 py-2">{{ 'share' | translate }}</label>
						<div class="assets-dropdown flex-fill" *ngIf="reviewLinkVersionAssets"
							[ngClass]="{ 'has-error': formSubmitted && formError.assets && !selectedAssets.length }">
							<mtm-assets-dropdown [section]="section" [subSection]="subSection" [data]="subSectionAssets"
								[versionData]="reviewLinkVersionAssets" [value]="selectedAssets"
								(valueChange)="assetChangeHandler($event)"
								(selectedAssetsChange)="selectedAssetsChange($event)">
							</mtm-assets-dropdown>
							<span class="d-block validation-error-message"
								*ngIf="formSubmitted && formError.assets && !selectedAssets.length">{{'fieldRequired'|translate}}</span>
						</div>
					</div>
					<div class="assets-link-detail pt-2 pb-2 mb-4 d-none">
						<div class="d-flex flex-column">
							<label class="get-link-title ml-4 mr-4 mt-2 mb-2 _600">{{ 'getALink' | translate }}</label>
							<div class="ml-4 mr-4 mt-0 mb-2">
								<span>{{ 'anyoneAccessLink' | translate }}</span>
							</div>
						</div>
						<div class="d-flex align-items-center justify-content-end">
							<!--
							<i class="mtm-icon mtm-icon-copy-paste d-block w-18 h-18"></i>
							<span class="ml-2 mr-2 _1-line-text-ellipsis" #linkElement [style.max-width]="'300px'"
								[style.width]="'100%'" [style.white-space]="'nowrap'">{{shortedSharingUrl}}</span>
							<div class="d-inline-block pointer mr-3" (click)="copyToClipboard($event)">
								<i class="mtm-icon mtm-icon-share-link-red d-block w-28 h-28 mr-2 pointer ml-auto"
									[attr.title]="isExpired ? linkCopied + expirationDate : linkCopied + ' &#10003;'"></i>
							</div>
							-->

							<!-- <mtm-url-shortener [redirectionUrl]="shortedSharingUrl"></mtm-url-shortener> -->
						</div>
					</div>
					<div class="user-type-info mb-3 mt-3 mt-md-0">
						<div class="user-type-info-dropdown" ngbDropdown>
							<div class="d-flex align-items-center dropdown-toggle pointer p-0" ngbDropdownToggle>
								<div *ngIf="userType=='anyone'" class="d-flex align-items-center">
									<i class="mtm-icon mtm-icon-anyone mr-2"></i>
									<div class="d-flex flex-column">
										<label class="_bold m-0">{{'anyone'|translate}}</label>
										<div class="user-type-info">{{'anyoneInfo'|translate}}</div>
									</div>
								</div>
								<div *ngIf="userType=='restricted'" class="d-flex align-items-center">
									<i class="mtm-icon mtm-icon-restricted mr-2"></i>
									<div class="d-flex flex-column">
										<label class="_bold m-0">{{'restricted'|translate}}</label>
										<div class="user-type-info text-wrap">{{'restrictedInfo'|translate}}</div>
									</div>
								</div>
							</div>
							<div ngbDropdownMenu>
								<div ngbDropdownItem class="d-flex align-items-center pointer"
									(click)="selectUserType('anyone')">
									<i class="mtm-icon mtm-icon-anyone mr-2"></i>
									<div class="d-flex flex-column">
										<label class="_bold m-0">{{'anyone'|translate}}</label>
										<div class="user-type-info text-wrap">{{'anyoneInfo'|translate}}</div>
									</div>
								</div>
								<div ngbDropdownItem class="d-flex align-items-center pointer"
									(click)="selectUserType('restricted')">
									<i class="mtm-icon mtm-icon-restricted mr-2"></i>
									<div class="d-flex flex-column">
										<label class="_bold m-0">{{'restricted'|translate}}</label>
										<div class="user-type-info text-wrap">{{'restrictedInfo'|translate}}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="assets-reviewer-invite">
						<div class="d-flex flex-column">
							<div class="d-flex flex-column align-items-start">
								<div class="d-flex flex-column flex-fill mb-md-3 w-100">
									<div class="d-flex flex-column flex-md-row">
										<label class="info-label m-0 py-2">{{'With'|translate}}</label>
										<div class="inviter-dropdown-input mb-md-3 w-100">
											<mtm-inviter-dropdown [data]="inviteData" [(value)]="invitedUsers"
												(selectedInvitersChange)="selectedInvitersChange($event)">
											</mtm-inviter-dropdown>
										</div>
									</div>
									<div class="inviter-message">
										<div class="d-flex flex-column flex-md-row">
											<label class="info-label m-0 py-2">{{'Message'|translate}}</label>
											<div class="d-block w-100">
												<textarea [(ngModel)]="messageToUsers" name="messageToUsers"
													class="input-message p-2 w-100"
													placeholder="{{'writeAMessage' | translate}}"
													[style.border]="'1px solid #ced4da'"></textarea>
											</div>
										</div>
									</div>
								</div>
								<div
									class="nav-button d-flex flex-column flex-md-row align-items-start w-100 mt-3 mt-md-0">
									<a *ngIf="!isNewReviewLink" [class.disabled]="isBusy" shortenedUrl
										[sourceUrl]="reviewLinkUrl"
										class="mtm-button mtm-main-button copy-link-button pointer d-flex align-items-center justify-content-center c-full-white pt-2 pb-2 mb-2 mb-md-0"
										(click)="copyShortenedUrl($event)">
										<i class="mtm-icon-share-link-red review-link"
											[attr.title]="isExpired ? linkCopied + expirationDate : linkCopied"></i>
										<i class="mtm-icon mtm-icon-copy-link mr-2"></i>
										<span class="text-uppercase _500" (click)="$event.preventDefault()">{{ 'Copy
											Link' | translate }}</span>
									</a>
									<a *ngIf="isNewReviewLink" [class.disabled]="isBusy"
										class="mtm-button mtm-main-button copy-link-button pointer d-flex align-items-center justify-content-center c-full-white pt-2 pb-2 mb-2 mb-md-0"
										(click)="copyShortenedUrl($event)">
										<i class="mtm-icon-share-link-red review-link"
											[attr.title]="isExpired ? linkCopied + expirationDate : linkCopied"></i>
										<i class="mtm-icon mtm-icon-copy-link mr-2"></i>
										<span class="text-uppercase _500" (click)="$event.preventDefault()">{{ 'Copy
											Link' | translate }}</span>
									</a>
									<a [class.disabled]="isBusy"
										class="mtm-button mtm-main-button pointer d-flex align-items-center justify-content-center b-mtm-black c-full-white pt-2 pb-2 ml-0 ml-md-auto"
										(click)="saveReviewLink({ clear: true, closeModal: true })">
										<i class="fa fa-copy-paste"></i>
										<span class="text-uppercase _500">{{ 'Done' | translate }}</span>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div [ngClass]="{ 'd-block': activeTab==='optional' }"
					[style.display]="activeTab==='optional' ? 'block' : 'none'">
					<div class="optional-settings-content">
						<div class="d-flex align-items-center flex-column flex-md-row" [style.gap]="'1rem'">
							<div class="flex-fill w-100">
								<label class="ml-0 mr-0 mt-0 mb-2 _500">{{ 'passwordProtection' | translate }}</label>
								<div class="password-input-control d-flex align-items-center"
									[style.position]="'relative'">
									<input [(ngModel)]="passwordProtection"
										type="{{passwordProtection ? 'password' : 'text'}}"
										placeholder="{{'typePassword' | translate}}"
										class="form-control d-block text password-input pt-0 pb-0 pl-3 pr-3"
										(blur)="saveReviewLink()" />
								</div>
							</div>
							<div class="flex-fill w-100">
								<label class="ml-0 mr-0 mt-0 mb-2 _500">{{ 'linkExpiration' | translate }}</label>
								<div class="input-group">
									<input class="form-control date-picker" [(ngModel)]="linkExpirationDate"
										ngbDatepicker #linkExpirationDateElement="ngbDatepicker"
										placeholder="{{'linkExpirationPlaceholder' | translate}}" firstDayOfWeek="1"
										(click)="linkExpirationDateElement.toggle()" (blur)="saveReviewLink()">
									<div class="input-group-addon" (blur)="saveReviewLink()"
										(click)="linkExpirationDateElement.toggle()"
										[mtmDatePickerDirective]=linkExpirationDateElement>
										<i class="fa fa-calendar-plus-o fa-lg text-danger" aria-hidden="true"></i>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div [ngClass]="{ 'd-block': activeTab==='manage-user' }"
					[style.display]="activeTab==='manage-user' ? 'block' : 'none'">
					<div class="manage-users-header">
						<label class="m-0 _600 pb-2 pt-2">{{ 'theySee' | translate }}</label>
					</div>
					<div class="d-flex align-items-center flex-wrap manage-users-header-content" [style.gap]="'1rem'">
						<div [style.position]="'relative'" [style.z-index]="managedUsers.length - i"
							class="manage-users-email-container d-flex flex-column"
							*ngFor="let managedUser of managedUsers;let i = index">
							<div class="manage-users-email d-flex align-items-center p-2 w-100">
								<div class="d-flex flex-column w-100">
									<label class="m-0 _600 pointer">{{ managedUser.fullName }}</label>
									<span class="pointer">{{ managedUser.email }}</span>
								</div>
								<i [attr.title]="'The user will not be able to access the link'"
									class="ml-2 fa fa-times pointer blacklisted-tooltipped"
									(click)="removeManagedUser(managedUser)"></i>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>