import { Injectable } from '@angular/core';
import { ApiService } from "app/shared/services/api.service";
import { AuthService } from "app/shared/services/auth.service";
import { Subject, Observable } from "rxjs";

@Injectable({
	providedIn: 'root'
})
export class RoleLookService {

	constructor(private api: ApiService, public auth: AuthService) { }

	public getRoleLooks(projectId: string, sectionId: string, subsectionId: string): Observable<any> {
		let endpoint = `/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/roleLooks`;
		return this.api.setProfileAndImagesProbForItems(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/roleLooks`);
	}


	public saveRoleLook(projectId: string, sectionId: string, subsectionId: string, roleLook: any): Observable<any> {
		return this.api.httpPost(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/roleLooks`, roleLook);
	}



	public deleteRoleLook(projectId: string, sectionId: string, subsectionId: string, roleLookId: string): Observable<any> {
		return this.api.httpDelete(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/roleLooks/${roleLookId}`);
	}


	public getRoleLook(projectId: string, sectionId: string, subsectionId: string, roleLookId: string): Observable<any> {
		return this.api.httpGet(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/roleLooks/${roleLookId}`);
	}


	public getRoleLookFiles(projectId: string, sectionId: string, subsectionId: string, roleLookId: string): Observable<any> {
		return this.api.httpGet(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/items/${roleLookId}/files`);
	}

	public renameRoleLook(projectId: string, sectionId: string, subsectionId: string, roleLookId: string, newName: string): Observable<any> {
		return this.api.httpPut(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/roleLooks/${roleLookId}?newName=${newName}`, {});
	}


	public deleteRoleLookFile(projectId: string, sectionId: string, subsectionId: string, roleLookId: string, fileId: string): Observable<any> {
		return this.api.httpDelete(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/items/${roleLookId}/files/${fileId}`);
	}



	public saveAccessories(projectId: string, sectionId: string, subsectionId: string, roleLookId: string, accessories: any[]): Observable<any> {
		return this.api.httpPut(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/roleLooks/${roleLookId}/accesssories`, accessories);
	}

}
