<div class="modal-container">
  <div class="modal-header">
    <div class="d-flex flex-column align-items-center w-100">
      <div class="flag-wrapper mb-2 d-inline-block">
        <mtm-status-flag-milestone [isTransparentBg]="true" [status]="statusValue || ProjectStatus.IN_PROGRESS" [flagWidth]="30" [flagHeight]="30"></mtm-status-flag-milestone>
      </div>
      <h4 class="modal-title text-center text-uppercase">{{milestone?.name}}</h4>
    </div>
  </div>
  <div class="modal-body">
    <form [formGroup]="milestoneForm" (ngSubmit)="save()">
      <div class="d-flex flex-column flex-md-row align-items-center input-row">
        <div class="d-flex flex-column align-items-start field-container drive-date-field">
          <label>{{'milestoneStatusEditor_dueDate'|translate}}</label>
          <div class="input-group position-relative date-field" [class.empty-date]="!milestoneForm.controls?.driveDate?.value">
            <input class="form-control date-picker" formControlName="driveDate" ngbDatepicker [readOnly]="true"
              #driveDate="ngbDatepicker" placeholder="{{'milestoneStatusEditor_driveDate' | translate}}"
              firstDayOfWeek="1">
              <span *ngIf="milestoneForm.controls?.driveDate?.value" class="date-label">{{milestoneForm.controls.driveDate.value|ngbDate}}</span>
            <img src="/assets/img/dashboard/date-edit.svg" height="11" width="12" priority />
          </div>
        </div>
        <div class="d-flex flex-column align-items-start field-container actual-date-field">
          <label>{{'milestoneStatusEditor_actualDate'|translate}}</label>
          <div class="input-group position-relative date-field" [class.empty-date]="!milestoneForm.controls?.actualDate?.value">
            <input class="form-control date-picker" formControlName="actualDate" ngbDatepicker
              #actualDate="ngbDatepicker" placeholder="{{'milestoneStatusEditor_actualDate' | translate}}"
              firstDayOfWeek="1" (click)="actualDate.toggle()">
              <span *ngIf="milestoneForm.controls?.actualDate?.value" class="date-label">{{milestoneForm.controls.actualDate.value|ngbDate}}</span>
            <img src="/assets/img/dashboard/date-edit.svg" height="11" width="12" (click)="actualDate.toggle()"
              priority />
          </div>
        </div>

        <div class="field-container status-field">
          <label>{{'status'|translate}}</label>
          <div class="d-flex" ngbDropdown placement="bottom">
            <button class="d-flex align-items-center justify-content-center selected-status px-2 status-button" ngbDropdownToggle [style.cursor]="'default'"
              [class.status-in-progress]="statusValue === ProjectStatus.IN_PROGRESS"
              [class.status-upcoming-expiry]="statusValue === ProjectStatus.UPCOMING_EXPIRY"
              [class.status-delayed]="statusValue === ProjectStatus.DELAYED"
              [class.status-completed]="statusValue === ProjectStatus.COMPLETED">
              <mtm-status-flag-milestone [isTransparentBg]="statusValue != ProjectStatus.IN_PROGRESS" [status]="statusValue" [flagWidth]="24" [flagHeight]="24"></mtm-status-flag-milestone>
              <span class="status-label mx-2">{{ ('sectionStatus_' + statusValue) |
                translate}}</span>
              <!-- <i class="fa fa-caret-down" aria-hidden="true"></i> -->
            </button>
            <!-- <div ngbDropdownMenu>
              <a *ngFor="let statusItem of targetStatusList"
                class="dropdown-item d-flex align-items-center justify-content-center status-item px-2"
                [class.status-in-progress]="statusItem === ProjectStatus.IN_PROGRESS"
                [class.status-delayed]="statusItem === ProjectStatus.DELAYED"
                [class.status-completed]="statusItem === ProjectStatus.COMPLETED" (click)="changeStatus(statusItem)">
                <mtm-status-flag [status]="statusItem" [flagWidth]="21" [flagHeight]="21"></mtm-status-flag>
                <span class="status-label mx-2">{{ ('sectionStatus_' + statusItem) |
                  translate}}</span>
              </a>
            </div> -->
          </div>
        </div>
      </div>

      <div class="d-flex flex-column flex-md-row align-items-center justify-content-center mt-4 mb-1 buttons">
        <button type="button" class="btn btn-large text-uppercase cancel-btn" (click)="cancel()"
          [disabled]="isLoading">{{'cancel' | translate}}</button>
        <button type="submit" class="btn btn-large text-uppercase apply-btn" [disabled]="isLoading">{{'apply' |
          translate}}</button>
      </div>
    </form>
  </div>
</div>