import * as _ from 'lodash';
import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { ProjectService } from "./project.service";
import { Observable, Subject } from "rxjs";

@Injectable({
	providedIn: 'root'
})
export class ReviewLinkService {
	constructor(private apiService: ApiService,
		private projectService: ProjectService) { }

	/**
	 * TO UPDATE
	 *
	 * Need to change param depend on API needs
	 *
	 * @param params
	 * @returns
	 */
	getReviewLinks(projectId: any, pagingState?: any, includeExpired: boolean = true): Observable<any> {
		let subject = new Subject<any>();
		let url = `/api/reviewlink/project/${projectId}/page`;
		const params = [];
		if (pagingState) {
			params.push(`pagingState=${pagingState}`);
		}
		if (!includeExpired){
			params.push('includeExpired=${includeExpired}');
		}
		if(params.length > 0){
			url += `?${params.join('&')}`;
		}

		this.apiService.httpGet(url)
			.subscribe((result: any) => {
				subject.next(result);
			});
		return subject.asObservable();
	}

	getReviewLink(linkId, request = null): Observable<any> {
		return this.apiService.httpPost(`/api/reviewlink/${linkId}`, request);
	}

	getReviewLinkFromList(link): Observable<any> {
		return this.apiService.httpGet(`/api/reviewlink/project/${link.projectId}/${link.id}`);
	}

	createReviewLinks(projectId: any, params: any) {
		return this.apiService.httpPut(`/api/reviewlink/project/${projectId}`, params);
	}

	checkReviewLinkExistence(body) {
		return this.apiService.httpPost(`/api/reviewlink/check-availability`, body);
	}

	editReviewLinks(params: any[]) { }

	deleteReviewLinks(projectId: any, reviewLinkId: any) {
		return this.apiService.httpDelete(`/api/reviewlink/project/${projectId}/${reviewLinkId}`);
	}

	confirmUserIdentity(username: any, email: any) { }

	reviewerLogin(username: any, password: any) { }
}
