<div class="manage-versions-container">
  <div class="p-3 modal-header">
    <h4 class="modal-title">{{'edit_manageVersions' | translate }}</h4>
  </div>
  <div class="modal-body">

    <div *ngIf="option?.title" class="text-left option-title">{{option.title}}</div>
    <div class="py-2 versions" *ngIf="versions" [dragula]="'versions-modal'" [(dragulaModel)]="versions">
      <div class="mb-2 p-2 version" *ngFor="let version of versions">
        <div class="d-flex align-items-center  version-container">
          <button class="btn badge drag-button">
            <i class="fa fa-arrows fa-lg" aria-hidden="true"></i>
          </button>
          <!--
          <div class="media-wrapper">
            <mtm-preview-asset [asset]="version" [isEditSection]="true" [backgroundColor]="'transparent'"
              [showEmptyMediaIcon]="false">
            </mtm-preview-asset>
          </div>
          -->
          <div class="d-flex flex-column px-3 file-info">
            {{ 'V' + version.versionNumber }}
          </div>
          <div class="d-flex flex-column flex-grow-1 px-3 date" *ngIf="version.createdAt">
            {{ version.createdAt | mtmDateTime }}
          </div>
          <div class="d-flex ml-auto action-buttons">
            <button class="btn badge remove-button" (click)="deleteVersion(version)">
              <i class="fa fa-trash fa-lg text-danger" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-buttons">
      <button class="btn cancel-btn" (click)="cancel()" [disabled]="isLoading">{{'cancel' | translate }}</button>
      <button class="btn save-btn" (click)="save()" [disabled]="isLoading">{{'save' | translate }}</button>
    </div>
  </div>
</div>