import { Component, inject, Input, OnDestroy, OnInit } from "@angular/core";
import { NgbActiveModal, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { CampaignMilestone, DriveCampaign, ProjectStatusList } from "app/shared/interfaces";
import { FormBuilder, Validators } from "@angular/forms";
import { ProjectV2ServiceService } from "app/shared/services/project-v2-service.service";
import { DateHelperService } from "app/shared/services/date-helper.service";
import { OverlayService } from "app/shared/services/overlayService";
import { TranslatePipe } from "app/shared/pipes/translate.pipe";
import { AuthService } from "app/shared/services/auth.service";
import { environment } from "environments/environment";

@Component({
  selector: 'mtm-milestone-status-editor-cpd',
  templateUrl: './milestone-status-editor-cpd.component.html',
  styleUrls: ['./milestone-status-editor-cpd.component.scss']
})
export class MilestoneStatusEditorCPDComponent implements OnInit, OnDestroy {
  private fb = inject(FormBuilder);
  private activeModal = inject(NgbActiveModal);
  private lorealProjectService = inject(ProjectV2ServiceService);
  private overlayService = inject(OverlayService);
  private translatePipe = inject(TranslatePipe);

  authUser: any;

  private _milestone: CampaignMilestone;
  isLoading: boolean;

  targetStatusList: string[] = [ProjectStatusList.IN_PROGRESS, ProjectStatusList.COMPLETED, ProjectStatusList.DELAYED];
  minDate: NgbDateStruct;
  maxDate: NgbDateStruct;

  get milestone(): CampaignMilestone {
    return this._milestone;
  }

  @Input()
  set milestone(value: CampaignMilestone) {
    this._milestone = value;
    this.updateFormValues();
  }

  @Input() campaign: DriveCampaign;

  milestoneForm = this.fb.group({
    id: ['', {
      validators: [Validators.required],
    }],
    driveDate: [null, {
      validators: [Validators.required],
    }],
    actualDate: [null],
    status: [ProjectStatusList.IN_PROGRESS, {
      validators: [Validators.required]
    }],
  });

  ProjectStatus = ProjectStatusList;

  get statusValue(): string {
    return this.milestoneForm.get('status').value;
  }

  constructor(private authService: AuthService) {
  }

  ngOnInit() {
    this.authUser = this.authService.getAuthUser();
  }

  ngOnDestroy() {
  }

  private updateFormValues() {
    if (!this.milestone) {
      return;
    }

    let value: Partial<typeof this.milestoneForm.value> = {
      id: this.milestone.id,
      status: this.milestone.status || ProjectStatusList.IN_PROGRESS
    };

    if (this.milestone.driveDate) {
      value.driveDate = DateHelperService.fromDateToDatepicker(new Date(this.milestone.driveDate));
    }
    if (this.milestone.actualDate) {
      value.actualDate = DateHelperService.fromDateToDatepicker(new Date(this.milestone.actualDate));
    }

    this.milestoneForm.patchValue(value);
  }

  changeStatus(newStatus: string) {
    this.milestoneForm.patchValue({
      status: newStatus
    });
  }

  cancel() {
    this.activeModal.dismiss();
  }

  save() {
    if (!this.milestoneForm.valid || this.isLoading) {
      return;
    }

    this.isLoading = true;

    const newMilestone = this.milestoneForm.value;

    const actualDate = newMilestone.actualDate ? DateHelperService.fromDateToUTCString(DateHelperService.fromDatePickerToDate(newMilestone.actualDate)) : null;
    this.lorealProjectService.updateDateStatusMilestone(this.campaign.id, { id: newMilestone.id, actualDate })
      .subscribe({
        next: (res) => {
          this.isLoading = false;
          const timelines = JSON.parse(res.timelines) as any[];
          this.campaign.timelines = JSON.stringify(timelines);
          this.activeModal.close(newMilestone);
        },
        error: (error) => {
          this.isLoading = false;
          console.error('Error updating timeline', error);
          this.overlayService.showError(this.translatePipe.transform('overlaySaveError'));
        },
      });
  }

  adjustMilestoneData(existingMilestone: CampaignMilestone, newMilestone: Partial<typeof this.milestoneForm.value>) {
    existingMilestone.status = newMilestone.status;

    if (newMilestone.actualDate) {
      existingMilestone.actualDate = DateHelperService.fromDateToUTCString(DateHelperService.fromDatePickerToDate(newMilestone.actualDate));
    } else {
      existingMilestone.actualDate = null;
    }
  }

}
