/*
    Classes for registering and handling custom events for external interaction support.
    Will be bound to plugin object as a message gateway between external elements and the plugin.
*/

import mitt from 'mitt';

/*
    A centralized collection of event callbacks organized by component and name
    Main reference for external event api
    These events will be bound to the plugin on initialization of their respective components
    NOTE - 'self' as second param in each function is a workaround for transpiler not properly
    keeping this , so we pass in instance to use as this for each fn - can't rely on bind
    because this is rewritten from symbol registry in transpiler and it's not present
*/

const EventRegistry = {
  AnnotationState: {
    openAnnotation: (event, _this) => {
      _this.openAnnotationById(event.detail.id);
    },
    closeAnnotation: (event, _this) => {
      _this.closeAnnotationById(event.detail.id);
    },
    newAnnotation: (event, _this) => {
      _this.createAndAddAnnotation(event.detail);
    },
    newAnnotationUpdate: (event, _this) => {
      let annotation = _this.findAnnotation(event.detail.id);
      if (!annotation) _this.newAnnotationUpdate(event.detail);
    },
    destroyAnnotation: (event, _this) => {
      _this.destroyAnnotationById(event.detail.id, event.detail.callStateChange);
    },
    updateAnnotation: (event, _this) => {
      let annotation = _this.findAnnotation(event.detail.id);
      if (annotation) _this.updateAnnotationById(event.detail);
    },
    editAnnotation: (event, _this) => {
      const annotation = _this.findAnnotation(event.detail.annotation.id);
      if (annotation) _this.editAnnotation(annotation);
    },
    saveEditedAnnotation: (event, _this) => {
      let isWSCall = false;
      if (event.detail.isWSCall) isWSCall = event.detail.isWSCall;
      const annotation = _this.findAnnotation(event.detail.annotationId);
      if (annotation) _this.saveEditedAnnotation(event.detail.annotationId);
    },
    newComment: (event, _this) => {
      let isWSCall = false;
      if (event.detail.isWSCall) isWSCall = event.detail.isWSCall;
      const annotation = _this.findAnnotation(event.detail.annotationId);
      let comment;
      if (event.detail.commentCreated) comment = _this.findComment(event.detail.commentId);
      if (annotation && !event.detail.commentCreated && !comment) {
        annotation.commentList.createComment(event.detail.body, null, null, isWSCall);
      }
      else if (annotation && event.detail.commentCreated && !comment) {
        annotation.commentList.createComment(event.detail.body, event.detail.parentId, event.detail.commentCreated, isWSCall);
      }

    },
    destroyComment: (event, _this) => {
      const annotation = _this.findAnnotation(event.detail.annotation.id);
      let comment;
      if (event.detail.id) comment = _this.findComment(event.detail.id);
      if (annotation && comment) annotation.commentList.destroyComment(event);
    },
    updateComment: function updateComment(event, _this) {
      const comment = _this.findComment(event.detail.commentId);
      const annotation = _this.findAnnotation(event.detail.annotationId);
      if (comment && annotation) annotation.commentList.editComment(event, comment);
    },
    hoverInAnnotation: (event, _this) => {
      _this.hoverInAnnotation(event.detail.annotation.id);
    },
    hoverOutAnnotation: (event, _this) => {
      _this.hoverOutAnnotation();
    },
    clickInAnnotation: (event, _this) => {
      _this.clickInAnnotation(event.detail.annotation.id);
    },
    clickOutAnnotation: (event, _this) => {
      _this.clickOutAnnotation();
    }
  },
  Controls: {
    addingAnnotation: (event, _this) => {
      _this.startAddNew();
    },
    cancelAddingAnnotation: (event, _this) => {
      _this.cancelAddNew();
    },
    saveAnnotation: (event, _this) => {
      _this.saveNew();
    },
    saveGeneralComment: (event, _this) => {
      _this.saveNewGeneralComment();
    }
  },
  AnnotationComments: {
    toggleAnnotationMode: (event, _this) => {
      _this.toggleAnnotationMode();
    },
    setZoom: (event, _this) => {
      _this.setCanvasZoom(event.detail.zoom);
    },
    resetImageOrientation: (event, _this) => {
      _this.resetImageOrientation();
    },
    toggleImageMove: (event, _this) => {
      _this.toggleImageMove();
    },
    dispose: (event, _this) => {
      _this.disposeAll();
    },
    togglePiklorColors: (event, _this) => {
      _this.togglePiklorColors(event.detail);
    },
    resize: (event, _this) => {
      _this.resize(event.detail.width, event.detail.height, event.detail.resetViewpoint ?? false);
    }
  },
  SelectableShape: {
    changeActiveTool: (event, _this) => {
      const tool = event.detail.tool;
      _this.changeActiveTool(tool);
    }
  }
};

export class EventDispatcher {

  pluginReady: boolean;
  pendingEvts: any[];
  registeredListeners: any[];
  eventRegistry: any;
  eventEmitter: any;

  constructor() {
    this.pluginReady = false;
    this.pendingEvts = [];
    this.registeredListeners = [];
    this.eventRegistry = EventRegistry;
    this.eventEmitter = mitt();
  }

  // Use the EventRegistry to mass register events on each component initialization
  registerListenersFor(obj, className) {
    const matchingEvents = this.eventRegistry[className];
    if (matchingEvents) {
      Object.keys(matchingEvents).forEach(key => {
        // Don't register again if already in cached collection
        if (!~this.registeredListeners.indexOf(key)) {
          const callback = matchingEvents[key].bind(obj);
          this.registerListener(key, evt => {
            if (!this.pluginReady) return;
            this.logCallback(key, className, evt);
            callback(evt, obj);
          });
        }
      });
    }
  }

  // Bind a listener
  // Register internal listeners to make sure callbacks are not duped as modules are reloaded
  // register = false for consumer usage with more flexibility
  registerListener(type, callback, register = true) {
    this.eventEmitter.on(type, callback);
    if (register) this.registeredListeners.push(type);
  }

  // Unbind a listener
  unregisterListener(type) {
    this.eventEmitter.off(type);
    const i = this.registeredListeners.indexOf(type);
    this.registeredListeners.splice(i, 1);
  }

  // Trigger an event
  fire(type, data) {
    if (!this.pluginReady) return;
    Logger.log(['evt-dispatch-FIRE', type, data]);
    this.eventEmitter.emit(type, { detail: data });
  }

  teardown() {
    this.registeredListeners.forEach(type => {
      this.unregisterListener(type);
    });
  }

  logCallback(eventName, className, event) {
    Logger.log(['evt-dispatch-RECEIVE', `${eventName} (${className})`, event]);
  }
};