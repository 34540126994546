import * as _ from 'lodash';
import { OnInit, Component, Input, Output, OnDestroy, OnChanges, SimpleChanges } from "@angular/core";
import { EventEmitter } from '@angular/core';

@Component({
	selector: 'mtm-assets-dropdown',
	templateUrl: './mtm-assets-dropdown.component.html',
	styleUrls: ['./mtm-assets-dropdown.component.scss']
})
export class MtmAssetsDropdownComponent implements OnInit, OnChanges, OnDestroy {
	@Input() section: any;
	@Input() subSection: any;
	@Input() data: any[] = [];
	@Input() versionData: any;
	@Output() selectedAssetsChange: EventEmitter<any> = new EventEmitter();

	isSelectAll: boolean = false;
	isOpen: boolean = false;
	component: any = this;
	moreValue: any = 0;

	modelValue: any = [];
	@Input()
	get value() {
		return this.modelValue || [];
	};
	@Output() valueChange: EventEmitter<any> = new EventEmitter();
	set value(val) {
		_.map(this.data, (asset) => {
			asset.isSelected = _.filter(val, (selectedAsset) => asset.optionId === selectedAsset.optionId).length > 0;
		});
		this.modelValue = val;
		this.isSelectAll = (this.modelValue.length === this.data.length) && this.modelValue.length > 0;
		this.valueChange.emit(this.modelValue);
	}

	constructor() {
		let component = this;
		window.addEventListener('click', (e) => {
			let dropdownComponent = $(e.target).closest('.mtm-assets-dropdown') || [];
			if (!dropdownComponent.length && component.isOpen) {
				component.isOpen = false;
			}
		});
	}

	ngOnChanges(changes: SimpleChanges): void { }

	ngOnInit() {
		this.versionData.isExpanded = !!this.value?.length;
		this.versionData.isSelected = !!this.value?.length;
	}

	ngOnDestroy() {
		let component = this;
		window.removeEventListener('click', (e) => {
			let dropdownComponent = $(e.target).closest('.mtm-assets-dropdown') || [];
			if (!dropdownComponent.length && component.isOpen) {
				component.isOpen = false;
			}
		});
	}

	toggleDropdown() {
		this.isOpen = !this.isOpen;
	}

	getValueCaptions() {
		if (!this.value.length) {
			return '';
		}
		this.moreValue = 0;
		let firstValue = this.value[0] || {};
		let result = firstValue.title || firstValue.name || firstValue.language || firstValue.accessory || firstValue.elementOfSetDesign || firstValue.articleOfCostumeDesign;
		if (this.value.length > 1) {
			for (let i = 1; i < this.value.length - (this.value.length - 2); i++) {
				let secondValue = this.value[i] || {};
				result = result + ', ' + (secondValue.title || secondValue.name || secondValue.language || secondValue.accessory || secondValue.elementOfSetDesign || secondValue.articleOfCostumeDesign);
			}
			this.moreValue = this.value.length - 2;
		}
		return result;
	}

	selectAllAssetsInSubSection() {
		this.isSelectAll = !this.isSelectAll;
		this.versionData.isSelected = this.isSelectAll;
		_.map(this.data, (data) => {
			data.isSelected = this.isSelectAll;
			return data;
		});
		this.versionData.topics.map((topic) => {
			topic.isSelected = this.isSelectAll;
			topic.assets.map((a) => {
				a.isSelected = this.isSelectAll;
				a.simpleVersions.map(v => {
					v.isSelected = this.isSelectAll;
					return v;
				})
				return a;
			});
			return topic;
		});
		this.setModelValue();
		this.isSelectAll = this.value.length === this.data.length;
		this.selectedAssetsChange.emit(this.modelValue);
	}

	selectTopicAssets(topic) {
		topic.isSelected = !topic.isSelected;
		topic.assets.map(a => {
			a.isSelected = topic.isSelected;
			a.simpleVersions.map(v => {
				v.isSelected = a.isSelected;
				return a;
			})
			return a;
		});
		this.versionData.isSelected = this.versionData.topics.filter(t => t.isSelected).length;
		this.setModelValue();
		this.isSelectAll = this.value.length === this.data.length;
		this.selectedAssetsChange.emit(this.modelValue);
	}


	selectAllAsset(topic, asset) {
		asset.isSelected = !asset.isSelected;
		asset.simpleVersions.map(v => {
			v.isSelected = asset.isSelected;
			return v;
		});
		topic.isSelected = topic.assets.filter(a => a.isSelected).length;
		this.versionData.isSelected = this.versionData.topics.filter(t => t.isSelected).length;
		this.setModelValue();
		this.isSelectAll = this.value.length === this.data.length;
		this.selectedAssetsChange.emit(this.modelValue);
	}

	selectVersionAsset(topic, asset, version) {
		version.isSelected = !version.isSelected;
		asset.isSelected = asset.simpleVersions.filter(v => v.isSelected).length;
		topic.isSelected = topic.assets.filter(a => a.isSelected).length;
		this.versionData.isSelected = this.versionData.topics.filter(t => t.isSelected).length;
		this.setModelValue();
		this.isSelectAll = this.value.length === this.data.length;
		this.selectedAssetsChange.emit(this.modelValue);
	}

	setModelValue() {
		this.value = _.filter(this.data, (data) => data.isSelected);
	}

}
