import { Injectable } from '@angular/core';
import { ApiService } from "app/shared/services/api.service";
import { AuthService } from "app/shared/services/auth.service";
import { Subject, Observable } from "rxjs";

@Injectable({
	providedIn: 'root'
})
export class AccessoriesService {

	constructor(private api: ApiService, public auth: AuthService) { }


	public getAccessories(projectId: string, sectionId: string, subsectionId: string): Observable<any> {
		return this.api.setProfileAndImagesProbForItems(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-accessories`);
	}

	public getAccessoriesSimple(projectId: string, sectionId: string, subsectionId: string): Observable<any> {
		return this.api.httpGet(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-accessories-simple`);
	}


	public saveAccessory(projectId: string, sectionId: string, subsectionId: string, accessory: any): Observable<any> {
		return this.api.httpPost(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-accessories?topicId=null&topic=null`, accessory);
	}

	public renameAccessory(projectId: string, sectionId: string, subsectionId: string, accessoryId: string, newName: string): Observable<any> {
		return this.api.httpPut(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-accessories/${accessoryId}?newName=${newName}`, {});
	}

	public deleteAccessory(projectId: string, sectionId: string, subsectionId: string, accessoryId: string): Observable<any> {
		return this.api.httpDelete(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-accessories/${accessoryId}`);
	}


	public getAccessory(projectId: string, sectionId: string, subsectionId: string, accessoryId: string): Observable<any> {
		return this.api.httpGet(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-accessories/${accessoryId}`);
	}

	public getAccessorySimple(projectId: string, sectionId: string, subsectionId: string, accessoryId: string): Observable<any> {
		return this.api.httpGet(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-accessories-simple/${accessoryId}`);
	}

	public getAccessoryfiles(projectId: string, sectionId: string, subsectionId: string, accessoryId: string): Observable<any> {
		return this.api.httpGet(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/items/${accessoryId}/files`);
	}


	public deleteAccessoryFile(projectId: string, sectionId: string, subsectionId: string, accessoryId: string, fileId: string): Observable<any> {
		return this.api.httpDelete(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/items/${accessoryId}/files/${fileId}`);
	}


}
