import * as _ from 'lodash';
import { Component, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { UntypedFormGroup } from "@angular/forms";
import { OverlayService } from 'app/shared/services/overlayService';
import { UUID } from 'angular2-uuid';
import { TranslatePipe } from 'app/shared/pipes/translate.pipe';
import { HelperService } from 'app/shared/services/helper.service';
import { onMtmDropdownClickHandler } from '..';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from "@angular/router";
const REFERENCE_DIRECTIONS = {
	FORM: 'FORM',
	OBJECT: 'OBJECT',
	ONLY_EVENT: 'ONLY_EVENT'
}

@Component({
	selector: 'mtm-dropdown',
	templateUrl: './mtm-dropdown.component.html',
	styleUrls: ['./mtm-dropdown.component.scss']
})
export class MTMDropdown {
	@Input() customBgCode: string = '#ECEEF4';
	@Input() customClass: string = '';
	@Input() isDisplaySearchBox: boolean = true;
	@Input() isDisabled: boolean = false;
	@Input() hideSelectedItem: boolean = false;
	@Input() dotItemSelectedText: String = 'itemSelected';
	@Input() dotItemsSelectedText: String = 'itemsSelected';
	@Input() isDefaultBorder: boolean = false;
	@Input() isDangerBorder: boolean = false;
	@Input() isDangerBorderForFormInValid: boolean = false;
	/* To be translated  translation key is select */
	@Input() title: string = 'Select';
	@Input() emptyListInfoText: string = '';
	@Input() items: any[] = [];
	@Input() itemLabelAccessor: string; // if that value isn't null then assumes object type the each item of items
	@Input() itemValueAccessor: string;
	@Input() isMultipleSelect: boolean = false;
	//component run like formControl with this way
	@Input() form: UntypedFormGroup;
	@Input() formControlAccessor: string;
	//component run like ngModel with this way
	@Input() refObject: any;
	@Input() objectVariableAccessor: string;
	@Input() objectVariable: any | any[];
	@Input() emptyValue: any;
	@Input() preventEmptyValue: boolean = false;
	@Input() suppressEmptyMessage: boolean = false;
	@Input() emptyValueAsValue: boolean = false;
	@Output() onChangeValue: EventEmitter<any | any[]> = new EventEmitter<any | any[]>();
	@Output() onChangeValueObject: EventEmitter<any | any[]> = new EventEmitter<any | any[]>();
	@Input() skipCheckTimeInterval: boolean;
	@Input() singleSelectionInMultipleValues: boolean = false;
	@Input() showSelectedItem: boolean = false;
	@Output() open: EventEmitter<any> = new EventEmitter<any>();
	/* ----------- * - * ----------- */
	protected isShowList: boolean = false;
	protected isItemsObjectType: boolean = true;
	protected REFERENCE_DIRECTION: String = '';

	protected displayList: { id: any, label: string, isChecked: boolean }[] = [];
	protected selectedItems: any[] = [];

	protected searchValue: string = '';

	checkInterval: any;
	id = UUID.UUID();
	selectedItem: any = {};
	hoverId: any;
	ngUnsubscribe = new Subject();

	constructor(private overlayService: OverlayService,
		 private router: Router,
		 private translate: TranslatePipe) {
		onMtmDropdownClickHandler.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(id => {
			if (this.id !== id) {
				if (this.isShowList) {
					this.isShowList = false;
				}
			} else {
				if (this.isMultipleSelect) {
					if (this.isShowList) {
						this.isShowList = false;
					}
				}
			}
			this.open.emit({ isOpen: this.isShowList, id: this.id });
		})
	}

	@HostListener('document:click', ['$event'])
	documentClick(e: any) {
		const parent = e.target.closest('mtm-dropdown');
		if (!parent) {
			this.isShowList = false;
		}
		this.open.emit({ isOpen: this.isShowList, id: this.id });
	}

	ngOnInit() {
		this.init();
	}

	ngOnChanges() {
		this.init();
	}

	ngOnDestroy() {
		this.breakInterval();
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	private breakInterval() {
		if (this.checkInterval)
			clearInterval(this.checkInterval);
	}
	private confInterval() {
		this.breakInterval();
		this.checkInterval = setInterval(() => {
			if (!this.isShowList) {
				this.selectedItem = {};
				this.init();
			}
			this.open.emit({ isOpen: this.isShowList, id: this.id });
		}, 1250);
	}

	toggleList(e, val) {

		if (this.isDisabled) {
			return;
		}

		e.stopImmediatePropagation();

		this.isShowList = !this.isShowList;

		this.selectedItem = this.isShowList ? val : {};

		this.open.emit({ isOpen: this.isShowList, id: this.id });
	}
	clickOutside(e) {
		this.isShowList = false;
		this.selectedItem = {};
		this.open.emit({ isOpen: this.isShowList, id: this.id });
	}

	private init() {
		this.breakInterval();
		if (!this.skipCheckTimeInterval) {
			this.confInterval();
		}

		this.isItemsObjectType = this.itemLabelAccessor ? true : false;
		if (this.form && this.formControlAccessor)
			this.REFERENCE_DIRECTION = REFERENCE_DIRECTIONS.FORM;
		else if (this.refObject && this.objectVariableAccessor)
			this.REFERENCE_DIRECTION = REFERENCE_DIRECTIONS.OBJECT;
		else
			this.REFERENCE_DIRECTION = REFERENCE_DIRECTIONS.ONLY_EVENT;

		this.displayList = [];
		if (this.isItemsObjectType)
			this.items.map(item => this.addItemToDisplayList(item[this.itemValueAccessor], item[this.itemLabelAccessor], this.isSelected(item[this.itemValueAccessor])));
		else
			this.items.map((item, index) => this.addItemToDisplayList(index, item, this.isSelected(item)));

		this.selectedItems = this.displayList.filter(item => item.isChecked);
		this.checkEmptyValue();
	}
	private addItemToDisplayList(id, label, isChecked) {
		this.displayList.push({
			id: id,
			label: label,
			isChecked: isChecked
		})
	}
	private isSelected(value) {
		if (!this.isMultipleSelect)
			switch (this.REFERENCE_DIRECTION) {
				case REFERENCE_DIRECTIONS.FORM: return this.form.controls[this.formControlAccessor].value == value;
				case REFERENCE_DIRECTIONS.OBJECT: return _.get(this.refObject, this.objectVariableAccessor) == value;
				default: return false;
			}
		else {
			let list;
			switch (this.REFERENCE_DIRECTION) {
				case REFERENCE_DIRECTIONS.FORM: list = this.form.controls[this.formControlAccessor].value; break;
				case REFERENCE_DIRECTIONS.OBJECT: list = _.get(this.refObject, this.objectVariableAccessor); break;
				default: list = [];
			}

			if (list == null || list == "") {
				list = []
			}

			return list.filter(k => k == value).length > 0;
		}
	}

	onSelectItem(item) {

		if (this.router.url.startsWith('/projects/edit') && item.isChecked) {
			this.overlayService.showError(this.emptyListInfoText, 'Error');
			return;
		}
		item.isChecked = !item.isChecked;
		if (item.isChecked) {
			if (this.router.url.startsWith('/projects/edit')) {
				this.selectedItems.forEach(itm => {
					if (itm.isChecked) {
						itm.isChecked = false;
					}
				});
				this.selectedItems = [];
				this.selectedItems.push(item);
				item.isChecked = true;
			} else {
				if (this.isMultipleSelect)
					this.selectedItems.push(item);
				else {
					this.selectedItems.map(item => item.isChecked = false);
					this.selectedItems = [item]
				}
			}
		} else {
			if (this.selectedItems.length == 1 && this.preventEmptyValue) {
				item.isChecked = true;
				if(!this.suppressEmptyMessage){
					this.overlayService.showError(this.emptyListInfoText, 'Error');
				}
				this.isShowList = false;
				return;
			}
			this.selectedItems.splice(this.selectedItems.findIndex(k => k.id == item.id), 1);
		}

		let selectedItemsValues: any[] = [];
		let selectedItemsItself: any[] = [];
		if (this.isItemsObjectType) {
			selectedItemsValues = this.selectedItems.map(item => { return item.id });
			selectedItemsItself = this.items.filter(item => this.selectedItems.filter(k => k.id == item[this.itemValueAccessor]).length > 0);
		} else {
			selectedItemsValues = this.selectedItems.map(item => { return item.label });
			selectedItemsItself = this.items.filter((item, index) => this.selectedItems.filter(k => k.id == index).length > 0);
		}

		if (this.isMultipleSelect) {
			switch (this.REFERENCE_DIRECTION) {
				case REFERENCE_DIRECTIONS.FORM:
					this.form.controls[this.formControlAccessor].setValue(selectedItemsValues);
					break;
				case REFERENCE_DIRECTIONS.OBJECT:
					this.refObject[this.objectVariableAccessor] = selectedItemsValues;
					break;
			}

			this.onChangeValue.emit(selectedItemsValues);
			this.onChangeValueObject.emit(selectedItemsItself);
		} else {
			let value = selectedItemsValues.length > 0 ? selectedItemsValues[0] : null;

			switch (this.REFERENCE_DIRECTION) {
				case REFERENCE_DIRECTIONS.FORM:
					this.form.controls[this.formControlAccessor].setValue(value == null ? '' : value);
					break;
				case REFERENCE_DIRECTIONS.OBJECT:
					_.set(this.refObject, this.objectVariableAccessor, value);
					break;
			}
			this.isShowList = !this.isShowList;
			this.onChangeValue.emit(value);
			this.onChangeValueObject.emit(value);
			this.open.emit({ isOpen: this.isShowList, id: this.id });
		}
		this.checkEmptyValue();
	}

	isEmptyValueSelected: any[] = [];
	checkEmptyValue() {
		if (!this.emptyValue) {
			return;
		}
		const isAlreadyEmbedded = _.filter(this.displayList, { [this.itemValueAccessor]: this.emptyValue[this.itemValueAccessor] });
		if (!isAlreadyEmbedded.length) {
			this.displayList = _.concat([this.emptyValue], this.displayList);
		}
		this.isEmptyValueSelected = _.filter(this.selectedItems, { [this.itemValueAccessor]: this.emptyValue[this.itemValueAccessor] });
		if (this.isEmptyValueSelected.length && (this.selectedItems.length)) {
			if (!this.emptyValueAsValue) {
				this.emptyValue.isChecked = true;
				this.selectedItems = [];
				this.selectedItems.push(this.emptyValue);
			}
		} else {
			if (!this.selectedItems.length) {
				if (!this.emptyValueAsValue) {
					this.emptyValue.isChecked = true;
					this.selectedItems.push(this.emptyValue);
				}
			} else {
				if (!this.emptyValueAsValue) {
					this.emptyValue.isChecked = false;
					this.selectedItems = _.filter(this.selectedItems, (item) => item[this.itemValueAccessor] !== this.emptyValue[this.itemValueAccessor]);
				}
			}
		}
	}

	getId() {
		return this.id;
	}

	getEmptyCaption() {
		if (this.emptyValueAsValue) {
			let selected = _.filter(this.displayList, (d) => d.isChecked);
			if (selected.length) {
				return '0 ' + this.translate.transform(this.dotItemSelectedText || '');
			} else {
				return this.translate.transform(this.title || '');
			}
		} else {
			return this.translate.transform(this.title || '');
		}

	}

	getSelectedLength() {
		if (this.emptyValueAsValue) {
			let selected = _.filter(this.displayList, (d) => d.isChecked && d[this.itemValueAccessor] !== this.emptyValue[this.itemValueAccessor]);
			return selected.length.toString();
		} else {
			return this.selectedItems.length.toString();
		}
	}

	getSelectedItems() {
		if (this.emptyValueAsValue) {
			let selected = _.filter(this.displayList, (d) => d.isChecked);
			return selected;
		} else {
			let keys = ['label', 'id'],
				filtered = this.selectedItems.filter(
					(s => o =>
						(k => !s.has(k) && s.add(k))
							(keys.map(k => o[k]).join('|'))
					)
						(new Set)
				);
			this.selectedItems = filtered;
			return this.selectedItems;
		}
	}

	addSelected() {
		let idx = this.selectedItems.find(dt => !dt.id);
		if (!idx) {
			this.selectedItems.push({});
		}
	}

	selectSingleValue(val, item, index) {
		let idx = this.selectedItems.indexOf(dt => dt[this.itemValueAccessor] === val[this.itemValueAccessor]);
		if (idx > -1) {
			this.selectedItems[idx] = item;
		} else {
			item.isChecked = true;
			this.selectedItems[index] = item;
		}

		this.selectedItems = _.uniqBy(this.selectedItems, 'id');

		let selectedItemsValues: any[] = [];
		let selectedItemsItself: any[] = [];
		if (this.isItemsObjectType) {
			selectedItemsValues = this.selectedItems.map(item => { return item.id });
			selectedItemsItself = this.items.filter(item => this.selectedItems.filter(k => k.id == item[this.itemValueAccessor]).length > 0);
		} else {
			selectedItemsValues = this.selectedItems.map(item => { return item.label });
			selectedItemsItself = this.items.filter((item, index) => this.selectedItems.filter(k => k.id == index).length > 0);
		}

		if (this.isMultipleSelect) {
			switch (this.REFERENCE_DIRECTION) {
				case REFERENCE_DIRECTIONS.FORM:
					this.form.controls[this.formControlAccessor].setValue(selectedItemsValues);
					break;
				case REFERENCE_DIRECTIONS.OBJECT:
					_.set(this.refObject, this.objectVariableAccessor, selectedItemsValues);
					break;
			}

			this.onChangeValue.emit(selectedItemsValues);
			this.onChangeValueObject.emit(selectedItemsItself);
		} else {
			let value = selectedItemsValues.length > 0 ? selectedItemsValues[0] : null;

			switch (this.REFERENCE_DIRECTION) {
				case REFERENCE_DIRECTIONS.FORM:
					this.form.controls[this.formControlAccessor].setValue(value == null ? '' : value);
					break;
				case REFERENCE_DIRECTIONS.OBJECT:
					_.set(this.refObject, this.objectVariableAccessor, value);
					break;
			}
			this.isShowList = !this.isShowList;
			this.onChangeValue.emit(value);
			this.onChangeValueObject.emit(value);
			this.open.emit({ isOpen: this.isShowList, id: this.id });
		}
		this.checkEmptyValue();
	}

	clearItem($event) {
		if(this.isDisabled) {
			return;
		}

		$event.isChecked = !!$event.isChecked;
		this.onSelectItem($event);
	}

	setHoverId(id) {
		this.hoverId = id;
	}

	checkLableLength(index) {
		const element = document.getElementById('labelText' + index);
		if (0 > element?.clientWidth - element?.scrollWidth) {
			return true;
		}
		return false;
	}
}
