import { Pipe, PipeTransform } from "@angular/core";
import { HelperService } from "../services/helper.service";

@Pipe({
  name: 'fileDisplayName',
})
export class FileDisplayNamePipe implements PipeTransform {
  transform(originalName: string, isExtensionless: boolean = false): string {
    if (!originalName) {
      return '';
    }

    const extensionParts = originalName.split('.');
    const extension = extensionParts.length > 1 ? extensionParts.pop() : '';
    const nameParts = extensionParts.join('.').split('_');
    const isTime = HelperService.isTimestamp(nameParts[nameParts.length - 1]);
    if (isTime) {
      nameParts.pop();
    }
    return nameParts.join('_') + (isExtensionless ? '' : (extension ? ('.' + extension) : ''));
  }
}
