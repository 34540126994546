import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { onLoadProject, ProjectService } from 'app/shared/services/project.service';


@Injectable({
	providedIn: 'root'
})
export class HairDresserService {
	constructor(private serviceApi: ApiService,
		private serviceProject: ProjectService) {
	}

	public getpreProdShootingBoardId(projectId): Observable<any> {
		return new Observable(observe => {

			let findSubSectionId = () => {
				try {
					let sectionIndex = this.serviceProject.sections.findIndex(k => k.section == "PRE_PRODUCTION");
					if (sectionIndex < 0) {
						observe.error();
						return;
					}

					let index = this.serviceProject.sections[sectionIndex].projectSubSections.findIndex(k => k.subSection == "CASTING");
					if (index < 0) {
						observe.error();
						return;
					}

					observe.next(this.serviceProject.sections[sectionIndex].projectSubSections[index]);
					observe.complete();
				} catch (err) {
					console.log(err);
					observe.error();
				}
			}

			try {
				if (this.serviceProject.projectOnloaded)
					findSubSectionId();
				else {
					onLoadProject.subscribe(() => {
						findSubSectionId();
					});
				}
			} catch (error) {
				observe.error();
			}
		});
	}

	public saveHairStyle(projectId, sectionId, subsectionId, hairStyle: any): Observable<any> {
		return this.serviceApi.httpPost(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/hairStyles`, hairStyle);
	}
	public getHairStyle(projectId, sectionId, subsectionId, hairStyleId): Observable<any> {
		return this.serviceApi.httpGet(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/hairStyles/${hairStyleId}`);
	}
	public getAllHairStyle(projectId, sectionId, subsectionId): Observable<any> {
		return this.serviceApi.setProfileAndImagesProbForItems(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/hairStyles`);
	}
	public removeHairStyle(projectId, sectionId, subsectionId, hairStyleId): Observable<any> {
		return this.serviceApi.httpDelete(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/hairStyles/${hairStyleId}`);
	}

	public renameHairStyle(projectId: string, sectionId: string, subsectionId: string, accessoryId: string, newName: string): Observable<any> {
		return this.serviceApi.httpPut(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/hairStyles/${accessoryId}?newName=${newName}`, {});
	}

	public saveAccessory(projectId, sectionId, subsectionId, accessory: any): Observable<any> {
		return this.serviceApi.httpPost(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-hairdresser-accessories`, accessory);
	}
	public getAccessory(projectId, sectionId, subsectionId, accessoryId): Observable<any> {
		return this.serviceApi.httpGet(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-hairdresser-accessories/${accessoryId}`);
	}

	public renameHairdresserAccessory(projectId: string, sectionId: string, subsectionId: string, accessoryId: string, newName: string): Observable<any> {
		return this.serviceApi.httpPut(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-hairdresser-accessories/${accessoryId}?newName=${newName}`, {});
	}

	public getAccessorySimpleInfo(projectId, sectionId, subsectionId, accessoryId): Observable<any> {
		return this.serviceApi.httpGet(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-hairdresser-accessories-simple/${accessoryId}`);
	}

	public getAllAccessories(projectId, sectionId, subsectionId): Observable<any> {
		let endpoint = `/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-hairdresser-accessories`;
		return this.serviceApi.setProfileAndImagesProbForItems(endpoint);
	}
	public removeAccessory(projectId, sectionId, subsectionId, accessoryId): Observable<any> {
		return this.serviceApi.httpDelete(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-hairdresser-accessories/${accessoryId}`);
	}
}
