import { Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  FileTransferService,
  listenerTranscodingFileReplaced,
  MTMCustomFile,
  TransferType,
  UploadRequest
} from "../../../services/signed-url";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

export enum FailedTranscodingTypes {
  Pdf = 'pdf',
  Png = 'png',
}

@Component({
  selector: 'mtm-pdf-transcode-error',
  templateUrl: './pdf-transcode-error.component.html',
  styleUrls: ['./pdf-transcode-error.component.scss']
})
export class PdfTranscodeErrorComponent implements OnInit, OnDestroy {
  @Input() file: any;
  @Input() transcodingType: FailedTranscodingTypes = FailedTranscodingTypes.Pdf;
  @Output() fileReplaced: EventEmitter<any> = new EventEmitter<any>();

  private transferService = inject(FileTransferService);
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  ngOnInit() {
    listenerTranscodingFileReplaced
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((file: MTMCustomFile) => {
        if (file?.extraInfo?.failedTranscodingFileId === this.file?.id) {
          console.log('file replaced', file, this.file);
          this.fileReplaced.emit(file);
        }
      })
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  get title(): string {
    if (this.transcodingType === FailedTranscodingTypes.Pdf) {
      return 'mediaPlayer_officeTranscodingFailed_title';
    } else {
      return 'mediaPlayer_photoshopTranscodingFailed_title';
    }
  }

  get description(): string {
    if (this.transcodingType === FailedTranscodingTypes.Pdf) {
      return 'mediaPlayer_officeTranscodingFailed_description';
    } else {
      return 'mediaPlayer_photoshopTranscodingFailed_description';
    }
  }

  get lastStep(): string {
    if (this.transcodingType === FailedTranscodingTypes.Pdf) {
      return 'mediaPlayer_officeTranscodingFailed_lastStep';
    } else {
      return 'mediaPlayer_photoshopTranscodingFailed_lastStep';
    }
  }

  get uploadFilter(): string {
    if (this.transcodingType === FailedTranscodingTypes.Pdf) {
      return 'PDF_FILTER';
    } else {
      return 'PNG_FILTER';
    }
  }

  onFileSelected(files: any) {
    if (!files.files.length) {
      return;
    }

    const file = files.files[0];
    let mtmFile = new MTMCustomFile(file, 0);
    mtmFile.transferType = TransferType.TranscodingFailed;
    mtmFile.extraInfo.failedTranscodingFileId = this.file.id;
    mtmFile.extraInfo.failedTranscodingReplacementType = this.transcodingType;

    const request: UploadRequest = {
      files: [mtmFile],
      uploadType: TransferType.TranscodingFailed
    }

    this.transferService.uploadFiles(request);
  }

  protected readonly FailedTranscodingTypes = FailedTranscodingTypes;
}
