<div class="card">
    <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
        <div class="row card-header mt-4 p-0">
            <div class="col-10">
                <h6 class="header-title">{{'heretimeline' | translate}}</h6>
                <p class="header-description mt-2">{{'adjustMad' | translate}}</p>
            </div>
            <div class="col d-flex justify-content-end">
                <button class="preview-btn _700 f-12 text-light text-uppercase" (click)="previewTimeline()">
                  {{'editDriveCampaign' | translate}}
                </button>
            </div>
        </div>
        <!-- timeline block -->
        <div class="mt-4" *ngIf="timelineData">
            <mtm-timeline-blocks [editTimelineComponent]="true" [timeLineData]="timelineData" (timelineDataUpdated)="handleTimelineDataUpdate($event)"></mtm-timeline-blocks>
        </div>
        <div class="mt-4" *ngIf="!timelineData">
          <mtm-timeline-skeleton></mtm-timeline-skeleton>
        </div>
        <div class="row mt-3">
            <div class="col-3">
                <span class="_700 f-12">{{'expectedMad' | translate}}</span>
                <div class="row">
                    <div class="col mt-3">
                        <input disabled="" type="text" class="mad-input w-100"
                            value="{{expectedMad}}">
                    </div>
                </div>
            </div>
            <div class="col-3">
                <span class="_700 f-12">{{'changeMad' | translate}}</span>
                <div class="row mt-3">
                    <div class="col">
                        <div class="input-group h-100 mb-4 ml-2">
                            <input [disabled]="!canEditMad" class="form-control h-100 date-picker" ngbDatepicker #changedMad="ngbDatepicker" firstDayOfWeek="1" (click)="changedMad.toggle()" (ngModelChange)="madDateChanged($event)" formControlName="changedMad">
                                <div class="input-group-addon d-flex flex-column justify-content-center" (click)="changedMad.toggle()" [mtmDatePickerDirective]=changedMad>
                                  <img src="../../../assets/img/square-calendar.svg" alt="">
                                </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <span class="_700 f-12">{{'changeReason' | translate}}</span>
                <div class="row mt-2 mr-1">
                    <div class="col mt-2">
                        <div class="select-wrapper mr-3">
                            <select class="dropdown-toggle section-filter"
                                (click)="getReasonCategory(isChangeReasonDropdownOpen,$event)">{{selectedReasonCategory}}
                                <option class="option-hover" [value]="null">{{'selectReason' | translate}}</option>
                                <option class="option-hover" *ngFor="let category of reasonCategory" [value]="category">{{category}}</option>
                            </select>
                        </div>
                        <!-- <div class="col p-0">
                            <div class="mt-2">
                                <div class=""
                                    [ngClass]="{'reasonCatDropDown' : isChangeReasonDropdownOpen, 'reasonCatDropDownclose': !isChangeReasonDropdownOpen}">
                                    <a (click)="changeReasonDropDown(isChangeReasonDropdownOpen)"
                                        class=" d-flex align-items-center btn mt-0 mb-0">
                                        <span class="">{{selectedReasonCategory}}</span>
                                        <i class="caret-postion"></i>
                                    </a>
                                </div>
                            </div>
                            <div *ngIf="isChangeReasonDropdownOpen">
                                <div>
                                    <div class="reasonCatDropDownopen" *ngFor="let category of reasonCategory">
                                        <div class="col d-flex mt-3"
                                            (click)="getReasonCategory(isChangeReasonDropdownOpen,category)">
                                            <p class="_400 f-12">{{category.name}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    </div>
                    <div class="col mt-2">
                        <input type="text" formControlName="description" placeholder="{{'description' | translate }}" class="expected-mad-description w-100">
                    </div>
                    <button class="apply-btn mt-2" type="button" (click)="onApply()">
                        <span class="_700 f-12 text-light text-uppercase">{{'apply' | translate}}</span>
                    </button>
                </div>
            </div>
        </div>
        <hr>
        <div class="row" *ngIf="!isCpd">
          <div class="col d-flex align-items-center">
            <span class="_700 f-12 mr-3">{{'Active Product' | translate}}</span>
            <div class="select-wrapper mr-3">
                <select class="dropdown-toggle section-filter" formControlName="activeProduct">{{selectedReasonCategory}}
                    <option class="option-hover" [value]="'Yes'">{{'Yes' | translate}}</option>
                    <option class="option-hover" [value]="'No'">{{'No' | translate}}</option>
                    <option class="option-hover" [value]="'Finished'">{{'Finished' | translate}}</option>
                </select>
            </div>
          </div>

        </div>
        <ng-container *ngFor="let timeline of timelinDetails">
            <div class="col my-2 pointer p-0" *ngIf="timeline.showBlock" >
              <div class="timeline-blocks" [style.background-color]="timeline.blockColor" (click)="$event.stopPropagation();toggleBlock(timeline)">
                {{ timeline.name }}
                <div class="toggle-block-icon ml-3" (click)="$event.stopPropagation();toggleBlock(timeline)">
                    <i class="fa" [ngClass]="{'fa-chevron-up': timeline.showProductionToggleIcons, 'fa-chevron-down': !timeline.showProductionToggleIcons}"></i>
                </div>
              </div>
              <div class="timeline-items d-flex flex-wrap" *ngIf="timeline.showProductionToggleIcons">
                <div class="col-6" *ngFor="let timeLineItem of timeline.items">
                  <div class="col mt-3">
                    <div>
                      <!-- <div class="col">
                        <label class="mtm-checkbox mtm-checkbox-green timeline-checkbox m-0">
                          <input [checked]="allTimeLines" class="form-check-input" (click)="selectedTimelines(timeLineItem)" type="checkbox">
                          <span class="checkmark"></span>
                        </label>
                      </div> -->
                      <div class="row ">
                        <span class="timeline-name">{{ timeLineItem.name }}</span>
                      </div>
                      <div class="row">
                        <span class="timeline-deliverydate">{{ timeLineItem.driveDate | date:'dd.MM.YYYY' }}</span>
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-4 p-0">
                        <span class="timeline-deliverydate text-uppercase">{{'default' | translate}}</span>
                        <div class="row">
                          <div class="col mt-1">
                            <input disabled="" type="text" class="default-input" value="{{ timeLineItem.driveDate | date:'dd.MM.YYYY' }}">
                          </div>
                        </div>
                      </div>
                      <div class="col-4 p-0 d-flex">
                        <div class="col">
                          <label class="mtm-checkbox mtm-checkbox-green m-0">
                            <input [checked]="timeLineItem.isNegociated" class="form-check-input" (click)="timelineNegociation(timeLineItem, timeLineItem.isNegociated)" type="checkbox">
                            <span class="checkmark"></span>
                          </label>
                          <span class="timeline-deliverydate text-uppercase">{{'negociated' | translate}}</span>
                          <div class="row">
                            <div class="col p-0 mt-1" *ngIf="timeLineItem.isNegociated">
                              <div class="negociated-input-group input-group" (click)="negoDate.toggle()">
                                <input #negoDateInput (blur)="negociatedDateChanged(negoDateInput, timeLineItem)"
                                  [value]="timeLineItem.negotiatedDate | date:'yyyy-MM-ddTHH:mm:ss.SSSZ' | date:'dd.MM.yyyy'"
                                  [disabled]="!canEditMilestoneDate && !timeLineItem.negotiatedDate " class="form-control h-100 date-picker"
                                  ngbDatepicker #negoDate="ngbDatepicker" firstDayOfWeek="1">
                                <div class="input-group-addon d-flex flex-column justify-content-center" #negoDateInput (blur)="negociatedDateChanged(negoDateInput, timeLineItem)" [mtmDatePickerDirective]=negoDate>
                                  <img src="../../../assets/img/square-calendar.svg" alt="">
                                </div>
                              </div>
                            </div>
                            <div class="col p-0 mt-1" *ngIf="!timeLineItem.isNegociated">
                              <input disabled="" type="text" class="default-input" value="---">
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 p-0">
                        <div class="col">
                          <span class="timeline-deliverydate text-uppercase">{{'real' | translate}}</span>
                          <div class="row">
                            <div class="col p-0 mt-1" >
                              <div class="real-input-group input-group">
                                <input [disabled]="!canEditMilestoneDate" class="form-control h-100 date-picker" ngbDatepicker #driveDate="ngbDatepicker" firstDayOfWeek="1" (click)="driveDate.toggle()" (ngModelChange)="realDateChanged($event, timeLineItem)" formControlName="realDate">
                                <div class="input-group-addon d-flex flex-column justify-content-center" (click)="driveDate.toggle()" [mtmDatePickerDirective]=driveDate>
                                  <img src="../../../assets/img/square-calendar.svg" alt="">
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

        <!-- <div class="row row-cols-2 ml-1">
          <ng-container *ngFor="let timelines of timelinDetails">
            <ng-container *ngFor="let timeLineItem of timelines.items; let i=index">
              <div class="col-6">
                <div class="row" *ngIf="timeLineItem">
                  <div class="col mt-3">
                    <div>
                      <div class="col">
                        <label class="mtm-checkbox mtm-checkbox-green timeline-checkbox m-0">
                          <input [checked]="allTimeLines" class="form-check-input" (click)="selectedTimelines(timeLineItem)" type="checkbox">
                          <span class="checkmark"></span>
                        </label>
                      </div>
                      <div class="row ml-3">
                        <span class="timeline-name">{{timeLineItem.name}}</span>
                      </div>
                      <div class="row ml-3">
                        <span class="timeline-deliverydate">{{timeLineItem.deliveryDate | date:'dd.MM.YYYY'}}</span>
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-4 p-0">
                        <span class="timeline-deliverydate text-uppercase">default</span>
                        <div class="row">
                          <div class="col mt-1">
                            <input disabled="" type="text" class="default-input" value="{{timeLineItem.driveDate | date:'dd.MM.YYYY'}}">
                          </div>
                        </div>
                      </div>
                      <div class="col-4 p-0 d-flex">
                        <div class="col">
                          <label class="mtm-checkbox mtm-checkbox-green m-0">
                            <input [checked]="timeLineItem.isNegociated" class="form-check-input" (click)="timelineNegociation(i, timeLineItem, timeLineItem.isNegociated)" type="checkbox">
                            <span class="checkmark"></span>
                          </label>
                          <span class="timeline-deliverydate text-uppercase">negociated</span>
                          <div class="row">
                            <div class="col p-0 mt-1" *ngIf="timeLineItem.isNegociated">
                              <div class="negociated-input-group input-group">
                                <input class="form-control h-100 date-picker" ngbDatepicker #negoDate="ngbDatepicker" firstDayOfWeek="1" (click)="negoDate.toggle()" (ngModelChange)="negociatedDateChanged(timeline, i)" formControlName="negociatedDate">
                                <div class="input-group-addon d-flex flex-column justify-content-center" (click)="negoDate.toggle()" [mtmDatePickerDirective]=negoDate>
                                  <img src="../../../assets/img/square-calendar.svg" alt="">
                                </div>
                              </div>
                            </div>
                            <div class="col p-0 mt-1" *ngIf="!timeLineItem.isNegociated">
                              <input disabled="" type="text" class="default-input" value="---">
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 p-0">
                        <span class="timeline-deliverydate text-uppercase">real</span>
                        <div class="row">
                          <div class="col mt-1">
                            <div class="negociated-input-group input-group">
                              <input class="form-control h-100 date-picker" ngbDatepicker #realDate="ngbDatepicker" firstDayOfWeek="1" (click)="realDate.toggle()" formControlName="realDate">
                              <div class="input-group-addon d-flex flex-column justify-content-center" (click)="realDate.toggle()" [mtmDatePickerDirective]=realDate>
                                <img src="../../../assets/img/square-calendar.svg" alt="">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </div> -->
        <hr>
        <div class="row my-3">
            <div class="container m-0 p-0" [style.max-width]="'1290px'" [style.min-width]="'1290px'">
                <div class="row">
                    <div class="col-sm ml-3">
                        <button (click)="returnToProject()" class="bg-white return-to-project-button" type="button">
                            <span class="_700 f-12 text-uppercase">{{'returnToProjects' | translate}}</span>
                        </button>
                    </div>
                    <!-- TODO:- uncomment code when work on user and permission -->
                    <!-- <div class="col-sm p-0 d-flex justify-content-end">
                    <button class="next-button" type="button" (click)="onContinue()">
                        <span class="_700 f-12 text-light text-uppercase">next</span>
                    </button>
                </div> -->
                </div>
            </div>
        </div>
        <!-- <mtm-detailed-timeline></mtm-detailed-timeline> -->
    </form>
</div>