<div class="preview-asset-container w-100 h-100">
    <div class="image-container" [ngClass]="{'edit-section': isEditSection, 'support-download': hasDownloadSupport}">
        <div class="h-75" [ngClass]="{'h-75': !isEditSection, 'h-100': isEditSection}">
            <div [style.position]="'relative'" class="d-flex align-items-center justify-content-center h-100"
                *ngIf="!asset?.items?.length && showEmptyMediaIcon">
                <i [style.position]="'absolute'" [style.font-size]="'6rem'" [style.color]="'rgb(180, 180, 180)'"
                    [style.z-index]="'0'" class="fa fa-arrow-circle-o-down" aria-hidden="true"></i>
                <span [style.color]="'#ffffff'" [style.font-size]="'1.25rem'" [style.z-index]="'1'"
                    [style.font-weight]="600">{{emptyMediaMessage}}</span>
            </div>
            <div class="single-user-container w-100 h-100 d-flex" [ngStyle]="{'border-color': backgroundColor}"
                *ngIf="asset?.items?.length === 1 || (singleMode && asset?.items?.length)">
                <div class="preview-image single-item" [assetPreviewDirective]="asset.items[0]"
                    [assetPreviewThumbnailPosition]="'horizontal'" [showFolderIfEmpty]="showFolderIfEmpty"
                    [ngClass]="{'active': asset.items[0].isActive}"
                    (click)="$event.stopPropagation(); clickAsset(0, asset.items[0])">
                </div>
            </div>
            <div class="two-users-container w-100 h-100 d-flex" *ngIf="asset?.items?.length === 2 && !singleMode"
                [ngStyle]="{'border-color': backgroundColor}">
                <div class="preview-image double-item" [assetPreviewDirective]="asset.items[0]"
                    [assetPreviewThumbnailPosition]="'vertical'" [ngClass]="{'active': asset.items[0].isActive}"
                    [ngStyle]="{'border-right': 'solid 5px ' + backgroundColor}" [showFolderIfEmpty]="showFolderIfEmpty"
                    (click)="$event.stopPropagation(); clickAsset(0, asset.items[0])">
                </div>
                <div class="preview-image double-item" [assetPreviewDirective]="asset.items[1]"
                    [assetPreviewThumbnailPosition]="'vertical'" [ngClass]="{'active': asset.items[1].isActive}"
                    [showFolderIfEmpty]="showFolderIfEmpty" [ngStyle]="{'border-left': 'solid 3px ' + backgroundColor}"
                    (click)="$event.stopPropagation(); clickAsset(1, asset.items[1])">
                </div>
            </div>
            <div class="three-users-container w-100 h-100 d-flex" *ngIf="asset?.items?.length === 3 && !singleMode"
                [ngStyle]="{'border-color': backgroundColor}">
                <div class="w-100 d-flex" [ngStyle]="{'border-right': 'solid 5px ' + backgroundColor}">
                    <div class="preview-image triple-item" [assetPreviewDirective]="asset.items[0]"
                        [showFolderIfEmpty]="showFolderIfEmpty" [assetPreviewThumbnailPosition]="'vertical'"
                        [ngClass]="{'active': asset.items[0].isActive}"
                        (click)="$event.stopPropagation(); clickAsset(0, asset.items[0])"></div>
                </div>
                <div class="w-100 d-flex flex-column" [ngStyle]="{'border-left': 'solid 3px ' + backgroundColor}">
                    <div class="preview-image triple-item" [assetPreviewDirective]="asset.items[1]"
                        [showFolderIfEmpty]="showFolderIfEmpty" [assetPreviewThumbnailPosition]="'horizontal'"
                        [ngClass]="{'active': asset.items[1].isActive}"
                        [ngStyle]="{'border-bottom': 'solid 3px ' + backgroundColor}"
                        (click)="$event.stopPropagation(); clickAsset(1, asset.items[1])">
                    </div>
                    <div class="preview-image triple-item" [assetPreviewDirective]="asset.items[2]"
                        [showFolderIfEmpty]="showFolderIfEmpty" [assetPreviewThumbnailPosition]="'horizontal'"
                        [ngClass]="{'active': asset.items[2].isActive}"
                        [ngStyle]="{'border-top': 'solid 3px ' + backgroundColor}"
                        (click)="$event.stopPropagation(); clickAsset(2, asset.items[2])">
                    </div>
                </div>
            </div>
            <div class="four-users-container w-100 h-100 d-flex" *ngIf="asset?.items?.length > 3 && !singleMode"
                [ngStyle]="{'border-color': backgroundColor}">
                <div class="w-100 d-flex flex-column" [ngStyle]="{'border-right': 'solid 5px ' + backgroundColor}">
                    <div class="preview-image quadruple-item" [assetPreviewDirective]="asset.items[0]"
                        [showFolderIfEmpty]="showFolderIfEmpty" [assetPreviewThumbnailPosition]="'horizontal'"
                        [ngClass]="{'active': asset.items[0].isActive}"
                        [ngStyle]="{'border-bottom': 'solid 3px ' + backgroundColor}"
                        (click)="$event.stopPropagation(); clickAsset(0, asset.items[0])">
                    </div>
                    <div class="preview-image quadruple-item" [assetPreviewDirective]="asset.items[1]"
                        [assetPreviewThumbnailPosition]="'horizontal'" [ngClass]="{'active': asset.items[1].isActive}"
                        [ngStyle]="{'border-top': 'solid 3px ' + backgroundColor}"
                        [showFolderIfEmpty]="showFolderIfEmpty"
                        (click)="$event.stopPropagation(); clickAsset(1, asset.items[1])">
                    </div>
                </div>
                <div class="w-100 d-flex flex-column" [ngStyle]="{'border-left': 'solid 3px ' + backgroundColor}">
                    <div class="preview-image quadruple-item" [assetPreviewDirective]="asset.items[2]"
                        [assetPreviewThumbnailPosition]="'horizontal'" [ngClass]="{'active': asset.items[2].isActive}"
                        [ngStyle]="{'border-bottom': 'solid 3px ' + backgroundColor}"
                        [showFolderIfEmpty]="showFolderIfEmpty"
                        (click)="$event.stopPropagation(); clickAsset(2, asset.items[2])">
                    </div>
                    <div class="preview-image quadruple-item" *ngIf="asset.items.length === 4"
                        [assetPreviewDirective]="asset.items[3]"
                        [ngStyle]="{'border-top': 'solid 3px ' + backgroundColor}"
                        [showFolderIfEmpty]="showFolderIfEmpty" [assetPreviewThumbnailPosition]="'horizontal'"
                        (click)="$event.stopPropagation(); clickAsset(3, asset.items[3])"
                        [ngClass]="{'active': asset.items[3].isActive}">
                    </div>
                    <div class="preview-image quadruple-item d-flex align-items-center justify-content-center"
                        *ngIf="asset.items.length > 4" [ngStyle]="{'border-top': 'solid 5px ' + backgroundColor}"
                        (click)="showAllAssets($event)">
                        <span class="assets-plus">+{{asset.items.length - 3}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!isEditSection" class="title h-25 mx-2">
            <div class="asset-title d-flex"
                (click)="$event.stopPropagation(); clickAsset(0, asset.items && asset.items.length ? asset.items[0]: null)">
                <mtm-comment-preview [isAssetFilename]="true"
                    [value]="asset?.title || asset?.name || asset?.articleOfCostumeDesign || asset?.elementOfSetDesign || asset?.accessory || asset?.language"
                    [isPreviewTitle]="true">
                </mtm-comment-preview>
            </div>
        </div>
    </div>

    <div *ngIf="!isEditSection" class="title-container d-flex align-items-center"
        [ngClass]="{'batch-container': asset.batch, 'justify-content-start': asset.batch, 'justify-content-center': !asset.batch}">
        <!--
        <div *ngIf="!showFileIcon" class="asset-index mr-2">
            <span class="d-flex align-items-center justify-content-center">{{index}}</span>
        </div>
        -->
        <ng-container *ngIf="!asset.batch">
            <div *ngIf="showFileIcon">
                <img height="34" width="46" [src]="asset.items[0].typeIcon" class="file-icon file"
                    onError="this.src='assets/img/icons/file.svg'" />
            </div>
            <div class="d-flex flex-column w-100">
                <!-- <div class="asset-title d-flex"
                    (click)="clickAsset(0, asset.items && asset.items.length ? asset.items[0]: null)">
                    <mtm-comment-preview [isAssetFilename]="true"
                        [value]="asset?.title || asset?.name || asset?.articleOfCostumeDesign || asset?.elementOfSetDesign || asset?.accessory || asset?.language"
                        [isPreviewTitle]="true">
                    </mtm-comment-preview>
                </div> -->
                <div *ngIf="asset?.simpleVersions?.length"
                    class="d-flex align-items-center asset-version-dropdown w-100" container="body" ngbDropdown>
                    <div class=" mt-auto d-flex align-items-center p-1 w-100" ngbDropdownToggle>
                        {{selectedVersion?.name}}
                    </div>
                    <div class="asset-version-dropdown-menu w-100" ngbDropdownMenu>
                        <div (click)="versionChange(version)" *ngFor="let version of asset.simpleVersions"
                            ngbDropdownItem>
                            {{version.name}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="voting-result" *ngIf="asset.viewedStatus">
                <div class="approved" *ngIf="asset.viewedStatus == 'APPROVED'"></div>
                <div class="rejected" *ngIf="asset.viewedStatus == 'REJECTED'"></div>
            </div>
        </ng-container>

        <ng-container *ngIf="asset.batch">
            <button class="d-flex align-items-center justify-content-center batch-btn px-2 py-1">
                <i></i>
                <span class="text-uppercase">{{'batchUpload_batchOptions' | translate }}</span>
            </button>
        </ng-container>
    </div>

    <div class="download-dropdown mt-2" ngbDropdown *ngIf="hasDownloadSupport && isTranscodedImageFile()">
        <div class="d-flex align-items-center px-3 py-1" ngbDropdownToggle>
            <img class="d-inline-block mr-2" src="/assets/img/download/general-download.svg" />
            <span class="text-uppercase">{{'downloadAs' | translate}} ...</span>
        </div>
        <div class="download-list w-100" ngbDropdownMenu>
            <div ngbDropdownItem (click)="downloadOriginalFile()">
                <div class="d-flex align-items-center w-100">
                    <img class="d-inline-block mr-2" src="/assets/img/download/general-download.svg" />
                    <span class="caption">{{'asset_originalImage' | translate }}</span>
                    <img class="ml-auto download-press" src="/assets/img/download/download-press.svg" />
                </div>
            </div>
            <div ngbDropdownItem (click)="downloadConvertedImage()">
                <div class="d-flex align-items-center w-100">
                    <img class="d-inline-block mr-2" src="/assets/img/download/general-download.svg" />
                    <span class="caption">{{'asset_transcodedImage' | translate }}</span>
                    <img class="ml-auto download-press" src="/assets/img/download/download-press.svg" />
                </div>
            </div>
        </div>
    </div>

    <div class="download-dropdown mt-2" ngbDropdown *ngIf="hasDownloadSupport && isTranscodedOfficeFile()">
        <div class="d-flex align-items-center px-3 py-1" ngbDropdownToggle>
            <img class="d-inline-block mr-2" src="/assets/img/download/general-download.svg" />
            <span class="text-uppercase">{{'downloadAs' | translate}} ...</span>
        </div>
        <div class="download-list w-100" ngbDropdownMenu>
            <div ngbDropdownItem (click)="downloadConvertedPDF()">
                <div class="d-flex align-items-center w-100">
                    <img class="d-inline-block mr-2" src="/assets/img/download/pdf-download.svg" />
                    <span class="caption">{{'asset_pdfDocument' | translate }}</span>
                    <img class="ml-auto download-press" src="/assets/img/download/download-press.svg" />
                </div>
            </div>
            <div ngbDropdownItem (click)="downloadOfficeFile()">
                <div class="d-flex align-items-center w-100">
                    <ng-container *ngIf="isPowerPointFile()">
                        <img class="d-inline-block mr-2" src="/assets/img/download/ppt-download.svg" />
                        <span class="caption">{{'asset_pptDocument' | translate }}</span>
                    </ng-container>
                    <ng-container *ngIf="isWordFile()">
                        <img class="d-inline-block mr-2" src="/assets/img/download/doc-download.svg" />
                        <span class="caption">{{'asset_docDocument' | translate }}</span>
                    </ng-container>
                    <ng-container *ngIf="isExcelFile()">
                        <img class="d-inline-block mr-2" src="/assets/img/download/xls-download.svg" />
                        <span class="caption">{{'asset_xlsDocument' | translate}}</span>
                    </ng-container>

                    <ng-container *ngIf="isOpenDocumentFile()">
                        <span class="d-inline-block mr-2">
                            <i class="fa fa-file" aria-hidden="true"></i>
                        </span>
                        <span class="caption">{{'asset_openDocument' | translate}}</span>
                    </ng-container>

                    <img class="ml-auto download-press" src="/assets/img/download/download-press.svg" />
                </div>
            </div>
        </div>
    </div>
</div>