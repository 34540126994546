<div class="asset-list-container" *ngIf="versionData?.files.length > 0">
  <div class="assets">
    <div class="asset-item" *ngFor="let file of versionData?.files"
         [assetPreviewThumbnailPosition]="'horizontal'"
         [assetPreviewDirective]="file" (click)="handleClick(file)"
         [class.active]="activeFile == file"
    >
    </div>
  </div>
</div>
