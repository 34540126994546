<div class="container">
	<div class="col">
		<div class="row">
			<img src="assets/img/logo.png" alt="MTM Logo" class="mx-auto mtm-logo">
		</div>
		<div class="row text-center justify-content-center">
			<h3>Reset Password</h3>
		</div>
		<div class="row justify-content-center mt-4">
			<div class="card px-5 py-4 mt-1">
				<form class="verification-form" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
					<p class="h5 mb-3">Hello, {{firstName}} {{lastName}}. Please enter your new password.</p>

					<!-- New Password -->
					<div class="row">
						<label class="form-group has-float-label m-0"
							[ngClass]="{'has-danger': formGroup.controls.newPassword.invalid && submitted}">
							<input class="form-control" type="password" formControlName="newPassword" placeholder=" "
								passwordToggle
								[ngClass]="{'form-control-danger': formGroup.controls.newPassword.invalid && submitted}">
							<span *ngIf="!(submitted && formGroup.controls.newPassword.invalid)">New Password</span>
							<span *ngIf="submitted && formGroup.controls.newPassword.invalid" class='text-danger'>Please
								enter a valid password</span>
						</label>
						<mtm-password-strength-meter class="w-100 d-block my-2"
							[passwordToCheck]="formGroup.controls.newPassword.value" [minPassLength]="minPassLength"
							(passwordStrength)="onPasswordStrengthChanged($event)"></mtm-password-strength-meter>
					</div>
					<div class="row">
						<mtm-password-checker class="w-100" [companyId]="companyId"
							[showValidation]="formGroup.controls.newPassword.dirty"
							[password]="formGroup.controls.newPassword.value" [(minPassLength)]="minPassLength"
							(passwordValidated)="passwordValidated($event)"></mtm-password-checker>
					</div>
					<!-- / New Password -->

					<!-- Re New Password -->
					<div class="row">
						<label class="form-group has-float-label"
							[ngClass]="{'has-danger': formGroup.controls.reNewPassword.invalid && submitted}">
							<input class="form-control" type="password" formControlName="reNewPassword" placeholder=" "
								passwordToggle
								[ngClass]="{'form-control-danger': formGroup.controls.reNewPassword.invalid && submitted}">
							<span *ngIf="!(submitted && formGroup.controls.reNewPassword.invalid)">Repeat
								Password</span>
							<span *ngIf="submitted && formGroup.controls.reNewPassword.invalid"
								class='text-danger'>Please enter a valid password</span>
						</label>
					</div>
					<!-- / Re New Password -->

					<div class="row">
						<button type="submit" class="btn btn-lg btn-primary btn-block">Reset Password</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>