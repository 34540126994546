import { Component, EventEmitter, Input, OnChanges, Output, SimpleChange } from '@angular/core';

@Component({
    selector: 'mtm-password-strength-meter',
    styleUrls: ['./password-strength-meter.component.scss'],
    templateUrl: './password-strength-meter.component.html',
})
export class PasswordStrengthComponent implements OnChanges {
    bar0: string;
    bar1: string;
    bar2: string;
    bar3: string;

    @Input() public passwordToCheck: string;
    @Input() minPassLength: number = 10;
    @Input() isRegistration?: boolean = false;

    @Output() passwordStrength = new EventEmitter<boolean>();

    private colors = ['darkred', 'orangered', 'orange', 'yellowgreen'];

    message: string;
    messageColor: string;

    ngOnInit(): void {
        if (this.isRegistration) {
            this.colors = this.colors.map(color => 
              color === 'yellowgreen' ? '#43CB9A' : color
            );
          }
          
    }

    checkStrength(password: string) {
        // 1
        let force = 0;

        // 2
        const regex = /[$-/:-?{-~!"^_@`\[\]]/g;
        const lowerLetters = /[a-z]+/.test(password);
        const upperLetters = /[A-Z]+/.test(password);
        const numbers = /[0-9]+/.test(password);
        const symbols = regex.test(password);

        // 3
        const flags = [lowerLetters, upperLetters, numbers, symbols];

        // 4
        let passedMatches = 0;
        for (const flag of flags) {
            passedMatches += flag === true ? 1 : 0;
        }

        // 5
        force += 2 * password.length + (password.length > this.minPassLength ? 1 : 0);
        force += passedMatches * 10;

        // 6
        force = password.length < this.minPassLength ? Math.min(force, 30) : force;

        // 7
        force = passedMatches === 1 ? Math.min(force, 10) : force;
        force = passedMatches === 2 ? Math.min(force, 20) : force;
        force = passedMatches === 3 ? Math.min(force, 30) : force;
        force = passedMatches === 4 ? Math.min(force, 40) : force;

        return force;
    }

    ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
        if (changes.passwordToCheck && changes.passwordToCheck.currentValue !== undefined) {
            const password = changes.passwordToCheck.currentValue;
    
            if (this.isRegistration) {
                this.setBarColors(4, '#637EA3');
            } else {
                this.setBarColors(4, '#DDD');
            }
    
            if (password) {
                const pwdStrength = this.checkStrength(password);
    
                // Emit true only if it meets the strongest condition
                this.passwordStrength.emit(pwdStrength >= 40);
    
                // Set bar colors to green if the password is very strong (score of 50)
                if (pwdStrength === 50) {
                    this.setBarColors(4, 'green');
                    this.message = 'Very Strong';
                } else {
                    const color = this.getColor(pwdStrength);
                    this.setBarColors(color.index, color.color);
    
                    // Update the message based on strength
                    switch (pwdStrength) {
                        case 10:
                            this.message = 'Poor';
                            break;
                        case 20:
                            this.message = 'Not Good';
                            break;
                        case 30:
                            this.message = 'Average';
                            break;
                        case 40:
                            this.message = 'Good';
                            break;
                    }
                }
            } else {
                this.message = '';
            }
        }
    }


    private getColor(strength: number) {
        let index = 0;

        if (strength === 10) {
            index = 0;
        } else if (strength === 20) {
            index = 1;
        } else if (strength === 30) {
            index = 2;
        } else if (strength === 40) {
            index = 3;
        } else {
            index = 4;
        }

        this.messageColor = this.colors[index];

        return {
            index: index + 1,
            color: this.colors[index],
        };
    }

    private setBarColors(count: number, color: string) {
        for (let n = 0; n < count; n++) {
            (this as any)['bar' + n] = color;
        }
    }
}
