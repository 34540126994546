import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { ProjectSubsectionComponent } from "./project-subsection.component";
import { SubsectionTopicsComponent } from "./topics/subsection-topics.component";
import { SubsectionExplorerComponent } from "./subsection-explorer/subsection-explorer.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CommonModule } from "@angular/common";
import { SharedModule } from "../sub-modules/SharedModule";
import { HttpClientModule } from "@angular/common/http";
import { NgSelectModule } from "@ng-select/ng-select";
import { ProjectSubsectionRoutingModule } from "./project-subsection.routing";
import { SubsectionOptionsComponent } from "./options/subsection-options.component";
import { SubsectionPreviewComponent } from "./preview/subsection-preview.component";
import { TopicRenameComponentComponent } from "./topics/rename/topic-rename.component";
import { TopicDeleteComponentComponent } from "./topics/delete/topic-delete.component";
import { SubsectionOptionsGridItemComponent } from "./options/grid-item/subsection-options-grid-item.component";
import { SubsectionOptionEditComponent } from "./options/edit/subsection-option-edit.component";
import { NewFilesFilterPipe } from "./options/new-files-filter.pipe";
import { FileDisplayNamePipe } from "../shared/pipes/file-display-name.pipe";
import { SubsectionCommentsComponent } from "./comments/subsection-comments.component";
import { MtmChatModule } from "../sub-modules/mtm-chat-module/mtm-chat.module";
import { AssetsApprovalComponent } from "./approval/assets-approval.component";
import {
  SubsectionPreviewAssetListComponent
} from "../shared/components/media-player/asset-list/subsection-preview-asset-list.component";
import { MtmCommentEditorComponent } from "../shared/components/mtm-comment-editor/mtm-comment-editor.component";
import { QuillModule } from "ngx-quill";
import { PickerComponent } from "@ctrl/ngx-emoji-mart";
import { Ng5SliderModule } from 'ng5-slider';
import { AssetVotersComponent } from "./asset-voters/asset-voters.component";
import { FileDeleteModalComponent } from "./files/delete/file-delete-modal.component";
import { SubsectionCompareComponent } from "../layouts/work/subsection/subsection-compare/subsection-compare.component";
import { VersionsComparerComponent } from "../shared/components/versions-comparer/versions-comparer.component";

@NgModule({
  declarations: [
    SubsectionCompareComponent,
    SubsectionTopicsComponent,
    SubsectionOptionsComponent,
    SubsectionPreviewComponent,
    SubsectionExplorerComponent,
    TopicRenameComponentComponent,
    TopicDeleteComponentComponent,
    SubsectionOptionsGridItemComponent,
    SubsectionOptionEditComponent,
    SubsectionCommentsComponent,
    AssetsApprovalComponent,
    VersionsComparerComponent,
    AssetVotersComponent,
    FileDeleteModalComponent,
    NewFilesFilterPipe,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    CommonModule,
    SharedModule,
    HttpClientModule,
    NgSelectModule,
    MtmChatModule,
    QuillModule,
    PickerComponent,
    Ng5SliderModule,
  ],
  providers: [
    NewFilesFilterPipe,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  exports: [

    SubsectionCompareComponent,
    SubsectionTopicsComponent,
    SubsectionOptionsComponent,
    SubsectionPreviewComponent,
    SubsectionExplorerComponent,
    TopicRenameComponentComponent,
    TopicDeleteComponentComponent,
    SubsectionOptionsGridItemComponent,
    SubsectionOptionEditComponent,
    SubsectionCommentsComponent,
    AssetsApprovalComponent,
    NewFilesFilterPipe,
    VersionsComparerComponent,
    AssetVotersComponent,
    FileDeleteModalComponent,

  ]
})
export class ProjectSubsectionSharedModule {

}
