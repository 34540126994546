<div class="project-permissions-container">
	<div class="project-card">
		<div class="user-permissions-header">
			<h2 class="user-permission-title">
				{{'userPermissions' | translate}}
			</h2>
			<p class="user-permission-subtitle">
				{{'userAndPermissionSubTitle' | translate}}
			</p>
		</div>
		<div class="card rounded">
			<div class="recommendation-row">
				<div class="unauth-overlay tooltipped" *ngIf="!authUser || authUser.globalRole.startsWith('PRODUCTION')"
					[attr.data-title]="'NoChangeAllowed' | translate">
				</div>
			</div>

			<div class="action-section">
				<div class="button-nav">
					<button class="btn btn-add" (click)="addNewUserModal()">
						<i class="mtm-icon mtm-icon-add-user"></i>
						<span>{{'addUser' | translate}}</span>
					</button>
					<button class="btn btn-add" (click)="createTeam()" [disabled]="!brandId">
						<i class="mtm-icon mtm-icon-add-group"></i>
						<span>{{'createTeam' | translate}}</span>
					</button>
					<button class="btn btn-add" (click)="addNewUserModal()" [disabled]="true">
						<i class="mtm-icon mtm-icon-import-team"></i>
						<span>{{'importTeam' | translate}}</span>
					</button>
				</div>


				<div class="d-flex flex-column align-items-lg-center flex-lg-row info-row">
					<div class="view-controls">

						<select class="form-control" [(ngModel)]="tableView">
							<option [ngValue]="'userView'">{{'team_usersView' | translate }}</option>
							<option [ngValue]="'teamView'">{{'team_teamsView' | translate }}</option>
						</select>
						<select class="form-control" *ngIf="tableView=='userView'">
							<option [ngValue]="''">{{'Select team' | translate }}</option>
						</select>

						<!--
					<p class="m-0 align-self-center" *ngIf="decision">
						The team members with the permission to recommend should all greenlight on element for
						presentation to client. The more members the more difficult the recommendation.
					</p>

					<p class="m-0 align-self-center" *ngIf="!decision">
						One Team member is the only one to recommend the elements presented to the client.
						Choose who by ticking the "Validation" box.
					</p>
					-->
						<!---->

					</div>
					<div class="d-flex flex-column flex-md-row flex-grow-1">
						<p class="text-center mb-0 text-md-left">
							{{'projectPermission_decisionMakerDescription' | translate}}
						</p>
						<div class="d-flex align-items-center align-items-md-start ml-lg-auto">
							<a href="#" class="text-danger d-flex align-items-center" [style.white-space]="'nowrap'"
								(click)="onOpenPermissionInfo();false">
								<i class="fa fa-cog fa-fw p-1 mr-1" aria-hidden="true"></i>
								<span class="text-uppercase font-weight-bold"
									[innerHTML]="'permissionDetails' | translate"></span>
							</a>
						</div>
					</div>
				</div>
			</div>
			<div *ngIf="tableView=='userView'" class="d-block">
				<div *ngIf="!!(localFilterParams?.fullName || localFilterParams?.email || localFilterParams?.roles.length)"
					class="d-flex justify-content-end">
					<label class="p-2">Total: {{(users | FilterUser: localFilterParams?.fullName :
						localFilterParams?.email
						: localFilterParams?.roles).length}} records</label>
				</div>
				<ag-grid-angular #projectPermissionTable
					[rowData]="users | FilterUser: localFilterParams?.fullName : localFilterParams?.email : localFilterParams?.roles : localFilterParams?.decisionMake"
					[debounceVerticalScrollbar]="true" [rowHeight]="100" [defaultColDef]="defaultColDef"
					class="ag-theme-alpine responsive-ag-grid " [headerHeight]="72"
					[columnDefs]="projectPermissionColumnsDef" [enableCellTextSelection]="true" [ensureDomOrder]="true"
					style="width: 100%; height: 680px; overflow-x: scroll;" (columnVisible)="autoSizeColumnWidth()"
					(rowDataUpdated)="autoSizeColumnWidth()" (gridReady)="onGridReady($event)" [context]="tableContext"
					[components]="components" [domLayout]="'autoHeight'">
				</ag-grid-angular>
			</div>
			<div *ngIf="tableView=='teamView'" class="d-block">
				<ag-grid-angular #projectTeamTable [rowData]="teams" [debounceVerticalScrollbar]="true" [rowHeight]="54"
					[defaultColDef]="defaultTeamColDef" class="ag-theme-alpine project-team-table responsive-ag-grid"
					[headerHeight]="54" [columnDefs]="projectTeamColumnsDef" [enableCellTextSelection]="true"
					[ensureDomOrder]="true" style="width: 100%; height: 680px;" (rowClicked)="editTeam($event)"
					(gridReady)="fitTableColumns($event)" [context]="teamTableContext" [components]="teamComponents">
				</ag-grid-angular>
			</div>
		</div>
	</div>

	<!-- bottom buttons -->

	<div class="d-flex flex-column flex-sm-row justify-content-between my-4 ml-1 mr-1 action-buttons">
		<button class="btn btn-light btn-lg mr-2 border-secondary btn-save rounded" (click)="continue()">
			<span>PREVIOUS</span>
		</button>
		<button class="btn btn-dark btn-lg btn-save btn-black rounded" (click)="goToTeamFiles()">
			<span>SAVE</span>
		</button>
	</div>
</div>