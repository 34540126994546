<div class="mb-2 comment-preview-component-container">
	<span *ngIf="!!displayValue" class="comment-edited-container ql-editor"
		[ngClass]="{ 'truncate-text': truncateText && isTruncated, 'preview-comment': isPreviewComment, 'preview-title': isPreviewTitle }"
		[innerHTML]="displayValue | safeHtml" [style.color]="textColor" [style.fontSize]="fontSize"
		[attr.title]="isPreviewTitle ? displayValue : null"></span>
	<div *ngIf="attachments?.length" class="my-3" [style.margin-left]="'-8px'" [style.margin-right]="'-8px'">
		<span class="d-block ml-2 mr-2 mb-2" (click)="showFiles=!showFiles" [style.font-size]="'12px'">
			<div class="d-flex align-items-center">
				<span class="mr-2">{{attachments.length}} File(s)</span>
				<i [style.font-size]="'14px'" class="fa"
					[ngClass]="{ 'fa-caret-right': !showFiles, 'fa-caret-down': showFiles }"></i>
			</div>
		</span>
		<div [hidden]="!showFiles">
			<div class="d-flex align-items-start text-center mt-2 mb-2 flex-wrap">
				<div class="d-flex text-center pointer file-content ml-2 mr-2 mb-2"
					*ngFor="let attachment of attachments">
					<hr-badgebtn-subcmp [file]="attachment" [isDisplayTrashBtn]="showDeleteFile"
						(onTrashFile)="deleteFile($event, attachment)" [directPreview]="true"></hr-badgebtn-subcmp>
					<hr-grid-subcmp [file]="attachment" [enableAudioTranscription]="enableAudioTranscription"
						[subSectionId]="subSectionId" [compactView]="true" [hideDeleteButton]="showDeleteFile"
						class="w-100" [forTranscript]="true" [projectId]="project.id"></hr-grid-subcmp>
				</div>
			</div>
		</div>
	</div>
</div>