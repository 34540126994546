import { Directive, ElementRef, OnDestroy, OnInit } from "@angular/core";

@Directive({
  selector: '[passwordToggle]'
})
export class PasswordToggleDirective implements OnInit, OnDestroy {
  private iconEl: HTMLElement | null = null;

  constructor(private el: ElementRef) { }

  get passwordEl(): HTMLInputElement {
    return this.el.nativeElement;
  }

  ngOnInit(): void {
    // Create the eye icon and set it to closed initially
    this.iconEl = document.createElement('i');
    this.iconEl.classList.add('fa', 'fa-eye-slash', 'position-absolute', 'eye-icon');
    this.iconEl.style.right = '10px';
    this.iconEl.style.cursor = 'pointer';
    this.iconEl.style.color = this.passwordEl.style.color;
    this.iconEl.style.width = '20px'; // Set a fixed width
    this.iconEl.style.height = '20px'; // Set a fixed height

    // Center the icon vertically within the input field
    this.iconEl.style.top = '50%';
    this.iconEl.style.transform = 'translateY(-50%)'; // Adjust to center vertically

    // Append the icon after the input element
    this.passwordEl.after(this.iconEl);

    // Set initial type to password
    this.passwordEl.setAttribute('type', 'password');

    // Add click event listener to toggle password visibility
    this.iconEl.addEventListener('click', () => {
      this.togglePassword();
    });
  }

  ngOnDestroy(): void {
    if (this.iconEl) {
      this.iconEl.removeEventListener('click', this.togglePassword.bind(this));
      this.iconEl.remove();
    }
  }

  togglePassword() {
    const isPasswordVisible = this.passwordEl.getAttribute('type') === 'text';
    this.passwordEl.setAttribute('type', isPasswordVisible ? 'password' : 'text');
    
    // Change icon based on visibility
    if (isPasswordVisible) {
      this.iconEl!.classList.remove('fa-eye');
      this.iconEl!.classList.add('fa-eye-slash');
      this.iconEl.style.top = '50%';
    } else {
      this.iconEl!.classList.remove('fa-eye-slash');
      this.iconEl!.classList.add('fa-eye');
      this.iconEl.style.top = '7px';
    }
  }
}
