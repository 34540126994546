import { ChangeDetectorRef, Component, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
// import { NGValidators } from "ng-validators";
import { AuthService } from './../../shared/services/auth.service';
import { ErrorMessageService } from './../../shared/services/error-message.service';
import { RegistrationService } from './../../shared/services/registration.service';
import { NotificationService } from "app/shared/services/notification.service";
import { OverlayService } from "../../shared/services/overlayService";
import * as $ from 'jquery';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { HelperService } from 'app/shared/services/helper.service';
import { MTMTelInputComponent } from 'app/shared/components/mtm-tel-input/mtm-tel-input.component';
import { TranslatePipe } from 'app/shared/pipes/translate.pipe';
import moment from 'moment';
import { BannerTypes } from 'app/subscriptions/shared/components/subscription-banner/subscription-banner.component';
import { TranslateService } from 'app/shared/services/translate.service';

@Component({
	templateUrl: './profile-info.component.html',
	styleUrls: ['./profile-info.component.scss']
})
export class ProfileInfoComponent implements OnInit, OnDestroy, OnChanges {
	@ViewChild('phoneInput') phoneInput: MTMTelInputComponent;

	companyId: string;
	isPasswordValid: boolean;
	strongPassword: boolean;
	formGroup: UntypedFormGroup;
	submitted: boolean = false;
	formErrors: any = null;
	companyType: string;
	ngUnsubscribe = new Subject();
	sending: boolean = false;
	isSSO: boolean = false;
	minPassLength: any;
	year: string = moment().format('YYYY');
	bannerTypes = BannerTypes;
	generatedPassword: string = '';
	selectedLength: number | null = 64; // Initialize with null to allow proper selection
	passwordStrength: number = 0;
	selectedLanguage: string = 'en_us'; // Default language set to 'EN'

	constructor(private fb: UntypedFormBuilder,
		private router: Router,
		private authService: AuthService,
		private overlayService: OverlayService,
		private route: ActivatedRoute,
		private registrationService: RegistrationService,
		private notificationService: NotificationService,
		private translatePipe: TranslatePipe,
		private translateService: TranslateService,
		private cdr: ChangeDetectorRef
	) { }

	ngOnDestroy(): void {
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	ngOnChanges() {
		this.cdr.detectChanges(); // Ensure change detection is triggered	
	}

	ngOnInit() {

		let user = this.authService.getAuthUser();
		// this.generatePassword();

		if (user) {
			if (user['phone'])
				(<any>$('#phone')).intlTelInput('setNumber', user['phone']);

			// Load form group
			this.loadFormGroup(null,
				(user['firstName']) ? user['firstName'] : null,
				(user['lastName']) ? user['lastName'] : null,
				(user['company'].name) ? user['company'].name : null,
				(user['jobTitle'].name) ? user['jobTitle'].name : null,
				(user['email']) ? user['email'] : null,
				(user['phone']) ? user['phone'] : null);

		} else {
			// Read params
			this.route.queryParams.pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe(params => {

				let id = (params['id']) ? params['id'] : null;
				let firstName = (params['firstName']) ? params['firstName'] : null;
				let lastName = (params['lastName']) ? params['lastName'] : null;
				let email = (params['email']) ? params['email'] : null;
				let phone = (params['phone']) ? params['phone'] : null;
				let company_name = (params['company_name']) ? params['company_name'] : null;
				let job_title = (params['job_title']) ? params['job_title'] : null;
				this.companyType = (params['company_type'] ? params['company_type'] : null);
				this.companyId = params['company_id'];
				// Load form group
				this.loadFormGroup(id, firstName, lastName, company_name, job_title, email, phone);
			});
		}
		// this.updatePasswordPattern();
	}


	setLanguage(language: string) {
		this.selectedLanguage = language;
		this.translateService.use(language);

	}

	private updatePasswordValidators() {
		const passwordControl = this.formGroup.get('password');
		const rePasswordControl = this.formGroup.get('rePassword');
	  
		// Remove the existing validators
		passwordControl.clearValidators();
		rePasswordControl.clearValidators();
	  
		// Add new validators with updated selectedLength
		passwordControl.setValidators([Validators.required, Validators.minLength(this.selectedLength)]);
		rePasswordControl.setValidators([Validators.required, Validators.minLength(this.selectedLength)]);
	  
		// Manually trigger validation after updating validators
		passwordControl.updateValueAndValidity();
		rePasswordControl.updateValueAndValidity();
	  }
	  

	minPassLengthChange(newLength: number) {
		console.log('Password selected length changed:', newLength);
		// You can use newLength as needed in your parent component
		this.selectedLength = newLength;

		
		// Manually update the validators for password and rePassword fields
		this.updatePasswordValidators();

		// Trigger validation to reflect changes
		this.formGroup.updateValueAndValidity();
		this.cdr.detectChanges(); // Ensure change detection is triggered
	}

	/**
	 * Submit form
	 */
	onSubmit() {
		HelperService.checkFormFields(this.formGroup);

		if (!this.formGroup.controls['phone'].value) {
			this.phoneInput?.isValidPhoneNumber();
		}

		// if (!this.isPasswordValid || this.sending) {
		// 	return;
		// }

		this.submitted = true;

		// Validate both password
		this.validatePasswordMatch();

		if (this.formGroup.valid) {
			this.sending = true;
			this.registrationService.update(
				this.formGroup.controls['userId'].value,
				this.formGroup.controls['firstName'].value,
				this.formGroup.controls['lastName'].value,
				this.formGroup.controls['company_name'].value,
				this.formGroup.controls['job_title'].value,
				this.formGroup.controls['email'].value,
				this.formGroup.controls['phone'].value,
				this.formGroup.controls['password'].value,
				this.formGroup.controls['rePassword'].value,
			).pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe({
				next: (data: any) => {
					this.sending = false;
					// Accept T&Cs and Navigate to profile avatar page
					this.overlayService.showSuccess(this.translatePipe.transform('profileUpdate_termAccepted'));
					this.router.navigate(['/entry/on-boarding/phone-verification']);
				},
				error: (err: any) => {
					this.sending = false;
					this.notificationService.open({
						title: this.translatePipe.transform('profileUpdate_registerFailed'),
						description: this.translatePipe.transform('profileUpdate_registerFailedDescription'),
						confirmBtn: this.translatePipe.transform('accept')
					});
					console.error(err);
				}
			});

		}

	}

	// ****************************************************************************************


	/**
	 * Load form group
	 */
	private loadFormGroup(userId: any = null, firstName: string = null, lastName: string = null, company_name: string = null, job_title: string = null, email: string = null, phone: string = null) {

		this.formGroup = this.fb.group({
			firstName: [firstName, [Validators.required, Validators.minLength(2), Validators.maxLength(16)]],
			lastName: [lastName, [Validators.required, Validators.minLength(2), Validators.maxLength(16)]],
			company_name: [company_name, [Validators.required, Validators.minLength(2), Validators.maxLength(100)]],
			job_title: [job_title, [Validators.required, Validators.minLength(2), Validators.maxLength(100)]],
			email: [email, [Validators.required, Validators.email]],
			phone: [phone, [Validators.required]],
			password: ['', [Validators.required, Validators.minLength(this.selectedLength)]],
			rePassword: ['', [Validators.required, Validators.minLength(this.selectedLength)]],
			userId: [userId],
		});

		// Subscribe to changes to get validation errors
		this.formGroup.valueChanges.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(data => this.onValueChanged(data));

		// Just run an empty validation
		this.onValueChanged();
	}


	/**
	 * Get validation error messages from the form group
	 * @param data
	 */
	private onValueChanged(data?) {
		if (this.formGroup)
			this.formErrors = ErrorMessageService.getFormErrors(this.formGroup);

		// this.formGroup.controls['rePassword'].updateValueAndValidity();
	}

	restrictInput(event: KeyboardEvent) {
		const inputElement = event.target as HTMLInputElement;
		const value = inputElement.value;

		// Prevent leading whitespace
		if (event.key === ' ' && value.length === 0) {
			event.preventDefault();
			return;
		}

		// Restrict to letters and spaces
		if (!/[a-zA-Z\s]/.test(event.key)) {
			event.preventDefault();
		}
	}

	restrictWhiteSpace(event: KeyboardEvent) {
		const inputElement = event.target as HTMLInputElement;
		const value = inputElement.value;

		// Prevent leading whitespace
		if (event.key === ' ' && value.length === 0) {
			event.preventDefault();
			return;
		}
	}

	/**
	 * Check if both passwords match
	 */
	private validatePasswordMatch() {
		let password = this.formGroup.controls['password'].value;
		let rePassword = this.formGroup.controls['rePassword'].value;

		if (password == '') {
			this.formGroup.controls['rePassword'].setErrors({
				error: true
			});
		}
		else if (password !== rePassword) {
			this.formGroup.controls['rePassword'].setErrors({
				mismatchedPasswords: true
			});
		}
		else {
			this.formGroup.controls['rePassword'].setErrors(null);
		}

	}

	passwordValidated(val) {
		this.isPasswordValid = val;
	}

	onPasswordStrengthChanged(event: boolean) {
		this.strongPassword = event;
	}


	generatePassword() {
		let length = this.selectedLength;
		const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()';
		this.generatedPassword = Array.from({ length }, () => charset.charAt(Math.floor(Math.random() * charset.length))).join('');
		this.updatePasswordStrength();

	}

	copyPassword() {
		navigator.clipboard.writeText(this.generatedPassword).then(() => {
			console.log('Password copied to clipboard');
		}).catch(err => {
			console.error('Failed to copy: ', err);
		});
	}

	updatePasswordPattern(event?: any) {
		// Extract the selected value from the event object
		this.selectedLength = event.target.value ? parseInt(event.target.value, 10) : null;

		// Call generate password and update strength based on selected length
		this.updatePasswordStrength();
		this.generatePassword();
	}

	updatePasswordStrength() {
		switch (this.selectedLength) {
			case 256:
				this.passwordStrength = 4; // All bars should be filled for 256 characters
				break;
			case 128:
				this.passwordStrength = 3; // All bars should be filled for 128 characters
				break;
			case 94:
				this.passwordStrength = 2; // Three bars should be filled for 64 characters
				break;
			case 64:
				this.passwordStrength = 1; // Two bars should be filled for 32 characters
				break;
			default:
				this.passwordStrength = 0; // Default case
				break;
		}
	}
}
