import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { AuthService } from "app/shared/services/auth.service";
import { PasswordGenerator } from "app/shared/services/password.service";
import { SecurityPolicyService } from "app/shared/services/security-policy.service";
import { Subject, catchError, forkJoin, map, of, takeUntil } from "rxjs";

@Component({
    selector: 'mtm-password-checker',
    templateUrl: './password-checker.component.html',
    styleUrls: ['./password-checker.component.scss']
})
export class PasswordCheckerComponent implements OnInit, OnChanges {
    @Input() password: any;
    @Input() showValidation: boolean;
    @Input() companyId: string;

    @Input() minPassLength: any;
    @Input() isRegistration?: boolean = false;

    @Output() minPassLengthChange: EventEmitter<any> = new EventEmitter();
    @Output() passwordValidated: EventEmitter<any> = new EventEmitter();
    
    samplePassword: any;

    securityConfig: any;
    platformSecurityConfig: any;
    passwordValidations: any;
    minPasswordLength = 0;
    platformSecurityConfigKeyValue: any = {};
    selectedLength: number = 64; // Initialize to a default or input value
    generatedOptions: number[] = []; // Holds dynamically generated options

    passwordGeneratorModel: any = {
        length: {
            value: 0,
            enable: true
        },
        cases: {
            value: false,
            enable: true
        },
        numbers: {
            value: false,
            enable: true
        },
        symbols: {
            value: false,
            enable: true
        }
    }
    minLength = 0;
    private ngUnsubscribe = new Subject();

    constructor(private authService: AuthService, private securityService: SecurityPolicyService, private passwordGenerator: PasswordGenerator) { }

    ngOnInit(): void {
        this.passwordValidations = {
            PASSWORD_COMPLEXITY: {
                NUMBER: { value: false, valid: true },
                SYMBOL: { value: false, valid: true },
                CASES: { value: false, valid: true }
            },
            PASSWORD_LENGTH: { value: 0, valid: true }
        };
        this.minPassLengthChange.emit(this.passwordValidations.PASSWORD_LENGTH.value);
    }

    // ngOnChanges(changes: SimpleChanges): void {
    //     if (changes.password) {
    //         if (!this.securityConfig) {
    //             this.getCompanySecurityConfig();
    //         } else {
    //             this.passwordValidations = this.getPasswordConfigByKey();
    //             this.generateSamplePassword({
    //                 length: this.passwordValidations?.PASSWORD_LENGTH?.value || 0,
    //                 lowercase: true,
    //                 uppercase: this.passwordValidations?.PASSWORD_COMPLEXITY?.CASES?.value,
    //                 numbers: this.passwordValidations?.PASSWORD_COMPLEXITY?.NUMBER?.value,
    //                 symbols: this.passwordValidations?.PASSWORD_COMPLEXITY?.SYMBOL?.value
    //             });

    //             this.emitPasswordValidEvent();
    //         }
    //         this.minPassLengthChange.emit(this.passwordValidations.PASSWORD_LENGTH.value);
    //     }
    // }
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.password) {
            if (!this.securityConfig) {
                this.getCompanySecurityConfig();
            } else {
                this.passwordValidations = this.getPasswordConfigByKey();
                
                const passwordLength = this.passwordValidations?.PASSWORD_LENGTH?.value || 0;
                const passwordComplexity = this.passwordValidations?.PASSWORD_COMPLEXITY || {};
               // this.selectedLength = this.passwordValidations?.PASSWORD_LENGTH?.value;
                this.minPassLengthChange.emit(this.passwordValidations.PASSWORD_LENGTH.value);
    
                this.generateSamplePassword({
                    length: passwordLength,
                    lowercase: true,
                    uppercase: passwordComplexity?.CASES?.value || false,
                    numbers: passwordComplexity?.NUMBER?.value || false,
                    symbols: passwordComplexity?.SYMBOL?.value || false
                });
    
                this.emitPasswordValidEvent();
            }
    
            // Ensure `PASSWORD_LENGTH` is defined before emitting the event
            const passwordLengthValue = this.passwordValidations?.PASSWORD_LENGTH?.value || 0;
            this.minPassLengthChange.emit(passwordLengthValue);
        }

        if (changes.selectedLength) {
            this.generateOptions(); // Regenerate options if selectedLength changes
        }
    }
    
    // Method to generate dropdown options dynamically based on `selectedLength`
    generateOptions(): void {
        const maxLimit = 128;
        this.generatedOptions = [this.selectedLength]; // Start with the current `selectedLength`

        let powerOfTwo = 1;
        while (powerOfTwo <= maxLimit) {
            if (powerOfTwo > this.selectedLength && powerOfTwo <= maxLimit) {
                this.generatedOptions.push(powerOfTwo);
            }
            powerOfTwo *= 2;
        }
    }

    getCompanySecurityConfig() {
        forkJoin(
            this.securityService.getSecurityConfig(this.companyId)
                .pipe(
                    map((res) => res),
                    catchError(e => of({ error: true }))
                ),
            this.securityService.getSecurityPolicyPlatformConfig()
                .pipe(
                    map((res) => res),
                    catchError(e => of({ error: true }))
                )
        ).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
            next: (res) => {
                this.securityConfig = res[0].error ? [] : res[0];
                this.platformSecurityConfig = res[1].error ? [] : res[1];
                this.platformSecurityConfig?.forEach(c => {
                    this.platformSecurityConfigKeyValue[c.configKey] = c.configValue;
                }) || {};
                this.passwordValidations = this.getPasswordConfigByKey();
                this.selectedLength = this.passwordValidations?.PASSWORD_LENGTH?.value;
                this.minPassLengthChange.emit(this.passwordValidations.PASSWORD_LENGTH.value);
                this.generateOptions(); 
                if (this.platformSecurityConfigKeyValue?.PASSWORD_LENGTH) {
                    if (this.passwordValidations?.PASSWORD_LENGTH?.value > this.platformSecurityConfigKeyValue?.PASSWORD_LENGTH) {
                        this.minLength = this.passwordValidations?.PASSWORD_LENGTH?.value;
                    } else {
                        this.minLength = this.platformSecurityConfigKeyValue?.PASSWORD_LENGTH;
                        this.passwordValidations.PASSWORD_LENGTH.value = this.platformSecurityConfigKeyValue?.PASSWORD_LENGTH?.value || 1;
                    }
                } else {
                    this.minLength = this.passwordValidations?.PASSWORD_LENGTH?.value;
                }
                if (this.platformSecurityConfigKeyValue?.PASSWORD_LENGTH > this.passwordValidations?.PASSWORD_LENGTH?.value) {
                    this.passwordValidations.PASSWORD_LENGTH.value = this.platformSecurityConfigKeyValue?.PASSWORD_LENGTH || 0;
                }
                if (this.platformSecurityConfigKeyValue?.PASSWORD_COMPLEXITY?.split(',').indexOf('NUMBER') > -1) {
                    if (!this.passwordValidations?.PASSWORD_COMPLEXITY?.NUMBER?.value) {
                        this.passwordValidations.PASSWORD_COMPLEXITY.NUMBER.value = true;
                    }
                }
                if (this.platformSecurityConfigKeyValue?.PASSWORD_COMPLEXITY?.split(',').indexOf('CASES') > -1) {
                    if (!this.passwordValidations?.PASSWORD_COMPLEXITY?.CASES?.value) {
                        this.passwordValidations.PASSWORD_COMPLEXITY.CASES.value = true;
                    }
                }
                if (this.platformSecurityConfigKeyValue?.PASSWORD_COMPLEXITY?.split(',').indexOf('SYMBOL') > -1) {
                    if (!this.passwordValidations?.PASSWORD_COMPLEXITY?.SYMBOL?.value) {
                        this.passwordValidations.PASSWORD_COMPLEXITY.SYMBOL.value = true;
                    }
                }
                this.passwordGeneratorModel = {
                    length: {
                        value: this.passwordValidations?.PASSWORD_LENGTH?.value || 0,
                        enable: this.passwordValidations?.PASSWORD_LENGTH?.value
                    },
                    cases: {
                        value: this.passwordValidations?.PASSWORD_COMPLEXITY?.CASES?.value,
                        enable: !this.passwordValidations?.PASSWORD_COMPLEXITY?.CASES?.value
                    },
                    numbers: {
                        value: this.passwordValidations?.PASSWORD_COMPLEXITY?.NUMBER?.value,
                        enable: !this.passwordValidations?.PASSWORD_COMPLEXITY?.NUMBER?.value
                    },
                    symbols: {
                        value: this.passwordValidations?.PASSWORD_COMPLEXITY?.SYMBOL?.value,
                        enable: !this.passwordValidations?.PASSWORD_COMPLEXITY?.SYMBOL?.value
                    }
                };
                this.generateSamplePassword({
                    length: this.passwordValidations?.PASSWORD_LENGTH?.value,
                    lowercase: true,
                    uppercase: this.passwordValidations?.PASSWORD_COMPLEXITY?.CASES?.value,
                    numbers: this.passwordValidations?.PASSWORD_COMPLEXITY?.NUMBER?.value,
                    symbols: this.passwordValidations?.PASSWORD_COMPLEXITY?.SYMBOL?.value
                });
                this.emitPasswordValidEvent();
                this.minPassLengthChange.emit(this.passwordValidations.PASSWORD_LENGTH.value);
            }
        });
    }

    getPlatformSecurityConfig() {
        this.securityService.getSecurityPolicyPlatformConfig()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                this.securityConfig = data;
            })
    }

    emitPasswordValidEvent() {
        let result: boolean = true;
        if (this.passwordValidations?.PASSWORD_LENGTH?.value || this.platformSecurityConfigKeyValue?.PASSWORD_LENGTH) {
            result = this.validatePasswordLength(this.minLength);
            if (!result) {
                this.passwordValidated.emit(result);
                return;
            }
        }
        if (this.passwordValidations?.PASSWORD_COMPLEXITY?.NUMBER?.value || this.platformSecurityConfigKeyValue?.PASSWORD_COMPLEXITY?.split(',').indexOf('NUMBER') > -1) {
            result = this.validateNumber();
            if (!result) {
                this.passwordValidated.emit(result);
                return;
            }
        }
        if (this.passwordValidations?.PASSWORD_COMPLEXITY?.SYMBOL?.value || this.platformSecurityConfigKeyValue?.PASSWORD_COMPLEXITY?.split(',').indexOf('SYMBOL') > -1) {
            result = this.validateSymbol();
            if (!result) {
                this.passwordValidated.emit(result);
                return;
            }
        }
        if (this.passwordValidations?.PASSWORD_COMPLEXITY?.CASES?.value || this.platformSecurityConfigKeyValue?.PASSWORD_COMPLEXITY?.split(',').indexOf('CASES') > -1) {
            result = this.validateCases();
            if (!result) {
                this.passwordValidated.emit(result);
                return;
            }
        }
        this.minPassLengthChange.emit(this.passwordValidations.PASSWORD_LENGTH.value);
        this.passwordValidated.emit(result);
    }

    getPasswordConfigByKey() {
        let passwordValidations: any = {
            PASSWORD_COMPLEXITY: {
                NUMBER: { value: false, valid: true },
                SYMBOL: { value: false, valid: true },
                CASES: { value: false, valid: true }
            },
            PASSWORD_LENGTH: { value: 0, valid: true }
        };
        const config = this.securityConfig.filter(c => c.configKey == 'PASSWORD_LENGTH' || c.configKey == 'PASSWORD_COMPLEXITY');
        if (config.length) {
            config.forEach(c => {
                if (c.configKey == 'PASSWORD_COMPLEXITY') {
                    passwordValidations.PASSWORD_COMPLEXITY = {
                        NUMBER: {
                            value: c.configValue?.split(',').indexOf('NUMBER') > -1,
                            valid: this.validateNumber()
                        },
                        SYMBOL: {
                            value: c.configValue?.split(',').indexOf('SYMBOL') > -1,
                            valid: this.validateSymbol()
                        },
                        CASES: {
                            value: c.configValue?.split(',').indexOf('CASES') > -1,
                            valid: this.validateCases()
                        }
                    }
                } else if (c.configKey == 'PASSWORD_LENGTH') {
                    passwordValidations.PASSWORD_LENGTH = {
                        value: parseInt(c.configValue),
                        valid: this.validatePasswordLength(parseInt(c.configValue))
                    };
                }
            });
        } else {
            passwordValidations = {
                PASSWORD_COMPLEXITY: {
                    NUMBER: { value: false, valid: true },
                    SYMBOL: { value: false, valid: true },
                    CASES: { value: false, valid: true }
                },
                PASSWORD_LENGTH: { value: 0, valid: true }
            }
        }
        return passwordValidations;
    }

    validatePasswordLength(length) {
        return this.password.length >= length;
    }

    validateNumber() {
        return this.password.match(/.*[0-9]/);
    }

    validateSymbol() {
        return this.password.match(/.*\W/);
    }

    validateCases() {
        return this.password.match(/.*[a-z]/) && this.password.match(/.*[A-Z]/);
    }

    generateSamplePassword(options, sample?) {
        if (this.samplePassword && !sample) {
            return;
        }
        this.samplePassword = this.passwordGenerator.generatePassword(options);
    }

    copyPassword(e) {
        const tempEl = document.createElement('textarea');
        tempEl.value = this.samplePassword;
        document.body.appendChild(tempEl);
        tempEl.select();
        document.execCommand('copy');
        document.body.removeChild(tempEl);
        this.showTooltip(e);
    }

    showTooltip(e) {
        let element = $(e.target);
        element.addClass('clipboard-tooltipped');
        setTimeout(() => {
            element.removeClass('clipboard-tooltipped');
        }, 3000);
    }
	passwordStrength: number = 0;

    updatePasswordStrength() {	
        this.passwordGeneratorModel.length.value = this.selectedLength;
        this.generateSamplePassword({ length: this.passwordGeneratorModel.length.value, numbers: this.passwordGeneratorModel.numbers.value, lowercase: true, uppercase: this.passwordGeneratorModel.cases.value, symbols: this.passwordGeneratorModel.symbols.value }, true);
		switch (this.selectedLength) {
		  case 256:
			this.passwordStrength = 4; // All bars should be filled for 256 characters
			break;
		  case 128:
			this.passwordStrength = 3; // All bars should be filled for 128 characters
			break;
		  case 94:
			this.passwordStrength = 2; // Three bars should be filled for 64 characters
			break;
		  case 64:
			this.passwordStrength = 1; // Two bars should be filled for 32 characters
			break;
		  default:
			this.passwordStrength = 0; // Default case
			break;
		}
	  }
}