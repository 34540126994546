import * as _ from 'lodash';
import * as moment from 'moment';
import { OnInit, Component, ViewChild, ElementRef, OnDestroy } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ProjectService } from "app/shared/services/project.service";
import { ReviewLinkService } from 'app/shared/services/review-link.service';
import { UUID } from 'angular2-uuid';
import { environment } from 'environments/environment';
import { AuthService } from 'app/shared/services/auth.service';
import { HelperService } from 'app/shared/services/helper.service';
import { MTMDatePickerDirective } from 'app/shared/directives/mtm-datepicker.directive';
import { OverlayService } from 'app/shared/services/overlayService';
import { TranslatePipe } from 'app/shared/pipes/translate.pipe';
import { MusicSelectionService } from 'app/shared/services/music-selection.service';
import { CastingVoiceOverService } from 'app/shared/services/castingVoiceOver.service';
import { Animation2dService } from 'app/shared/services/animation-2d.service';
import { Animation3dService } from 'app/shared/services/animation-3d.service';
import { LocationService } from 'app/shared/services/location.service';
import { StylismService } from 'app/shared/services/stylism.service';
import { StoryboardService } from 'app/shared/services/storyboard.service';
import { SetDesignService } from 'app/shared/services/set-design.service';
import { EditingService } from 'app/shared/services/editing.service';
import { MusicService } from 'app/shared/services/music.service';
import { VoiceOverRecordingService } from 'app/shared/services/voice-over-recording.service';
import { AccessoriesService } from 'app/shared/services/accessories.service';
import { ActorService } from 'app/shared/services/actor.service';
import { HairDresserService } from 'app/shared/services/hairdress.service';
import { ShootingBoardService } from 'app/shared/services/shooting-board.service';
import { DigitalEffectsService } from 'app/shared/services/digital-effects.service';
import { ColorGradingService } from 'app/shared/services/color-grading.service';
import { SoundDesignService } from 'app/shared/services/sound-design.service';
import { SubtitlesService } from 'app/shared/services/subtitles.service';
import { ShootAndTakeService } from 'app/shared/services/shoot-and-take.service';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { ExportService } from 'app/shared/services/export.service';
import { SubsectionService } from 'app/shared/services/subsection.service';
import { CastingService } from 'app/shared/services/casting.service';
import { RoleLookService } from 'app/shared/services/role-look.service';
import { LoadingDataComponent } from '../../loading-data/loading-data.component';

@Component({
	selector: 'review-link-settings',
	templateUrl: './review-link-settings.component.html',
	styleUrls: ['./review-link-settings.component.scss']
})
export class ReviewUiLinkSettingsComponent implements OnInit, OnDestroy {
	@ViewChild('linkElement', { static: false }) linkElement: ElementRef;
	@ViewChild('linkExpirationDateElement', { static: true }) linkExpirationDateElement: MTMDatePickerDirective;
	@ViewChild('loadingRef', { static: true }) public loadingRef: LoadingDataComponent;
	section: any;
	subSection: any;
	activeTab: string = 'share';
	shortedSharingUrl: string;
	items = [];
	subSectionAssets: any[] = [];
	selectedAssets: any[] = [];
	inviteData: any = [];
	invitedUsers: any = [];
	settingDataModel: any = {};
	reviewLinkId: any = UUID.UUID();
	passwordProtection: any;
	messageToUsers: any;
	formSubmitted: boolean = false;
	formError: any;
	expirationDate: any;
	linkExpirationDate: any;
	blacklistedParticipants: any = [];
	managedUsers: any = [];
	updatedReviewLink;
	isBusy: boolean = false;
	linkCopied: any;
	isExpired: boolean = false;
	topics: any = [];
	userType: string = 'anyone';
	ngUnsubscribe = new Subject();
	isLoading: boolean;
	reviewLink: any;
	isNewReviewLink: boolean = false;

	constructor(
		private projectService: ProjectService,
		public activeModal: NgbActiveModal,
		private overlayService: OverlayService,
		private reviewLinkService: ReviewLinkService,
		private translatePipe: TranslatePipe,
		private authService: AuthService,
		private castingVoiceOverService: CastingVoiceOverService,
		private roleLookService: RoleLookService,
		public location: LocationService,
		private stylism: StylismService,
		public storyboard: StoryboardService,
		private setDesignService: SetDesignService,
		private accessoriesService: AccessoriesService,
		private actorService: ActorService,
		private hairDresserService: HairDresserService,
		private shootingBoardService: ShootingBoardService,
		private subsectionService: SubsectionService,
		private castingService: CastingService,
		private shootAndTakeService: ShootAndTakeService
	) { }

	ngOnDestroy(): void {
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	ngOnInit(): void {
		this.inviteData = this.projectService.project.participants;
		this.shortedSharingUrl = HelperService.getCurrentOrigin() + '/review/' + this.reviewLinkId;
		this.linkCopied = this.translatePipe.transform('linkCopied');
		this.isLoading = true;
		this.loadingRef.showLoading();
	}

	settingsTabSelect(tab: string) {
		this.activeTab = tab;
	}

	closeModal(force?: boolean) {
		if (this.isBusy && !force) {
			return;
		}
		this.activeModal.close({ data: this.updatedReviewLink });
	}

	saveReviewLink(args: any = {}) {
		if (!args.clear || this.isBusy) {
			return;
		}
		this.formSubmitted = true;
		this.formError = {};
		const assets = args.assets || this.selectedAssets || [];
		let reviewLinkAssets = [];
		for (let i = 0; i < assets.length; i++) {
			for (let j = 0; j < assets[i].simpleVersions.length; j++) {
				if (assets[i].simpleVersions[j].isSelected) {
					reviewLinkAssets.push({
						sectionId: this.section.id,
						sectionName: this.section.name,
						subSectionId: this.subSection.id,
						subSectionName: this.subSection.name,
						version: assets[i].simpleVersions[j].versionNumber,
						name: assets[i].title,
						itemId: assets[i].optionId
					});
				}
			}
		}
		if (!reviewLinkAssets.length) {
			this.formError.assets = true;
		}
		if (!HelperService.isObjectEmpty(this.formError)) {
			return;
		}
		if (!this.reviewLink) {
			const defaultDate = moment().add(1, 'M');
			this.linkExpirationDate = { 'year': defaultDate.year(), 'month': defaultDate.month() + 1, 'day': defaultDate.date() };
		}
		let expirationDate = this.linkExpirationDate
			? moment(HelperService.getDateFromDatepicker(this.linkExpirationDate)).utc().valueOf()
			: null;
		this.isBusy = true;

		this.reviewLinkService.checkReviewLinkExistence({
			assets: reviewLinkAssets,
			creator: this.authService.getAuthUser().email,
			projectId: this.projectService.project.id
		}).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (res: any) => {
				if (res.id) {
					this.reviewLinkId = res.id;
					this.setParams({ reviewLink: res, updated: true });
				}
				this.createReviewLink(reviewLinkAssets, expirationDate, args);
			},
			error: (err) => {
				if (err.errorCode == 'NOT_FOUND') {
					this.createReviewLink(reviewLinkAssets, expirationDate, args);
				} else {
					this.overlayService.showError(err.message, err.errorCode);
					this.isBusy = false;
				}
			}
		});
	}

	assetChangeHandler(assets) {
		this.selectedAssets = assets;
		this.saveReviewLink({ clear: true });
	}

	private createReviewLink(reviewLinkAssets, expirationDate, args) {
		this.reviewLinkService.createReviewLinks(
			this.projectService.project.id, {
			assets: reviewLinkAssets,
			blacklistedParticipants: this.blacklistedParticipants,
			creator: this.authService.getAuthUser().email,
			expiration: expirationDate,
			id: this.reviewLinkId,
			link: this.shortedSharingUrl,
			message: this.messageToUsers,
			participants: _.map([...(_.get(args, 'inviters') || this.invitedUsers), ...this.managedUsers], (usr) => usr.email),
			password: this.passwordProtection || null,
			projectId: this.projectService.project.id
		}).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe((res: any) => {
			if (!args.clear) {
				res.participants = _.map([...(_.get(args, 'inviters') || this.invitedUsers), ...this.managedUsers], (usr) => usr.email);
				this.updatedReviewLink = res;
				this.setParams({
					reviewLink: res,
					clear: args.clear,
					updated: true
				});
			}
			this.reviewLink = res;
			if (args.closeModal) {
				this.overlayService.showSuccess('Data saved.', 'Success');
				this.activeModal.close();
			}
			this.isBusy = false;
		}, err => {
			this.overlayService.showError(err.message, err.errorCode);
			this.isBusy = false;
		});
	}

	copyShortenedUrl(e) {
		if (!this.reviewLink) {
			return;
		}
		HelperService.copyToClipboard(this.reviewLink.shortUrl);
		this.showTooltip(e);
		this.overlayService.showSuccess(this.translatePipe.transform('copiedToClipboard'));
	}

	showTooltip(e) {
		let element = $(e.target).find('.mtm-icon-share-link-red');
		element.addClass('clipboard-tooltipped');
		setTimeout(() => {
			element.removeClass('clipboard-tooltipped');
		}, 3000);
	}

	get reviewLinkUrl(): string {
		return this.reviewLink ? `${HelperService.getCurrentOrigin()}/review/${this.reviewLink.id}` : '';
	}

	isReviewLinkExpired() {
		this.expirationDate = this.linkExpirationDate
			? moment(HelperService.getDateFromDatepicker(this.linkExpirationDate)).utc().valueOf()
			: null;
		if (this.expirationDate) {
			if (moment.unix(this.expirationDate / 1000).isBefore(moment())) {
				this.expirationDate = moment.unix(this.expirationDate / 1000).format('YYYY-MM-DD');
				this.linkCopied = this.translatePipe.transform('linkCopiedExpired');
				return true;
			}
		}
		this.linkCopied = this.translatePipe.transform('linkCopied');
		return false;
	}

	removeManagedUser(user) {
		if (this.isBusy) {
			return;
		}
		this.managedUsers = _.filter(this.managedUsers, (managedUser) => user.email !== managedUser.email);
		this.blacklistedParticipants.push(user.email);
		this.saveReviewLink({});
	}

	getSubSectionTopic() {
		const { subSection } = this.subSection;
		if (subSection === 'MAKE-UP') {
			return this.roleLookService.getRoleLooks(this.projectService.project.id, this.section.id, this.subSection.id);
		} else if (subSection === 'CASTING') {
			return this.castingService.getCastings(this.projectService.project.id, this.section.id, this.subSection.id);
		} else if (subSection === 'STYLISM_/_COSTUME_DESIGN') {
			return this.stylism.getWardrobes(this.projectService.project.id, this.section.id, this.subSection.id);
		} else if (subSection === 'VOICE_OVER_CASTING') {
			return this.castingVoiceOverService.getCastingVoiceOverRoles(this.projectService.project.id, this.section.id, this.subSection.id);
		} else if (subSection === 'SHOOTING_BOARD') {
			return this.shootingBoardService.getScenes(this.projectService.project.id, this.section.id, this.subSection.id);
		} else if (subSection === 'SET_DESIGN' || subSection === 'LOCATION') {
			return this.location.getSettings(this.projectService.project.id, this.section.id, this.subSection.id);
		} else if (subSection === 'HAIR_DRESSER') {
			return this.hairDresserService.getAllHairStyle(this.projectService.project.id, this.section.id, this.subSection.id);
		} else if (subSection === 'SCENES') {
			return this.shootAndTakeService.getAllShotsForShooting(this.projectService.project.id, this.section.id, this.subSection.id);
		} else {
			return this.subsectionService.getTopics(this.projectService.project.id, this.section.id, this.subSection.id);
		}
	}

	public setParams(args: any) {
		this.isNewReviewLink = !args.reviewLink;
		if (args.reviewLink) {
			this.reviewLink = args.reviewLink;
			this.section = this.projectService.project.sectionsId[args.reviewLink.assets[0].sectionId];
			this.subSection = this.projectService.project.subsectionsId[args.reviewLink.assets[0].subSectionId];
			this.reviewLinkId = args.reviewLink.id;
			this.getSubSectionTopic()
				.pipe(
					takeUntil(this.ngUnsubscribe)
				).subscribe(res => {
					this.topics = res;
					this.topics = this.topics.map(t => {
						t.title = t.title || t.name;
						t.topicId = t.topicId || t.id;
						return t;
					})
					this.getRequests(this.projectService.project.id, this.subSection).pipe(
						takeUntil(this.ngUnsubscribe)
					).subscribe(
						(res: any) => {
							if (args.clear || args.init) {
								this.messageToUsers = '';
								let data = _.uniqBy([...this.projectService.project.participants, ...args.reviewLink.participantsUsers], 'email');
								this.managedUsers = _.filter(data,
									(usr) => {
										return !!_.find(args.reviewLink.participants, (u) => u === usr.email && usr.email !== args.reviewLink.creatorUser.email);
									}
								);
							}
							this.invitedUsers = args.reviewLink.participantsUsers.filter(p => p.username != args.reviewLink.creator);
							this.blacklistedParticipants = args.reviewLink.blacklistedParticipants;
							if (!args.updated) {
								this.subSectionAssets = res.sort((a: any, b: any) => a.order - b.order);
								this.selectedAssets = _.filter(
									this.subSectionAssets,
									(subSectionAsset) => _.filter(args.reviewLink.assets, (asset) => asset.itemId === subSectionAsset.optionId).length > 0
								);
							}
							this.inviteData = _.filter(this.inviteData, (data) => {
								return !_.filter(this.managedUsers, (managedUser) => {
									return managedUser.email === data.email;
								}).length;
							})
							this.userType = this.invitedUsers.length ? 'restricted' : 'anyone';
							//this.shortedSharingUrl = 'https:' + environment.web.baseUrl + '/review/' + args.reviewLink.id;
							this.shortedSharingUrl = HelperService.getCurrentOrigin() + '/review/' + args.reviewLink.id;
							this.passwordProtection = args.reviewLink.password;
							if (args.reviewLink.expiration && !args.updated) {
								this.linkExpirationDate = {
									year: parseInt(moment.unix(args.reviewLink.expiration / 1000).format('YYYY')),
									month: parseInt(moment.unix(args.reviewLink.expiration / 1000).format('M')),
									day: parseInt(moment.unix(args.reviewLink.expiration / 1000).format('D'))
								}
							}
							if (!args.updated) {
								this.setReviewLinkVersioningAssets(args.reviewLink);
							}
							this.isLoading = false;
							this.loadingRef.hide();
						}, (err: any) => {
							this.isLoading = false;
							this.loadingRef.hide();
						}
						, err => {
							this.isLoading = false;
							this.loadingRef.hide();
						});
				});
		} else {
			this.section = this.projectService.project.sectionsId[args.section];
			this.subSection = this.projectService.project.subsectionsId[args.subSection];
			this.subSectionAssets = args.assets.sort((a: any, b: any) => a.order - b.order);
			this.selectedAssets = _.filter(this.subSectionAssets, (asset) => asset.optionId === args.parentItem.optionId);
			this.getSubSectionTopic()
				.pipe(
					takeUntil(this.ngUnsubscribe)
				).subscribe((res: any) => {
					this.topics = res;
					this.topics = this.topics.map(t => {
						t.title = t.title || t.name;
						t.topicId = t.topicId || t.id;
						return t;
					})
					this.setReviewLinkVersioningAssets();
					this.isLoading = false;
					this.loadingRef.hide();
				}, (err) => {
					this.isLoading = false;
					this.loadingRef.hide();
				});
		}
	}

	reviewLinkVersionAssets: any;
	setReviewLinkVersioningAssets(reviewLink?) {
		this.reviewLinkVersionAssets = {
			label: this.subSection.name,
			value: this.subSection.id,
			topics: this.topics.map(t => {
				return {
					label: t.title,
					value: t.topicId,
					assets: this.getTopicAssets(t)
				};
			})
		}
		this.subSectionAssets.map((asset) => {
			asset.isSelected = this.selectedAssets.filter((selectedAsset) => asset.optionId === selectedAsset.optionId).length > 0;
			asset.simpleVersions = asset.simpleVersions || asset.versions;
			if (HelperService.isObjectEmpty(asset.simpleVersions)) {
				asset.simpleVersions = [];
			}
			if (asset.isSelected) {
				asset.isExpanded = true;
				asset.simpleVersions.map(v => {
					v.isSelected = reviewLink ? reviewLink.assets.find(a => a.itemId === asset.optionId && v.versionNumber === a.version) : asset.isSelected;
					return v;
				});
				this.reviewLinkVersionAssets.topics.map(t => {
					t.isSelected = t.isExpanded =
						t.assets.filter(a => a.isSelected).length ||
						(
							asset.looks?.includes(t.value) ||
							asset.castings?.includes(t.value) ||
							asset.roles?.includes(t.value) ||
							asset.sceneId === t.value ||
							asset.locations?.includes(t.value) ||
							asset.optionId === t.value ||
							asset.shotSelected === t.value ||
							asset.wardrobes?.includes(t.value)
						);
					return t;
				})
			} else {
				asset.simpleVersions.map(v => {
					v.isSelected = false;
					return v;
				});
				asset.isSelected = false;
				asset.isExpanded = false;
			}
		});
		if (!reviewLink) {
			this.saveReviewLink({ clear: true });
		}
	}

	getTopicAssets(t) {
		return this.subSectionAssets.filter(asset => (
			asset.looks?.includes(t.topicId) ||
			asset.castings?.includes(t.topicId) ||
			asset.roles?.includes(t.topicId) ||
			asset.sceneId === t.topicId ||
			asset.locations?.includes(t.topicId) ||
			asset.topicId === t.topicId ||
			asset.shotSelected === t.topicId ||
			asset.wardrobes?.includes(t.topicId)
		));
	}

	selectedAssetsChange(data) {
		this.saveReviewLink({});
	}

	selectedInvitersChange(data) {
		this.blacklistedParticipants = _.filter(this.blacklistedParticipants, (email) => email !== data.newData);
		this.userType = this.invitedUsers.length ? 'restricted' : 'anyone';
		this.saveReviewLink({});
	}

	getRequests(projectId: any, subSectionData: any) {
		let request: any;
		if (subSectionData.subSection === 'STYLISM_/_COSTUME_DESIGN') {
			request = this.stylism.getSimpleArticles(projectId, subSectionData.projectSectionId, subSectionData.id);
		} else if (subSectionData.subSection === 'CASTING') {
			request = this.actorService.getActorsSimple(projectId, subSectionData.projectSectionId, subSectionData.id);
		} else if (subSectionData.subSection === 'LOCATION') {
			request = this.location.getSimpleSites(projectId, subSectionData.projectSectionId, subSectionData.id);
		} else if (subSectionData.subSection === 'SET_DESIGN') {
			request = this.setDesignService.getSimpleArticles(projectId, subSectionData.projectSectionId, subSectionData.id);
		} else if (subSectionData.subSection === 'MAKE-UP') {
			request = this.accessoriesService.getAccessoriesSimple(projectId, subSectionData.projectSectionId, subSectionData.id);
		} else if (subSectionData.subSection === 'VOICE_OVER_CASTING') {
			request = this.castingVoiceOverService.getSimpleCastingVoiceOverActors(projectId, subSectionData.projectSectionId, subSectionData.id);
		} else if (subSectionData.subSection === 'SHOOTING_BOARD') {
			request = this.shootingBoardService.getSimpleShots(projectId, subSectionData.projectSectionId, subSectionData.id);
		} else {
			request = this.subsectionService.getOptions(projectId, subSectionData.projectSectionId, subSectionData.id);
		}
		return request;
	}

	selectUserType(userType) {
		this.userType = userType;
		if (userType == 'anyone') {
			this.invitedUsers = [];
		}
	}
}
