<div class="modal-container" [class.disabled-milestone-timeline]="activeMilestone == false">
  <div class="modal-header px-0 pb-2 pb-md-3 d-flex flex-column flex-md-row align-items-center">
    <div class="d-flex align-items-center w-100 header-title">
      <div class="flag-wrapper d-inline-block mr-3">
        <mtm-status-flag-milestone [isTransparentBg]="true" [status]="statusValue || ProjectStatus.IN_PROGRESS" [flagWidth]="30" [flagHeight]="30"></mtm-status-flag-milestone>
      </div>
      <h4 class="modal-title text-uppercase">{{milestone?.name}}</h4>
    </div>
    <div class="d-flex align-items-center ml-md-auto active-milestone-field mt-3 mt-md-0 active-milestone-control">
      <label class="text-nowrap my-0 mr-3 _600">{{ (milestone.activeMilestone == false ? 'Disabled Milestone' : 'Active Milestone') | translate }}</label>
      <div class="d-flex align-items-center ml-auto ml-md-none">
        <ui-switch class="d-flex align-items-center" defaultBgColor="black" size="small" switchColor="#efefef"
          [(ngModel)]="activeMilestone" (click)="updateActiveMilestone()"></ui-switch>
      </div>
    </div>
  </div>
  <div class="modal-body">
    <form [formGroup]="milestoneForm" (ngSubmit)="save()">
      <div class="input-field d-flex flex-column align-items-center">
        <div class="d-flex flex-column flex-md-row align-items-center input-row">
          <div class="d-flex flex-column align-items-start field-container drive-date-field">
            <label>{{'milestoneStatusEditor_driveDate'|translate}}</label>
            <div class="input-group position-relative date-field" [class.has-error]="milestoneForm.controls?.driveDate?.errors" [class.empty-date]="!milestoneForm.controls?.driveDate?.value">
              <input class="form-control date-picker" formControlName="driveDate" ngbDatepicker [readOnly]="true"
                #driveDate="ngbDatepicker" placeholder="{{'milestoneStatusEditor_driveDate' | translate}}"
                firstDayOfWeek="1">
              <span *ngIf="milestoneForm.controls?.driveDate?.value" class="date-label">{{milestoneForm.controls.driveDate.value|ngbDate}}</span>
              <img src="/assets/img/dashboard/date-edit.svg" height="11" width="12" priority />
            </div>
          </div>
          <div class="field-container status-field">
            <div class="d-flex align-items-center mb-2 tooltip-dropdown" ngbDropdown placement="bottom">
              <i class="mtm-icon mtm-icon-info-grey mr-2 p-0" ngbDropdownToggle></i>
              <label class="m-0">{{'status'|translate}}</label>
              <div ngbDropdownMenu class="tooltip-dropdown-menu">
                <label class="mb-1 _600">{{'Status'|translate}}</label>
                <div class="status-info">
                  <div class="d-flex align-items-start mb-2">
                    <mtm-status-flag-milestone [style.margin-top]="'2px'" [isTransparentBg]="true" class="mr-2 flag-wrapper" [status]="ProjectStatus.IN_PROGRESS" [flagWidth]="18" [flagHeight]="18"></mtm-status-flag-milestone>
                    <label class="m-0">{{'milestone_inprogress'|translate}}</label>
                  </div>
                  <div class="d-flex align-items-start mb-2">
                    <mtm-status-flag-milestone [style.margin-top]="'2px'" class="mr-2" [status]="ProjectStatus.COMPLETED" [flagWidth]="18" [flagHeight]="18"></mtm-status-flag-milestone>
                    <label class="m-0">{{'milestone_completed'|translate}}</label>
                  </div>
                  <div class="d-flex align-items-start mb-2">
                    <mtm-status-flag-milestone [style.margin-top]="'2px'" class="mr-2" [status]="ProjectStatus.UPCOMING_EXPIRY" [flagWidth]="18" [flagHeight]="18"></mtm-status-flag-milestone>
                    <label class="m-0">{{'milestone_upcoming_expiry'|translate}}</label>
                  </div>
                  <div class="d-flex align-items-start mb-2">
                    <mtm-status-flag-milestone [style.margin-top]="'2px'" class="mr-2" [status]="ProjectStatus.DELAYED" [flagWidth]="18" [flagHeight]="18"></mtm-status-flag-milestone>
                    <label class="m-0">{{'milestone_overdue'|translate}}</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex" ngbDropdown placement="bottom">
              <div class="d-flex align-items-center justify-content-center selected-status px-2" ngbDropdownToggle [style.cursor]="'default'"
                [class.status-in-progress]="statusValue === ProjectStatus.IN_PROGRESS"
                [class.status-delayed]="statusValue === ProjectStatus.DELAYED"
                [class.status-upcoming-expiry]="statusValue === ProjectStatus.UPCOMING_EXPIRY"
                [class.status-completed]="statusValue === ProjectStatus.COMPLETED">
                <mtm-status-flag-milestone [isTransparentBg]="statusValue != ProjectStatus.IN_PROGRESS" [status]="statusValue" [flagWidth]="24" [flagHeight]="24"></mtm-status-flag-milestone>
                <span class="status-label mx-2">{{ ('sectionStatus_' + statusValue) |
                  translate}}</span>
              </div>
              <!-- <div ngbDropdownMenu>
                <a *ngFor="let statusItem of targetStatusList"
                  class="dropdown-item d-flex align-items-center justify-content-center status-item px-2"
                  [class.status-in-progress]="statusItem === ProjectStatus.IN_PROGRESS"
                  [class.status-delayed]="statusItem === ProjectStatus.DELAYED"
                  [class.status-upcoming-expiry]="statusValue === ProjectStatus.UPCOMING_EXPIRY"
                  [class.status-completed]="statusItem === ProjectStatus.COMPLETED" (click)="changeStatus(statusItem)">
                  <mtm-status-flag-milestone [status]="statusItem" [flagWidth]="21" [flagHeight]="21"></mtm-status-flag-milestone>
                  <span class="status-label mx-2">{{ ('sectionStatus_' + statusItem) |
                    translate}}</span>
                </a>
              </div> -->
            </div>
          </div>
        </div>
        <div class="d-flex flex-column flex-md-row align-items-center input-row">
          <div class="d-flex flex-column align-items-start field-container">
            <div class="d-flex align-items-center mb-2 tooltip-dropdown" ngbDropdown placement="bottom">
              <i class="mtm-icon mtm-icon-info-grey mr-2 p-0" ngbDropdownToggle></i>
              <label class="m-0">{{'milestoneStatusEditor_negotiatedDate'|translate}}</label>
              <div ngbDropdownMenu class="tooltip-dropdown-menu">
                <label class="mb-1 _600">{{'Rules'|translate}}</label>
                <div class="status-info">
                  {{'milestone_rules_info'|translate}}
                </div>
              </div>
            </div>
            <div class="input-group position-relative date-field" [class.has-error]="milestoneForm.controls?.negotiatedDate?.errors" [class.empty-date]="!milestoneForm.controls?.negotiatedDate?.value">
              <input class="form-control date-picker" formControlName="negotiatedDate" ngbDatepicker [maxDate]="negotiatedMaxDate" [minDate]="minNegotiatedDate"
                #negotiatedDate="ngbDatepicker" placeholder="{{'milestoneStatusEditor_negotiatedDate' | translate}}"
                firstDayOfWeek="1" (click)="negotiatedDate.toggle()">
                <span *ngIf="milestoneForm.controls?.negotiatedDate?.value" class="date-label">{{milestoneForm.controls.negotiatedDate.value|ngbDate}}</span>
              <img src="/assets/img/dashboard/date-edit.svg" height="11" width="12" (click)="negotiatedDate.toggle()"
                priority />
            </div>
          </div>
          <div class="d-flex flex-column align-items-start field-container">
            <label>{{'milestoneStatusEditor_actualDate'|translate}}</label>
            <div class="input-group position-relative date-field" [class.has-error]="milestoneForm.controls?.actualDate?.errors" [class.empty-date]="!milestoneForm.controls?.actualDate?.value">
              <input class="form-control date-picker" formControlName="actualDate" ngbDatepicker
                #actualDate="ngbDatepicker" placeholder="{{'milestoneStatusEditor_actualDate' | translate}}"
                firstDayOfWeek="1" (click)="actualDate.toggle()">
              <span *ngIf="milestoneForm.controls?.actualDate?.value" class="date-label">{{milestoneForm.controls.actualDate.value|ngbDate}}</span>
              <img src="/assets/img/dashboard/date-edit.svg" height="11" width="12" (click)="actualDate.toggle()"
                priority />
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex flex-column flex-md-row align-items-center justify-content-center mt-2 mb-1 buttons">
        <button type="button" class="btn btn-large text-uppercase cancel-btn" (click)="cancel()"
          [disabled]="isLoading">{{'cancel' | translate}}</button>
        <button type="submit" class="btn btn-large text-uppercase apply-btn" [disabled]="isLoading">{{'apply' |
          translate}}</button>
      </div>
    </form>
  </div>
</div>