<div>
	<div [attr.id]="id" class="mtm-dropdown d-block {{customClass}}" *ngIf="!singleSelectionInMultipleValues">
		<div class="btn-group form-control pt-0 pb-0 pr-1 w-100" [style.background-color]="white" [ngClass]="{
			'default-border': isDefaultBorder && !isDangerBorder,
			'danger-border': isDangerBorder || (isDangerBorderForFormInValid && form?.controls[formControlAccessor]?.invalid)
			}">
			<a (click)="toggleList($event)" class="d-flex align-items-center btn dropdown-toggle mt-0 mb-0"
				[disabled]="isDisabled" [ngClass]="{'cr-not-allowed': isDisabled}" style="padding:2.5px;">
				<span class="float-left multiple" *ngIf="!selectedItems.length">{{getEmptyCaption()}}</span>
				<span class="float-left single selected-long-text" [ngbTooltip]="selectedItems[0].label | translate"
					placement="bottom" *ngIf="!isMultipleSelect && selectedItems.length">{{selectedItems[0].label |
					translate}}</span>
				<div class="multiple-select-container" *ngIf="isMultipleSelect && selectedItems.length">
					<span class="pr-1 text-info">
						{{emptyValueAsValue && isEmptyValueSelected ? getSelectedLength() : selectedItems.length}}
					</span>
					<span *ngIf="selectedItems.length==1" class="multiple-select-dot-selected-text">
						{{dotItemSelectedText || '' | translate}}</span>
					<span *ngIf="selectedItems.length>1" class="multiple-select-dot-selected-text">
						{{dotItemsSelectedText || '' | translate}}</span>
				</div>
				<span class="caret"></span>
			</a>
			<ul class="dropdown-menu w-100 item-list-menu" (clickOutside)="clickOutside(e)"
				[style.background-color]="customBgCode" [class.d-block]="isShowList" role="menu">
				<div *ngIf="isDisplaySearchBox" class="w-100">
					<input [(ngModel)]="searchValue" class="w-100 pl-1 pr-1 mb-1 form-control-sm border"
						[placeholder]="'search'| translate" [style.background]="'transparent'">
				</div>
				<h6 *ngIf="!displayList.length" class="mb-0 pl-3 overflow-hidden text-truncate text-warning">
					{{emptyListInfoText}}
				</h6>
				<li (mouseleave)="hoverId = null" (mouseenter)="setHoverId(item.id)" role="menuitem"
					*ngFor="let item of displayList | searchMTMDropdownPipe:searchValue; let i = index;"
					class="d-flex flex-row position-relative">
					<!--
				<div *ngIf="item.id == hoverId && checkLableLength(i)" class="d-flex bg-dark tip hover-text text-center"> {{item.label | translate}} </div>
				-->
					<div class="dropdown-item d-flex flex-row align-items-center pl-1"
						(click)="onSelectItem(item);false">
						<span class="checked-container">
							<i [class.d-none]="!item.isChecked" aria-hidden="true"
								class="fa fa-check ml-1 mr-1 text-success"></i>
						</span>
						<span id="labelText{{i}}" class="long-text w-100 h-100"
							[title]="checkLableLength(i) ? ( item.label | translate ) : ''">
							{{item.label | translate}}
						</span>
					</div>
				</li>
			</ul>
		</div>
		<ul *ngIf="(!hideSelectedItem && isMultipleSelect && getSelectedItems().length) || showSelectedItem"
			class="lastRow w-100 pt-1 pb-1 pr-1 mb-1">
			<li *ngFor="let selectedItem of getSelectedItems();let i = index" class="selected-list-item _600"
				[class.cr-not-allowed]="isDisabled">
				<i class="fa fa-window-close mr-2" (click)="clearItem(selectedItem)" aria-hidden="true"></i>
				{{selectedItem.label | translate}}
			</li>
		</ul>
	</div>
	<div [attr.id]="id" class="mtm-dropdown d-block {{customClass}}" *ngIf="singleSelectionInMultipleValues">
		<div *ngIf="!getSelectedItems().length" class="btn-group form-control pt-0 pb-0 pr-1 w-100"
			[style.background-color]="customBgCode" [ngClass]="{
		'default-border': isDefaultBorder && !isDangerBorder,
		'danger-border': isDangerBorder || (isDangerBorderForFormInValid && form.controls[formControlAccessor].invalid)
		}">
			<a (click)="toggleList($event)" class="d-flex align-items-center btn dropdown-toggle mt-0 mb-0"
				[disabled]="isDisabled" [ngClass]="{'cr-not-allowed': isDisabled}"
				[style.background-color]="customBgCode" style="padding:2.5px;">
				<span class="float-left multiple" *ngIf="!selectedItems.length">{{getEmptyCaption()}}</span>
				<span class="float-left single"
					*ngIf="!isMultipleSelect && selectedItems.length">{{selectedItems[0].label}}</span>
				<div class="multiple-select-container" *ngIf="isMultipleSelect && selectedItems.length">
					<span class="pr-1 text-info">
						{{emptyValueAsValue && isEmptyValueSelected ? getSelectedLength() : selectedItems.length}}
					</span>
					<span *ngIf="selectedItems.length==1" class="multiple-select-dot-selected-text">
						{{dotItemSelectedText || '' | translate}}</span>
					<span *ngIf="selectedItems.length>1" class="multiple-select-dot-selected-text">
						{{dotItemsSelectedText || '' | translate}}</span>
				</div>
				<span class="caret"></span>
			</a>
			<ul class="dropdown-menu w-100 item-list-menu" (clickOutside)="clickOutside(e)"
				[style.background-color]="customBgCode" [class.d-block]="isShowList" role="menu">
				<div *ngIf="isDisplaySearchBox" class="w-100">
					<input (click)="$event.stopImmediatePropagation()" [(ngModel)]="searchValue"
						class="w-100 pl-1 pr-1 mb-1 form-control-sm border" placeholder="Search..."
						[style.background]="'transparent'">
				</div>
				<h6 *ngIf="!displayList.length" class="mb-0 pl-3 overflow-hidden text-truncate text-warning">
					{{emptyListInfoText}}
				</h6>S
				<li role="menuitem" *ngFor="let item of displayList | searchMTMDropdownPipe:searchValue"
					class="d-flex flex-row">
					<div class="dropdown-item d-flex flex-row align-items-center pl-1"
						(click)="onSelectItem(item);false">
						<span class="w-100 h-100 pl-2">
							{{item.label | translate}}
						</span>
					</div>
				</li>
			</ul>
		</div>
		<div class="w-100" *ngIf="getSelectedItems().length">
			<div *ngFor="let val of getSelectedItems();let i=index"
				class="btn-group form-control pt-0 pb-0 pr-1 w-100 mb-1" [style.background-color]="customBgCode"
				[ngClass]="{
		'default-border': isDefaultBorder && !isDangerBorder,
		'danger-border': isDangerBorder || (isDangerBorderForFormInValid && form.controls[formControlAccessor].invalid)
		}">
				<a (click)="toggleList($event, val)" class="d-flex align-items-center btn dropdown-toggle mt-0 mb-0"
					[disabled]="isDisabled" [ngClass]="{'cr-not-allowed': isDisabled}"
					[style.background-color]="customBgCode" style="padding:2.5px;">
					<div class="multiple-select-container">
						<span class="pr-1 text-truncate">
							{{val.label || (title | translate)}}
						</span>
					</div>
					<span class="caret"></span>
				</a>
				<ul class="dropdown-menu w-100 item-list-menu" (clickOutside)="clickOutside(e)"
					[style.background-color]="customBgCode" [class.d-block]="isShowList && (selectedItem===val)"
					role="menu">
					<div *ngIf="isDisplaySearchBox" class="w-100">
						<input (click)="$event.stopImmediatePropagation()" [(ngModel)]="searchValue"
							class="w-100 pl-1 pr-1 mb-1 form-control-sm border" placeholder="Search..."
							[style.background]="'transparent'">
					</div>
					<h6 *ngIf="!displayList.length" class="mb-0 pl-3 overflow-hidden text-truncate text-warning">
						{{emptyListInfoText}}
					</h6>
					<li role="menuitem" *ngFor="let item of displayList | searchMTMDropdownPipe:searchValue"
						class="d-flex flex-row">
						<div class="dropdown-item d-flex flex-row align-items-center pl-1"
							(click)="selectSingleValue(val, item, i);false">
							<span class="w-100 h-100 pl-2">
								{{item.label | translate}}
							</span>
						</div>
					</li>
				</ul>
			</div>
		</div>
		<div class="pt-2 pb-2 d-inline-block pointer"
			*ngIf="singleSelectionInMultipleValues && getSelectedItems().length">
			<i (click)="addSelected()" [style.font-size]="'1.6125rem'" class="fa fa-plus-circle c-mtm-brand"
				aria-hidden="true"></i>
		</div>
	</div>