import { FileUploader, SignedURLUploadRequest, UploadRequest } from "./file.uploader";
import { ApiService, UrlSanitizer } from "../api.service";
import { inject, Injectable } from "@angular/core";
import { HttpClient, HttpContext, HttpEventType } from "@angular/common/http";
import { MTMCustomFile } from "./mtm-custom-file";
import { HelperService } from "../helper.service";
import { IS_UNCANCELABLE_REQUEST } from "../../models";
import { TransferStatus } from "./transfer-status";
import {
  listenerFileUploadCompleted,
  listenerTranscodingFileReplaced,
  listenerUploadProgressChanged,
  listenerUploadStatusChanged
} from "./listeners";
import { TransferProgress } from "./transfer-progress";
import { EMPTY, Observable, Subject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class FailedTranscodingUploader implements FileUploader {
  private apiService = inject(ApiService);
  private http = inject(HttpClient);

  uploadFiles(request: UploadRequest) {
    this.constructPipeline(request);
    this.preUpload(request);
  }

  constructPipeline(request: UploadRequest) {
    request.files.forEach(item => {
      item.pipelines = [
        'preUpload',
        'upload',
        'postUpload'
      ];
      if (item.pipelines.length > 0) {
        item.step = item.pipelines[0];
        listenerUploadStatusChanged.next(item);
      }
      this.handleUploadStep(item);
    });

  }

  private handleUploadStep(mtmFile: MTMCustomFile) {
    if (!mtmFile.progress$) {
      mtmFile.progress$ = new Subject<TransferProgress>();
    }

    mtmFile.progress$.subscribe({
      next: (progress: TransferProgress) => {
        mtmFile.startIndex = progress.startIndex;

        switch (progress.step) {
          case 'next':
            this.proceedNextUploadStep(mtmFile);
            break;
          case 'error':
            if (!navigator.onLine) {
              mtmFile.uploadStatusCode = TransferStatus.ERROR;
              listenerUploadStatusChanged.next(mtmFile);
              return;
            } else {

              mtmFile.retryCount++;
              if (mtmFile.retryCount >= mtmFile.maxRetry) {
                mtmFile.uploadStatusCode = TransferStatus.ERROR;
                listenerUploadStatusChanged.next(mtmFile);
                return;
              }
            }
            break;
          default:
            if (progress.step) {
              mtmFile.step = progress.step;
            }
            break;
        }

        switch (mtmFile.step) {
          case 'postUpload':
            this.postUpload(mtmFile);
            break;
          case 'upload':
            this.performUpload(mtmFile);
            break;
        }
      },
      error: (err) => {
        mtmFile.uploadStatusCode = TransferStatus.ERROR;
        listenerUploadStatusChanged.next(mtmFile);
      }
    });

  }

  private proceedNextUploadStep(mtmFile: MTMCustomFile) {
    let currentIndex = mtmFile.pipelines.indexOf(mtmFile.step);
    if (currentIndex > -1 && currentIndex < mtmFile.pipelines.length - 1) {
      currentIndex++;
      mtmFile.step = mtmFile.pipelines[currentIndex];
      listenerUploadStatusChanged.next(mtmFile);
      return;
    }

    mtmFile.step = 'done';
    listenerUploadStatusChanged.next(mtmFile);
    listenerFileUploadCompleted.emit(mtmFile);
    listenerTranscodingFileReplaced.emit(mtmFile);
    if (mtmFile.cleanUp) {
      mtmFile.cleanUp();
    } else {
      mtmFile.progress$.complete();
      mtmFile.progress$ = null;
    }
  }

  private preUpload(request: UploadRequest) {
    const mtmFile = request.files[0];
    const failedFileId = mtmFile.extraInfo.failedTranscodingFileId;
    const failedReplacementType = mtmFile.extraInfo.failedTranscodingReplacementType;
    const preUploadUrl = `/api/commons/transcode-failed-pre-upload/${failedFileId}/${failedReplacementType}`;
    console.log('preupload url', preUploadUrl);
    this.apiService.httpGet(preUploadUrl)
      .subscribe({
        next: (result: any) => {
          mtmFile.preUploadUrl = preUploadUrl;
          mtmFile.sessionUrl = result.signedUrl;
          mtmFile.progress$.next({
            status: TransferStatus.SENDING,
            startIndex: 0,
            step: 'next'
          });
        },
        error: err => {
          mtmFile.progress$.error({
            handled: true,
            error: err
          });
        }
      });
  }

  private performUpload(file: MTMCustomFile) {
    const signedUrl = file.sessionUrl;
    const headers: Record<string, string> = {};
    HelperService.getHttpHeaders().forEach((values, name) => {
      if (values.length && name && name != 'Content-Type') {
        headers[name] = values[0];
      }
    });
    headers['Content-Type'] = file.file.type;
    //let safeUrl = UrlSanitizer.sanitizeUrl(signedUrl)
    file.currentRequest = this.http.put(signedUrl, file.file, {
      headers: headers,
      reportProgress: true,
      observe: 'events',
      context: new HttpContext().set(IS_UNCANCELABLE_REQUEST, true)
    }).subscribe({
      next: (response: any) => {
        switch (response.type) {
          case HttpEventType.UploadProgress:
            file.uploadStatusCode = TransferStatus.SENDING;
            const actualPercentage = 100 * response.loaded / response.total
            file.progress = Math.round(actualPercentage);
            if (file.progress > 0) {
              this.calculateRemainingTime(file, actualPercentage);
              listenerUploadProgressChanged.next(file);
            }
            break;
          case HttpEventType.Response:
            file.progress = 100;
            file.extraInfo.remainingTime = 0;
            file.uploadStatusCode = TransferStatus.UPLOADED;
            file.progress$.next({
              status: TransferStatus.UPLOADED,
              startIndex: file.fileSize,
              step: 'next'
            });
            break;
        }
      },
      error: (err) => {
        file.progress$.error({
          handled: true,
          error: err
        });
      }
    });
  }

  private calculateRemainingTime(file: MTMCustomFile, percentage: number) {
    const elapsedTime = Date.now() - file.createTime;
    const expectedDuration = 100 / file.progress * elapsedTime;
    file.extraInfo.remainingTime = expectedDuration - elapsedTime;
  }

  private postUpload(file: MTMCustomFile) {
    const failedFileId = file.extraInfo.failedTranscodingFileId;
    const url = `/api/commons/transcode-failed-update-status/${failedFileId}`;
    file.currentRequest = this.apiService.newPost(url, {}, { uncancelable: true })
      .subscribe({
        next: (dbElement: any) => {
          listenerUploadStatusChanged.emit(file);
          file.uploadStatusCode = TransferStatus.COMPLETED;
          file.progress$.next({
            status: TransferStatus.COMPLETED,
            startIndex: file.startIndex,
            step: 'next'
          });
        },
        error: err => {
          file.progress$.error({
            handled: true,
            error: err
          });
        }
      });
  }

  pauseUpload(file: MTMCustomFile) {
  }

  resumeUpload(file: MTMCustomFile) {
  }

  cancelUpload(file: MTMCustomFile): Observable<any> {
    if (file.currentRequest) {
      file.currentRequest.unsubscribe();
    }
    return EMPTY;
  }
}
