import { Component, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService } from './../../shared/services/auth.service';
import { AdminService } from './../../admin/services/admin.service';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { RegistrationService } from './../../shared/services/registration.service';
import { ChangePhoneModalComponent } from './change-phone-modal/change-phone-modal.component';
import { NotificationService } from './../../shared/services/notification.service';
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { SubscriptionService } from "app/subscriptions/services/subscription.service";
import { UserService } from "app/shared/services/user.service";

@Component({
	templateUrl: './phone-verification.component.html',
	styleUrls: ['./phone-verification.component.scss']
})
export class PhoneVerificationComponent implements OnInit, OnDestroy {


	// The last two digits from phone number.
	lastTwoDigits: string = null;
	verificationForm: UntypedFormGroup;
	submitted: boolean = false;
	sending: boolean;
	sent: boolean;
	ngUnsubscribe = new Subject();

	constructor(private fb: UntypedFormBuilder, private router: Router, private authService: AuthService,
		private adminService: AdminService, private registrationService: RegistrationService,
		private subscriptionService: SubscriptionService,
		private modalService: NgbModal, private notificationService: NotificationService,
		private userService: UserService
	) {

	}
	ngOnDestroy(): void {
		this.ngUnsubscribe.next(true);
		this.ngUnsubscribe.complete();
	}

	ngOnInit() {

		// Load form group
		this.loadFormGroup();

		// Set last two digits
		this.setLastTwoDigits();
	}


	/**
	 * Submit login form
	 */
	onSubmit() {
		this.submitted = true;

		if (this.sending) {
			return;
		}

		if (this.verificationForm.valid) {
			// Verify code
			this.sending = true;
			this.authService.sendVerificationCode(+this.verificationForm.controls['code'].value).pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe({
				next: (data: any) => {
					if (data.hasLtgPermission) {
						localStorage.setItem('hasLtgPermission', JSON.stringify(data.hasLtgPermission));
					}
					this.authService.setAuthUser(data.user);
					this.authService.setAuthToken(data.auth.token).subscribe({
						next: () => {
							// Change activationStatus in localStorage
							this.authService.setActivationStatus(data.activationStatus);
							this.userService.getUserProfileSettings().subscribe({
								next: (data) => {
									this.authService.setAuthUserSettings(data);

									this.subscriptionService.getCurrentPlan().subscribe({
										next: sub => {
											this.sending = false;
											this.authService.setAuthUserSubscription(sub);
											this.router.navigate(['/entry/on-boarding/profile-avatar']);
										},
										error: () => {
											this.sending = false;
											console.log('failed to get current plan');
										}
									});
								},
								error: () => {
									this.sending = false;
									console.log('failed to get user profile settings');
								}
							});


						}, error: err => {
							this.sending = false;
							console.log('failed to set auth token');
						}
					})
				},
				error: (err: any) => {
					this.sending = false;
					console.log('verification code error', err);

					// Display notification
					this.notificationService.open({
						title: 'Phone Verification',
						description: `Error trying to verify your phone number:\n${err.errorCode}\nPlease, try again.`,
						confirmBtn: 'Accept'
					});
				}
			})

		}

	}


	/**
	 * Return if number is a valid key
	 * @param e
	 * @returns {boolean}
	 */
	isNumberKey(e) {
		let charCode = (e.which) ? e.which : e.keyCode;
		if (charCode > 31 && (charCode < 48 || (charCode > 57 && charCode != 190 && charCode != 110)))
			return false;
		else
			return true;
	}


	// **************************************************************************************************


	/**
	 * Load form group
	 */
	private loadFormGroup() {
		this.verificationForm = this.fb.group({
			code: ['', [Validators.required, Validators.maxLength(4), Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
			trust: ['', []]
		});
	}


	/**
	 * Set last two digits
	 */
	private setLastTwoDigits() {
		let authUser = this.authService.getAuthUser();
		if (authUser.phone)
			this.lastTwoDigits = authUser.phone.slice(-2);
		else
			this.lastTwoDigits = '';
	}

	/**
	 * Resend verification code to user
	 */
	resend() {
		if (!(this.sending || this.sent)) {
			this.sending = true;
			this.authService
				.resendVerificationCode().pipe(
					takeUntil(this.ngUnsubscribe)
				)
				.subscribe((data: any) => {
					if (data.message === 'OK') {
						this.sent = true;
						this.sending = false;
						setTimeout(() => this.sent = false, 10000);
					} else {
						this.sending = false;
						this.sent = false;
					}
				}, (err: any) => {
					console.log('ERROR', err);
				});
		}

		return false;
	}

	openChangePhoneModal() {
		let modal = this.modalService.open(ChangePhoneModalComponent, { size: 'lg' });

		modal.result.then((data) => {
			if (data.alternatePhoneSent) {
				this.lastTwoDigits = data.alternatePhone.slice(-2);
				this.notificationService.open({
					title: 'Verification code sent!',
					centerHeader: true,
					confirmBtn: 'Continue'
				})
			}
		}, (reason) => {
			console.log('ERROR', reason);
		});
		return false;
	}

	onBack() {
		this.router.navigate(['/entry/on-boarding/terms-and-conditions']);
	}
}
