<div class="text-center mt-3">
	<div class="row text-left">
		<div class="col-1" style="max-width: 1.33333%;" [ngClass]="{ 'registration-selection': isRegistration }">
			<input [ngClass]="{ 'registration-selection': isRegistration }" style="transform: scale(1.25);" #saveUsername [(ngModel)]="isTermsCondition"  [style.color]="isRegistration ? '#637EA3' : '#000'"
				(ngModelChanges)="onModelChanged()" type="checkbox" data-toggle="toggle" 
				[ngModelOptions]="{standalone: true}">
		</div>
		<div *ngIf="!isRegistration" class="col-10 col-md-11">
			{{ 'registerReadAndAgree1' | translate }}
			<a href="#" (click)="onRedirectToTCP(this.getLocale()	 +'_TERMSandCONDITIONS');false;"
				style="color:#e4442a">
				{{ 'registerTermsAndConditions' | translate }}</a>
			{{ 'registerReadAndAgree2' | translate }}
			<a *ngIf="companyType == 'ADVERTISER'" href="#"
				(click)="onRedirectToTCP(this.getLocale() +'_TERMSofSALES');false;" style="color:#e4442a">
				{{ 'registerTermsOfService' | translate }}</a>
			<a *ngIf="companyType == 'PRODUCER'" href="#"
				(click)="onRedirectToTCP(this.getLocale() +'_TERMSofSERVICEPRODUCER');false;" style="color:#e4442a">
				{{ 'registerTermsOfService' | translate }}</a>
			{{ 'registerReadAndAgree3' | translate }}
			<a href="#" (click)="onRedirectToTCP(this.getLocale() +'_PRIVACYPOLICY');false;" style="color:#e4442a">
				{{ 'registerPrivacyPolicy' | translate }}</a>
			.
		</div>
		<div *ngIf="isRegistration" class="col-10 col-md-11">
			{{ 'singupregisterTitle' | translate }}
			<a href="#" (click)="onRedirectToTCP(this.getLocale()	 +'_TERMSandCONDITIONS');false;"
				style="color:#e4442a">
				{{ 'registerTermsAndConditions' | translate }}</a>
			{{ 'registerReadAndAgree2' | translate }}
			
			<span>
				{{ 'signuptermsAndConditionsTitle' | translate }}</span>
			.
		</div>
	</div>
</div>