<div class="mtm-asset-list-component">
    <div *ngIf="!asWidget" class="asset-list-header-content d-flex align-item-center mb-2"
        [ngClass]="{ 'flex-column':  asSelector }">
        <div class="title d-flex">
            <div class="d-flex align-items-center mr-3" *ngIf="showSettingButton">
                <button class="setting-btn" (click)="editTimeline()">
                    <img src=".../../../../../assets/img/project-v2/setting-icon.svg" />
                </button>
            </div>
            <span class="_700" *ngIf="!hideTitle">{{'Assets List'|translate}}</span>
        </div>
        <div class="d-flex align-items-center" [ngClass]="{ 'ml-auto': !asSelector }">
            <div *ngIf="asSelector" class="sub-title w-100">
                <span class="_700">{{'Choose one or several asset from the recommended asset list or create a new
                    asset.'|translate}}</span>
            </div>
            <div class="ml-auto d-flex align-item-center" *ngIf="!hideFilter">
                <div class="switch-btn no-pointer d-flex align-items-center mr-3">
                    <ui-switch [(ngModel)]="isFilterVideoActive" defaultBgColor="black" size="small"
                        switchColor="#efefef" (click)="filterAssets()"></ui-switch>
                    <span class="_700 ml-2">{{ 'Video'|translate }}</span>
                </div>
                <div class="switch-btn no-pointer d-flex align-items-center mr-3">
                    <ui-switch [(ngModel)]="isFilterStillActive" defaultBgColor="black" size="small"
                        switchColor="#efefef" (click)="filterAssets()"></ui-switch>
                    <span class="_700 ml-2">{{'Still'|translate}}</span>
                </div>
            </div>
        </div>
    </div>
    <!-- <div *ngIf="asWidget" class="widget-nav mb-3">
        <div class="title d-flex align-item-center py-3">
            <label class="_700 mb-0">{{'Assets List'|translate}}</label>
            <div class="ml-auto">
                <button
                    class="btn btn-block btn-orange text-uppercase position-relative btn-new-asset text-nowrap py-1 px-4"
                    (click)="addAsset()">
                    <i class="p-1 fa fa-plus" aria-hidden="true"></i>
                    <span class="ml-2">{{ 'Add an asset' | translate }}</span>
                </button>
            </div>
        </div>
    </div> -->
    <div class="d-flex flex-column flex-md-row asset-list-body-content">
        <div class="asset-category-wrapper flex-fill mx-2 mb-0 mb-md-3">
            <div class="category-title">
                <label class="m-0 w-100 text-center p-2">{{'AWARENESS'|translate}}</label>
            </div>
            <div class="asset-list d-flex flex-column p-2">
                <div class="asset-wrapper mb-2">
                    <div class="asset-content">
                        <div class="asset-media mb-2 p-2 status-border {{affectedAssets[content.id]?.status || (affectedAssets[content.id] ? 'UNKNOWN' : 'NOT_AFFECTED')}}"
                            [ngClass]="{ 'selected': selectedAssetTypes.indexOf(content.id) > -1, 'as-selector': asSelector, 'already-selected': asSelector && !(!affectedAssets[content.id] || (selectedAssetTypes.indexOf(content.id) > -1)) && !readOnly }"
                            *ngFor="let content of getGroupedAsset('AWARENESS')">
                            <div class="already-selected-asset-info px-2 py-1 d-flex align-items-center"
                                #drop="ngbDropdown" ngbDropdown (mouseleave)="out(drop)" (mouseenter)="over(drop)"
                                *ngIf="asSelector && !(!affectedAssets[content.id] || (selectedAssetTypes.indexOf(content.id) > -1)) && !readOnly">
                                <i class="mtm-icon mtm-icon-linked-item mr-2"></i>
                                <span class="text-uppercase">{{'assetListLinked'|translate}}</span>
                                <div class="project-info-dropdown-menu w-100" ngbDropdownMenu>
                                    <div class="project-logo w-100">
                                        <img *ngIf="projects[affectedAssets[content.id]?.projectId]?.avatarUrl"
                                            class="h-100 w-100 image-style"
                                            [src]="projects[affectedAssets[content.id]?.projectId]?.avatarUrl + '?v=' + cacheBuster">
                                        <label *ngIf="!projects[affectedAssets[content.id]?.projectId]?.avatarUrl"
                                            class="d-flex align-items-center justify-content-center m-0 c-full-white project-title text-uppercase h-100 w-100"
                                            [style.backgroundColor]="timelineService.getColorFromName(projects[affectedAssets[content.id]?.projectId]?.name)">
                                            {{projects[affectedAssets[content.id]?.projectId]?.name | slice:0:2}}
                                        </label>
                                        <div class="milestone-name _700"
                                            *ngIf="projects[affectedAssets[content.id]?.projectId]?.milestoneName">
                                            {{projects[affectedAssets[content.id]?.projectId]?.milestoneName}}</div>
                                    </div>
                                    <div class="project-info d-flex align-items-center justify-content-center p-1">
                                        <label
                                            class="m-0 text-center">{{projects[affectedAssets[content.id]?.projectId]?.name}}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="radio-button-selector"
                                *ngIf="(!readOnly && asSelector) && (!affectedAssets[content.id] || (selectedAssetTypes.indexOf(content.id) > -1))">
                                <label class="mtm-checkbox mtm-checkbox-green m-0" [ngClass]="{ 'disabled': isBusy }">
                                    <input [disabled]="isBusy" name="selectedAssetTypes" class="form-check-input"
                                        (click)="selectAsset($event, content)" type="checkbox"
                                        [checked]="selectedAssetTypes.indexOf(content.id) > -1">
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                            <div *ngIf="canEdit" class="edit-asset-button d-flex">
                                <i class="mtm-icon mtm-icon-pencil-grey pointer"
                                    (click)="openEditAssetModal('Awareness', content)"></i>
                                <i class="fa fa-trash pointer" (click)="deleteAsset('Awareness', content)"></i>
                            </div>
                            <div class="asset-content-header d-flex mb-2">
                                <div class="d-flex align-items-center mr-2" *ngFor="let media of content.bets">
                                    <i class="mtm-icon mtm-icon-{{media?.toLowerCase()}} mr-1"></i>
                                    <span class="text-uppercase _700">{{media}}</span>
                                </div>
                            </div>
                            <div class="asset-content-body d-flex">
                                <div class="size-info flex-fill w-50 d-flex flex-column">
                                    <div *ngFor="let size of content.length" class="d-flex align-items-center">
                                        <span>{{size}}</span>
                                    </div>
                                </div>
                                <div class="flex-fill w-50 d-flex flex-column content-info px-2">
                                    <div class="d-flex align-items-center">
                                        <span class="mr-2 _700 text-uppercase">Focus:</span>
                                        <span class="text-ellipsis">{{content.focus}}</span>
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <span class="mr-2 _700 text-uppercase">Type:</span>
                                        <span class="text-ellipsis">{{content.type}}</span>
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <span class="text-ellipsis">{{content.detail}}</span>
                                    </div>
                                </div>
                                <div *ngIf="!!affectedAssets[content.id]?.status && !asSelector"
                                    class="d-flex flex-column delivery-time">
                                    <label class="m-0 text text-uppercase">{{'Delivery'|translate}}</label>
                                    <div class="input-group position-relative">
                                        <input class="form-control date-picker" ngbDatepicker
                                            [(ngModel)]="affectedAssets[content.id].deliveryDate"
                                            (ngModelChange)="updateDeliveryDate(affectedAssets[content.id])"
                                            #negotiatedDate="ngbDatepicker" placeholder="xx.xx.xxxx" firstDayOfWeek="1"
                                            (click)="negotiatedDate.toggle()">
                                    </div>
                                </div>
                                <div class="content-info-mobile w-100">
                                    <div class="d-flex align-items-center">
                                        <div class="flex-fill w-50 d-flex">
                                            <div *ngFor="let size of content.length" class="d-flex align-items-center">
                                                <span>{{size}}</span>
                                            </div>
                                        </div>
                                        <div class="d-flex align-item-center ml-auto">
                                            <label
                                                class="m-0 d-flex align-items-center mr-2 text text-uppercase">{{'Delivery'|translate}}</label>
                                            <div *ngIf="!!affectedAssets[content.id]?.status && !asSelector"
                                                class="d-flex flex-column">
                                                <div class="input-group position-relative">
                                                    <input class="form-control date-picker" ngbDatepicker
                                                        [(ngModel)]="affectedAssets[content.id].deliveryDate"
                                                        (ngModelChange)="updateDeliveryDate(affectedAssets[content.id])"
                                                        #negotiatedDate="ngbDatepicker" placeholder="xx.xx.xxxx"
                                                        firstDayOfWeek="1" (click)="negotiatedDate.toggle()">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex-fill d-flex flex-column flex-sm-row mt-1">
                                        <div class="d-flex align-items-center flex-fill w-100 mr-2">
                                            <span class="mr-2 _700 text-uppercase mr-1">Focus:</span>
                                            <span class="text-ellipsis">{{content.focus}}</span>
                                        </div>
                                        <div class="d-flex align-items-center flex-fill w-100 mr-2">
                                            <span class="mr-2 _700 text-uppercase mr-1">Type:</span>
                                            <span class="text-ellipsis">{{content.type}}</span>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center flex-fill w-100">
                                        <span class="text-ellipsis">{{content.detail}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="status-info {{affectedAssets[content.id]?.status || (affectedAssets[content.id] ? 'UNKNOWN' : 'NOT_AFFECTED')}}"
                                *ngIf="showStatus">
                                <div class="d-block" (click)="$event.stopImmediatePropagation()">
                                    <div ngbDropdown class="d-flex align-items-center"
                                        [dropdownClass]="'status-'+content.id" [autoClose]="true"
                                        #projectStatusDropdown>
                                        <button (click)="$event.stopImmediatePropagation()" type="button"
                                            [disabled]="!affectedAssets[content.id]"
                                            class="d-flex align-items-center btn project-status-dropdown"
                                            ngbDropdownToggle>
                                            <div
                                                class="d-flex align-items-center project-status {{affectedAssets[content.id]?.status || (affectedAssets[content.id] ? 'UNKNOWN' : 'NOT_AFFECTED')}}">
                                                <div class="project-union-icon-container">
                                                    <i
                                                        class="project-union-icon mtm-icon mtm-icon-union-{{projectColor[(affectedAssets[content.id]?.status || (affectedAssets[content.id] ? 'UNKNOWN' : 'NOT_AFFECTED'))]}}"></i>
                                                </div>
                                                <span
                                                    class="status-text">{{'task_'+(affectedAssets[content.id]?.status||(affectedAssets[content.id]
                                                    ? 'UNKNOWN' : 'NOT_AFFECTED'))|translate}}</span>
                                                <i class="fa fa-chevron-down ml-auto pointer"></i>
                                            </div>
                                        </button>
                                        <div class="project-dropdown-menu" ngbDropdownMenu>
                                            <div *ngIf="affectedAssets[content.id]?.status!=projectStatus.Completed"
                                                (click)="$event.stopImmediatePropagation();changeProjectStatus(affectedAssets[content.id],projectStatus.Completed)"
                                                class="project-dropdown-project pt-1 pb-1 pl-2 pr-2 pointer">
                                                <div class="d-flex align-items-center project-status-display COMPLETED">
                                                    <i class="project-status-circle-color mr-2"></i>
                                                    <span>{{'task_COMPLETED'|translate}}</span>
                                                </div>
                                            </div>
                                            <div *ngIf="affectedAssets[content.id]?.status!=projectStatus.InProgress"
                                                (click)="$event.stopImmediatePropagation();changeProjectStatus(affectedAssets[content.id],projectStatus.InProgress)"
                                                class="project-dropdown-project pt-1 pb-1 pl-2 pr-2 pointer">
                                                <div
                                                    class="d-flex align-items-center project-status-display IN_PROGRESS">
                                                    <i class="project-status-circle-color mr-2"></i>
                                                    <span>{{'task_IN_PROGRESS'|translate}}</span>
                                                </div>
                                            </div>
                                            <div *ngIf="affectedAssets[content.id]?.status!=projectStatus.UpcomingExpiry"
                                                (click)="$event.stopImmediatePropagation();changeProjectStatus(affectedAssets[content.id],projectStatus.UpcomingExpiry)"
                                                class="project-dropdown-project pt-1 pb-1 pl-2 pr-2 pointer">
                                                <div
                                                    class="d-flex align-items-center project-status-display UPCOMING_EXPIRY">
                                                    <i class="project-status-circle-color mr-2"></i>
                                                    <span>{{'task_UPCOMING_EXPIRY'|translate}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="canAdd" class="add-new-asset-button d-flex align-items-center justify-content-center"
                    (click)="addNewAsset('Awareness')">
                    +
                </div>
            </div>
        </div>
        <div class="asset-category-wrapper flex-fill mx-2 mb-0 mb-md-3">
            <div class="category-title">
                <label class="m-0 w-100 text-center p-2">{{'CONSIDERATION'|translate}}</label>
            </div>
            <div class="asset-list d-flex flex-column p-2">
                <div class="asset-wrapper mb-2">
                    <div class="asset-content">
                        <div class="asset-media mb-2 p-2 status-border {{affectedAssets[content.id]?.status || (affectedAssets[content.id] ? 'UNKNOWN' : 'NOT_AFFECTED')}}"
                            [ngClass]="{ 'selected': selectedAssetTypes.indexOf(content.id) > -1, 'as-selector': asSelector, 'already-selected': asSelector && !(!affectedAssets[content.id] || (selectedAssetTypes.indexOf(content.id) > -1)) && !readOnly }"
                            *ngFor="let content of getGroupedAsset('CONSIDERATION')">
                            <div class="already-selected-asset-info px-2 py-1 d-flex align-items-center"
                                #drop="ngbDropdown" ngbDropdown (mouseleave)="out(drop)" (mouseenter)="over(drop)"
                                *ngIf="asSelector && !(!affectedAssets[content.id] || (selectedAssetTypes.indexOf(content.id) > -1)) && !readOnly">
                                <i class="mtm-icon mtm-icon-linked-item mr-2"></i>
                                <span class="text-uppercase">{{'assetListLinked'|translate}}</span>
                                <div class="project-info-dropdown-menu w-100" ngbDropdownMenu>
                                    <div class="project-logo w-100">
                                        <img *ngIf="projects[affectedAssets[content.id]?.projectId]?.avatarUrl"
                                            class="h-100 w-100 image-style"
                                            [src]="projects[affectedAssets[content.id]?.projectId]?.avatarUrl + '?v=' + cacheBuster">
                                        <label *ngIf="!projects[affectedAssets[content.id]?.projectId]?.avatarUrl"
                                            class="d-flex align-items-center justify-content-center m-0 c-full-white project-title text-uppercase h-100 w-100"
                                            [style.backgroundColor]="timelineService.getColorFromName(projects[affectedAssets[content.id]?.projectId]?.name)">
                                            {{projects[affectedAssets[content.id]?.projectId]?.name | slice:0:2}}
                                        </label>
                                        <div class="milestone-name _700"
                                            *ngIf="projects[affectedAssets[content.id]?.projectId]?.milestoneName">
                                            {{projects[affectedAssets[content.id]?.projectId]?.milestoneName}}</div>
                                    </div>
                                    <div class="project-info d-flex align-items-center justify-content-center p-1">
                                        <label
                                            class="m-0 text-center">{{projects[affectedAssets[content.id]?.projectId]?.name}}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="radio-button-selector"
                                *ngIf="(!readOnly && asSelector) && (!affectedAssets[content.id] || (selectedAssetTypes.indexOf(content.id) > -1))">
                                <label class="mtm-checkbox mtm-checkbox-green m-0" [ngClass]="{ 'disabled': isBusy }">
                                    <input [disabled]="isBusy" name="selectedAssetTypes" class="form-check-input"
                                        (click)="selectAsset($event, content)" type="checkbox"
                                        [checked]="selectedAssetTypes.indexOf(content.id) > -1">
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                            <div *ngIf="canEdit" class="edit-asset-button d-flex">
                                <i class="mtm-icon mtm-icon-pencil-grey pointer"
                                    (click)="openEditAssetModal('Consideration', content)"></i>
                                <i class="fa fa-trash pointer" (click)="deleteAsset('Consideration', content)"></i>
                            </div>
                            <div class="asset-content-header d-flex mb-2">
                                <div class="d-flex align-items-center mr-2" *ngFor="let media of content.bets">
                                    <i class="mtm-icon mtm-icon-{{media?.toLowerCase()}} mr-1"></i>
                                    <span class="text-uppercase _700">{{media}}</span>
                                </div>
                            </div>
                            <div class="asset-content-body d-flex">
                                <div class="size-info flex-fill w-50 d-flex flex-column">
                                    <div *ngFor="let size of content.length" class="d-flex align-items-center">
                                        <span>{{size}}</span>
                                    </div>
                                </div>
                                <div class="flex-fill w-50 d-flex flex-column content-info px-2">
                                    <div class="d-flex align-items-center">
                                        <span class="mr-2 _700 text-uppercase">Focus:</span>
                                        <span class="text-ellipsis">{{content.focus}}</span>
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <span class="mr-2 _700 text-uppercase">Type:</span>
                                        <span class="text-ellipsis">{{content.type}}</span>
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <span class="text-ellipsis">{{content.detail}}</span>
                                    </div>
                                </div>
                                <div *ngIf="!!affectedAssets[content.id]?.status && !asSelector"
                                    class="d-flex flex-column delivery-time">
                                    <label class="m-0 text text-uppercase">{{'Delivery'|translate}}</label>
                                    <div class="input-group position-relative">
                                        <input class="form-control date-picker" ngbDatepicker
                                            [(ngModel)]="affectedAssets[content.id].deliveryDate"
                                            (ngModelChange)="updateDeliveryDate(affectedAssets[content.id])"
                                            #negotiatedDate="ngbDatepicker" placeholder="xx.xx.xxxx" firstDayOfWeek="1"
                                            (click)="negotiatedDate.toggle()">
                                    </div>
                                </div>
                                <div class="content-info-mobile w-100">
                                    <div class="d-flex align-items-center">
                                        <div class="flex-fill w-50 d-flex">
                                            <div *ngFor="let size of content.length" class="d-flex align-items-center">
                                                <span>{{size}}</span>
                                            </div>
                                        </div>
                                        <div class="d-flex align-item-center ml-auto">
                                            <label
                                                class="m-0 d-flex align-items-center mr-2 text text-uppercase">{{'Delivery'|translate}}</label>
                                            <div *ngIf="!!affectedAssets[content.id]?.status && !asSelector"
                                                class="d-flex flex-column">
                                                <div class="input-group position-relative">
                                                    <input class="form-control date-picker" ngbDatepicker
                                                        [(ngModel)]="affectedAssets[content.id].deliveryDate"
                                                        (ngModelChange)="updateDeliveryDate(affectedAssets[content.id])"
                                                        #negotiatedDate="ngbDatepicker" placeholder="xx.xx.xxxx"
                                                        firstDayOfWeek="1" (click)="negotiatedDate.toggle()">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex-fill d-flex flex-column flex-sm-row mt-1">
                                        <div class="d-flex align-items-center flex-fill w-100 mr-2">
                                            <span class="mr-2 _700 text-uppercase mr-1">Focus:</span>
                                            <span class="text-ellipsis">{{content.focus}}</span>
                                        </div>
                                        <div class="d-flex align-items-center flex-fill w-100 mr-2">
                                            <span class="mr-2 _700 text-uppercase mr-1">Type:</span>
                                            <span class="text-ellipsis">{{content.type}}</span>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center flex-fill w-100">
                                        <span class="text-ellipsis">{{content.detail}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="status-info {{affectedAssets[content.id]?.status || (affectedAssets[content.id] ? 'UNKNOWN' : 'NOT_AFFECTED')}}"
                                *ngIf="showStatus">
                                <div class="d-block" (click)="$event.stopImmediatePropagation()">
                                    <div ngbDropdown class="d-flex align-items-center"
                                        [dropdownClass]="'status-'+content.id" [autoClose]="true"
                                        #projectStatusDropdown>
                                        <button (click)="$event.stopImmediatePropagation()" type="button"
                                            [disabled]="!affectedAssets[content.id]"
                                            class="d-flex align-items-center btn project-status-dropdown"
                                            ngbDropdownToggle>
                                            <div
                                                class="d-flex align-items-center project-status {{affectedAssets[content.id]?.status || (affectedAssets[content.id] ? 'UNKNOWN' : 'NOT_AFFECTED')}}">
                                                <div class="project-union-icon-container">
                                                    <i
                                                        class="project-union-icon mtm-icon mtm-icon-union-{{projectColor[(affectedAssets[content.id]?.status || (affectedAssets[content.id] ? 'UNKNOWN' : 'NOT_AFFECTED'))]}}"></i>
                                                </div>
                                                <span
                                                    class="status-text">{{'task_'+(affectedAssets[content.id]?.status||(affectedAssets[content.id]
                                                    ? 'UNKNOWN' : 'NOT_AFFECTED'))|translate}}</span>
                                                <i class="fa fa-chevron-down ml-auto pointer"></i>
                                            </div>
                                        </button>
                                        <div class="project-dropdown-menu" ngbDropdownMenu>
                                            <div *ngIf="affectedAssets[content.id]?.status!=projectStatus.Completed"
                                                (click)="$event.stopImmediatePropagation();changeProjectStatus(affectedAssets[content.id],projectStatus.Completed)"
                                                class="project-dropdown-project pt-1 pb-1 pl-2 pr-2 pointer">
                                                <div class="d-flex align-items-center project-status-display COMPLETED">
                                                    <i class="project-status-circle-color mr-2"></i>
                                                    <span>{{'task_COMPLETED'|translate}}</span>
                                                </div>
                                            </div>
                                            <div *ngIf="affectedAssets[content.id]?.status!=projectStatus.InProgress"
                                                (click)="$event.stopImmediatePropagation();changeProjectStatus(affectedAssets[content.id],projectStatus.InProgress)"
                                                class="project-dropdown-project pt-1 pb-1 pl-2 pr-2 pointer">
                                                <div
                                                    class="d-flex align-items-center project-status-display IN_PROGRESS">
                                                    <i class="project-status-circle-color mr-2"></i>
                                                    <span>{{'task_IN_PROGRESS'|translate}}</span>
                                                </div>
                                            </div>
                                            <div *ngIf="affectedAssets[content.id]?.status!=projectStatus.UpcomingExpiry"
                                                (click)="$event.stopImmediatePropagation();changeProjectStatus(affectedAssets[content.id],projectStatus.UpcomingExpiry)"
                                                class="project-dropdown-project pt-1 pb-1 pl-2 pr-2 pointer">
                                                <div
                                                    class="d-flex align-items-center project-status-display UPCOMING_EXPIRY">
                                                    <i class="project-status-circle-color mr-2"></i>
                                                    <span>{{'task_UPCOMING_EXPIRY'|translate}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="canAdd" class="add-new-asset-button d-flex align-items-center justify-content-center"
                    (click)="addNewAsset('Consideration')">
                    +
                </div>
            </div>
        </div>
        <div class="asset-category-wrapper flex-fill mx-2 mb-0 mb-md-3">
            <div class="category-title">
                <label class="m-0 w-100 text-center p-2">{{'CONVERSION'|translate}}</label>
            </div>
            <div class="asset-list d-flex flex-column p-2">
                <div class="asset-wrapper mb-2">
                    <div class="asset-content">
                        <div class="asset-media mb-2 p-2 status-border {{affectedAssets[content.id]?.status || (affectedAssets[content.id] ? 'UNKNOWN' : 'NOT_AFFECTED')}}"
                            [ngClass]="{ 'selected': selectedAssetTypes.indexOf(content.id) > -1, 'as-selector': asSelector, 'already-selected': asSelector && !(!affectedAssets[content.id] || (selectedAssetTypes.indexOf(content.id) > -1)) && !readOnly }"
                            *ngFor="let content of getGroupedAsset('CONVERSION')">
                            <div class="already-selected-asset-info px-2 py-1 d-flex align-items-center"
                                #drop="ngbDropdown" ngbDropdown (mouseleave)="out(drop)" (mouseenter)="over(drop)"
                                *ngIf="asSelector && !(!affectedAssets[content.id] || (selectedAssetTypes.indexOf(content.id) > -1)) && !readOnly">
                                <i class="mtm-icon mtm-icon-linked-item mr-2"></i>
                                <span class="text-uppercase">{{'assetListLinked'|translate}}</span>
                                <div class="project-info-dropdown-menu w-100" ngbDropdownMenu>
                                    <div class="project-logo w-100">
                                        <img *ngIf="projects[affectedAssets[content.id]?.projectId]?.avatarUrl"
                                            class="h-100 w-100 image-style"
                                            [src]="projects[affectedAssets[content.id]?.projectId]?.avatarUrl + '?v=' + cacheBuster">
                                        <label *ngIf="!projects[affectedAssets[content.id]?.projectId]?.avatarUrl"
                                            class="d-flex align-items-center justify-content-center m-0 c-full-white project-title text-uppercase h-100 w-100"
                                            [style.backgroundColor]="timelineService.getColorFromName(projects[affectedAssets[content.id]?.projectId]?.name)">
                                            {{projects[affectedAssets[content.id]?.projectId]?.name | slice:0:2}}
                                        </label>
                                        <div class="milestone-name _700"
                                            *ngIf="projects[affectedAssets[content.id]?.projectId]?.milestoneName">
                                            {{projects[affectedAssets[content.id]?.projectId]?.milestoneName}}</div>
                                    </div>
                                    <div class="project-info d-flex align-items-center justify-content-center p-1">
                                        <label
                                            class="m-0 text-center">{{projects[affectedAssets[content.id]?.projectId]?.name}}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="radio-button-selector"
                                *ngIf="(!readOnly && asSelector) && (!affectedAssets[content.id] || (selectedAssetTypes.indexOf(content.id) > -1))">
                                <label class="mtm-checkbox mtm-checkbox-green m-0" [ngClass]="{ 'disabled': isBusy }">
                                    <input [disabled]="isBusy" name="selectedAssetTypes" class="form-check-input"
                                        (click)="selectAsset($event, content)" type="checkbox"
                                        [checked]="selectedAssetTypes.indexOf(content.id) > -1">
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                            <div *ngIf="canEdit" class="edit-asset-button d-flex">
                                <i class="mtm-icon mtm-icon-pencil-grey pointer"
                                    (click)="openEditAssetModal('Conversion', content)"></i>
                                <i class="fa fa-trash pointer" (click)="deleteAsset('Conversion', content)"></i>
                            </div>
                            <div class="asset-content-header d-flex mb-2">
                                <div class="d-flex align-items-center mr-2" *ngFor="let media of content.bets">
                                    <i class="mtm-icon mtm-icon-{{media?.toLowerCase()}} mr-1"></i>
                                    <span class="text-uppercase _700">{{media}}</span>
                                </div>
                            </div>
                            <div class="asset-content-body d-flex">
                                <div class="size-info flex-fill w-50 d-flex flex-column">
                                    <div *ngFor="let size of content.length" class="d-flex align-items-center">
                                        <span>{{size}}</span>
                                    </div>
                                </div>
                                <div class="flex-fill w-50 d-flex flex-column content-info px-2">
                                    <div class="d-flex align-items-center">
                                        <span class="mr-2 _700 text-uppercase">Focus:</span>
                                        <span class="text-ellipsis">{{content.focus}}</span>
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <span class="mr-2 _700 text-uppercase">Type:</span>
                                        <span class="text-ellipsis">{{content.type}}</span>
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <span class="text-ellipsis">{{content.detail}}</span>
                                    </div>
                                </div>
                                <div *ngIf="!!affectedAssets[content.id]?.status && !asSelector"
                                    class="d-flex flex-column delivery-time">
                                    <label class="m-0 text text-uppercase">{{'Delivery'|translate}}</label>
                                    <div class="input-group position-relative">
                                        <input class="form-control date-picker" ngbDatepicker
                                            [(ngModel)]="affectedAssets[content.id].deliveryDate"
                                            (ngModelChange)="updateDeliveryDate(affectedAssets[content.id])"
                                            #negotiatedDate="ngbDatepicker" placeholder="xx.xx.xxxx" firstDayOfWeek="1"
                                            (click)="negotiatedDate.toggle()">
                                    </div>
                                </div>
                                <div class="content-info-mobile w-100">
                                    <div class="d-flex align-items-center">
                                        <div class="flex-fill w-50 d-flex">
                                            <div *ngFor="let size of content.length" class="d-flex align-items-center">
                                                <span>{{size}}</span>
                                            </div>
                                        </div>
                                        <div class="d-flex align-item-center ml-auto">
                                            <label
                                                class="m-0 d-flex align-items-center mr-2 text text-uppercase">{{'Delivery'|translate}}</label>
                                            <div *ngIf="!!affectedAssets[content.id]?.status && !asSelector"
                                                class="d-flex flex-column">
                                                <div class="input-group position-relative">
                                                    <input class="form-control date-picker" ngbDatepicker
                                                        [(ngModel)]="affectedAssets[content.id].deliveryDate"
                                                        (ngModelChange)="updateDeliveryDate(affectedAssets[content.id])"
                                                        #negotiatedDate="ngbDatepicker" placeholder="xx.xx.xxxx"
                                                        firstDayOfWeek="1" (click)="negotiatedDate.toggle()">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex-fill d-flex flex-column flex-sm-row mt-1">
                                        <div class="d-flex align-items-center flex-fill w-100 mr-2">
                                            <span class="mr-2 _700 text-uppercase mr-1">Focus:</span>
                                            <span class="text-ellipsis">{{content.focus}}</span>
                                        </div>
                                        <div class="d-flex align-items-center flex-fill w-100 mr-2">
                                            <span class="mr-2 _700 text-uppercase mr-1">Type:</span>
                                            <span class="text-ellipsis">{{content.type}}</span>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center flex-fill w-100">
                                        <span class="text-ellipsis">{{content.detail}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="status-info {{affectedAssets[content.id]?.status || (affectedAssets[content.id] ? 'UNKNOWN' : 'NOT_AFFECTED')}}"
                                *ngIf="showStatus">
                                <div class="d-block" (click)="$event.stopImmediatePropagation()">
                                    <div ngbDropdown class="d-flex align-items-center"
                                        [dropdownClass]="'status-'+content.id" [autoClose]="true"
                                        #projectStatusDropdown>
                                        <button (click)="$event.stopImmediatePropagation()" type="button"
                                            [disabled]="!affectedAssets[content.id]"
                                            class="d-flex align-items-center btn project-status-dropdown"
                                            ngbDropdownToggle>
                                            <div
                                                class="d-flex align-items-center project-status {{affectedAssets[content.id]?.status || (affectedAssets[content.id] ? 'UNKNOWN' : 'NOT_AFFECTED')}}">
                                                <div class="project-union-icon-container">
                                                    <i
                                                        class="project-union-icon mtm-icon mtm-icon-union-{{projectColor[(affectedAssets[content.id]?.status || (affectedAssets[content.id] ? 'UNKNOWN' : 'NOT_AFFECTED'))]}}"></i>
                                                </div>
                                                <span
                                                    class="status-text">{{'task_'+(affectedAssets[content.id]?.status||(affectedAssets[content.id]
                                                    ? 'UNKNOWN' : 'NOT_AFFECTED'))|translate}}</span>
                                                <i class="fa fa-chevron-down ml-auto pointer"></i>
                                            </div>
                                        </button>
                                        <div class="project-dropdown-menu" ngbDropdownMenu>
                                            <div *ngIf="affectedAssets[content.id]?.status!=projectStatus.Completed"
                                                (click)="$event.stopImmediatePropagation();changeProjectStatus(affectedAssets[content.id],projectStatus.Completed)"
                                                class="project-dropdown-project pt-1 pb-1 pl-2 pr-2 pointer">
                                                <div class="d-flex align-items-center project-status-display COMPLETED">
                                                    <i class="project-status-circle-color mr-2"></i>
                                                    <span>{{'task_COMPLETED'|translate}}</span>
                                                </div>
                                            </div>
                                            <div *ngIf="affectedAssets[content.id]?.status!=projectStatus.InProgress"
                                                (click)="$event.stopImmediatePropagation();changeProjectStatus(affectedAssets[content.id],projectStatus.InProgress)"
                                                class="project-dropdown-project pt-1 pb-1 pl-2 pr-2 pointer">
                                                <div
                                                    class="d-flex align-items-center project-status-display IN_PROGRESS">
                                                    <i class="project-status-circle-color mr-2"></i>
                                                    <span>{{'task_IN_PROGRESS'|translate}}</span>
                                                </div>
                                            </div>
                                            <div *ngIf="affectedAssets[content.id]?.status!=projectStatus.UpcomingExpiry"
                                                (click)="$event.stopImmediatePropagation();changeProjectStatus(affectedAssets[content.id],projectStatus.UpcomingExpiry)"
                                                class="project-dropdown-project pt-1 pb-1 pl-2 pr-2 pointer">
                                                <div
                                                    class="d-flex align-items-center project-status-display UPCOMING_EXPIRY">
                                                    <i class="project-status-circle-color mr-2"></i>
                                                    <span>{{'task_UPCOMING_EXPIRY'|translate}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="canAdd" class="add-new-asset-button d-flex align-items-center justify-content-center"
                    (click)="addNewAsset('Conversion')">
                    +
                </div>
            </div>
        </div>
    </div>
</div>