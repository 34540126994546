import { Component, inject, Input, OnInit } from "@angular/core";
import { FileTransferService, MTMCustomFile, TransferStatus } from "app/shared/services/signed-url";
import { Router } from "@angular/router";


@Component({
  selector: 'mtm-upload-popup-item',
  templateUrl: './upload-popup-item.component.html',
  styleUrls: ['./upload-popup-item.component.scss']
})
export class UploadPopupItemComponent implements OnInit {
  @Input() file: MTMCustomFile;

  transferService = inject(FileTransferService);
  router = inject(Router);
  transferStatus = TransferStatus;

  inProgressStatusList = [TransferStatus.NEW, TransferStatus.WAITING, TransferStatus.SENDING];
  resumableStatusList = [TransferStatus.INACTIVE, TransferStatus.PAUSED];

  get hasPreviewUrl(): boolean {
    return !!this.file?.extraInfo?.previewUrl;
  }


  ngOnInit(): void {
    this.checkUploadCompletion();
  }
  
  checkUploadCompletion() {
    const intervalId = setInterval(() => {
      if (this.file.uploadStatusCode === this.transferStatus.COMPLETED) {
        setTimeout(() => {
          this.transferService.cancelUpload(this.file).subscribe(() => {
          });
        }, 30000);
        clearInterval(intervalId);
      }
    }, 1000);
  }

  goToSource(event: MouseEvent) {
    this.stopEvent(event);
    if (!this.file?.extraInfo?.previewUrl) {
      return;
    }

    try {
     let url = new URL(this.file.extraInfo.previewUrl, window.location.origin);
     console.log('previewUrl', this.file.extraInfo.previewUrl, url);
     const fragment = url.hash?.substring(1) || '';
     this.router.navigate([url.pathname], { queryParams:  Object.fromEntries(url.searchParams), fragment });
    }catch{
    }
  }

  stopEvent(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  pauseUpload(event: MouseEvent) {
    this.stopEvent(event);
    this.transferService.pauseUpload(this.file);
  }

  resumeUpload(event: MouseEvent) {
    this.stopEvent(event);
    this.transferService.resumeUpload(this.file);
  }

  removeFromList(event: MouseEvent) {
    this.stopEvent(event);
    this.transferService.cancelUpload(this.file).subscribe(() => { });
  }

  handleUploadError(event: MouseEvent) {
    this.stopEvent(event);
    if (this.file?.isResumable()) {
      this.transferService.resumeUpload(this.file);
    } else {
      this.removeFromList(event);
    }
  }
}
