<div class="container container-inner mt-3 change-password-page">
	<div class="modal-label-info p-2" *ngIf="asModal">
		<div class="label-info px-3">{{'forceUpdatePassword'|translate}}</div>
		<i class="fa fa-times pointer" (click)="closeModal()"></i>
		<hr class="mx-3 mb-2" />
	</div>
	<form [formGroup]="formGroup" (ngSubmit)="onSubmit()" *ngIf="formGroup">
		<div class="row">
			<div class="col-12 col-md-6">
				<!-- Current password -->
				<div class="card-block p-0">
					<p class="card-text options">
						<label class="form-group row has-float-label"
							[ngClass]="{'has-danger': formGroup.controls.currentPassword.invalid && submitted}">
							<input class="form-control" type="password" formControlName="currentPassword"
								placeholder=" "
								[ngClass]="{'form-control-danger': formGroup.controls.currentPassword.invalid && submitted}"
								passwordToggle />
							<span *ngIf="!(submitted && formErrors.currentPassword)">Current Password</span>
							<span class="text-danger"
								*ngIf="submitted && formErrors.currentPassword">{{formErrors.currentPassword}}</span>
						</label>
					</p>
				</div>

				<mtm-password-checker [companyId]="authUser.companyId"
					[showValidation]="formGroup.controls.newPassword.dirty"
					[password]="formGroup.controls.newPassword.value" [(minPassLength)]="minPassLength"
					(passwordValidated)="passwordValidated($event)"></mtm-password-checker>
			</div>
			<div class="col-12 col-md-6">
				<!-- New password -->
				<div class="card-block p-0 pb-0">
					<p class="card-text options">
						<label class="form-group mb-1 row has-float-label"
							[ngClass]="{'has-danger': formGroup.controls.newPassword.invalid && submitted}">
							<input class="form-control" type="password" formControlName="newPassword" placeholder=" "
								[ngClass]="{'form-control-danger': formGroup.controls.newPassword.invalid && submitted}"
								passwordToggle />
							<span *ngIf="!(submitted && formErrors.newPassword)">New Password</span>
							<span class="text-danger"
								*ngIf="submitted && formErrors.newPassword">{{formErrors.newPassword}}</span>
						</label>
						<mtm-password-strength-meter [passwordToCheck]="formGroup.controls.newPassword.value"
							(passwordStrength)="onPasswordStrengthChanged($event)" [minPassLength]="minPassLength"></mtm-password-strength-meter>
					</p>
				</div>
				<!-- / New password -->
				<!-- Repeat new password -->
				<div class="card-block px-0">
					<p class="card-text options">
						<label class="form-group row has-float-label"
							[ngClass]="{'has-danger': formGroup.controls.reNewPassword.invalid && submitted}">
							<input class="form-control" type="password" formControlName="reNewPassword" placeholder=" "
								[ngClass]="{'form-control-danger': formGroup.controls.reNewPassword.invalid && submitted}"
								passwordToggle />
							<span *ngIf="!(submitted && formErrors.reNewPassword)">Repeat Password</span>
							<span class="text-danger"
								*ngIf="submitted && formErrors.reNewPassword">{{formErrors.reNewPassword}}</span>
						</label>
					</p>
				</div>
				<!-- / Repeat new password -->
				<div class="d-flex justify-content-end">
					<button type="submit" class="btn btn-lg btn-orange">
						Save Changes
					</button>
				</div>
			</div>
		</div>
	</form>
</div>