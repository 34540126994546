// import { Injectable } from '@angular/core';
// import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
// import { Observable } from 'rxjs';

// @Injectable({
//   providedIn: 'root'
// })
// export class UserpermissiontypeGuard implements CanActivate {
//   canActivate(
//     route: ActivatedRouteSnapshot,
//     state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
//     return true;
//   }
  
// }
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../../../app/shared/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserpermissiontypeGuard implements CanActivate {
  
  constructor(private router: Router, private authService: AuthService) { }
  isAgencyUser: boolean = false;

  canActivate(): boolean {
    let authUser = this.authService.getAuthUser();
    this.isAgencyUser = authUser.userPermissionType === 'AGENCY'; 
    if (this.isAgencyUser) {
      this.router.navigate(['/workspaces/projects']); // Redirect if permission is 'agency'
      return false;
    }
    return true;
  }
}
