<div class="login-form-container">
    <div *ngIf="!showRegistrationFields">
        <div class="text-center mb-4">
            <h6>{{'signInTitle' | translate}}</h6>
        </div>

        <div class="d-flex flex-column flex-md-row align-items-center justify-content-center px-3 mb-4 social-buttons">
            <button type="button" class="btn btn-social-login d-flex align-items-center justify-content-start px-3"
                (click)="onGoogleLogin()">
                <img class="mr-3" ngSrc="/assets/img/login/google.svg" alt="Continue with Google" width="20"
                    height="20" />
                <span class="fw-700">{{'login_signInWithGoogle' | translate }}</span>
            </button>
            <button type="button" class="btn btn-social-login d-flex align-items-center justify-content-start px-3"
                (click)="onAzureLogin()">
                <img class="mr-3" ngSrc="/assets/img/login/microsoft.svg" alt="Continue with Microsoft" width="21"
                    height="21" />
                <span class="fw-700">{{'login_signInWithMicrosoft' | translate }}</span>
            </button>
        </div>
        <div class="login-divider">
            <small>{{'login_signInEmailLabel' | translate}}</small>
        </div>
        <form class="pb-3 p-md-0 login-form" [formGroup]="loginForm" (ngSubmit)="onNewSubmit($event)">

            <div class="form-group" [ngClass]="{'has-danger': loginForm.controls.email.invalid && submitted}">
                <label for="email-tb">{{'signInEmail' | translate}}</label>
                <input id="email-tb" class="form-control" type="email" formControlName="email"
                    placeholder="{{'signInEmail' | translate}}"
                    [ngClass]="{'form-control-danger': loginForm.controls.email.invalid && submitted}">
                <span *ngIf="submitted && loginForm.controls.email.invalid"
                    class='text-danger'>{{'login_error_emailRequired' | translate}}</span>
            </div>
            <div class="form-group" [ngClass]="{'has-danger': loginForm.controls.password.invalid && submitted}">
                <label for="password-tb">{{'signInPassword' | translate}}</label>
                <div class="position-relative password-wrapper">
                    <input id="password-tb" class="form-control pr-5" type="password" formControlName="password"
                        placeholder="{{'signInPassword' | translate}}"
                        [ngClass]="{'form-control-danger': loginForm.controls.password.invalid && submitted}"
                        passwordToggle>
                </div>
                <span *ngIf="submitted && loginForm.controls.password.invalid"
                    class='text-danger'>{{'login_error_passwordRequired' | translate}}</span>
            </div>
            <div class="row justify-content-end m-3">
                <!--
              <label class="form-check-label ml-20">
                  <input id="remember-me-cb" formControlName="remember_me" type="checkbox" class="form-check-input">
                  {{'signInRemember' | translate}}
              </label>
              -->
                <a id="forgot-your-details-link" class="forgot-password" href="#"
                    (click)="onOpenForgotPasswordModal()">{{'signInForgot' | translate}}</a>
            </div>
            <div class="text-center">
                <button id="signin-btn" type="submit" class="btn btn-lg btn-submit-login btn-block"
                    [disabled]="loading">
                    {{submitBtn | translate}}
                </button>
            </div>


        </form>
        <div class="card-text options password-checker d-none" *ngIf="authUser?.companyId">
            <mtm-password-checker [companyId]="authUser.companyId" [showValidation]="loginForm.controls.password.dirty"
                [password]="loginForm.controls.password.value" [(minPassLength)]="minPassLength"
                (passwordValidated)="passwordValidated($event)"></mtm-password-checker>
        </div>

        <div *ngIf="fromOneShot" class="row justify-content-between">
            <label>
                {{'registerDoHaveAccount' | translate}}
                <span class="register" (click)="showRegistrationFields=!showRegistrationFields">{{'signUpButton' |
                    translate}}</span>
            </label>
        </div>

    </div>


    <div *ngIf="showRegistrationFields">
        <div class="text-center my-4" *ngIf="projectId">
            <h5 class="register-title">{{'registerTitle' | translate}}</h5>
            <h6 class="fw-700">{{'registerSubTitle' | translate}}</h6>
        </div>
        <div class="text-center my-4" *ngIf="!projectId">
            <h6 class="font-weight-bold">Want to find out more about MTM?</h6>
        </div>
        <form [formGroup]="registrationForm">
            <div class="registrationArea">
                <div class="form-group"
                    [ngClass]="{'has-danger': registrationForm.controls['firstName'].hasError('required') && registrationForm.controls['firstName'].touched}">
                    <label for="firstName-new">{{'registerFirstName' | translate}}</label>
                    <input id="firstName-new" class="form-control" type="text" formControlName="firstName"
                        placeholder=" "
                        [ngClass]="{'form-control-danger': registrationForm.controls['firstName'].hasError('required') && registrationForm.controls['firstName'].touched}">

                </div>
                <div class="form-group"
                    [ngClass]="{'has-danger': registrationForm.controls['lastName'].hasError('required') && registrationForm.controls['lastName'].touched}">
                    <label for="lastName-new">{{'registerLastName' | translate}}</label>
                    <input id="lastName-new" class="form-control" type="text" formControlName="lastName" placeholder=" "
                        [ngClass]="{'form-control-danger': registrationForm.controls['lastName'].hasError('required') && registrationForm.controls['lastName'].touched}">
                </div>
            </div>

            <div class="form-group"
                [ngClass]="{'has-danger': registrationForm.controls['company'].hasError('required') && registrationForm.controls['company'].touched}">
                <label for="company-new">{{'registerCompany' | translate}}</label>
                <input id="company-new" class="form-control" type="text" formControlName="company" placeholder=" "
                    [ngClass]="{'form-control-danger': registrationForm.controls['company'].hasError('required') && registrationForm.controls['company'].touched}">
            </div>

            <div class="form-group"
                [ngClass]="{'has-danger': registrationForm.controls['companyUrl'].hasError('required') && registrationForm.controls['companyUrl'].touched}">
                <label for="domain-new">{{'registerCompanyDomainName' | translate}}</label>
                <input class="form-control" type="text" formControlName="companyUrl" placeholder="e.g company.com"
                    id="domain-new" (keyup)="checkDomainNameMatch()" (change)="checkDomainNameMatch()"
                    [ngClass]="{'form-control-danger': registrationForm.controls['companyUrl'].hasError('required') && registrationForm.controls['companyUrl'].touched}">
            </div>

            <div class="form-group">
                <label for="country-new">{{'country' | translate}}</label>
                <select class="form-control" id="country-new"
                    [ngClass]="{'has-danger-combo-box': isCountryValid() && registrationForm.controls['country'].touched ,'combo-box':!registrationForm.controls['country'].touched}"
                    *ngIf="listCountryCodes" formControlName="country" (change)="onSelectCountry($event)"
                    [ngModelOptions]="{standalone: true}">
                    <option value="">{{'searchCountry' | translate}}</option>
                    <option [value]="item.regionCode" *ngFor="let item of listCountryCodes">{{item.countryName}}
                    </option>
                </select>
            </div>

            <div class="form-group" [ngClass]="{'has-danger': (registrationForm.controls['companyTaxId'].hasError('required') || registrationForm.controls['companyTaxId'].hasError('pattern'))
				    && registrationForm.controls['companyTaxId'].touched} ">
                <label for="companyTaxId">{{'registerCompanyTaxId' | translate}}</label>
                <input class="form-control" type="text" formControlName="companyTaxId" id="companyTaxId"
                    placeholder="{{vatPlaceHolder ? vatPlaceHolder : ('registerCompanyTaxId' | translate)}}" [ngClass]="{'form-control-danger': (registrationForm.controls['companyTaxId'].hasError('required') || registrationForm.controls['companyTaxId'].hasError('pattern') )
					    && registrationForm.controls['companyTaxId'].touched}">
            </div>

            <div class="form-group" [ngClass]="{'has-danger': (registrationForm.controls['email'].hasError('required') || registrationForm.controls['email'].hasError('pattern') || !isMatchDomainNameWithEmail)
				    && registrationForm.controls['email'].touched}">
                <label>{{'signInEmail' | translate}}</label>
                <input class="form-control" type="email" formControlName="email" placeholder="e.g name@company.com"
                    [ngClass]="{'form-control-danger': (registrationForm.controls['email'].hasError('required') || registrationForm.controls['email'].hasError('pattern') ||!isMatchDomainNameWithEmail)
					    && registrationForm.controls['email'].touched}" (change)="IsMatchDomainNameWithEmailOnChange()"
                    (keyup)="checkDomainNameMatch()">
            </div>

            <selector-mtm-tel-input [cardForm]=registrationForm [cardFormKey]="'phone'">
            </selector-mtm-tel-input>

            <mtm-terms-conditions-check [companyType]="companyType" #mtmTermsConditionCheck>
            </mtm-terms-conditions-check>

            <div class="text-center mt-3">
                <button id="register-btn" type="submit" class="btn btn-lg btn-submit-registration btn-block"
                    [disabled]="registrationForm.invalid ||!mtmTermsConditionCheck.isTermsCondition || !isMatchDomainNameWithEmail"
                    (click)="saveRegistration()">
                    {{'registerButton' | translate}}
                </button>
            </div>

            <div *ngIf="fromOneShot" class="row justify-content-between my-2 text-center">
                <label>
                    {{'registerAlreadyAccount' | translate}}
                    <span class="register"
                        (click)="showRegistrationFields=!showRegistrationFields">{{'registerSignInButton' |
                        translate}}</span>
                </label>
            </div>


        </form>
    </div>
</div>