<div class="card" [ngClass]="{'notification-view': isNotificationView}" *ngIf="timelineLoaded">
    <div class="row recap-section mt-3">
        <div *ngIf="!timelineData" class="col p-0 d-flex justify-content-center align-items-center">
            <mtm-grid-skeleton [borderRadius]="6" [gridCount]="1" [widthSizeInPixels]="1180"
                [HeightSizeInPixels]="165"></mtm-grid-skeleton>
        </div>
        <div *ngIf="timelineData" class="content-top-banner col p-0 d-flex justify-content-center align-items-center">
            <img class="w-100 header-img" *ngIf="!isCpd" [src]="timelineData?.banner ? timelineData.banner : headerImage" />
            <div class="col logo-div d-flex flex-wrap align-items-center">
                <div class="col-lg-2 col-lg-6 col-md-6 col-sm-12 mw-100 w-100">
                    <div class="ml-3 ">
                        <div class="logo-img-div d-flex justify-content-center align-items-center">
                            <!-- <img [src]="timelineService.brandLogo()" /> -->
                            <img *ngIf="timelineData?.logo" [src]="timelineData?.logo" class="logo" alt="">
                            <span *ngIf="!timelineData?.logo" class="d-flex justify-content-center align-items-center logo c-full-white profile-text _700 text-uppercase"
                                [style.backgroundColor]="timelineService.getColorFromName(timelineData?.name)">
                                {{timelineData?.name | slice:0:2}}
                            </span>
                        </div>
                        <div class="company-header-title-div"><span class="company-title">{{headerTitle}}</span>
                        </div>
                        <div class="company-title-div"> <span class="company-header-title">{{headerSubtitle}}</span>
                        </div>
                    </div>
                 
                </div>
                <div class="col-lg-2 col-lg-6 col-md-6 col-sm-12 mw-100 w-100">
                    <div class="file-download-btn-div">
                        <button class="file-download-btn" (click)="downloadTimeline()"><i
                                class="fa fa-file fa-lg mr-3 file-download-icon"></i> <span
                                class="file-download-btn-title text-uppercase">{{'downloadAsPpt' | translate}}</span> </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container mt-3" style="max-width: 1239px">
        <!-- summary block -->
        <div *ngIf="!timelineData">
            <mtm-grid-skeleton [borderRadius]="6" [gridCount]="4" [widthSizeInPixels]="280"
            [HeightSizeInPixels]="67"></mtm-grid-skeleton>
            <mtm-grid-skeleton [borderRadius]="6" [gridCount]="4" [widthSizeInPixels]="280"
            [HeightSizeInPixels]="67"></mtm-grid-skeleton>
            <mtm-grid-skeleton [borderRadius]="6" [gridCount]="3" [widthSizeInPixels]="360"
            [HeightSizeInPixels]="28"></mtm-grid-skeleton>
        </div>
        <div *ngIf="timelineData" class="">
            <mtm-summary-block *ngIf="!isCpd" [timelineData]="timelineData"></mtm-summary-block>
            <mtm-summary-block-cpd *ngIf="isCpd" [timelineData]="timelineData"></mtm-summary-block-cpd>
        </div>
        <!-- <hr class="horizontal-line"> -->
        <!-- timeline block -->
        <div class="mt-4" *ngIf="!timelineData">
            <mtm-grid-skeleton [borderRadius]="6" [gridCount]="11" [widthSizeInPixels]="89"
            [HeightSizeInPixels]="80"></mtm-grid-skeleton>
        </div>
        <div class="mt-4" *ngIf="timelineData && !fromNotification">
            <mtm-timeline-blocks [isRecap]="true" [isHallmark]="summaryDetail['Project Type']==='Hallmarks'" [timeLineData]="timelineData"></mtm-timeline-blocks>
        </div>

        <hr class="horizontal-line" *ngIf="timelineData && !fromNotification">
        <!-- full timeline view block -->
        <div class="mt-4" *ngIf="!timelineData">
            <mtm-grid-skeleton [borderRadius]="6" [gridCount]="24" [widthSizeInPixels]="30"
            [HeightSizeInPixels]="44"></mtm-grid-skeleton>
        </div>
        <div class="mt-4" *ngIf="timelineData">
            <mtm-timeline-full-view [timeLineData]="timelineData"></mtm-timeline-full-view>
        </div>
    </div>

    <!-- ADD VISUALIZATION block -->
    <div class="card mt-5 visualization-div justify-content-center " style="margin-bottom: 80px !important;">
        <div class="row m-1 justify-content-center">
            <div *ngIf="!timelineData">
                <mtm-grid-skeleton [borderRadius]="6" [gridCount]="1" [widthSizeInPixels]="192"
                [HeightSizeInPixels]="48"></mtm-grid-skeleton>
            </div>
            <div *ngIf="timelineData" class="add-visualization-btn-div">
                <button class="visualization-btn" (click)="createVisualization()"><i
                        class="fa fa-plus-circle fa-lg mr-3"></i>
                    <span class="f-12 _700">{{'addVisualization' | translate}}</span> </button>
            </div>
            <div *ngIf="!timelineData" class="col">
                <div>
                    <mtm-grid-skeleton [borderRadius]="6" [gridCount]="1" [widthSizeInPixels]="950"
                    [HeightSizeInPixels]="48"></mtm-grid-skeleton>
                </div>
            </div>
            <div *ngIf="timelineData" class="col">
                <div class="row mt-1 ml-2">
                    <span class="f-12 _700">{{'addVisualizationTitle' | translate}}</span>
                </div>
                <div class="row ml-2">
                    <span class="mt-2 f-12 _500">{{'addVisualizationSubTitle' | translate}}</span>
                </div>
            </div>
        </div>
        <div class="mt-3 mr-3 ml-3 " [ngClass]="{'mb-5': !isVisulizationEdit}" [style.border-radius]="'10px'"
             *ngIf="visualizations.length">
            <div *ngFor="let v of visualizations;let i = index">
                <div class="visualization-edit-div mt-3 p-2 cursor recap-section" (click)="openMilestoneBlock(i);"
                    [ngClass]="{'visualization-edit-div-border' : isVisulizationEdit && i == milestoneBlockIndex }">
                    <div class="col-lg-6 col-md-6 col-sm-12" style=" cursor: default;"
                        (click)="$event.stopPropagation()">
                        <i (click)="editVisualizationName(visualizationNameElement, i);"
                            class="fa fa-pencil fa-lg pointer" [style.color]="'#fff'" aria-hidden="true"></i>
                        <span #visualizationNameElement class="ml-2 f-14 _500">{{v.name}}</span>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 justify-content-end align-items-center recap-section my-1">
                        <div class="mr-3 d-flex align-items-center flex-wrap" [style.gap]="'10px'">
                            <span class="_700 f-10 text-uppercase mr-3">{{'milestonesDisplay' | translate}}</span>
                            <div class="select-wrapper mr-3" [style.width]="'148px'" (click)="$event.stopPropagation()">
                                <select class="milestone-filter dropdown-toggle section-filter f-10" #milestoneSelect
                                    id="{{ 'milestoneSelect-' + v.id }}" (change)="filterMilestone($event, i)">
                                    <option *ngFor="let item of milestoneFilterDropdown" class="option-hover f-10"
                                        [value]="item">{{item | titlecase | translate }}</option>
                                </select>
                            </div>
                            <div class="delete-icon-div mr-3 pointer" (click)="deleteVisualization(i)">
                                <i class="fa fa-trash delete-btn-icon" aria-hidden="true"></i>
                            </div>
                            <div>
                                <i class="fa fa-angle-down fa-lg pointer" [style.color]="'#fff'" aria-hidden="true"
                                    [ngClass]="{'visualization-edit-mod': !isVisulizationEdit && i !== milestoneBlockIndex ,'visualization-edit-mod': isVisulizationEdit && i == milestoneBlockIndex }"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- drag and drop block -->
                <!-- <div class="col" *ngIf="isVisulizationEdit && i !== milestoneBlockIndex"> -->
                <div class="visualization-card-div mb-5" *ngIf="v.showMilestone ">
                    <div class="row m-0 p-2">
                        <div class="d-flex flex-wrap w-100 drag-area-div" *ngIf="!v.isBothSelected">
                            <div class="d-flex flex-wrap w-100">
                                <div class="col-sm-1 mt-2 p-0" [style.max-width]="'73px'"
                                    *ngFor="let item of v.milestone">
                                    <div class="visualization-card-mad-div" [style.background]="item.blockColor">
                                        <div class="">
                                            <span class="milestone-card-div-text">{{item.mad}}</span>
                                        </div>
                                        <div class="milestone-mad-date">
                                            <span class="milestone-card-div-text">{{item.driveDate | date:
                                                'dd.MM.YYYY'}}</span>
                                        </div>
                                    </div>
                                    <div class="milestone-card-div">
                                        <div class="d-flex justify-content-center align-items-center">
                                            <img class="pointer" (click)="removeMilestone(item,v.id)"
                                                src="../../../assets/img/project-v2/cross-icon.svg" alt="">
                                        </div>
                                        <div>
                                            <span class="milestone-name-text _700">{{item?.blockName}}</span>
                                            <span class="milestone-name-text">{{item.name}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex flex-wrap w-100" *ngIf="v.isBothSelected">
                            <div class="col">
                                <div class="row"><span class="f-14 _600">Colored ordered sorted</span></div>
                                <div class="row both-sorting">
                                    <div class="col-sm-1 mt-2 p-0" [style.max-width]="'73px'"
                                        *ngFor="let item of v.colorSortedMilestones">
                                        <div class="visualization-card-mad-div" [style.background]="item.blockColor">
                                            <div class="">
                                                <span class="milestone-card-div-text">{{item.mad}}</span>
                                            </div>
                                            <div class="milestone-mad-date">
                                                <span class="milestone-card-div-text">{{item.driveDate | date:
                                                    'dd.MM.YYYY'}}</span>
                                            </div>
                                        </div>
                                        <div class="milestone-card-div">
                                            <div class="d-flex justify-content-center align-items-center">
                                                <img class="pointer" (click)="removeMilestone(item,v.id)"
                                                    src="../../../assets/img/project-v2/cross-icon.svg" alt="">
                                            </div>
                                            <div>
                                                <span class="milestone-name-text _700">{{item?.blockName}}</span>
                                                <span class="milestone-name-text">{{item.name}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-2"><span class="f-14 _600">Chronological order sorted</span></div>
                                <div class="row both-sorting">
                                    <div class="col-sm-1 mt-2 p-0" [style.max-width]="'73px'"
                                        *ngFor="let item of v.chronologicalSortedMilestones">
                                        <div class="visualization-card-mad-div" [style.background]="item.blockColor">
                                            <div class="">
                                                <span class="milestone-card-div-text">{{item.mad}}</span>
                                            </div>
                                            <div class="milestone-mad-date">
                                                <span class="milestone-card-div-text">{{item.driveDate | date:
                                                    'dd.MM.YYYY'}}</span>
                                            </div>
                                        </div>
                                        <div class="milestone-card-div">
                                            <div class="d-flex justify-content-center align-items-center">
                                                <img class="pointer" (click)="removeMilestone(item,v.id)"
                                                    src="../../../assets/img/project-v2/cross-icon.svg" alt="">
                                            </div>
                                            <div>
                                                <span class="milestone-name-text _700">{{item?.blockName}}</span>
                                                <span class="milestone-name-text">{{item.name}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- TODO: comment drag and drop functionality whenever data is ready we will use it -->

                            <!-- <div [ngClass]="{'drag-btn mb-5': milestoneTimeline.length > 0}"
                                class="drag-area example-list" [style.min-width]="'73px'" [style.max-width]="'100%'"
                                cdkDropList #doneList="cdkDropList" [cdkDropListData]="milestoneTimeline"
                                [cdkDropListConnectedTo]="[todoList]" (cdkDropListDropped)="drop($event)"> -->
                            <!-- <div [ngClass]="{'drag-btn mb-5': v.milestone.length > 0}"
                                class="drag-area example-list" [style.min-width]="'73px'" [style.max-width]="'100%'">
                                <div class=" d-flex justify-content-center align-items-center w-100">
                                    <i class="pointer fa fa-plus-circle fa-lg"></i>
                                </div>
                            </div> -->
                        </div>
                    </div>
                    <div class="row m-0">
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <span class="mt-2 f-12 _500">{{'addMilestoneTitle' | translate}}</span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class=" d-flex justify-content-center align-items-center flex-wrap" [style.gap]="'6px'">
                                <div><span class="f-10 _600">{{'addColorGroup' | translate}}</span></div>
                                <div id="visualization-filter" [style.width]="'150px'" [style.max-width]="'200px'">
                                    <ng-select class="section-filter f-10 " [items]="namesAndColors"
                                        [(ngModel)]="visualizations[i].selectedColors" (change)="addColorGroup(v.id)"
                                        [multiple]="true" bindLabel="name" bindValue="blockColor" [clearable]="false"
                                        [searchable]="false" [hideSelected]="false" placeholder="{{'selectColorGroup' | translate}}"
                                        [closeOnSelect]="false">
                                        <ng-template ng-multi-label-tmp let-items="items">
                                            <div class="selected-options _600 f-10 d-flex justify-content-center align-items-center"
                                                style="gap: 10px;">
                                                {{ visualizations[i].selectedColors.length }}
                                                <div *ngFor="let item of visualizations[i].selectedColors; let i = index"
                                                    class="color-badge" [style.background]="item" [title]="item"></div>
                                            </div>
                                        </ng-template>
                                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                            <div class="option-item" [class.selected]="isSelected(item)">
                                                {{ item.name }}
                                            </div>
                                        </ng-template>
                                    </ng-select>

                                </div>
                                <div>
                                    <button class="add-milestone-btn" (click)="toggleMilestoneButton(v.id)">{{
                                        getButtonTitle(v.id) }}</button>
                                </div>
                                <div class="">
                                    <input [(ngModel)]="timelinesearch" class="search-box filter"
                                        (ngModelChange)="searchTimeline()" type="text" placeholder="{{'search' | translate}}"
                                        autocomplete="off" />
                                    <i class="fa fa-search search-icon" aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr class="visualization-card-horizontal-line">
                    <!-- TODO: comment drag and drop functionality whenever data is ready we will use it -->
                    <!-- <div class="row m-0 example-list" cdkDropList #todoList="cdkDropList"
                        [cdkDropListData]="timeLineMilestoneData" [cdkDropListConnectedTo]="[doneList]"
                        (cdkDropListDropped)="drop($event)"> -->
                    <!-- <div class="col-2 mb-3 mt-3 drag-item" *ngFor="let item of timeLineMilestoneData"
                            [cdkDragData]="item" cdkDrag> -->
                    <div class="row m-0 example-list">
                        <div class="col-sm-6 col-lg-2 col-md-4 mb-3 mt-3" *ngFor="let item of timeLineMilestoneData">
                            <!-- <div class="drag-item"> -->
                            <div class="visualization-card-mad-div" [style.background]="item.blockColor">
                                <div class="">
                                    <span class="visualization-card-mad-div-text">{{item.mad}}</span>
                                </div>
                                <div class="mad-date">
                                    <span class="visualization-card-mad-div-text">{{item.driveDate | date:
                                        'dd.MM.YYYY'}}</span>
                                </div>
                            </div>
                            <div class="visualization-card-name-div">
                                <div>
                                    <span class="visualization-card-name-div-text _700">{{item?.blockName}}</span>
                                    <span class="visualization-card-name-div-text">{{item.name}}</span>
                                </div>
                                <div class="pointer" (click)="addMilestone(item,v.id,i)"
                                    *ngIf="!(v.milestone && v.milestone.includes(item))">
                                    <i class="fa fa-plus-circle fa-lg"></i>
                                </div>
                                <div class="pointer" *ngIf="(v.milestone && v.milestone.includes(item))"
                                    (click)="removeMilestone(item,v.id)">
                                    <i class="fa fa-times-circle fa-lg" [style.color]="'#E45933'"
                                        aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- </div> -->
            </div>
        </div>
    </div>
    <div *ngIf="isCpd && !!assetBundle" class="d-flex">
        <mtm-asset-list class="w-100" [bundle]="assetBundle" [canAdd]="true" [canEdit]="true" [asSelector]="false" [showSettingButton]="false" [assetStar]="assetStar" (assetsUpdated)="assetBundleUpdated($event)"></mtm-asset-list>
    </div>

    <div class="d-flex align-items-center mt-4">
        <button class="btn btn b-mtm-black c-mtm-full-white py-2 px-3 text-uppercase ml-auto" (click)="goToCampaign()">Go To Campaign</button>
    </div>

    <!-- <div class="d-flex mt-4">
        <mtm-asset-type-selector class="w-100"></mtm-asset-type-selector>
    </div> -->
</div>