import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
declare const gtag: any;
const GOOGLE_ANALYTICS_ID = 'G-KVH51QCYG5';
@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {
  gtag: any;
  constructor() {
    if (typeof gtag !== 'undefined') {
      this.gtag = gtag;
    }
  }

  public sendEvent = (
    eventName: string,
    eventCategory: string,
    eventLabel: string | null = null,
    eventValue: number | null = null
  ) => {
    if (!this.gtag) {
      return;
    }
    this.gtag('event', eventName, {
      /* eslint-disable @typescript-eslint/naming-convention */
      event_category: eventCategory,
      event_label: eventLabel,
      /* eslint-enable @typescript-eslint/naming-convention */
      value: eventValue
    });
  };

  public sendPageView(url: string) {
    if (!this.gtag) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/naming-convention
    this.gtag('config', GOOGLE_ANALYTICS_ID, { page_path: url });
  }

  public sendUserAnalyticsConfig(driveCampaign: string, workspace: string, project: string) {
    const brand = JSON.parse(localStorage.getItem('brand'));
    const auth_user = JSON.parse(localStorage.getItem('auth_user'));
    const hashedEmail = CryptoJS.MD5(auth_user.email).toString();
    const hashedUsername = CryptoJS.MD5(auth_user.username).toString();

    if (typeof gtag === 'function') {
      gtag('config', GOOGLE_ANALYTICS_ID, {
        page_path: window.location.pathname, // Set page path to track pageviews in GA4
      });

      // Send user properties in GA4 format
      gtag('set', 'user_properties', {
        mtm_email: hashedEmail,
        mtm_user_brand: brand?.name,
        mtm_user_company: auth_user.company.name,
        mtm_user_role: auth_user.globalRole,
        mtm_user_type: auth_user.userPermissionType,
        mtm_drive_campaign: driveCampaign,
        mtm_workspace: workspace,
        mtm_user_name: hashedUsername,
        mtm_project: project
      });
    }
  }

}
