<div class="mtm-assets-dropdown mtm-assets-dropdown-component">
	<div class="mtm-assets-list">
		<div class="mtm-assets-item root select-all-items p-2 d-flex align-items-center pointer item-label"
			[ngClass]="{ 'open': isOpen, 'selected': versionData.isSelected }" (click)="toggleDropdown()">
			<div class="d-flex align-items-center w-100" [hidden]="!((!(value.length && !isOpen)) || isSelectAll)">
				<span class="all-assets-title">{{ 'allAssets' | translate }} - {{versionData?.label}}</span>
			</div>
			<div class="d-flex align-items-center" [hidden]="(!(value.length && !isOpen)) || isSelectAll">
				<span class="values-title _1-line-text-ellipsis" [style.max-width]="'480px'" [style.width]="'auto'"
					[style.white-space]="'nowrap'">{{getValueCaptions()}}</span>
				<span class="values-title" *ngIf="moreValue" [style.white-space]="'nowrap'">and {{moreValue}}
					more...</span>
			</div>
			<div class="ml-auto mtm-icon"
				[ngClass]="{ 'mtm-icon-folder-open': isOpen, 'mtm-icon-folder-closed': !isOpen }">
			</div>
		</div>
		<div [hidden]="!isOpen" class="items-list">
			<div class="mtm-assets-item pl-3 pt-2 pb-1 pr-3">
				<div (click)="versionData.isExpanded=!versionData.isExpanded"
					class="pointer subsection-info d-flex align-items-center item-label"
					[ngClass]="{ 'expanded': versionData.isExpanded }">
					<div class="d-flex align-items-center">
						<div class="mtm-icon"
							[ngClass]="{ 'mtm-icon-folder-open': versionData.isExpanded, 'mtm-icon-folder-closed': !versionData.isExpanded }">
						</div>
						<span class="ml-2 p-2">{{versionData.label}}</span>
					</div>
					<div class="ml-auto">
						<i class="fa fa-fw fa-check pointer" [ngClass]="{ 'selected': versionData?.isSelected }"
							[style.background-color]="'#FFFFFF'" [style.padding]="'3px 0 3px 0'"
							(click)="$event.stopImmediatePropagation();selectAllAssetsInSubSection()"></i>
					</div>
				</div>
				<div [hidden]="!versionData.isExpanded" class="topics-list">
					<div class="topic-item pl-3" *ngFor="let topic of versionData.topics">
						<div (click)="topic.isExpanded=!topic.isExpanded"
							class="topic-info d-flex pointer align-items-center item-label"
							[ngClass]="{ 'expanded': topic.isExpanded }">
							<div class="d-flex align-items-center">
								<div class="mtm-icon"
									[ngClass]="{ 'mtm-icon-folder-open': topic.isExpanded, 'mtm-icon-folder-closed': !topic.isExpanded }">
								</div>
								<span class="ml-2 p-2">{{topic.label}}</span>
							</div>
							<div class="ml-auto">
								<i class="fa fa-fw fa-check pointer" [ngClass]="{ 'selected': topic?.isSelected }"
									[style.background-color]="'#FFFFFF'" [style.padding]="'3px 0 3px 0'"
									(click)="$event.stopImmediatePropagation();selectTopicAssets(topic)"></i>
							</div>
						</div>
						<div [hidden]="!topic.isExpanded" class="asset-list pl-3" *ngFor="let asset of topic.assets">
							<div (click)="asset.isExpanded=!asset.isExpanded"
								class="asset-info d-flex align-items-center pointer item-label"
								[ngClass]="{ 'expanded': asset.isExpanded }">
								<div class="d-flex align-items-center">
									<div class="mtm-icon"
										[ngClass]="{ 'mtm-icon-folder-open': asset.isExpanded, 'mtm-icon-folder-closed': !asset.isExpanded }">
									</div>
									<span class="ml-2 p-2">{{ asset?.title || asset?.name || asset?.language ||
										asset?.accessory ||
										asset?.elementOfSetDesign ||
										asset?.articleOfCostumeDesign }}</span>
								</div>
								<div class="ml-auto">
									<i class="fa fa-fw fa-check pointer" [ngClass]="{ 'selected': asset?.isSelected }"
										[style.background-color]="'#FFFFFF'" [style.padding]="'3px 0 3px 0'"
										(click)="$event.stopImmediatePropagation();selectAllAsset(topic, asset)"></i>
								</div>
							</div>
							<div [hidden]="!asset.isExpanded" class="asset-version-list pl-3">
								<div *ngFor="let version of asset.simpleVersions">
									<div class="version-info d-flex align-items-center item-label"
										[ngClass]="{ 'expanded': version.isExpanded }">
										<div class="d-flex align-items-center">
											<div class="mtm-icon mtm-icon-folder-closed"></div>
										</div>
										<span class="ml-2 p-2">{{version.name || version.versionName}}</span>
										<div class="ml-auto">
											<i class="fa fa-fw fa-check pointer"
												[ngClass]="{ 'selected': version?.isSelected }"
												[style.background-color]="'#FFFFFF'" [style.padding]="'3px 0 3px 0'"
												(click)="$event.stopImmediatePropagation();selectVersionAsset(topic, asset, version)"></i>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>