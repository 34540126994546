import { EventEmitter, Injectable } from '@angular/core';
import { ApiService } from "app/shared/services/api.service";
import { AuthService } from "app/shared/services/auth.service";
import { Observable } from "rxjs";
import { GetVersionFilesParams, ShotUpdateParams } from '../interfaces';

export let onElementReordered: EventEmitter<any> = new EventEmitter<any>();

@Injectable({
	providedIn: 'root'
})
export class ShootingBoardService {

	private sceneSelected: any;

	constructor(private api: ApiService, public auth: AuthService) { }

	public getShootingBoards(projectId: string, sectionId: string, subsectionId: string): Observable<any> {
		return this.api.setProfileAndImagesProbForItems(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/shootingShots`);
	}

	public getShootingBoardsWithSceneId(projectId: string, sectionId: string, subsectionId: string, sceneId: string): Observable<any> {
		return this.api.setProfileAndImagesProbForItems(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/shootingShots/scene/${sceneId}`);
	}

	public saveShootingBoard(projectId: string, sectionId: string, subsectionId: string, shootingBoard: any): Observable<any> {
		return this.api.httpPost(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/shootingShot`, shootingBoard);
	}

	public deleteShootingBoard(projectId: string, sectionId: string, subsectionId: string, shootingBoardId: string): Observable<any> {
		return this.api.httpDelete(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/shootingShot/${shootingBoardId}`);
	}

	public getShootingBoard(projectId: string, sectionId: string, subsectionId: string, shootingBoardId: string): Observable<any> {
		return this.api.httpGet(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/shootingShot/${shootingBoardId}`);
	}

	public getScenes(projectId: string, sectionId: string, subsectionId: string): Observable<any> {
		let endpoint = `/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/scenes`;
		return this.api.setProfileAndImagesProbForItems(endpoint);
	}

	public saveScene(projectId: string, sectionId: string, subsectionId: string, scene: any): Observable<any> {
		return this.api.httpPost(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/scene`, scene);
	}

	public renameScene(projectId: string, sectionId: string, subsectionId: string, sceneId: string, newName: string): Observable<any> {
		return this.api.httpPut(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/scene/${sceneId}?newName=${newName}`, {});
	}

	public deleteScene(projectId: string, sectionId: string, subsectionId: string, sceneId: string): Observable<any> {
		return this.api.httpDelete(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/scene/${sceneId}`);
	}

	public getScene(projectId: string, sectionId: string, subsectionId: string, sceneId: string): Observable<any> {
		return this.api.httpGet(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/scene/${sceneId}`);
	}

	public setSceneSelected(sceneSelected: any) {
		this.sceneSelected = sceneSelected;
	}

	public getSceneSelected() {
		return this.sceneSelected;
	}

	public getSimpleShots(projectId: string, sectionId: string, subsectionId: string): Observable<any> {
		const url = `/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-shootingShot-simple`;
		return this.api.httpGet(url);
	}

	public getVersionedShots(projectId: string, sectionId: string, subsectionId: string): Observable<any> {
		const url = `/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-shootingShots`;
		return this.api.httpGet(url);
	}

	public getVersionedShootingShots(projectId: string, sectionId: string, subsectionId: string, shootingShotId: string): Observable<any> {
		const url = `/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-shootingShot/${shootingShotId}`;
		return this.api.httpGet(url);
	}

	public getSimpleShot(projectId: string, sectionId: string, subsectionId: string, shootingShotId: string): Observable<any> {
		const url = `/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-shootingShot-simple/${shootingShotId}`;
		return this.api.httpGet(url);
	}

	public renameShootingShots(projectId: string, sectionId: string, subsectionId: string, shootingShotId: string, newName: string): Observable<any> {
		return this.api.httpPut(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-shootingShot/${shootingShotId}?newName=${newName}`, {});
	}

	public deleteVersionedshootingShot(projectId: string, sectionId: string, subsectionId: string, shootingShotId: string): Observable<any> {
		const url = `/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-shootingShot/${shootingShotId}`;
		return this.api.httpDelete(url);
	}

	public saveVersionedShootingshots(args: ShotUpdateParams): Observable<any> {
		let url = `/api/projects/${args.projectId}/sections/${args.sectionId}/subsections/${args.subsectionId}/v-shootingShot?topic=null&topicId=null`;
		/*
		const urlParams = [];
		if(args.topicid){
			urlParams.push('scene='+encodeURIComponent(args.topic));
		}
		if(args.sceneId){
			urlParams.push('sceneId='+args.sceneId);
		}
		const queryString = urlParams.join('&');
		if(queryString){
			url+= '?'+ queryString;
		}
		 */
		return this.api.httpPost(url, args.shots);
	}

	public getVersion(args: GetVersionFilesParams): Observable<any> {
		let url = `/api/projects/${args.projectId}/sections/${args.sectionId}/subsections/${args.subsectionId}/` +
			`v-shootingShot/${args.itemId}/${args.versionNumber}`;
		return this.api.httpGet(url);
	}
}
