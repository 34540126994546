import { Component, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { EMPTY_ID } from "app/shared/components/task/task-status.const";
import { AuthService } from "app/shared/services/auth.service";
import { CryptoService } from "app/shared/services/crypto.service";
import { EmitterService } from "app/shared/services/emitter.service";
import { MtmBreadcrumbService } from "app/shared/services/mtm-breadcrumb.service";
import { Authority, PermissionService } from "app/shared/services/permissions.service";
import { ProjectV2ServiceService } from "app/shared/services/project-v2-service.service";
import { onLoadProject, ProjectService } from "app/shared/services/project.service";
import { TimelineService } from "app/shared/services/timeline.service";
import { WorkspaceService } from "app/shared/services/workspace.service";
import { environment } from "environments/environment";
import { Subject, takeUntil } from "rxjs";
import { GoogleAnalyticsService } from "app/kanban/core/services/google-analytics.service"

@Component({
    selector: 'mtm-project-asset-list',
    templateUrl: './project-asset-list.component.html',
    styleUrls: ['./project-asset-list.component.scss']
})
export class ProjectAssetListComponent implements OnInit, OnChanges, OnDestroy {

    ngUnsubscribe = new Subject();
    assetBundle: any = null;
    timelineData: any;
    isCpd: boolean = false;
    authUser: any;
    assetStar: any;
    selectedAssets: any = [];
    project: any;
    workspaceId: string;
    campaignId: string;
    hasLtgPermission: boolean;
    workspaceName: string;

    constructor(
        private service: ProjectService,
        private permissionService: PermissionService,
        private authService: AuthService,
        private projectV2ServiceService: ProjectV2ServiceService,
        private timelineService: TimelineService,
        private router: Router,
        private cryptoService: CryptoService,
        private googleAnalyticsService: GoogleAnalyticsService,
        private breadcrumbService: MtmBreadcrumbService,
        private workspaceService: WorkspaceService,
        private route: ActivatedRoute,
    ) {
        onLoadProject.pipe(
            takeUntil(this.ngUnsubscribe)
        ).subscribe((project) => {
            this.project = project;
            this.hasLtgPermission = JSON.parse(localStorage.getItem('hasLtgPermission'));
            if (!this.hasLtgPermission) {
                if (!this.permissionService.hasAuthority(Authority.U)) {
                    this.router.navigate(['projects', project.id]);
                }
            } else {
                if (this.project.assetList?.length) {
                    this.selectedAssets = this.project.assetList;
                    this.workspaceId = this.project.assetList[0].workspaceId;
                    this.campaignId = this.project.assetList[0].campaignId;
                } else {
                    this.workspaceId = this.project.workspaceId;
                    this.campaignId = this.project.campaignId;
                }
                if (this.isCpd && this.campaignId && this.workspaceId) {
                    this.getTimeline();
                }
            }
            this.retrieveExistingWorkspaceName();
        })
    }

    ngOnInit(): void {
        this.hasLtgPermission = JSON.parse(localStorage.getItem('hasLtgPermission'));
        this.authUser = this.authService.getAuthUser();
        this.isCpd = this.authUser.companyId == environment.ltg.cpdCompanyId;
        if (!this.service.project?.id || !this.hasLtgPermission) {
            if (!this.permissionService.hasAuthority(Authority.U)) {
                this.router.navigate(['projects', this.service.project.id]);
            }
        } else {
            this.project = this.service.project;
            if (this.project.assetList?.length) {
                this.selectedAssets = this.project.assetList;
                this.workspaceId = this.project.assetList[0].workspaceId;
                this.campaignId = this.project.assetList[0].campaignId;
            } else {
                this.workspaceId = this.project.workspaceId;
                this.campaignId = this.project.campaignId;
            }
            if (this.isCpd && this.campaignId && this.workspaceId) {
                this.getTimeline();
            }
            this.retrieveExistingWorkspaceName();
        }
    }

    ngOnChanges(changes: SimpleChanges): void { }

    ngOnDestroy(): void {
        this.breadcrumbService.clearLinks();
        this.ngUnsubscribe.next(undefined);;
        this.ngUnsubscribe.complete();
    }

    retrieveExistingWorkspaceName() {
        if (this.project && this.project.company && this.project.workspaceId) {
            this.workspaceService.getWorkspaces(this.project.company.id)
                .subscribe(workspaces => {
                    const existing = workspaces.find(w => w.id == this.project.workspaceId);
                    if (!existing && !this.hasLtgPermission) {
                        this.router.navigate(['/projects']);
                        return;
                    }
                    if (existing && existing.name) {
                        this.workspaceName = existing.name;
                    }
                    this.setBreadcrumb();
                });
        }
    }

    assetBundleUpdated(e) {
        this.timelineData.assetList = JSON.stringify(e);
        this.projectV2ServiceService.createTimeline(this.timelineData).pipe(
            takeUntil(this.ngUnsubscribe)
        ).subscribe({
            next: (result: any) => {
            }
        });
    }

    async getTimeline() {
        if (!this.isCpd || !this.workspaceId || !this.campaignId) {
            return;
        }
        try {
            const driveCampaign = await this.timelineService.getTimeLine(this.workspaceId, this.campaignId);
            if (driveCampaign) {
                this.timelineData = driveCampaign;
                const criteria = JSON.parse(this.timelineData.criteria);
                this.assetBundle = JSON.parse(this.timelineData.assetList);
                this.assetStar = criteria['Asset Bundle'];
            }
        } catch (error) {
            this.assetBundle = [];
            console.error('An error occurred while fetching timeline data:', error);
        }
    }

    assetBundleSelected(e) {
        this.selectedAssets = JSON.parse(JSON.stringify(this.assetBundle.filter(a => e.indexOf(a.id) > -1)));
        for (let i = 0; i < this.selectedAssets?.length; i++) {
            this.selectedAssets[i].campaignId = this.campaignId;
            this.selectedAssets[i].milestoneId = this.project.milestoneId || EMPTY_ID;
            this.selectedAssets[i].workspaceId = this.workspaceId;
            this.selectedAssets[i].assetListId = this.selectedAssets[i].id;
            const asset = this.project.assetList.find(a => a.assetListId == this.selectedAssets[i].id);
            this.selectedAssets[i].status = asset?.status || 'TO_DO';
        }
        if (this.project?.id) {
            this.saveProject();
        }
    }

    saveProject(): void {
        let selectedAssets = [];
        const brand = JSON.parse(localStorage.getItem('brand'));
        if (this.selectedAssets?.length) {
            for (let i = 0; i < this.selectedAssets.length; i++) {
                selectedAssets.push({
                    assetListId: this.selectedAssets[i].assetListId,
                    brandId: brand.id,
                    campaignId: this.campaignId,
                    milestoneId: this.project.milestoneId || EMPTY_ID,
                    projectId: this.service.project?.id || this.selectedAssets[i].projectId,
                    workspaceId: this.workspaceId,
                    status: this.selectedAssets[i].status
                });
            }
        }
        this.project.assetList = selectedAssets;
        this.service.saveProject(this.project, this.isCpd).pipe(
            takeUntil(this.ngUnsubscribe)
        ).subscribe({
            next: () => {
                EmitterService.get('PROJECT_ASSET_LINKED').emit();
            }
        })
    }

    setBreadcrumb() {
        let links = [];
        const hasLtgPermission = this.authService.checkLtgPermission();
        if (hasLtgPermission) {
            const brand = this.authService.getMyBrand();
            if (brand) {
                links.push({
                    label: brand.name
                })
            }
            if (this.workspaceId) {
                links.push({
                    label: 'Workspaces',
                    url: ['/workspaces']
                });
                links.push({
                    label: this.workspaceName,
                    url: ['/workspaces', 'campaign-drive'],
                    queryParams: {
                        wid: this.cryptoService.encryptString(this.workspaceId)
                    }
                });
            }
            links.push({
                label: this.isCpd ? 'Campaigns & Projects' : 'Drive Campaigns & Projects',
                url: ['/workspaces', 'campaign-drive'],
                queryParams: {
                    wid: this.cryptoService.encryptString(this.workspaceId)
                }
            });
            if (this.workspaceName)
                this.googleAnalyticsService.sendUserAnalyticsConfig('', this.workspaceName,this.project?.name);
        } else {
            const activeWorkspace = this.workspaceService.getActiveWorkspace();
            links.push({
                label: 'Workspaces',
                url: ['/workspaces']
            });
            links.push({
                label: activeWorkspace.name,
                url: ['/projects']
            });
            if (activeWorkspace.name)
                this.googleAnalyticsService.sendUserAnalyticsConfig('', activeWorkspace.name,this.project?.name);

        }

        links.push({
            label: !this.project?.id ? 'New Project' : this.project.name,
            url: ['projects', 'edit', this.project.id, 'company'],
            queryParams: {
                workspaceId: this.workspaceId,
                dcId: this.campaignId,
                isdc: true
            }
        })

        links.push({
            label: 'Add Asset'
        })
        this.breadcrumbService.setLinks(links);
    }

    returnToProject() {
        this.router.navigate(['projects', this.service.project.id]);
    }

    continue() {
        this.router.navigate(['projects', 'edit', this.service.project.id, 'assets']);
    }
}