import { AfterViewInit, ChangeDetectorRef, Component, inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from "@angular/core";
// import { l } from "@angular/core/src/render3";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { AgGridAngular } from "ag-grid-angular";
import { ColDef } from "ag-grid-community";
import { CompanyRolesAndPermissionComponent } from "app/shared/components/company-roles-and-permissions/company-roles-and-permissions.component";
import { TranslatePipe } from "app/shared/pipes/translate.pipe";
import { EmitterService } from "app/shared/services/emitter.service";
import { HelperService } from "app/shared/services/helper.service";
import { OverlayService } from "app/shared/services/overlayService";
import { Authority, PermissionService } from "app/shared/services/permissions.service";
import { activeLanguageUpdated } from "app/shared/services/translate.service";
import { UserService } from "app/shared/services/user.service";
import { WorkspaceService } from "app/shared/services/workspace.service";
import { UserWorkspaceRole } from "app/workspace/models/workspace.model";
import { Subject } from "rxjs";
import { forkJoin, zip } from "rxjs";
import { finalize, takeUntil } from "rxjs/operators";
import { AuthService } from "../../../../shared/services/auth.service";
import { NewWorkspaceUserComponent } from "../new-user/new-workspace-user.component";
import { UserPermissionTableHeader } from "./table/header/user-permission-table-header.component";
import { userPermissionColumnsDef } from "./table/user-permission-table.coldef";
import { WithPendingGuard } from "app/shared/decorators";
import { TimelineService } from "app/shared/services/timeline.service";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { BreadcrumbService } from 'ng5-breadcrumb';
import { MtmBreadcrumbLink, MtmBreadcrumbService } from 'app/shared/services/mtm-breadcrumb.service';
import { ActiveWorkspaceUpdatedEvent, HeaderInfo, WorkspaceAvatarUpdatedEvent, WorkspaceCoverUpdatedEvent, WorkspaceOnSaveEvent, WorkspaceUpdatedEvent } from 'app/shared/interfaces';
import { CryptoService } from 'app/shared/services/crypto.service';
import { WorkspaceEditHeaderComponent } from "../header/header.component";
import { UploadService } from "app/shared/services/upload.service";
import { ApiService } from "app/shared/services/api.service";
import { UploadModalComponent } from "app/on-boarding/profile-avatar/upload-modal/upload-modal.component";
import { UploadFileComponent } from "app/shared/components/mtm-upload-file/upload-file.component";
import { ThemeService } from "app/shared/services/theme.service";
import { GoogleAnalyticsService } from "app/kanban/core/services/google-analytics.service"
import { noHtmlValidator } from "app/shared/validators/nohtml.validator";
@Component({
	selector: 'mtm-edit-workspace-permissions',
	templateUrl: './workspace-permissions.component.html',
	styleUrls: ['./workspace-permissions.component.scss']
})
export class EditWorkspacePermissionsComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild(AgGridAngular) userPermissionTable!: AgGridAngular;
	@Input() setBreadcrumb: () => void;
	@ViewChild("avatarUploader", { static: false }) avatarUploader: UploadFileComponent;
	@ViewChild("coverUploader", { static: false }) coverUploader: UploadFileComponent;
	@Input() owner: string;

	private _workspaceId: string = '';
	_workspace: any = null;
	workspaceName: string;
	private workspaces: any[] = null;
	private allRoles: any[] = [];
	private availableRoles: any[] = [];
	private companyUsers: any[];
	private rolesToExclude: Set<string> = new Set();
	private newModalRef: NgbModalRef;
	private ngUnsubscribe = new Subject();
	users: UserWorkspaceRole[] = [];
	filteredUsers: UserWorkspaceRole[] = [];
	firstNameFilter: string = '';
	lastNameFilter: string = '';
	emailFilter: string = '';
	sortedColumn: string = '';
	sortAscending: boolean = true;
	rowFiltered: boolean = false;
	isPending: boolean = false;
	public timelineService = inject(TimelineService);
	private companyUsersList: any[];
	cacheBuster: any = new Date().getTime().toString();

	activationStatuses = [
		{ code: 'ACTIVE_COMPLETED', label: 'Active Completed' },
		{ code: 'PENDING_INPROGRESS', label: 'Pending In Progress' }
	];

	// get workspace(): any {
	// 	return this._workspace;
	// }

	// @Input()
	// set workspace(value: any) {
	// 	this._workspace = value;
	// 	if (value) {
	// 		console.log('value', value)
	// 		if (value.avatarUrl)
	// 			this.avatarUrl = HelperService.getCachebusterUrl(value.avatarUrl);
	// 		if (value.coverUrl)
	// 			this.coverUrl = HelperService.getCachebusterUrl(value.coverUrl);
	// 	}

	// }

	get workspaceId(): string {
		return this._workspaceId;
	}

	@Input()
	set workspaceId(value: string) {
		if (value) {
			this._workspaceId = value;
			this.loadWorkspace();
		}
	}
	// @Input() workspaceId: string;

	private getRoleOrder(code: string): number {
		switch (code) {
			case 'COMPANY_PRINCIPAL':
				return 1;
			case 'COMPANY_REGULAR':
				return 3;
			default:
				return 2;
		}
	}

	@Input()
	set roles(value: any[]) {
		this.allRoles = value;
		this.initRoleSet();
		let isAdminUser = this.permissionService.hasAuthority(Authority.Z, null);
		let rolesToShow = value.filter(r => (r.code.startsWith('COMPANY') && !this.rolesToExclude.has(r.code)) || (isAdminUser && r.code === 'ADMIN'));
		rolesToShow.sort((a: any, b: any) => (this.getRoleOrder(a.code) < this.getRoleOrder(b.code) ? -1 : 1));
		this.availableRoles = rolesToShow;
		this.initUsers();
	}

	defaultColDef: ColDef = {
		sortable: true,
		resizable: true,
		filter: true,
		headerValueGetter: this.getHeaderText.bind(this),
	};

	userPermissionColumnsDef = userPermissionColumnsDef;
	tableContext: any;
	public components: { [p: string]: any } = { agColumnHeader: UserPermissionTableHeader, };

	selectedRoles: any = [];
	headerForm: UntypedFormGroup = null;
	avatarUrl: string = '';
	coverUrl: string = '';
	submitted: boolean = false;
	private uploadModal: NgbModalRef;

	constructor(private authService: AuthService,
		private workspaceService: WorkspaceService,
		private permissionService: PermissionService,
		private overlayService: OverlayService,
		private modalService: NgbModal,
		private translatePipe: TranslatePipe,
		private fb: UntypedFormBuilder,
		private cd: ChangeDetectorRef,
		private breadcrumbService: BreadcrumbService,
		private mtmBreadcrumbService: MtmBreadcrumbService,
		private cryptoService: CryptoService,
		private googleAnalyticsService: GoogleAnalyticsService,
		private userService: UserService,
		private uploadService: UploadService,
		private apiService: ApiService,
		private themeService: ThemeService) {
		this.tableContext = {
			componentParent: this
		};
	}

	private loadWorkspace() {
		const authUser = this.authService.getAuthUser();

		// Create observables for both API calls
		const workspace$ = this.workspaceService.getWorkspaceById(authUser.companyId, this._workspaceId, true, {
			redirectOnForbidden: true
		});
		const users$ = this.userService.getUserByWorkspacId(authUser.company.id, this._workspaceId);
		const companyUserData$ = this.userService.getUsersByCompanyId(authUser.company.id);

		// Use forkJoin to run both in parallel
		forkJoin([workspace$, users$, companyUserData$])
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe({
				next: ([workspace, users, companyUserList]) => {
					console.log('users', users)
					console.log('workspace', workspace)

					// Handle workspace data
					this.workspaces = [workspace];
					this._workspace = workspace;
					if (this._workspace.coverUrl)
						this._workspace.coverUrl = this._workspace?.coverUrl + '?v=' + this.cacheBuster;
					if (this._workspace.avatarUrl)
						this._workspace.avatarUrl = this._workspace?.avatarUrl + '?v=' + this.cacheBuster;
					this.workspaceName = this._workspace.name;
					this.headerForm.patchValue({
						name: this._workspace.name
					});

					// Handle users data
					this.companyUsers = users;
					this.companyUsersList = companyUserList;

					this.initUsers();

					this.cd.detectChanges();
				},
				error: (err) => {
					console.error('Error fetching data', err);
				}
			});
	}


	private initRoleSet() {
		if (this.rolesToExclude.size > 0)
			return;

		if (this.rolesToExclude.size == 0) {
			if (!this.permissionService.hasAuthority(Authority.S, null))
				this.rolesToExclude.add('COMPANY_PRINCIPAL');

			this.rolesToExclude.add('COMPANY_OCCASIONAL');
		}
		const authUser = this.authService.getAuthUser();
		this.rolesToExclude.add(authUser.company.companyType == 'ADVERTISER' ? 'COMPANY_PRODUCTION_OWNER' : 'COMPANY_PROJECT_OWNER');
	}

	ngOnInit() {
		this.googleAnalyticsService.sendUserAnalyticsConfig('', '', '');
		this.cacheBuster = new Date().getTime().toString();
		this.initRoleSet();
		const authUser = this.authService.getAuthUser();
		// this.userService.getUsersByCompanyId(authUser.company.id)
		// 	.subscribe(data => {
		// 		this.companyUsersList = data;
		// 		// this.initUsers();
		// 	});

		activeLanguageUpdated
			.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
				setTimeout(() => this.userPermissionTable?.api?.refreshHeader(), 100);
			});
		this.initForm();

		if (this.setBreadcrumb) {
			this.setBreadcrumb();
		}

		EmitterService.get(WorkspaceOnSaveEvent).pipe(takeUntil(this.ngUnsubscribe)).subscribe((result: any) => {
			this.saveWorkspace();
		});
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.userPermissionTable?.api?.refreshHeader();
		}, 100);
	}

	ngOnDestroy() {
		this.mtmBreadcrumbService.setLinks([]);
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	private initForm() {
		this.headerForm = this.fb.group({
			'name': [this.workspaceName ? this.workspaceName : '', [Validators.required, noHtmlValidator]]
		})
	}

	isValid(field: string): boolean {
		const control = this.headerForm?.get(field);
		return (control?.invalid || control?.hasError('noHtmlValidator')) && (control?.touched || this.submitted);
	}

	isRoleChangable(code: string, username: string): { shouldChange: boolean, canChange: boolean } {
		const shouldChange = !code || code.startsWith('PROJECT') || code.startsWith('PRODUCTION')
			|| code == 'COMPANY_OCCASIONAL';
		let canChange = shouldChange || (code.startsWith('COMPANY') && code != 'COMPANY_PRINCIPAL');
		if (canChange) {
			//check if user is part of multiple company workspaces
			//if yes then cant
			const involvedWorkspaces = this.workspaces.filter(w => Array.isArray(w.usernames) && w.usernames.indexOf(username) > -1);
			if (involvedWorkspaces.length > 1) {
				canChange = false;
			}
		}

		return {
			shouldChange,
			canChange
		};
	}

	private initUsers() {
		if (!this._workspace && !this.allRoles)
			return;

		this.users = [];
		if (this._workspace && this._workspace.usernames && this.companyUsers) {
			this._workspace.usernames.forEach(u => {
				const user = this.companyUsers.find(cu => cu.username == u);
				if (user != null) {
					const userRole = this.allRoles.find(r => r.code == user.globalRole);
					let roleLabel = userRole ? userRole.label : '';
					let roleCode = user.globalRole;
					const { shouldChange, canChange } = this.isRoleChangable(roleCode, user.username);
					if (shouldChange) {
						roleCode = '';
						roleLabel = '';
					}
					const statusInfo = this.activationStatuses.find(s => s.code == user.activationStatus);
					const userData = {
						user,
						fullName: user.fullName ? user.fullName.trim() : '',
						username: user.username,
						jobTitle: user.jobTitle,
						roleLabel: roleLabel,
						role: roleCode,
						canChange,
						status: statusInfo,
						firstName: user.firstName,
						lastName: user.lastName

					};
					this.users.push(userData);
				}
			});
			this.filterUsers();
		}
	}

	@WithPendingGuard
	removeUser(user) {
		if (!user || user.code == 'COMPANY_PRINCIPAL')
			return;
		const index = this.users.findIndex(u => u.username == user.username);
		if (index == -1)
			return;

		const authUser = this.authService.getAuthUser();
		this.isPending = true;
		this.workspaceService.removeUserFromWorkspace(authUser.company.id, this._workspace.id, user.username)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe({
				next: () => {
					this.isPending = false;
					this.users.splice(index, 1);
					this.filterUsers();
				},
				error: () => {
					this.isPending = false;
					this.overlayService.showError(this.translatePipe.transform('workspace_removeUserFailed'), 'Error');
				}
			});

	}

	showUserModal() {
		this.newModalRef = this.modalService.open(NewWorkspaceUserComponent, {
			size: 'lg',
			backdrop: 'static',
			keyboard: false
		});
		const instance = this.newModalRef.componentInstance;
		instance.workspaceRoles = this.availableRoles.filter(r => r.code != 'ADMIN');
		instance.projectRoles = this.allRoles.filter(r => r.code.startsWith('PROJECT') || r.code.startsWith('PRODUCTION'));
		instance.companyUsers = this.companyUsersList;
		instance.currentUsers = [...this.users];
		instance.workspaces = this.workspaces;
		this.newModalRef.result.then(result => {
			this.addNewResult(result);
			if (result) {
				this.loadWorkspace();
			};
		});
	}

	addNewResult(result: any) {
		const { username, role } = result.formData;

		const currentUser = this.users.find(u => u.username == username);
		if (currentUser != null)
			return;

		let selectedRole = this.allRoles.find(a => a.code == role);
		if (!selectedRole)
			return;

		let roleLabel = selectedRole.label;
		let newUser: UserWorkspaceRole = null;
		// if (result.userData.activationStatus === 'ACTIVE_COMPLETED') {
		// 	this.companyUsers.push(result.userData);
		// }
		this.companyUsers.push(result.userData);

		const companyUser = this.companyUsers.find(u => u.username == result.userData.username);
		if (companyUser) {
			//if existing company user,
			//if has global role and role isnt starting with PROJECT and PRODUCTION then change role
			//else keep his global role
			let newRole = role;
			let currentRole = companyUser.globalRole;
			let { canChange } = this.isRoleChangable(currentRole, companyUser.username);
			if (!canChange) {
				newRole = currentRole;
				selectedRole = this.allRoles.find(a => a.code == newRole);
				if (!selectedRole)
					return;
				roleLabel = selectedRole.label;
			}
			const statusInfo = this.activationStatuses.find(s => s.code == companyUser.activationStatus);

			newUser = {
				user: companyUser,
				fullName: companyUser.fullName,
				username,
				jobTitle: companyUser.jobTitle,
				roleLabel,
				role: newRole,
				canChange,
				status: statusInfo,
				firstName: companyUser.firstName,
				lastName: companyUser.lastName
			};
		} else {
			const statusInfo = this.activationStatuses.find(s => s.code == 'PENDING_INPROGRESS');
			newUser = {
				fullName: '',
				username,
				jobTitle: '',
				roleLabel,
				role,
				canChange: true,
				status: statusInfo,
				firstName: '',
				lastName: '',
			};
		}

		const authUser = this.authService.getAuthUser();
		this.workspaceService.addUserToWorkspace(authUser.company.id, this._workspace.id,
			newUser.username, newUser.role, result.defaultProjectRoles || [])
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(() => {
				this.users.push(newUser);
				this.filterUsers();
			}, () => {
				this.overlayService.showError(this.translatePipe.transform('workspace_addUserFailed'), 'Error');
			});
	}

	updateRole(role, user) {
		if (role == '')
			return;


		const authUser = this.authService.getAuthUser();
		this.workspaceService.addUserToWorkspace(authUser.company.id, this._workspace.id,
			user.username, role, [])
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe({
				next: () => {
					this.overlayService.showSuccess(this.translatePipe.transform('overlayChangeSave'), 'Success');
				},
				error: () => {
					this.overlayService.showError(this.translatePipe.transform('workspace_updateRoleFailed'), 'Error');
				}
			});
	}

	saveWorkspace() {
		// Will be called from edit-workspace due to save button being present there
		if (this.isPending) return;
		
		this.submitted = true;
		// Check if the form is valid
		if (this.headerForm.invalid) {
			// Mark all inputs as touched to trigger validation messages
			this.headerForm.markAllAsTouched();
			
			// Check for htmlNotAllowed error in any form control
			const hasHtmlError = Object.keys(this.headerForm.controls).some(key => 
				this.headerForm.controls[key].hasError('htmlNotAllowed')
			);

			// Show an error message to the user
			if (hasHtmlError) {
				this.overlayService.showError(this.translatePipe.transform('validationFailed'), 'Error');
			} else {
				this.overlayService.showError(this.translatePipe.transform('mandatoryFieldRequired'), 'Error');
			}
			return; 
		}

		const updatedData = Object.assign({}, this._workspace, this.headerForm.value);

		this.isPending = true;

		this.workspaceService.editWorkspace(updatedData.id, updatedData).pipe(
			finalize(() => this.isPending = false),
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (result: any) => {
				EmitterService.get(WorkspaceUpdatedEvent).emit(result);
				this._workspace.name = this.headerForm.value.name;
				this.overlayService.showSuccess(this.translatePipe.transform('overlayChangeSave'), 'Success');
			}, error: (error) => {
				let errorCode = error.errorCode;
				let errorMessage = errorCode == 'DUPLICATE_NAME' ? 'workspace_edit_duplicateError' : 'workspace_edit_error';
				this.overlayService.showError(
					this.translatePipe.transform(errorMessage, { name: updatedData.name }), 'Error');
			}
		});
	}


	save() {
		const authUser = this.authService.getAuthUser();
		const workspaceData = {
			companyId: authUser.company.id,
			name: this._workspace.name,
			userNames: this.users.map(u => u.username),
			projectIds: this._workspace.projectIds,
			id: this._workspace.id
		};

		const $obs = [];
		this.users.forEach(user => {
			$obs.push(this.workspaceService.addUserToWorkspace(authUser.company.id, this._workspace.id,
				user.username, user.role, []));
		});
		forkJoin([
			...$obs
		]).pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(() => {
				this.overlayService.showSuccess(this.translatePipe.transform('overlayChangeSave'), 'Success');
			});
	}

	showPermissionDetailsInfo() {
		this.modalService.open(CompanyRolesAndPermissionComponent, { backdrop: true, windowClass: 'company-roles-and-permission-modal' });
	}

	sortColumn(columnName) {
		if (this.sortedColumn != columnName) {
			this.sortAscending = true;
			this.sortedColumn = columnName;
		} else {
			this.sortAscending = !this.sortAscending;
		}
		this.filterUsers();
	}

	filterUsers() {
		this.filteredUsers = [...this.users];
		this.rowFiltered = !!(this.emailFilter || this.firstNameFilter || this.lastNameFilter || this.selectedRoles?.roles?.length);
		if (this.emailFilter || this.firstNameFilter || this.lastNameFilter || this.selectedRoles?.roles?.length) {
			this.filteredUsers = this.filteredUsers.filter(user => {
				let isMatched = true;

				if (this.emailFilter && isMatched) {
					if (!user.username) {
						isMatched = false;
					} else {
						isMatched = user.username.toLowerCase().indexOf(this.emailFilter.toLowerCase()) > -1;
					}
				}

				if (this.firstNameFilter && isMatched) {
					if (!user.firstName) {
						isMatched = false;
					} else {
						isMatched = HelperService.getNormalizedName(user.firstName).indexOf(HelperService.getNormalizedName(this.firstNameFilter)) > -1;
					}
				}

				if (this.lastNameFilter && isMatched) {
					if (!user.lastName) {
						isMatched = false;
					} else {
						isMatched = HelperService.getNormalizedName(user.lastName).indexOf(HelperService.getNormalizedName(this.lastNameFilter)) > -1;
					}
				}

				if (this.selectedRoles.roles?.length && isMatched) {
					if (!user.role) {
						isMatched = false;
					} else {
						isMatched = this.selectedRoles.roles.find(r => r == user.role);
					}
				}

				return isMatched;
			})
		}

		if (this.sortedColumn) {
			this.filteredUsers.sort((a, b) => {
				const multiplier = this.sortAscending ? 1 : -1;
				return (a[this.sortedColumn] || '').localeCompare((b[this.sortedColumn] || '')) * multiplier;
			});
		}
		console.log('this.filteredUsers', this.filteredUsers)
	}

	autoSizeColumnWidth() {
		this.userPermissionTable.columnApi.autoSizeAllColumns();
	}

	getHeaderText(params: any) {
		let headerText = '';

		switch (params.colDef.field) {
			case 'fullName':
				headerText = 'user';
				break;
			case 'username':
				headerText = 'email';
				break;
			case 'firstName':
				headerText = 'firstName';
				break;
			case 'lastName':
				headerText = 'lastName';
				break;
			case 'roleLabel':
				headerText = 'role';
				break;
			case 'action':
				headerText = 'action';
				break
		}

		return this.translatePipe.transform(headerText);
	}

	updateWorkspaceName() {
		this.workspaceService.formValue = this.headerForm;
	}

	private openModal() {
		this.uploadModal = this.modalService.open(UploadModalComponent, { size: 'lg' });
		this.uploadModal.componentInstance.setCrop(false);
	}

	editAvatar() {
		this.avatarUploader.upload();
	}

	editCover() {
		this.coverUploader.upload();
	}

	fileChangeListener($event, imageType: string = 'avatar'): void {
		const files = $event.files[0];
		if (files.length == 0)
			return;

		if (imageType === 'avatar') {
			this.openModal();
			this.uploadModal.componentInstance.imageFile = files;
		}

		const file = files;
		const fileReader = new FileReader();
		fileReader.onloadend = (loadEvent: any) => {
			if (imageType == 'cover') {
				this.setCoverImage(loadEvent.target.result);
			} else {
				this.setAvatarImageOnModal();
			}

		};
		fileReader.readAsDataURL(file);
	}

	private setCoverImage(result: string) {
		const authUser = this.authService.getAuthUser();
		this.coverUrl = result;
		this._workspace.coverUrl = result;
		if (this._workspace.coverUrl)
			this._workspace.coverUrl = this._workspace?.coverUrl + '?v=' + this.cacheBuster;
		const workspaceUrl = this.apiService.baseUrl + `/api/workspace/${authUser.company.id}/${this._workspace.id}/cover`;
		this.uploadService.uploadImage(result, workspaceUrl).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe((data: any) => {
			this.coverUrl = HelperService.getCachebusterUrl(data.coverUrl);
			this._workspace.coverUrl = this.coverUrl
			this.workspaceService.setActiveWorkspaceCoverAndAvatar({
				cover: data.coverUrl
			})
			EmitterService.get(WorkspaceCoverUpdatedEvent).emit(this._workspace.coverUrl);
		});
	}

	private setAvatarImageOnModal() {
		this.uploadModal.componentInstance.uploadObserver.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe((result) => {
			if (result) {
				const authUser = this.authService.getAuthUser();
				this.avatarUrl = result;
				this._workspace.avatarUrl = result;
				if (this._workspace.coverUrl)
					this._workspace.avatarUrl = this._workspace?.avatarUrl + '?v=' + this.cacheBuster;
				const workspaceUrl = this.apiService.baseUrl + `/api/workspace/${authUser.company.id}/${this._workspace.id}/avatar`;

				this.uploadService.uploadImage(result, workspaceUrl).pipe(
					takeUntil(this.ngUnsubscribe)
				).subscribe((data: any) => {
					this.avatarUrl = HelperService.getCachebusterUrl(data.avatarUrl);
					this._workspace.avatarUrl = this.avatarUrl;
					const headerUpdate: Partial<HeaderInfo> = {};
					headerUpdate.avatarImage = HelperService.getCachebusterUrl(data.avatarUrl);
					this.themeService.updateHeaderInfo(headerUpdate);


					EmitterService.get(WorkspaceAvatarUpdatedEvent).emit({
						workspaceId: result.id,
						avatarUrl: HelperService.getCachebusterUrl(data.avatarUrl)
					});
					this.workspaceService.setActiveWorkspaceCoverAndAvatar({
						avatar: HelperService.getCachebusterUrl(data.avatarUrl)
					})
				});

			}
		});
	}

	checkOwner(user: any): boolean {
		// Ensure owners array exists and then check if the inviter is included
		if (this._workspace.createdBy === user) {
			// Check if the owner's email is the same as the user's email
			return false
		}
		return true; // Return false if the owners array doesn't exist
	}


}
