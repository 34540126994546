import { Injectable } from '@angular/core';
import { ApiService } from "app/shared/services/api.service";
import { AuthService } from "app/shared/services/auth.service";
import { Subject, Observable } from "rxjs";
import { SetDesignUpdateParams } from '../interfaces';

@Injectable({
	providedIn: 'root'
})
export class SetDesignService {
	private tempFiles: any[] = null;

	constructor(private api: ApiService, public auth: AuthService) { }
	public storeTempFiles(data: any[]) {
		this.tempFiles = data;
	}

	public retrieveTempFiles(): any[] {
		const files = this.tempFiles;
		this.tempFiles = [];
		return files;
	}
	public getSettings(projectId: string, sectionId: string, subsectionId: string): Observable<any> {
		let endpoint = `/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/designs`;
		return this.api.setProfileAndImagesProbForItems(endpoint);
	}

	public getArticleCategories(projectId: string, sectionId: string, subsectionId: string): Observable<any> {
		return this.api.setProfileAndImagesProbForItems(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/set-design-article-categories`);
	}

	public getArticles(projectId: string, sectionId: string, subsectionId: string): Observable<any> {
		return this.api.setProfileAndImagesProbForItems(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/designArticles`);
	}

	public saveSetting(projectId: string, sectionId: string, subsectionId: string, design: any): Observable<any> {
		return this.api.httpPost(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/designs`, design);
	}

	public saveArticleCategory(projectId: string, sectionId: string, subsectionId: string, articleCategory: any): Observable<any> {
		return this.api.httpPost(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/set-design-article-categories`, articleCategory);
	}

	public renameSetDesignArticleCategory(projectId: string, sectionId: string, subsectionId: string, setDesignId: string, newName: string): Observable<any> {
		return this.api.httpPut(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/set-design-article-categories/${setDesignId}?newName=${newName}`, {});
	}

	public renameDesign(projectId: string, sectionId: string, subsectionId: string, setDesignId: string, newName: string): Observable<any> {
		return this.api.httpPut(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/designs/${setDesignId}?newName=${newName}`, {});
	}

	public saveArticle(projectId: string, sectionId: string, subsectionId: string, design: any): Observable<any> {
		return this.api.httpPost(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/designArticles`, design);
	}

	public deleteSetting(projectId: string, sectionId: string, subsectionId: string, settingId: string): Observable<any> {
		return this.api.httpDelete(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/designs/${settingId}`);
	}

	public deleteArticleCategory(projectId: string, sectionId: string, subsectionId: string, id: string): Observable<any> {
		return this.api.httpDelete(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/set-design-article-categories/${id}`);
	}

	public deleteArticle(projectId: string, sectionId: string, subsectionId: string, designId: string): Observable<any> {
		return this.api.httpDelete(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/designArticles/${designId}`);
	}

	public getSetting(projectId: string, sectionId: string, subsectionId: string, settingId: string): Observable<any> {
		return this.api.httpGet(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/designs/${settingId}`);
	}

	public getArticleCategory(projectId: string, sectionId: string, subsectionId: string, id: string): Observable<any> {
		return this.api.httpGet(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/set-design-article-categories/${id}`);
	}

	public getArticle(projectId: string, sectionId: string, subsectionId: string, designId: string): Observable<any> {
		return this.api.httpGet(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/designArticles/${designId}`);
	}

	public saveVersionedArticle(args: SetDesignUpdateParams): Observable<any> {
		let url = `/api/projects/${args.projectId}/sections/${args.sectionId}/subsections/${args.subSectionId}/v-designArticles`;
		const urlParams = [];
		if (args.topic) {
			urlParams.push('topic=' + encodeURIComponent(args.topic));
		}
		if (args.topicid) {
			urlParams.push('topicId=' + args.topicid);
		}
		const queryString = urlParams.join('&');
		if (queryString) {
			url += '?' + queryString;
		}
		return this.api.httpPost(url, args.setDesign);
	}

	public getVersionedArticle(projectId: string, sectionId: string, subsectionId: string, articleId: string): Observable<any> {
		const url = `/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-designArticles/${articleId}`;
		return this.api.httpGet(url);
	}

	public renameDesignArticle(projectId: string, sectionId: string, subsectionId: string, articleId: string, newName: string): Observable<any> {
		return this.api.httpPut(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-designArticles/${articleId}?newName=${newName}`, {});
	}

	public getSimpleArticle(projectId: string, sectionId: string, subsectionId: string, designId: string): Observable<any> {
		const url = `/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-designArticles-simple/${designId}`;
		return this.api.httpGet(url);
	}

	public getVersionedArticles(projectId: string, sectionId: string, subsectionId: string): Observable<any> {
		return this.api.setProfileAndImagesProbForItems(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-designArticles`);
	}

	public deleteVersionedArticle(projectId: string, sectionId: string, subsectionId: string, siteId: string): Observable<any> {
		const url = `/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-designArticles/${siteId}`;
		return this.api.httpDelete(url);
	}

	public getSimpleArticles(projectId: string, sectionId: string, subsectionId: string): Observable<any> {
		const url = `/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-designArticles-simple`;
		return this.api.httpGet(url);
	}

}
