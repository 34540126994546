<ng-template ngbModalContainer></ng-template>
<mtm-banner [bannerType]="bannerTypes.MaintenanceOnly"></mtm-banner>
<div class="p-3 d-lg-flex p-xl-4  login-container">
	<aside class="d-flex flex-column align-items-center justify-content-between
	    rounded-xl py-3 mb-4 mb-lg-0 intro-column">
		<div class="row pointer	" [style.gap]="'5px'">
			<span [ngStyle]="{ color: selectedLanguage === 'en_us'? '#ffffff' : '#898D98' }" class="f-14 _700"
				(click)="setLanguage('en_us')">
				EN
			</span>
			<span [ngStyle]="{ color: selectedLanguage === 'fr' ? '#ffffff' : '#898D98' }" class="f-14 _700"
				(click)="setLanguage('fr')">
				FR
			</span>
		</div>
		<div class="d-flex flex-column align-items-center justify-content-center flex-grow-1 logo-container">
			<img ngSrc="/assets/img/login/login-logo-white.svg" width="134" height="112" alt="MTM Logo" class="mb-4" />

			<div class="motto-desc">
				<span class="mr-1 mr-sm-0">{{'login_mottoDescription1' | translate}}</span>
				<span>{{'login_mottoDescription2' | translate}}</span>
			</div>

			<h2 class="fw-700 motto">{{'entry_aside_motto' | translate}}</h2>
		</div>

		<div class="text-center copyright">©{{year}} {{'entry_aside_copyright' | translate}} MASTER THE MONSTER</div>

	</aside>


	<div class="container main-container">
		<form class="personal-info-form mx-auto" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
			<h2 class="form-title _700">{{'profileUpdate_title' | translate }}</h2>
			<p class="form-subtitle f-14 _400">{{'profileUpdate_subtitle'| translate}}</p>

			<div class="row">
				<!-- Name -->
				<div class="form-group col-md-6 col-12">
					<label for="firstName">{{'firstName' | translate }}</label>
					<input type="text" id="firstName" formControlName="firstName" (keypress)="restrictInput($event)"
						[placeholder]="'firstName' | translate" class="form-control"
						[ngClass]="{'form-control-danger': formGroup.controls.firstName.invalid && submitted}" />
					<!-- <span *ngIf="!(submitted && formGroup.controls.firstName.invalid)">{{'firstName' | translate }}</span>
			 <span *ngIf="submitted && formGroup.controls.firstName.invalid" class='text-danger'>{{'profileUpdate_invalidFirstName' | translate }}</span> -->
				</div>
				<!-- Last Name -->
				<div class="form-group col-md-6 col-12">
					<label for="lastName">{{'lastName' | translate }}</label>
					<input type="text" id="lastName" formControlName="lastName" (keypress)="restrictInput($event)"
						[placeholder]="'lastName' | translate" class="form-control"
						[ngClass]="{'form-control-danger': formGroup.controls.lastName.invalid && submitted}" />
					<!-- <span *ngIf="!(submitted && formGroup.controls.lastName.invalid)">{{'lastName' | translate }}</span>	
				<span *ngIf="submitted && formGroup.controls.lastName.invalid" class='text-danger'>{{'profileUpdate_invalidLastName'|translate}}</span> -->
				</div>
			</div>

			<div class="row">
				<!-- Company -->
				<div class="form-group col-md-6 col-12">
					<label for="Company">{{'Company' | translate}}</label>
					<input type="text" id="Company" class="form-control" type="text" style="color: #fff !important;"
						[style.background]="'#637EA3'" formControlName="company_name" placeholder=" " readonly
						[ngClass]="{'form-control-danger': formGroup.controls.company_name.invalid && submitted}">
					<!-- <span *ngIf="!(submitted && formGroup.controls.company_name.invalid)">{{'Company' | translate}}</span>
		  <span *ngIf="submitted && formGroup.controls.company_name.invalid" class='text-danger'>{{'profileUpdate_invalidCompany'|translate}}</span> -->
				</div>
				<!-- Job Title -->
				<div class="form-group col-md-6 col-12">
					<label for="jobTitle">{{'jobTitle' | translate }}</label>
					<input type="text" id="jobTitle" formControlName="job_title" [placeholder]="'jobTitle' | translate"
						class="form-control"
						[ngClass]="{'form-control-danger': formGroup.controls.job_title.invalid && submitted}"
						(keypress)="restrictWhiteSpace($event)" />
				</div>
			</div>

			<div class="row">
				<!-- Phone -->
				<div class="form-group col-md-6 col-12">
					<label for="phone">{{'Phone' | translate }}</label>
					<selector-mtm-tel-input [isSignup]="true" [cardForm]=formGroup [cardFormKey]="'phone'" #phoneInput>
					</selector-mtm-tel-input>
					<!-- <input type="text" id="phone" placeholder="+33 658789878" class="form-control" /> -->
				</div>
				<!-- Email -->
				<div class="form-group col-md-6 col-12">
					<label for="email">{{'Email' | translate }}</label>
					<input type="email" id="email" formControlName="email" [placeholder]="'Email' | translate" readonly
						class="form-control" />
				</div>
			</div>

			<div class="row">
				<!-- Password -->
				<div class="form-group col-md-6 col-12">
					<label for="password">{{'password' | translate }}</label>
					<div class="position-relative">
						<input type="password" id="password" formControlName="password"
							placeholder="{{'password' | translate}}" passwordToggle class="form-control pr-5"
							[ngClass]="{'form-control-danger': formGroup.controls.password.invalid && submitted}" />
					</div>
				</div>
				<!-- Confirm Password -->
				<div class="form-group col-md-6 col-12">
					<label for="confirmPassword">{{'repeatPassword' | translate }}</label>
					<div class="position-relative">
						<input type="password" id="confirmPassword" formControlName="rePassword"
							placeholder="{{'password' | translate}}" passwordToggle class="form-control pr-5"
							[ngClass]="{'form-control-danger': formGroup.controls.rePassword.invalid && submitted}" />
					</div>
				</div>
			</div>

			<div class="password-generator mb-lg-5 mb-5 mb-sm-0 mb-md-0">
				<!-- <label class="f-14 _400" for="randomPassword" [style.color]="'#637EA3'">Generate random password:</label> -->
				<!-- <div class="d-flex flex-column flex-md-row align-items-center" [style.gap]="'10px'">
				<input type="text" id="randomPassword" class="form-control generated-password mb-2 mb-md-0" [value]="generatedPassword" readonly />
				
				<select class="form-select w-auto ms-md-2 mb-2 mb-md-0" [(ngModel)]="selectedLength" (change)="updatePasswordPattern($event)">
					<option [value]="64">64 car.</option>
					<option [value]="94">94 car.</option>
					<option [value]="128">128 car.</option>
					<option [value]="256">256 car.</option>
				  </select>
				
				<button type="button" class="reload-btn btn btn-outline-none ms-md-2" (click)="generatePassword()"> 
				  <img src="../../../assets/svg/redo.svg" width="21" height="21" alt="">
				</button>
				
				<button type="button" class="reload-btn btn btn-outline-none ms-md-2" (click)="copyPassword()"> 
				  <img src="../../../assets/svg/copy.svg" width="21" height="21" alt="">
				</button>
			  </div>
			  <div class="d-flex flex-wrap w-100 mt-2" [style.gap]="'5px'">
				<div class="col" [ngClass]="{'bg-success': passwordStrength >= 1, 'bg-weak': passwordStrength < 1}"></div>
				<div class="col" [ngClass]="{'bg-success': passwordStrength >= 2, 'bg-weak': passwordStrength < 2}"></div>
				<div class="col" [ngClass]="{'bg-success': passwordStrength >= 3, 'bg-weak': passwordStrength < 3}"></div>
				<div class="col" [ngClass]="{'bg-success': passwordStrength >= 4, 'bg-weak': passwordStrength < 4}"></div>
			  </div> -->
				<mtm-password-checker [isRegistration]="true" [companyId]="companyId" class="w-100"
					[(minPassLength)]="minPassLength" [showValidation]="formGroup.controls.password.dirty"
					[password]="formGroup.controls.password.value"
					(passwordValidated)="passwordValidated($event)"
					(minPassLengthChange)="minPassLengthChange($event)"
					></mtm-password-checker>
				<mtm-password-strength-meter [isRegistration]="true" class="w-100"
					[passwordToCheck]="formGroup.controls.password.value" [minPassLength]="minPassLength"
					(passwordStrength)="onPasswordStrengthChanged($event)"></mtm-password-strength-meter>

			</div>

			<div class="row mt-lg-5 mt-sm-0 mt-md-0  mt-5">
				<!-- Password -->
				<div class="form-group col-12 d-flex w-100 align-items-center">
					<mtm-terms-conditions-check [isRegistration]="true" [companyType]="companyType"
						#mtmTermsConditionCheck style="display: inline-block; width: 100%;">
					</mtm-terms-conditions-check>
					<!-- <label class="form-check-label f-11 _400"  >
					<input  #mtmTermsConditionCheck type="checkbox" class="form-check-input" />
				</label>
				<span class="f-11 _400">I have read and accept the General Terms and Conditions of Use, the General Terms and Conditions of Sale and
					the Privacy Policy.</span> -->
				</div>
			</div>
			<div class="">
				<button type="submit"
					[disabled]="!formGroup.valid || !mtmTermsConditionCheck.isTermsCondition || sending"
					class="btn btn-lg btn-primary btn-block">{{'continue' | translate}}</button>
				<!-- <button  type="submit" [disabled]="!mtmTermsConditionCheck.isTermsCondition || sending"
			class="btn btn-lg btn-primary btn-block">{{'continue' | translate}}</button> -->
			</div>
		</form>
	</div>
</div>