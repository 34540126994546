import { Injectable } from '@angular/core';
import { ApiService } from "app/shared/services/api.service";
import { AuthService } from "app/shared/services/auth.service";
import { Subject, Observable } from "rxjs";
import { GetVersionFilesParams, SiteUpdateParams } from '../interfaces';

@Injectable({
	providedIn: 'root'
})
export class LocationService {
	private tempFiles: any[] = null;

	constructor(private api: ApiService, public auth: AuthService) { }

	public storeTempFiles(data: any[]) {
		this.tempFiles = data;
	}

	public retrieveTempFiles(): any[] {
		const files = this.tempFiles;
		this.tempFiles = [];
		return files;
	}

	/**
	 * Retrieve subsection's Locations
	 * @param projectId
	 * @param sectionId
	 * @param subsectionId
	 */
	public getSettings(projectId: string, sectionId: string, subsectionId: string): Observable<any> {
		let endpoint = `/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/locations`;
		return this.api.setProfileAndImagesProbForItems(endpoint);
	}

	/**
	 * Retrieve subsection's Locations
	 * @param projectId
	 * @param sectionId
	 * @param subsectionId
	 */
	public getSites(projectId: string, sectionId: string, subsectionId: string): Observable<any> {
		let endpoint = `/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/sites`;
		return this.api.setProfileAndImagesProbForItems(endpoint);
	}

	public renameLocation(projectId: string, sectionId: string, subsectionId: string, locationId: string, newName: string): Observable<any> {
		return this.api.httpPut(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/locations/${locationId}?newName=${newName}`, {});
	}


	/**
 * Create a new Location
 * @param projectId
 * @param sectionId
 * @param subsectionId
 * @param location
 */
	public saveSite(projectId: string, sectionId: string, subsectionId: string, location: any): Observable<any> {
		return this.api.httpPost(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/sites`, location);
	}

	/**
	 * Create a new Location
	 * @param projectId
	 * @param sectionId
	 * @param subsectionId
	 * @param location
	 */
	public saveSetting(projectId: string, sectionId: string, subsectionId: string, location: any): Observable<any> {
		return this.api.httpPost(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/locations`, location);
	}

	/**
	 * Delete a Location given by ID
	 * @param projectId
	 * @param sectionId
	 * @param subsectionId
	 * @param styleId
	 */
	public deleteSite(projectId: string, sectionId: string, subsectionId: string, locationId: string): Observable<any> {
		return this.api.httpDelete(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/sites/${locationId}`);
	}

	/**
	 * Delete a Setting given by ID
	 * @param projectId
	 * @param sectionId
	 * @param subsectionId
	 * @param styleId
	 */
	public deleteSetting(projectId: string, sectionId: string, subsectionId: string, settingId: string): Observable<any> {
		return this.api.httpDelete(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/locations/${settingId}`);
	}


	/**
	 * Retrieve location by ID
	 * @param projectId
	 * @param sectionId
	 * @param subsectionId
	 * @param locationId
	 */
	public getSite(projectId: string, sectionId: string, subsectionId: string, locationId: string): Observable<any> {
		return this.api.httpGet(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/sites/${locationId}`);
	}

	/**
	 * Retrieve setting by ID
	 * @param projectId
	 * @param sectionId
	 * @param subsectionId
	 * @param settingId
	 */
	public getSetting(projectId: string, sectionId: string, subsectionId: string, settingId: string): Observable<any> {
		return this.api.httpGet(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/locations/${settingId}`);
	}


	/**
	 * Retrieve location's files
	 * @param projectId
	 * @param sectionId
	 * @param subsectionId
	 * @param locationId
	 */
	public getSiteFiles(projectId: string, sectionId: string, subsectionId: string, locationId: string): Observable<any> {
		return this.api.httpGet(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/items/${locationId}/files`);
	}


	/**
	 * Delete location's file by ID
	 * @param projectId
	 * @param sectionId
	 * @param subsectionId
	 * @param styleId
	 * @param fileId
	 */
	public deleteSiteFile(projectId: string, sectionId: string, subsectionId: string, locationId: string, fileId: string): Observable<any> {
		return this.api.httpDelete(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/items/${locationId}/files/${fileId}`);
	}

	/**
	 * Delete location's file by ID
	 * @param projectId
	 * @param sectionId
	 * @param subsectionId
	 * @param styleId
	 * @param fileId
	 */
	public deleteSettingFile(projectId: string, sectionId: string, subsectionId: string, locationId: string, fileId: string): Observable<any> {
		return this.api.httpDelete(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/items/${locationId}/files/${fileId}`);
	}

	public postNotificationForUploadBried(projectId: string, sectionId: string, subsectionId: string, username: string): Observable<any> {
		return this.api.httpGet(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/notificationForBrief/${username}`);
	}

	public getVersionedSites(projectId: string, sectionId: string, subsectionId: string): Observable<any> {
		const url = `/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-sites`;
		return this.api.httpGet(url);
	}

	public getSimpleSites(projectId: string, sectionId: string, subsectionId: string): Observable<any> {
		const url = `/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-sites-simple`;
		return this.api.httpGet(url);
	}

	public renameSite(projectId: string, sectionId: string, subsectionId: string, siteId: string, newName: string): Observable<any> {
		return this.api.httpPut(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-sites/${siteId}?newName=${newName}`, {});
	}

	public getVersionedSite(projectId: string, sectionId: string, subsectionId: string, siteId: string): Observable<any> {
		const url = `/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-sites/${siteId}`;
		return this.api.httpGet(url);
	}

	public getSimpleSite(projectId: string, sectionId: string, subsectionId: string, siteId: string): Observable<any> {
		const url = `/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-sites-simple/${siteId}`;
		return this.api.httpGet(url);
	}

	public deleteVersionedSite(projectId: string, sectionId: string, subsectionId: string, siteId: string): Observable<any> {
		const url = `/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-sites/${siteId}`;
		return this.api.httpDelete(url);
	}

	public saveVersionedSites(args: SiteUpdateParams): Observable<any> {
		let url = `/api/projects/${args.projectId}/sections/${args.sectionId}/subsections/${args.subsectionId}/v-sites`;
		const urlParams = [];
		if (args.topic) {
			urlParams.push('topic=' + encodeURIComponent(args.topic));
		}
		if (args.topicId) {
			urlParams.push('topicId=' + args.topicId);
		}
		const queryString = urlParams.join('&');
		if (queryString) {
			url += '?' + queryString;
		}
		return this.api.httpPost(url, args.siteDto);
	}

	public getVersion(args: GetVersionFilesParams): Observable<any> {
		let url = `/api/projects/${args.projectId}/sections/${args.sectionId}/subsections/${args.subsectionId}/` +
			`v-sites/${args.itemId}/${args.versionNumber}`;
		return this.api.httpGet(url);
	}
}
