<div class="file-conversion-container">
    <div class="file-error-icon">
        <img src="/assets/img/file-broken.svg" alt="Error Icon" />
    </div>
    <div class="d-flex flex-column align-items-center justify-content-center">
        <div class="error-title f-14 _700">
            <div>{{title | translate }}</div>
            <div>{{'mediaPlayer_transcoding_failed_subtitle'| translate }}</div>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="d-flex w-100 align-items-center justify-content-center mb-4">
            <span class="f-14 _400">{{description | translate}}</span>
        </div>
        <div class="d-flex w-100 align-items-center justify-content-center">
            <ul *ngIf="transcodingType == FailedTranscodingTypes.Pdf">
                <li>1. {{'mediaPlayer_officeTranscodingFailed_step1' | translate}}</li>
                <li>2. {{'mediaPlayer_officeTranscodingFailed_step2' | translate}}</li>
                <li>3. {{'mediaPlayer_officeTranscodingFailed_step3' | translate}}</li>
                <li>4. {{'mediaPlayer_officeTranscodingFailed_step4' | translate}}</li>
            </ul>
            <ul *ngIf="transcodingType == FailedTranscodingTypes.Png">
                <li>1. {{'mediaPlayer_photoshopTranscodingFailed_step1' | translate}}</li>
                <li>2. {{'mediaPlayer_photoshopTranscodingFailed_step2' | translate}}</li>
                <li>3. {{'mediaPlayer_photoshopTranscodingFailed_step3' | translate}}</li>
                <li>4. {{'mediaPlayer_photoshopTranscodingFailed_step4' | translate}}</li>
                <li>5. {{'mediaPlayer_photoshopTranscodingFailed_step5' | translate}}</li>
            </ul>
        </div>
        <div class="d-flex w-100 align-items-center justify-content-center mb-3">
            <span>{{lastStep | translate}}</span>
        </div>

    </div>
    <div class="d-flex w-100 flex-wrap justify-content-center">
        <!--
      <div class="file-upload-area d-flex justify-content-center align-items-center flex-wrap">
            <div class="d-flex justify-content-center align-items-center w-100">
                <img width="22" height="22" src="../../../../../assets/img//file-download.svg" alt="">
                <span class="upload-label ml-2">UPLOAD YOUR FILE</span>
            </div>
                <input type="file" id="file-upload" class="file-input" (change)="onFileSelected($event)" />
            <span class="f-14 _400">Drag and Drop</span>
        </div>
        -->
        <mtm-upload-file (files)="onFileSelected($event)" [preDefinedFilter]="uploadFilter" [bgColor]="'#FFF'"
            [inputText]="'mediaPlayer_transcoding_uploadTitle' | translate"
            [secondaryText]="'mediaPlayer_transcoding_uploadDescription' | translate" style="background-color: white;"
            [color]="'#000'">
        </mtm-upload-file>
    </div>
</div>