import { Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { Subject } from "rxjs";
import { SubsectionService } from "../../../services/subsection.service";
import { takeUntil } from "rxjs/operators";
import { OptionVersion } from "../../../interfaces";

@Component({
  selector: 'subsection-preview-asset-list',
  templateUrl: './subsection-preview-asset-list.component.html',
  styleUrls: ['./subsection-preview-asset-list.component.scss']
})
export class SubsectionPreviewAssetListComponent implements OnInit, OnDestroy {
  @Input() activeFile: any;
  @Output() fileClick: EventEmitter<any> = new EventEmitter<any>();

  private subsectionService = inject(SubsectionService);

  versionData: OptionVersion | null = null;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  ngOnInit() {
    this.subsectionService.subsection$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(state => {
        this.versionData = state.versionData
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  handleClick(file: any) {
    this.subsectionService.updateSubsectionState({
      activeFile: file,
      activeFileId: file.id
    })
    this.fileClick.emit(file);
  }
}
