import { Directive, ElementRef, EventEmitter, HostListener, Output } from "@angular/core";
import { NgControl } from "@angular/forms";

//from https://stackoverflow.com/questions/61528198/custom-directive-for-trimming-whitespace-in-angular-9
@Directive({
  selector: '[trimOnBlur]'
})
export class TrimDirective {
  @Output() ngModelChange = new EventEmitter();

  constructor(private el: ElementRef,
    private control: NgControl) {
  }

  /**
   * Trim the input value on focus out of input component
   */
  @HostListener('focusout') onFocusOut() {
    this.trimValue();
  }

  private trimValue() {
    (this.el.nativeElement as HTMLInputElement).value = (this.el.nativeElement as HTMLInputElement).value.trim();
    console.log('trimValue', this.el.nativeElement.value)
    this.ngModelChange.emit(this.el.nativeElement.value)
    this.control.control?.setValue(this.el.nativeElement.value)
  }

}
