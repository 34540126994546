import { AfterViewInit, Component, inject, OnDestroy, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { AuthService } from "../../../shared/services/auth.service";
import { Subject } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { finalize, take, takeUntil } from "rxjs/operators";
import { forkJoin } from "rxjs";
import { UserService } from "../../../shared/services/user.service";
import { ProjectService } from "app/shared/services/project.service";
import { zip } from "rxjs";
import { WorkspaceService } from "app/shared/services/workspace.service";
import { DataTransferBetweenComponentsService } from "app/shared/services/data-transfer-between-components.service";
import { BreadcrumbService } from "ng5-breadcrumb";
import { EmitterService } from "app/shared/services/emitter.service";
import { Authority, PermissionService } from "app/shared/services/permissions.service";
import { ThemeService } from "app/shared/services/theme.service";
import { HelperService } from "app/shared/services/helper.service";
import { TranslatePipe } from "app/shared/pipes/translate.pipe";
import { ActivityLogService } from "app/shared/services/activity-log.service";
import { ActiveWorkspaceUpdatedEvent, ActivityLogLevels, HeaderInfo, WorkspaceAvatarUpdatedEvent, WorkspaceDTO, WorkspaceOnSaveEvent, WorkspaceUpdatedEvent } from "app/shared/interfaces";
import { MtmBreadcrumbLink, MtmBreadcrumbService } from "app/shared/services/mtm-breadcrumb.service";
import { TimelineService } from "app/shared/services/timeline.service";
import { NotificationService } from "app/shared/services/notification.service";
import { WithPendingGuard } from "app/shared/decorators";
import { OverlayService } from "app/shared/services/overlayService";
import { UntypedFormGroup } from "@angular/forms";
import { CryptoService } from "app/shared/services/crypto.service";

@Component({
	selector: 'mtm-edit-workspace',
	templateUrl: './edit-workspace.component.html',
	styleUrls: ['./edit-workspace.component.scss']
})
export class EditWorkspaceComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild('headerContent', { static: false }) headerContent: TemplateRef<any>;
	workspace: any = {};
	private ngUnsubscribe = new Subject();
	workspaceId: string;
	tabList: string[] = ['workspace_edit_workspacesPermissions', 'workspace_edit_projectsPermissions', 'workspace_edit_usersAndTeams'];
	tabIndex: number = 0;
	companyUsers: any[] = [];
	roles: any[] = [];
	authUser: any = {};
	activeWorkspace: WorkspaceDTO | null = null;
	isworkspace: boolean = true;
	isproject: boolean = false;
	isuser: boolean = false;
	isPending: boolean = false;

	constructor(private authService: AuthService,
		private router: Router,
		private route: ActivatedRoute,
		private activityLogService: ActivityLogService,
		private permissionService: PermissionService,
		private workspaceService: WorkspaceService,
		private userService: UserService,
		private projectService: ProjectService,
		private breadcrumbService: BreadcrumbService,
		private themeService: ThemeService,
		private translatePipe: TranslatePipe,
		private transferService: DataTransferBetweenComponentsService,
		private mtmBreadcrumbService: MtmBreadcrumbService,
		private notificationService: NotificationService,
		private cryptoService: CryptoService,
		private overlayService: OverlayService) {

	}

	ngOnInit() {
		this.authUser = this.authService.getAuthUser();
		EmitterService.get(ActiveWorkspaceUpdatedEvent).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
			next: (updates) => {
				this.activeWorkspace = updates.workspace;
				this.updateHeaderInfo();
				this.setBreadcrumb();
			}
		});
		if (!this.authUser) {
			this.router.navigate(['/entry']);
		}

		this.route.params.subscribe(
			params => {
				this.workspaceId = params['workspaceId'];
				this.workspace = {};
				this.companyUsers = [];
				this.roles = [];
				this.initWorkspace();
			}
		)

		EmitterService.get(WorkspaceAvatarUpdatedEvent)
		.pipe(takeUntil(this.ngUnsubscribe))
		.subscribe((info: any) => {
			const { workspaceId = '', avatarUrl = '' } = info;
			if (info.avatarUrl) {
				this.activeWorkspace.avatarUrl = avatarUrl;
				this.updateHeaderInfo();
			}
		});

		EmitterService.get(WorkspaceUpdatedEvent).pipe(takeUntil(this.ngUnsubscribe)).subscribe((result: any) => {
			this.workspace.name = result.name;
			this.activeWorkspace = result;
			this.updateHeaderInfo();
			this.transferService.ngOnChangesBreadcrumbComponent();
		});
	}

	ngAfterViewInit(): void {
		this.setBreadcrumb();

		this.themeService.updateContent({
			searchPanel: null,
			fullWidthContent: true
		});
		// this.themeService.setContent({
		// 	rightPane: null,
		// 	backgroundImage: null,
		// 	header: this.headerContent
		// });
	}

	reloadUsers() {
		const authUser = this.authService.getAuthUser();
		this.userService.getUsersByCompanyId(authUser.company.id)
			.subscribe(data => {
				this.companyUsers = data;
			});

	}

	configureBreadcrumb() {
		this.breadcrumbService.addCallbackForRoute('/workspace', () => this.translatePipe.transform('workspace'));
		this.breadcrumbService.hideRouteRegex('workspace/edit/([0-9a-z-]+)?$');

		this.breadcrumbService.addCallbackForRouteRegex(
			'workspace/edit$',
			() => {
				let workspaceName = this.workspace.name;
				return `${workspaceName}`;
			}
		)

		this.transferService.ngOnChangesBreadcrumbComponent();
	}


	initWorkspace() {
		const authUser = this.authService.getAuthUser();

		zip(
			this.workspaceService.getWorkspaceById(authUser.company.id, this.workspaceId, true, {
				redirectOnForbidden: true
			}),
			this.userService.getUsersByCompanyId(authUser.company.id),
			this.projectService.getSortedRoles()
		).pipe(take(1))
			.subscribe(data => {
				const workspace = data[0];
				if (workspace) {
					this.workspace = workspace;
					this.workspaceService.setActiveWorkspace(this.workspace);
					this.checkUserAccess();
					this.configureBreadcrumb();
					this.activityLogService.activityLogAccess.next({
						hasAccess: true,
						level: ActivityLogLevels.WORKSPACE,
						workspaceId: this.workspaceId,
					});
				}
				if (data[1])
					this.companyUsers = data[1];
				if (data[2])
					this.roles = data[2].map(r => {
						return { code: r.id, label: r.name };
					});
			});
	}

	//check the user here instead of in guard, as need to call service
	private checkUserAccess() {
		if (!this.workspace) {
			this.router.navigate(['/entry']);
			return;
		}

		//admin & company principal can access
		if (this.permissionService.hasAuthority(Authority.S, null)) {
			return;
		}

		//only workspace owner of that workspace can access
		const allowedRoles = ['COMPANY_PROJECT_OWNER', 'COMPANY_PRODUCTION_OWNER'];
		let canAccess = false;
		if (allowedRoles.indexOf(this.authUser.globalRole) > -1 && this.workspace.usernames) {
			if (this.workspace.usernames.indexOf(this.authUser.username) > -1)
				canAccess = true;
		}

		if (!canAccess) {
			this.router.navigate(['/entry']);
			return;
		}
	}

	ngOnDestroy() {
		this.mtmBreadcrumbService.setLinks([]);
		this.themeService.updateContent({
			searchPanel: null,
			fullWidthContent: false
		})
		this.activityLogService.activityLogAccess.next({
			hasAccess: false,
		});
		// this.themeService.resetOptions();
		this.ngUnsubscribe.next(undefined);
		this.ngUnsubscribe.complete();
	}

	toggleTab(index: number) {
		this.tabIndex = index;
		switch (index) {
			case 0:
				this.isworkspace = true;
				this.isproject = false;
				this.isuser = false;
				break;
			case 1:
				this.isworkspace = false;
				this.isproject = true;
				this.isuser = false;
				break;
			case 2:
				this.isworkspace = false;
				this.isproject = false;
				this.isuser = true;
				break;

			default:
				break;
		}
	}


	// update header
	private updateHeaderInfo() {
		const headerUpdate: Partial<HeaderInfo> = {};
		if (this.activeWorkspace) {
			headerUpdate.avatarImage = this.activeWorkspace.avatarUrl;
			headerUpdate.avatarName = this.activeWorkspace.name;
			headerUpdate.title = this.activeWorkspace.name;
			this.themeService.updateHeaderInfo(headerUpdate);
		}
	}
	// update breadcrumb
	setBreadcrumb() {
		const brand = JSON.parse(localStorage.getItem('brand'));
		const pageLinks: MtmBreadcrumbLink[] = [
			{
				label: brand.name
			}, {
				label: 'Workspaces',
				url: ['/workspaces']
			},
			{
				label: this.activeWorkspace?.name,
				url: ['/workspaces', 'campaign-drive'],
				queryParams: {
					wid: this.cryptoService.encryptString(this.workspaceId)
				}
			},
			{
				label: 'Settings',
			}
		]

		if (this.tabIndex === 0) {
			pageLinks.push({
				label: 'Workspace’s Permissions',
			})
		} else if (this.tabIndex === 1) {
			pageLinks.push({
				label: 'Project’s Permissions',
			})
		} else {
			pageLinks.push({
				label: 'Users & Teams',
			})
		}
		this.mtmBreadcrumbService.setLinks(pageLinks);
	}
	deleteWorkspace() {
		this.notificationService.open({
			title: this.translatePipe.transform('workspace_delete_title'),
			description: this.translatePipe.transform('workspace_delete_confirm', { name: this.workspace.name }),
			confirmBtn: this.translatePipe.transform('yes'),
			cancelBtn: this.translatePipe.transform('no')
		}).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe((confirm: any) => {
			if (!confirm)
				return;
			this.deleteCurrentWorkspace();
		});
	}

	@WithPendingGuard
	private deleteCurrentWorkspace() {
		const user = this.authService.getAuthUser();
		this.isPending = true;
		this.workspaceService.deleteWorkspace(user.company.id, this.workspace.id)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe({
				next: () => {
					this.workspaceService.getWorkspaces(user.company.id)
						.pipe(takeUntil(this.ngUnsubscribe))
						.subscribe({
							next: (result) => {
								this.isPending = false;
								if (result.length) {
									const workspaces = result.sort((a, b) => a.name.localeCompare(b.name));
									this.setNewActiveWorkspace(workspaces[0]);
								} else {
									this.unsetWorkspace();
								}
							}, error: () => {
								this.isPending = false;
								this.unsetWorkspace();
							}
						});

				}, error: err => {
					this.isPending = false;
					this.overlayService.showError(this.translatePipe.transform('workspace_delete_error'), 'Error');
				}
			});
	}
	private unsetWorkspace() {
		this.workspaceService.unsetActiveWorkspace();
		EmitterService.get(WorkspaceUpdatedEvent).emit({});
		this.router.navigate(['/projects']);
	}

	private setNewActiveWorkspace(workspace: any) {
		this.workspaceService.setActiveWorkspace(workspace);
		EmitterService.get(WorkspaceUpdatedEvent).emit({});
		this.router.navigate(['/projects']);
	}

	headerForm: UntypedFormGroup = null;

	onSave() {
		EmitterService.get(WorkspaceOnSaveEvent).emit();
	}

	save() {
		this.headerForm = this.workspaceService.formValue;
		// this.validateFields(this.headerForm);
		// if (!this.headerForm.valid)
		// 	return;
		const updatedData = Object.assign({}, this.workspace, this.headerForm.value);
		this.isPending = true;
		this.workspaceService.editWorkspace(updatedData.id, updatedData).pipe(
			finalize(() => this.isPending = false),
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (result: any) => {
				EmitterService.get(WorkspaceUpdatedEvent).emit(result);
				this.activeWorkspace = result;
				this.updateHeaderInfo();
				this.workspace.name = this.headerForm.value.name;
				this.transferService.ngOnChangesBreadcrumbComponent();
				this.overlayService.showSuccess(this.translatePipe.transform('overlayChangeSave'), 'Success');
			}, error: (error) => {
				let errorCode = error.errorCode;
				let errorMessage = errorCode == 'DUPLICATE_NAME' ? 'workspace_edit_duplicateError' : 'workspace_edit_error';
				this.overlayService.showError(
					this.translatePipe.transform(errorMessage, { name: updatedData.name }), 'Error');
			}
		});
	}
}
