import { Injectable } from '@angular/core';
import { ApiService } from "app/shared/services/api.service";
import { AuthService } from "app/shared/services/auth.service";
import { Subject, Observable } from "rxjs";
import { CastingVOActorParams } from "../interfaces/casting-vo-actor.interface";

@Injectable({
	providedIn: 'root'
})
export class CastingVoiceOverService {
	public rightss: any[] = [
		{ label: 'Yes', value: 'YES' },
		{ label: 'No', value: 'NO' },
		{ label: 'Buyout', value: 'BUYOUT' }
	];

	public copyRightss: any[] = [
		{ label: 'Priceless', value: 'PRICELESS' },
		{ label: 'High', value: 'HIGH' },
		{ label: 'Medium-plunging', value: 'MEDIUM' },
		{ label: 'Low', value: 'LOW' }
	];

	public approvals: any[] = [
		{ label: 'Very easy', value: 'VERY_EASY' },
		{ label: 'Easy', value: 'EASY' },
		{ label: 'Complicated', value: 'COMPLICATED' },
		{ label: 'Very Complicated', value: 'VERY_COMPLICATED' }
	];

	constructor(private api: ApiService, public auth: AuthService) { }

	/**
	 * Retrieve subsection's Roles
	 * @param projectId
	 * @param sectionId
	 * @param subsectionId
	 */
	public getCastingVoiceOverRoles(projectId: string, sectionId: string, subsectionId: string): Observable<any> {
		let endpoint = `/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/castingVoiceOverRoles`;
		return this.api.setProfileAndImagesProbForItems(endpoint);
	}

	/**
	 * Retrieve subsection's Actors
	 * @param projectId
	 * @param sectionId
	 * @param subsectionId
	 */
	public getCastingVoiceOverActors(projectId: string, sectionId: string, subsectionId: string): Observable<any> {
		let endpoint = `/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-castingVoiceOverActors`;
		return this.api.setProfileAndImagesProbForItems(endpoint);
	}

	public getSimpleCastingVoiceOverActors(projectId: string, sectionId: string, subsectionId: string): Observable<any> {
		let endpoint = `/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-castingVoiceOverActors-simple`;
		return this.api.setProfileAndImagesProbForItems(endpoint);
	}


	/**
	 * Create a new Actor
	 * @param projectId
	 * @param sectionId
	 * @param subsectionId
	 * @param castingVoiceOverActor
	 */
	public saveCastingVoiceOverActor(args: CastingVOActorParams): Observable<any> {
		let url = `/api/projects/${args.projectId}/sections/${args.sectionId}/subsections/${args.subsectionId}/v-castingVoiceOverActors`;

		if (args.description) {
			url += `?description=` + encodeURIComponent(args.description);
		}

		return this.api.httpPost(url, args.data);
	}

	public renameCastingVoiceOverActor(projectId: string, sectionId: string, subsectionId: string, animationId: string, newName: string): Observable<any> {
		return this.api.httpPut(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-castingVoiceOverActors/${animationId}?newName=${newName}`, {});
	}

	/**
	 * Create a new Role
	 * @param projectId
	 * @param sectionId
	 * @param subsectionId
	 * @param castingVoiceOverRole
	 */
	public saveCastingVoiceOverRole(projectId: string, sectionId: string, subsectionId: string, castingVoiceOverRole: any): Observable<any> {
		return this.api.httpPost(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/castingVoiceOverRoles`, castingVoiceOverRole);
	}

	public renameCastingVoiceOverRole(projectId: string, sectionId: string, subsectionId: string, castingVoiceOverRolesId: string, newName: string): Observable<any> {
		return this.api.httpPut(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/castingVoiceOverRoles/${castingVoiceOverRolesId}?newName=${newName}`, {});
	}

	/**
	 * Delete a Actor given by ID
	 * @param projectId
	 * @param sectionId
	 * @param subsectionId
	 * @param castingVoiceOverActorId
	 */
	public deleteCastingVoiceOverActor(projectId: string, sectionId: string, subsectionId: string, castingVoiceOverActorId: string): Observable<any> {
		return this.api.httpDelete(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-castingVoiceOverActors/${castingVoiceOverActorId}`);
	}

	/**
	 * Delete a Role given by ID
	 * @param projectId
	 * @param sectionId
	 * @param subsectionId
	 * @param castingVoiceOverRoleId
	 */
	public deleteCastingVoiceOverRole(projectId: string, sectionId: string, subsectionId: string, castingVoiceOverRoleId: string): Observable<any> {
		return this.api.httpDelete(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/castingVoiceOverRoles/${castingVoiceOverRoleId}`);
	}


	/**
	 * Retrieve actor by ID
	 * @param projectId
	 * @param sectionId
	 * @param subsectionId
	 * @param castingVoiceOverActorId
	 */
	public getCastingVoiceOverActor(projectId: string, sectionId: string, subsectionId: string, castingVoiceOverActorId: string): Observable<any> {
		return this.api.setProfileAndImagesProbForItems(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-castingVoiceOverActors/${castingVoiceOverActorId}`);
	}

	/**
	* Retrieve actor simple info by ID
	* @param projectId
	* @param sectionId
	* @param subsectionId
	* @param castingVoiceOverActorId
	*/
	public getSimpleCastingVoiceOverActor(projectId: string, sectionId: string, subsectionId: string, castingVoiceOverActorId: string): Observable<any> {
		return this.api.httpGet(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-castingVoiceOverActors-simple/${castingVoiceOverActorId}`);
	}

	/**
	 * Retrieve Role by ID
	 * @param projectId
	 * @param sectionId
	 * @param subsectionId
	 * @param castingVoiceOverRoleId
	 */
	public getCastingVoiceOverRole(projectId: string, sectionId: string, subsectionId: string, castingVoiceOverRoleId: string): Observable<any> {
		return this.api.httpGet(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/castingVoiceOverRoles/${castingVoiceOverRoleId}`);
	}


}
