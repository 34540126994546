import * as _ from 'lodash';
import * as moment from 'moment';
import {
	Component,
	OnInit,
	Input,
	Output,
	EventEmitter,
	OnDestroy,
	ViewEncapsulation,
	AfterViewInit,
	SimpleChanges
} from '@angular/core';
import {
	CommentsService,
	commentFileAdd,
	commentFileUploaded,
	commentFileUploading
} from "app/shared/services/comments.service";
import { AuthService } from "app/shared/services/auth.service";
import { NotificationService } from "app/shared/services/notification.service";
import { UserService } from "app/shared/services/user.service";
import { PermissionService } from 'app/shared/services/permissions.service';
import {
	MtmWebSocketService,
	wsListenerANNOTATION_ADDED,
	wsListenerCOMMENT_ADD,
	wsListenerCOMMENT_CREATED,
	WS_SUBSCRIBE_TOPIC_TYPES
} from 'app/shared/services/mtm-websocket.service';
import {
	Annotation,
	AnnotationComment,
	AnnotationService,
	onAddingAnnotation,
	onAddNewAnnotation,
	onAnnotationFocused,
	onAnnotationsLoaded,
	onAnnotationsStateChanged,
	onAudioPluginReady,
	onCancelAddingAnnotation,
	onCommentInputFocused,
	onCommentPluginReady,
	onDeleteAnnotation,
	onDeleteCommentAnnotation,
	onImagePluginReady,
	onOpenAnnotation,
	onPlayerReady,
	onReplyCommentAnnotation,
	onShowAnnotationCommentReplies,
	onVideoPaused,
	onVideoPlayerDisposed,
	wsAnnotationChange
} from 'app/shared/services/annotation.service';
import { Subject } from 'rxjs';
import { HelperService } from 'app/shared/services/helper.service';
import { TranslateService } from "app/shared/services/translate.service";
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { EmitterService } from "../../services/emitter.service";
import { AnnotationPrivateCondition, CommentHighlight } from "../../interfaces";
import { CommentEditFocusEvent, CommentReplyFocusEvent } from "../../models";
import { environment } from 'environments/environment';
import { projectKeyFileDeleted } from 'app/shared/services/signed-url';
import { MtmStompService } from 'app/shared/services/mtm-stomp.service';
import { DirectoryService } from 'app/shared/services/directory.service';
import { onSectionsLoad } from 'app/shared/services/project.service';


@Component({
	selector: 'mtm-comment',
	templateUrl: './comment.component.html',
	styleUrls: ['./comment.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class CommentComponent implements OnInit, OnDestroy, AfterViewInit {

	@Input() projectId: string;
	@Input() sectionId: string;
	@Input() subsectionId: string;
	@Input() typeId: any;
	@Input() item: any;
	@Input() annotationComments: any[] = [];
	@Input() isAnnotationModeActive: boolean = false;
	@Input() projectParticipants: any[];
	@Input() isAnnotationCommentEnable: boolean;
	@Input() previewAnnotation: any;
	@Input() player: any;
	@Input() isPlayerReady: boolean;
	@Input() annotationPlugin: any;
	@Input() hightLightedComment: any;
	@Input() isAnnotationComment: boolean = true;
	@Input() borderColor: any = "rgb(169, 169, 169)";
	@Input() hightLightedCommentAssetVersion: any;

	@Input() totalInitialComments: number = 2; //only if viewMore is true
	@Output() changeApplied: EventEmitter<boolean> = new EventEmitter();

	private _viewMore: boolean = false;
	get viewMore(): boolean {
		return this._viewMore;
	}

	@Input()
	set viewMore(value: boolean) {
		this._viewMore = value;
		if (this._viewMore) {
			this.isAllShown = false;
		}
	}

	private message: string = '';
	public commentObjects: any[] = [];
	users: any = {};
	private commentedUsers: any[] = [];
	private myUser: any;

	isCommentable: boolean = false;
	private isBusy: boolean = false;
	isVideoFile: boolean = false;
	isImageFile: boolean = false;
	isAudioFile: boolean = false;
	isTimeRangeCommentShow: boolean = false;
	mediaAnnotations: any[] = [];
	isRangeActive: boolean = false;
	replyMessage: string = '';
	activeTimeRange: string = '';
	startTime: number = 0;
	endTime: number = 0;
	generalComments: any = [];
	replyCommentId: any;
	replyShowId: any;
	comments: any[];
	commentsToDisplay: any[] = [];
	isAllShown: boolean = false;
	showCommentsTotal: boolean = false;
	lang: any;
	isAnnotationActive: boolean = false;
	publicReviewLink: boolean = false;
	commentNotificationChecked: boolean = false;
	generalCommentMapped: boolean = false;
	highlightToCheck: any = null; //check if there is any reply to be highlighted
	ngUnsubscribe = new Subject();
	comment$ = new Subject();
	annotation$ = new Subject();
	// annotationsObject: any[] = [];
	wsListeners: any[] = [];
	editedComment: any;
	wsId: string;
	newFiles: any = [];
	uploadingFiles: any = {};
	privateCondition: AnnotationPrivateCondition = null;
	isLoadingComments: boolean = false;
	isLoadingAnnotations: boolean = false;
	canScrollToHighlightedComment: boolean = false;
	authUser: any;

	get textColor(): string {
		return !!this.previewAnnotation ? (!!this.privateCondition ? '#FFFFFF' : '#000000') : 'inherit'
	}

	get isLoading(): boolean {
		return this.isLoadingComments || this.isLoadingAnnotations;
	}

	constructor(
		private commentsService: CommentsService,
		public auth: AuthService,
		private notificationService: NotificationService,
		private userApi: UserService,
		//private webSocketService: MtmWebSocketService,
		private servicePermission: PermissionService,
		private annotationService: AnnotationService,
		private translateService: TranslateService,
		private activatedRoute: ActivatedRoute,
		private authService: AuthService,
		public userService: UserService,
		private directoryService: DirectoryService,
		private stompService: MtmStompService
	) {
		this.wsId = this.stompService.subscribeToService();
		this.publicReviewLink = this.activatedRoute.snapshot.data.publicReviewLink;
		commentFileUploading.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(data => {
			this.uploadingFiles[data.comment.id] = data.files;
		});
		commentFileUploaded.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(data => {
			const fileData = data.file;
			this.uploadingFiles[data.comment.id] = this.uploadingFiles[data.comment.id].filter(f => f.name != fileData.file.name);
		})
	}

	ngAfterViewInit(): void {
		this.authUser = this.authService.getAuthUserName();
		//this.webSocketService.initConnectWS();
		// this.stompService.subscribeToListener('NEW_COMMENT_ADDED', this.wsId, [this.projectId, this.sectionId, this.subsectionId], (message) => this.wsNewCommentAddedHandler(message), wsListenerCOMMENT_ADD);
		// this.stompService.subscribeToListener('NEW_ANNOTATION_ADDED', this.wsId, [this.projectId, this.sectionId, this.subsectionId], (args) => this.wsNewAnnotationAddedHandler(args), wsListenerANNOTATION_ADDED);
		onAnnotationsStateChanged.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.onAnnotationStateChanged(args)
		})
		onVideoPlayerDisposed.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.onVideoPlayerDisposed(args)
		})
		onVideoPaused.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.onVideoPausedHandler(args)
		})
		onPlayerReady.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.onPlayerReadyHandler(args)
		})
		onShowAnnotationCommentReplies.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.onShowAnnotationCommentReplies(args)
		})
		onAddingAnnotation.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.onAddingAnnotationHandler(args)
		})
		onCancelAddingAnnotation.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.onCancelAddingAnnotationHandler(args)
		})
		onImagePluginReady.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.onImagePluginReadyHandler(args)
		})
		onAudioPluginReady.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.onAudioPluginReadyHandler(args)
		})
		onCommentPluginReady.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.onCommentPluginReadyHandler(args)
		})

		this.stompService.subscribeToListener('COMMENT_CREATED', this.wsId, [this.projectId, this.sectionId, this.subsectionId],
			(args: any) => this.wsOnCommentCreate(args), wsListenerCOMMENT_CREATED);

		wsAnnotationChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => this.setDisplayedComments());
		window["commentComp"] = this;

		this.annotationService.privateConditionChange$
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((privateCondition: AnnotationPrivateCondition) => {

				if (this.previewAnnotation) {
					return;
				}

				this.privateCondition = privateCondition;
				this.reloadAnnotations(null);
			});
	}

	ngOnInit() {
		this.myUser = this.auth.getAuthUser();
		this.lang = this.translateService.getLang();
		this.checkCommentAbleAuthority();
		this.prepareNewCommentSubscription();
		this.prepareNewAnnotationSubscription();
		this.handleTaskQueueEvents();
	}

	get annotationsObject() {
		if (this.annotationPlugin) return this.annotationPlugin?.annotationState?.annotations;
		else return [];
	}

	private reloadAnnotations(changes: SimpleChanges | null) {
		this.annotation$.next(undefined);
		this.getAnnotations().pipe(
			takeUntil(this.annotation$),
			takeUntil(this.ngUnsubscribe)
		).subscribe((data) => {
			try {
				onAnnotationsLoaded.emit({
					act: 'annotationsLoaded',
					data: data,
					projectId: this.projectId,
					sectionId: this.sectionId,
					subSectionId: this.subsectionId,
					typeId: this.typeId,
					itemId: this.item?.id,
					previewAnnotation: this.previewAnnotation
				});
				this.startTime = 0;
				this.endTime = 0;

				//TODO: find a better way to handle this, ideally via new event
				setTimeout(() => {
					this.setDisplayedComments();
				}, 100);

			} catch (err) {

			}
		});
	}

	ngOnChanges(changes: any) {
		//if the only changes is just the highlighted comment, don't reset the media annotations
		if (!(Object.keys(changes).length == 1 && changes.hightLightedComment)) {
			this.mediaAnnotations = [];
		}

		this.commentObjects = [];
		this.resetComponent();

		if (!this.previewAnnotation && (changes?.projectId?.currentValue || changes?.sectionId?.currentValue || changes?.subsectionId?.currentValue ||
			changes?.typeId?.currentValue)) {
			this.loadGeneralComments();
		}

		if (!changes.previewAnnotation && (changes?.projectId?.currentValue || changes?.sectionId?.currentValue || changes?.subsectionId?.currentValue ||
			changes?.typeId?.currentValue || changes?.item?.currentValue)) {
			this.reloadAnnotations(changes);
		}

		if (changes && changes.hightLightedComment && changes.hightLightedComment.currentValue) {
			if (this.hightLightedComment.parentId) {
				this.replyShowId = this.hightLightedComment.parentId;
			}
			if (this.highlightToCheck?.commentId != this.hightLightedComment.id) {
				this.highlightToCheck = { commentId: this.hightLightedComment.id };
			}
			if (this.hightLightedComment.checkReplies) {
				this.highlightToCheck.checkReplies = true;
			}
			if (this.hightLightedComment.waitForResolve) {
				this.highlightToCheck.waitForResolve = true;
			}
		}

		if (changes && changes.previewAnnotation && changes.previewAnnotation.currentValue) {
			this.mediaAnnotations = [changes.previewAnnotation.currentValue];
			_.map(this.mediaAnnotations, (annotation) => {
				if (annotation.range) {
					annotation.hasRange = true; onSectionsLoad
					if (annotation.range.start >= annotation.range.end) {
						annotation.range.end = annotation.range.start
					}
					annotation.rangeInTimeString = {
						start: annotation.range.start * 1000,
						end: annotation.range.end * 1000
					}
					annotation.secondRangeString = {
						start: annotation.range.start * 1000,
						end: annotation.range.end * 1000
					}
					let shapes = JSON.parse(annotation.shape);
					if (_.get(shapes, 'objects', []).length > 0) {
						if (parseFloat(annotation.range.start) === parseFloat(annotation.range.end) || parseFloat(annotation.range.end) - parseFloat(annotation.range.start) <= 0.06) {
							annotation.isCommentInTimeRange = false;
						} else {
							annotation.isCommentInTimeRange = true;
						}
					} else {
						// if (parseFloat(annotation.range.start) === parseFloat(annotation.range.end) || parseFloat(annotation.range.end) - parseFloat(annotation.range.start) <= 0.06) {
						// 	annotation.isCommentInTimeRange = false;
						// } else {
						// 	annotation.isCommentInTimeRange = true;
						// }
						annotation.isCommentInTimeRange = !(
							parseFloat(annotation.range.start) === parseFloat(annotation.range.end) ||
							parseFloat(annotation.range.end) - parseFloat(annotation.range.start) <= 0.06
						);

					}
				} else {
					annotation.hasRange = false;
				}
				annotation.color = '#A9A9A9';
				annotation.colorOpacity = HelperService.hexToRgbA(annotation.color, 1);
			});
			this.setDisplayedComments();
			this.reLoadUsers();
		} else {
			this.checkCommentAbleAuthority();
			if (this.viewMore) {
				this.isAllShown = false;
			}
			//this.loadGeneralComments();
		}

	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next(undefined);
		this.ngUnsubscribe.complete();
		this.comment$.next(undefined);
		this.comment$.complete();
		this.annotation$.next(undefined);
		this.annotation$.complete();
		this.stompService.unsubscribeToService(this.wsId);
	}

	private handleTaskQueueEvents() {
		EmitterService.get(CommentHighlight)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((data: any) => {
				const { resolve, fileId, assetId, commentId } = data;
				if (!this.highlightToCheck) {
					this.highlightToCheck = {
						commentId: commentId,
						waitForResolve: true,
						typeId: assetId,
						itemId: fileId
					}
				} else if (this.highlightToCheck.commentId == commentId) {
					this.highlightToCheck = {
						...this.highlightToCheck,
						waitForResolve: true,
						typeId: assetId,
						itemId: fileId
					}
				}
				resolve(true);
				this.canScrollToHighlightedComment = true;
				this.checkHighlightScroll();
			});
	}

	onVideoPausedHandler(args: any) {
		if (args.player) {
			this.startTime = args.player.currentTime();
		}
	}

	onAnnotationStateChanged(args: any) {
		this.annotation$.next(undefined);
	}

	onPlayerReadyHandler(args: any) {
		if (args.player) {
			this.player = args.player;
			this.isPlayerReady = true;
			this.annotationPlugin = args.annotationPlugin;
			this.setDisplayedComments();
			this.reLoadUsers();
		}
	}

	onImagePluginReadyHandler(args: any) {
		this.annotationPlugin = args.annotationPlugin;
		this.setDisplayedComments();
		this.reLoadUsers();
	}

	onAudioPluginReadyHandler(args: any) {
		this.annotationPlugin = args.annotationPlugin;
		this.setDisplayedComments();
		this.reLoadUsers();
	}

	onCommentPluginReadyHandler(args: any) {
		this.annotationPlugin = args.annotationPlugin;
		this.setDisplayedComments();
		this.reLoadUsers();
	}


	onVideoPlayerDisposed(args: any) {
		if (args.player) {
			this.player = args.player;
			this.annotationPlugin = args.annotationPlugin
			this.isPlayerReady = false;
		}
	}

	onShowAnnotationCommentReplies(args: any) {
		if (!this.previewAnnotation) {
			this.replyShowId = args.comment.id;
			setTimeout(() => {
				this.scrollToPreviewComment();
			}, 0);
		}
	}

	onAddingAnnotationHandler(args: any) {
		this.isAnnotationActive = true;
	}

	onCancelAddingAnnotationHandler(args: any) {
		this.isAnnotationActive = false;
	}

	prepareNewCommentSubscription() {
		this.stompService.subscribeToListener('NEW_COMMENT_ADDED', this.wsId, [this.projectId, this.sectionId, this.subsectionId], (message) => this.wsNewCommentAddedHandler(message), wsListenerCOMMENT_ADD);
		// this.webSocketService.unsubscribeOtherWSTopic(WS_SUBSCRIBE_TOPIC_TYPES.NEW_COMMENT_ADDED, [this.projectId, this.sectionId, this.subsectionId]);
		// setTimeout(() => {
		// 	this.webSocketService.addWSTopicSubscribe(WS_SUBSCRIBE_TOPIC_TYPES.NEW_COMMENT_ADDED, [this.projectId, this.sectionId, this.subsectionId]);
		// }, 350);
	}

	prepareNewAnnotationSubscription() {
		this.stompService.subscribeToListener('NEW_ANNOTATION_ADDED', this.wsId, [this.projectId, this.sectionId, this.subsectionId], (args) => this.wsNewAnnotationAddedHandler(args), wsListenerANNOTATION_ADDED);
		// this.webSocketService.unsubscribeOtherWSTopic(WS_SUBSCRIBE_TOPIC_TYPES.NEW_ANNOTATION_ADDED, [this.projectId, this.sectionId, this.subsectionId]);
		// setTimeout(() => {
		// 	this.webSocketService.addWSTopicSubscribe(WS_SUBSCRIBE_TOPIC_TYPES.NEW_ANNOTATION_ADDED, [this.projectId, this.sectionId, this.subsectionId]);
		// }, 350);
	}

	wsNewCommentAddedHandler(data) {
		this.commentObjects = _.filter(data, (comment) => comment.typeId === this.typeId);
		_.map(this.commentObjects, (comment) => {
			if (comment.id === this.replyShowId) {
				comment.isShowReplies = true;
			}
			if (HelperService.isObjectEmpty(comment.updatetime)) {
				comment.updatetime = comment.time;
			}
		});
		this.commentObjects.sort((a, b) => {
			return a.time - b.time;
		});
		this.mapGeneralCommentModel();
		this.reLoadUsers();
	}

	wsNewAnnotationAddedHandler(data: any) {
		if ((this.annotationPlugin && this.item) || !this.isAnnotationModeActive) {
			data = _.filter(data, (comment) => (comment.typeId === this.typeId) && (comment.itemId === this.item.id));
			let annotations = new Array<Annotation>();
			_.map(data, (annotation: any) => {
				let parent = _.filter(annotation.comments, (comment) => {
					return HelperService.isObjectEmpty(comment.parentId);
				});
				let replies = _.filter(annotation.comments, (comment) => {
					return !HelperService.isObjectEmpty(comment.parentId);
				});
				replies.sort((commentA: any, commentB: any) => {
					return _.get(commentA, 'meta.datetime') - _.get(commentB, 'meta.datetime');
				});
				annotation.comments = [...parent, ...replies];
				let entity = this.annotationService.getAnnotationViewModel(annotation, this.projectParticipants);
				annotations.push(entity);
			});
			annotations.sort((annotationA: any, annotationB: any) => {
				return _.get(annotationB.comments, '[0].meta.datetime') - _.get(annotationA.comments, '[0].meta.datetime');
			});
			let newMediaAnnotationData = annotations;
			_.map(newMediaAnnotationData, (annotation: any) => {
				if (annotation.range.start >= annotation.range.end) {
					annotation.range.end = annotation.range.start
				}
				annotation.rangeInTimeString = {
					start: annotation.range.start * 1000,
					end: annotation.range.end * 1000
				}
				annotation.secondRangeString = {
					start: annotation.range.start * 1000,
					end: annotation.range.end * 1000
				}
				let shapes = JSON.parse(annotation.shape);
				if (_.get(shapes, 'objects', []).length > 0) {
					if (parseFloat(annotation.range.start) === parseFloat(annotation.range.end) || parseFloat(annotation.range.end) - parseFloat(annotation.range.start) <= 0.06) {
						annotation.isCommentInTimeRange = false;
					} else {
						annotation.isCommentInTimeRange = true;
					}
				} else {
					// if (parseFloat(annotation.range.start) === parseFloat(annotation.range.end) || parseFloat(annotation.range.end) - parseFloat(annotation.range.start) <= 0.06) {
					// 	annotation.isCommentInTimeRange = false;
					// } else {
					// 	annotation.isCommentInTimeRange = true;
					// }
					annotation.isCommentInTimeRange = !(
						parseFloat(annotation.range.start) === parseFloat(annotation.range.end) ||
						parseFloat(annotation.range.end) - parseFloat(annotation.range.start) <= 0.06
					);

				}
				annotation.color = '#A9A9A9';
				annotation.colorOpacity = HelperService.hexToRgbA(annotation.color, 1);
			});

			this.mediaAnnotations = newMediaAnnotationData;
			this.setDisplayedComments();
			this.reLoadUsers();
			onAnnotationsLoaded.emit({
				act: 'annotationsLoaded',
				data: this.mediaAnnotations || [],
				projectId: this.projectId,
				sectionId: this.sectionId,
				subSectionId: this.subsectionId,
				typeId: this.typeId,
				itemId: this.item.id,
				previewAnnotation: this.previewAnnotation
			});
		}
	}

	private checkCommentAbleAuthority() {
		this.isCommentable = this.servicePermission.isCommentableAuthority(this.projectId, this.subsectionId) || this.publicReviewLink;
	}

	/**
	 * Load comments
	 */
	private loadGeneralComments() {
		this.isLoadingComments = true;
		this.comment$.next(undefined);
		this.commentsService.getCommentsBySectionAndSubsectionAndTypeId(this.projectId, this.sectionId, this.subsectionId, this.typeId)
			.pipe(
				takeUntil(this.comment$),
				takeUntil(this.ngUnsubscribe)
			).subscribe({
				next: (data: any) => {
					this.commentObjects = _.filter(data, (comment) => comment.typeId === this.typeId);
					_.map(this.commentObjects, (comment) => {
						if (comment.id === this.replyShowId) {
							comment.isShowReplies = true;
						}
						if (HelperService.isObjectEmpty(comment.updatetime)) {
							comment.updatetime = comment.time;
						}
					});
					this.commentObjects.sort((a, b) => {
						return a.time - b.time;
					});
					this.isLoadingComments = false;
					this.mapGeneralCommentModel();
					this.reLoadUsers();
				}, error: (err: any) => {
					console.log(err);
					this.generalComments = [];
					this.isLoadingComments = false;
					this.setDisplayedComments();
				}
			}
			);
	}

	mapGeneralCommentModel() {
		this.generalComments = _.filter(this.commentObjects, (comment) => comment.typeId === this.typeId && !comment.parentId);
		_.map(this.generalComments, (generalComment: any) => {
			generalComment.replies = _.filter(this.commentObjects, (comment) => {
				return comment.parentId
					? comment.parentId == generalComment.id
					: false;
			});
		});
		this.generalCommentMapped = true;
		this.setDisplayedComments();
	}

	/**
	 * Load Users info
	 */
	private loadUsers(users: any[]): void {
		users.forEach((username) => {
			this.userApi.getUserByUsername(username).pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe((userData) => {
				this.users[username] = userData;
				this.commentedUsers.push(userData);
			});
		});
	}

	/**
	 * Send new message
	 * @param message
	 */
	onSendComment() {
		if (this.message === '') return;
		if (this.isBusy) return;
		if (this.isTimeRangeCommentShow) {
			if (!this.message || !this.isPlayerReady) {
				return;
			}
			let body = this.message;
			if (this.isRangeActive) {
				if (this.startTime >= this.endTime) {
					this.endTime = this.startTime + 0.5;
				}
			} else {
				this.endTime = this.startTime;
			}
			onAddNewAnnotation.emit({
				act: 'newAnnotation',
				annotationWithoutShape: true,
				annotation: {
					range: {
						start: parseFloat((Math.round(this.startTime * 100) / 100).toFixed(1)),
						end: parseFloat((Math.round(this.endTime * 100) / 100).toFixed(1))
					},
					commentStr: body
				},
				projectId: this.projectId,
				sectionId: this.sectionId,
				subSectionId: this.subsectionId,
				typeId: this.typeId,
				itemId: this.item.id
			});
			this.resetComponent();
		} else {
			this.onChangeApplied();
			if (this.isTextContentEmptyAndCommentAuthority(this.message))
				return;

			this.isBusy = true;
			this.commentsService.postComment(this.message, this.projectId, this.sectionId, this.subsectionId, this.typeId, void 0, void 0, void 0, false, false).pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe(
				(data: any) => {
					this.resetComponent();
				},
				(err: any) => {
					this.message = '';
					this.isBusy = false;
				}
			)
		}
	}

	resetComponent() {
		this.startTime = 0;
		this.endTime = 0
		this.message = '';
		this.isVideoFile = HelperService.isVideoFile(this.item);
		this.isImageFile = HelperService.isImageFile(this.item);
		this.isAudioFile = HelperService.isAudioFile(this.item);
		this.isTimeRangeCommentShow = false;
		this.isBusy = false;
		this.activeTimeRange = '';
		this.isRangeActive = false;
		this.replyMessage = '';
		this.replyCommentId = void 0;
		this.editedComment = null;
		this.newFiles = [];
	}

	/**
	 * Delete a comment
	 * @param comment
	 */
	private onDeleteComment(comment: any) {
		this.commentsService.deleteComment(comment).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(
			(data: any) => {
				let _comments = [];
				this.commentObjects.forEach((c: any) => {
					if (c.id != comment.id || c.time != comment.time || c.username != comment.username || c.message != comment.message)
						_comments.push(c);
				})
				this.commentObjects = _comments;
				this.mapGeneralCommentModel();
				this.onChangeApplied();
			});
	}


	/**
	 * Reply comment
	 *
	 * @param comment
	 */
	private replyComment(comment: any) {
		this.replyMessage = '';
		comment.isReplying = true;
		this.replyCommentId = comment.id;
		setTimeout(() => {
			EmitterService.get(CommentReplyFocusEvent).emit({
				parentId: comment.id
			});
		}, 100);

	}

	showReplies(comment: any) {
		if (this.previewAnnotation) {
			onShowAnnotationCommentReplies.emit({ comment });
		} else {
			this.replyShowId = (this.replyShowId === comment.id ? void 0 : comment.id);
		}
	}

	/**
	 * Edit comment
	 *
	 * @param comment
	 */
	private editComment(comment: any): void {
		if (this.isCommentable === true) {
			comment.editingMessage = comment.body;
			comment.isMessageEdit = true;
			this.replyCommentId = void 0;
			comment.isReplying = false;
			this.editedComment = comment;
			setTimeout(() => EmitterService.get(CommentEditFocusEvent).emit({
				commentId: comment.id
			}), 100);
		} else if (this.isCommentable == false || ((!this.isPlayerReady && comment.annotation) && this.isAnnotationModeActive)) {
			return;
		} else {
			comment.editingMessage = comment.body;
			comment.isMessageEdit = true;
			this.editedComment = comment;
			setTimeout(() => EmitterService.get(CommentEditFocusEvent).emit({
				commentId: comment.id
			}), 100);
		}
	}

	private sendEditedComment(comment: any, attachments: any) {
		if (comment.editingMessage === '') return;
		if (!comment.annotation) {
			this.onChangeApplied();
			if (!comment.isMessageEdit || this.isTextContentEmptyAndCommentAuthority(comment.editingMessage)) {
				return;
			}
			this.commentsService
				.postComment(comment.editingMessage, this.projectId, this.sectionId, this.subsectionId, this.typeId, comment.id, comment.datetime, comment.parentId, false, true, comment.files).pipe(
					takeUntil(this.ngUnsubscribe)
				).subscribe(
					(data: any) => {
						this.commentObjects.forEach((c) => {
							if (c.id === data.id && c.time == data.time && c.username == data.username)
								c.message = data.message;
						});
					}, err => {
						console.log("went an error when were editing comment");
					});
			comment.isMessageEdit = false;
			comment.editingMessage = '';
			this.editedComment = null;
		} else {
			if (this.isAnnotationModeActive) {
				if (!comment.isMessageEdit || this.isTextContentEmptyAndCommentAuthority(comment.editingMessage)) {
					return;
				}
				this.annotationPlugin.fire('updateComment', {
					annotationId: comment.annotation.id,
					commentId: comment.id,
					body: comment.editingMessage,
					files: attachments
				});
				comment.isMessageEdit = false;
				comment.editingMessage = '';
				this.editedComment = null;
			} else {
				let annotation = _.find(this.mediaAnnotations, (annotation) => annotation.id === comment.annotation.id);
				let editedComment = _.find(annotation.comments, (commentData) => comment.id === commentData.id);
				editedComment.meta.updatetime = moment.utc().valueOf();
				editedComment.body = comment.editingMessage;
				this.isBusy = true;
				this.annotationService
					.addNewAnnotation(this.projectId, this.sectionId, this.subsectionId, this.typeId, this.item.id, annotation, this.projectParticipants).pipe(
						takeUntil(this.ngUnsubscribe)
					)
					.subscribe(
						(res: any) => {
							this.isBusy = false;
						},
						(err: any) => {
							this.isBusy = false;
						}
					);
			}
		}
	}

	/**
	 * delete comment
	 *
	 * @param comment
	 */
	private deleteComment(comment: any, isAnnotationComment?: boolean, annotation?: any): void {
		if (isAnnotationComment) {
			if (annotation && !comment.parentId) {
				onDeleteAnnotation.emit({
					act: 'deleteAnnotation',
					annotation: annotation,
					projectId: this.projectId,
					sectionId: this.sectionId,
					subSectionId: this.subsectionId,
					typeId: this.typeId,
					itemId: this.item.id
				});
				this.annotationService
					.deleteAnnotation(this.projectId, this.sectionId, this.subsectionId, this.typeId, this.item.id, comment.annotation.id).pipe(
						takeUntil(this.ngUnsubscribe)
					)
					.subscribe(
						(data) => {
							onAnnotationsStateChanged.emit({
								args: 'annotationStateChanged',
								data: this.mediaAnnotations,
								projectId: this.projectId,
								sectionId: this.sectionId,
								subSectionId: this.subsectionId,
								typeId: this.typeId,
								itemId: this.item.id
							});
							this.isBusy = false;
						},
						(err) => {
							this.isBusy = false;
						}
					);
			} else {
				onDeleteCommentAnnotation.emit({
					act: 'deleteAnnotationComment',
					comment: comment,
					annotation: annotation,
					projectId: this.projectId,
					sectionId: this.sectionId,
					subSectionId: this.subsectionId,
					typeId: this.typeId,
					itemId: this.item.id
				});
				this.annotationService
					.deleteAnnotationComment(this.projectId, this.sectionId, this.subsectionId, this.typeId, this.item.id, comment.annotation.id, comment.id).pipe(
						takeUntil(this.ngUnsubscribe)
					)
					.subscribe(
						(data) => {
							onAnnotationsStateChanged.emit({
								args: 'annotationStateChanged',
								data: this.mediaAnnotations,
								projectId: this.projectId,
								sectionId: this.sectionId,
								subSectionId: this.subsectionId,
								typeId: this.typeId,
								itemId: this.item.id
							});
							this.isBusy = false;
						},
						(err) => {
							this.isBusy = false;
						}
					);
			}
		} else {
			this.onDeleteComment(comment);
		}
	}

	showDeleteConfirmModal(comment: any, isAnnotationComment?: boolean, annotation?: any) {
		this.notificationService.open({
			title: 'Delete Comment',
			description: 'Are you sure you want to delete this comment?',
			confirmBtn: 'Delete',
			cancelBtn: 'No',
			windowClass: 'delete-comment-modal',
		}).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(confirm => {
			if (!confirm) {
				return;
			}
			if (isAnnotationComment) {
				this.deleteComment(_.omit(comment, ['replies']), isAnnotationComment, annotation);
			} else {
				this.deleteComment(_.omit(_.find(this.commentObjects, (object) => object.id === comment.id), ['replies']));
			}
		});
	}

	/**
	 * Open modal to confirm the deletion
	 */
	private confirmDeletion(comment: any): void {
		if (comment.annotation) {
			this.showDeleteConfirmModal(comment, true, _.find(this.mediaAnnotations, (annotation) => annotation.id === comment.annotation.id));
			// if (this.isAnnotationModeActive) {
			// 	this.showDeleteConfirmModal(comment, true, _.find(this.mediaAnnotations, (annotation) => annotation.id === comment.annotation.id));
			// } else {
			// 	this.showDeleteConfirmModal(comment, true, _.find(this.mediaAnnotations, (annotation) => annotation.id === comment.annotation.id));
			// }
		} else {
			this.showDeleteConfirmModal(comment);
		}
	}

	private isTextContentEmptyAndCommentAuthority(textContent: string): boolean {
		return HelperService.isObjectEmpty(textContent) || this.isCommentable == false ? true : false;
	}

	private onChangeApplied() {
		this.changeApplied.emit(true);
	}

	getAnnotations() {
		let subject = new Subject<any>();
		this.annotation$.next(undefined);
		this.isLoadingAnnotations = true;

		this.annotationService
			.getMediaAnnotations({
				projectId: this.projectId,
				sectionId: this.sectionId,
				subsectionId: this.subsectionId,
				typeId: this.typeId,
				itemId: this.item.id,
				participants: this.projectParticipants,
				privateConditionRequest: this.privateCondition
			})
			//.getAnnotations(this.projectId, this.sectionId, this.subsectionId, this.typeId, this.item.id, this.projectParticipants)
			.pipe(
				takeUntil(this.annotation$),
				takeUntil(this.ngUnsubscribe)
			)
			.subscribe({
				next: (data) => {
					this.mediaAnnotations = data;
					_.map(this.mediaAnnotations, (annotation) => {
						if (annotation.range.start >= annotation.range.end) {
							annotation.range.end = annotation.range.start
						}
						annotation.rangeInTimeString = {
							start: annotation.range.start * 1000,
							end: annotation.range.end * 1000
						}
						annotation.secondRangeString = {
							start: annotation.range.start * 1000,
							end: annotation.range.end * 1000
						}
						let shapes = JSON.parse(annotation.shape);
						if (_.get(shapes, 'objects', []).length > 0) {
							if (parseFloat(annotation.range.start) === parseFloat(annotation.range.end) || parseFloat(annotation.range.end) - parseFloat(annotation.range.start) <= 0.06) {
								annotation.isCommentInTimeRange = false;
							} else {
								annotation.isCommentInTimeRange = true;
							}
						} else {
							// if (parseFloat(annotation.range.start) === parseFloat(annotation.range.end) || parseFloat(annotation.range.end) - parseFloat(annotation.range.start) <= 0.06) {
							// 	annotation.isCommentInTimeRange = false;
							// } else {
							// 	annotation.isCommentInTimeRange = true;
							// }
							annotation.isCommentInTimeRange = !(
								parseFloat(annotation.range.start) === parseFloat(annotation.range.end) ||
								parseFloat(annotation.range.end) - parseFloat(annotation.range.start) <= 0.06
							);

						}
						annotation.color = '#A9A9A9';
						annotation.colorOpacity = HelperService.hexToRgbA(annotation.color, 1);
					});
					this.isLoadingAnnotations = false;
					this.setDisplayedComments();
					this.reLoadUsers();
					subject.next(data);
				},
				error: (err) => {
					this.mediaAnnotations = [];
					this.isLoadingAnnotations = false;
					subject.error(err);
				}
			});

		return subject.asObservable();
	}

	formatAnnotationData(annotation: any) {
		if (annotation.range.start >= annotation.range.end) {
			annotation.range.end = annotation.range.start
		}
		if (!annotation.comments && annotation.commentList) {
			annotation.comments = annotation.commentList.comments
		}
		annotation.rangeInTimeString = {
			start: annotation.range.start * 1000,
			end: annotation.range.end * 1000
		}
		annotation.secondRangeString = {
			start: annotation.range.start * 1000,
			end: annotation.range.end * 1000
		}
		let shapes = JSON.parse(annotation.shape);
		if (_.get(shapes, 'objects', []).length > 0) {
			if (parseFloat(annotation.range.start) === parseFloat(annotation.range.end) || parseFloat(annotation.range.end) - parseFloat(annotation.range.start) <= 0.06) {
				annotation.isCommentInTimeRange = false;
			} else {
				annotation.isCommentInTimeRange = true;
			}
		} else {
			annotation.isCommentInTimeRange = !(
				parseFloat(annotation.range.start) === parseFloat(annotation.range.end) ||
				parseFloat(annotation.range.end) - parseFloat(annotation.range.start) <= 0.06
			);
		}
		annotation.color = '#A9A9A9';
		annotation.colorOpacity = HelperService.hexToRgbA(annotation.color, 1);

		return annotation;
	}

	setDisplayedComments() {
		if (this.isLoadingAnnotations || this.isLoadingComments) {
			return;
		}

		if (this.annotationsObject) {
			this.mediaAnnotations = []

			this.annotationsObject.forEach(annotation => {
				this.mediaAnnotations.push(this.formatAnnotationData(annotation))
			});
		}

		if (this.previewAnnotation) {
			this.mediaAnnotations = _.filter(this.mediaAnnotations, { id: this.previewAnnotation.id });
			this.generalComments = [];
		}


		this.comments =
			this.commentsService.mergeComment(!this.privateCondition ? (this.generalComments || []) : [], this.mediaAnnotations || []);
		this.comments.map(comment => {
			if (comment.id == this.editedComment?.id) {
				comment.isMessageEdit = true;
				comment.editingMessage = comment.body;
			} else if (comment.id == this.replyShowId) {
				comment.replies.forEach(r => {
					if (r.id == this.editedComment?.id) {
						r.isMessageEdit = true;
						r.editingMessage = r.body;
					}
				})
			}
			return comment;
		});
		if (this.viewMore) {
			if (this.comments.length > this.totalInitialComments && !this.isAllShown) {
				this.commentsToDisplay = this.comments.slice(0, this.totalInitialComments);
			} else {
				this.commentsToDisplay = this.comments;
			}

		} else {
			this.commentsToDisplay = this.comments;
			this.isAllShown = true;
		}


		this.checkHighlightScroll();

		if (this.generalCommentMapped) {
			this.checkNotification();
		}
	}

	checkHighlightScroll() {
		if (!this.highlightToCheck) {
			return;
		}
		if (this.highlightToCheck.checkReplies) {
			this.commentsToDisplay.forEach(comment => {
				if (!comment?.replies?.length) {
					return;
				}
				const highlightedReply = comment.replies.find(reply => reply.id == this.hightLightedComment.id);
				if (!highlightedReply) {
					return;
				}
				this.replyShowId = comment.id;
				this.highlightToCheck.waitForResolve = false;
			});
		}

		if (this.canScrollToHighlightedComment) {
			setTimeout(() => {
				this.scrollToHighlightedComment();
			}, 1000);
		}

		if (!this.highlightToCheck.waitForResolve) {
			this.highlightToCheck = null;
		}
	}

	checkNotification() {
		/*
	  if (this.commentNotificationChecked) {
		  return;
		}
	
		this.commentNotificationChecked = true;
		const { notificationCode, commentId } = this.activatedRoute.snapshot.queryParams;
		if (notificationCode && commentId && this.isCommentDeleted(commentId)) {
		  this.notificationService.open({
			title: this.translatePipe.transform('commentDeletedTitle'),
			description: this.translatePipe.transform('commentDeleted'),
			confirmBtn: this.translatePipe.transform('ok')
		  }).pipe(
			takeUntil(this.ngUnsubscribe)
		  ).subscribe(confirm => { });
		}
		 */
	}

	isCommentDeleted(id) {
		return !this.comments.find((c) => c.id == id || c.replies.find(r => r.id == id));
	}

	reLoadUsers() {
		let users = [];
		this.commentObjects.forEach((c) => {
			if (users.indexOf(_.get(c, 'username')) === -1)
				users.push(c.username);
		});
		users = _.uniq([...users, ...this.loadUsersFromAnnotations()]);
		this.loadUsers(users || []);
	}

	loadUsersFromAnnotations() {
		let users = [];
		_.forEach(this.mediaAnnotations, (annotation) => {
			_.forEach(annotation.comments, (comment) => {
				users.push(comment.meta.user_id);
			});
		});
		return users;
	}

	selectComment(comment: any) {
		if (this.isAnnotationActive) {
			this.notificationService.open({
				description: 'You have an unsaved annotation/comment. Continue without saving?',
				confirmBtn: 'Continue',
				cancelBtn: 'Cancel',
				windowClass: 'discard-annotation-modal',
				icon: 'warning'
			}).pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe(confirm => {
				if (confirm) {
					this.switchCommentFocused(comment);
				}
			});
		} else {
			this.switchCommentFocused(comment);
		}
	}

	switchCommentFocused(comment: any) {
		if (comment.annotation) {
			this.hightLightedComment = comment;
			onAnnotationFocused.emit('onAnnotationFocused');
			onOpenAnnotation.emit({
				act: 'openAnnotation',
				annotation: _.find(this.mediaAnnotations, { id: comment.annotation.id }),
				projectId: this.projectId,
				sectionId: this.sectionId,
				subSectionId: this.subsectionId,
				typeId: this.typeId,
				itemId: this.item.id
			});
			this.scrollToActiveAnnotation();
		} else {
			this.hightLightedComment = void 0;
		}
	}

	scrollToHighlightedComment() {
		let inPreviewElements = document.getElementsByClassName('highlight') as any;
		if (inPreviewElements.length) {
			inPreviewElements[0].scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
			this.canScrollToHighlightedComment = false;
		}
	}

	scrollToPreviewComment() {
		let inPreviewElements = document.getElementsByClassName('comment-preview-active') as any;
		if (inPreviewElements.length) {
			inPreviewElements[0].scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
		}
	}

	scrollToActiveAnnotation() {
		let stylismElement;
		if (this.isAudioFile) {
			stylismElement = document.getElementsByClassName('audio-annotation-component') as any;
		} else if (this.isVideoFile) {
			stylismElement = document.getElementsByClassName('stylism') as any;
			let videoJsElement = document.getElementsByClassName('video-js') as any;
			videoJsElement[0].classList.add("vjs-has-started");
		}
		if (stylismElement.length) {
			stylismElement[0].scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
		}
	}

	newAnnotationAdd() {
		this.isRangeActive = true;
		this.disableAnnotation();
	}

	onTimeRangeCloseClick() {
		if (this.isRangeActive) {
			this.isRangeActive = false;
			this.endTime = this.startTime;
		} else {
			this.isTimeRangeCommentShow = false;
		}
		this.disableAnnotation();
	}

	replyAnnotationComment(annotation: any) {
		let body = this.replyMessage;
		onReplyCommentAnnotation.emit({
			act: 'replyAnnotationComment',
			data: {
				annotation: annotation,
				commentBody: body,
				files: this.newFiles
			},
			projectId: this.projectId,
			sectionId: this.sectionId,
			subSectionId: this.subsectionId,
			typeId: this.typeId,
			itemId: this.item.id,
			file: this.newFiles
		});
		this.replyMessage = '';
		this.replyCommentId = void 0;
		//this.newFiles = null;
	}

	guid() {
		let s4 = () => {
			return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
		}
		return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
	}

	wsOnCommentCreate(eventData) {
		let annotation = eventData.mediaAnnotation;
		let comment = this.annotationService.getCommentViewModel(eventData.commentCreated, this.projectParticipants);
		if ((this.item.id === annotation.itemId) && this.annotationPlugin) {
			let commentObj = this.comments.find(c => c.annotation?.id == annotation?.id);
			if (comment.parentId) {
				commentObj.replies.push(comment);
			} else {
				this.comments.push({ ...comment, annotation });
			}
			this.annotationPlugin.fire("newAnnotationUpdate", annotation);
			commentFileAdd.emit({
				projectId: this.projectId,
				sectionId: this.sectionId,
				subSectionId: this.subsectionId,
				comment: comment,
				annotationId: annotation.id,
				files: this.newFiles
			})
			wsAnnotationChange.emit();
			this.newFiles = [];
		}
	}

	onReplyComment(comment: any) {
		if (this.replyMessage === '' && !comment.annotation) return;
		if (!comment.annotation) {
			if (this.isBusy) {
				return;
			}
			this.onChangeApplied();
			if (this.isTextContentEmptyAndCommentAuthority(this.replyMessage)) {
				return;
			}
			this.isBusy = true;
			this.commentsService.postComment(this.replyMessage, this.projectId, this.sectionId, this.subsectionId, this.typeId, comment.id, _.get(comment, 'datetime', '').toString(), comment.parentId, true, false, comment.files).pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe(
				(data: any) => {
					this.resetComponent();
				},
				(err: any) => {
					console.log(err);
					this.message = '';
					this.isBusy = false;
				}
			)
		} else {
			if (this.isAnnotationModeActive) {
				let body = this.replyMessage || ' ';
				onReplyCommentAnnotation.emit({
					act: 'replyAnnotationComment',
					data: {
						annotation: _.find(this.mediaAnnotations, (annotation) => annotation.id === comment.annotation.id),
						commentBody: body
					},
					projectId: this.projectId,
					sectionId: this.sectionId,
					subSectionId: this.subsectionId,
					typeId: this.typeId,
					itemId: this.item.id
				});
				this.replyMessage = '';
				comment.isReplying = false;
				this.replyCommentId = void 0;
			} else {
				let user = this.authService.getAuthUser();
				let annotation = _.find(this.mediaAnnotations, (annotation) => annotation.id === comment.annotation.id);
				let annotationComment = new AnnotationComment();
				let time = moment.utc().valueOf();
				annotationComment.id = this.guid();
				annotationComment.body = this.replyMessage;
				annotationComment.parentId = comment.id;
				annotationComment.files = comment.files;
				annotationComment.meta = {
					datetime: time,
					updatetime: time,
					user_id: user.username,
					user_name: user.fullName
				}
				annotation.comments.push(annotationComment);
				this.isBusy = true;
				this.annotationService
					.addNewAnnotation(this.projectId, this.sectionId, this.subsectionId, this.typeId, this.item.id, annotation, this.projectParticipants).pipe(
						takeUntil(this.ngUnsubscribe)
					)
					.subscribe(
						(res: any) => {
							this.isBusy = false;
							this.replyMessage = '';
							comment.isReplying = false;
							this.replyCommentId = void 0;
						},
						(err: any) => {
							this.isBusy = false;
						}
					);
			}
		}
		if (!this.previewAnnotation) {
			setTimeout(() => {
				let commentInputAreaElements = document.querySelectorAll('.comment-section:not(.in-preview)') as any;
				if (commentInputAreaElements.length) {
					commentInputAreaElements[0].scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
					this.replyShowId = comment.id;
					this.replyCommentId = void 0;
				}
			}, 0);
		}
	}

	onStartTimeRangeChange(act: string) {
		this.endTime = 0;
		if (this.activeTimeRange !== 'startTime') {
			this.startTime = this.player.currentTime();
		}
		this.activeTimeRange = 'startTime';
		if (act === 'add') {
			this.startTime++;
		} else {
			this.startTime--;
			if (this.startTime < 0) {
				this.startTime = 0;
			}
		}
		if (this.startTime > this.player.duration()) {
			this.startTime = this.player.duration();
		}
		if (this.player) {
			this.player.currentTime(this.startTime);
		}
		this.disableAnnotation();
	}

	onEndTimeRangeChange(act: string) {
		if (this.activeTimeRange !== 'endTime') {
			this.endTime = this.player.currentTime();
		}
		this.activeTimeRange = 'endTime';
		if (act === 'add') {
			this.endTime++;
		} else {
			this.endTime--;
			if (this.endTime < 0) {
				this.endTime = 0;
			}
		}
		if (this.endTime > this.player.duration()) {
			this.endTime = this.player.duration();
		}
		if (this.endTime < this.startTime) {
			this.endTime = this.startTime;
		}
		this.disableAnnotation();
	}

	getTimeDisplay(time: any, isStartTime: boolean) {
		let timeDisplay = new Date(parseFloat(time) * 1000).toISOString().substr(14, 5);
		if (timeDisplay === '00:00' && !isStartTime) {
			timeDisplay = '--:--';
		}
		if (!isStartTime && (this.startTime === this.endTime)) {
			timeDisplay = '--:--';
		}
		return timeDisplay;
	}

	disableAnnotation() {
		onCommentInputFocused.emit({
			act: 'onCommentInputFocused',
			projectId: this.projectId,
			sectionId: this.sectionId,
			subSectionId: this.subsectionId,
			typeId: this.typeId,
			itemId: this.item.id
		});
		if (this.player) {
			this.startTime = this.player.currentTime();
		}
	}

	addNewVideoComment() {
		this.isTimeRangeCommentShow = true;
		this.disableAnnotation();
	}

	closeMarker() {
		if ((this.isImageFile || this.isAudioFile) && this.annotationPlugin) {
			this.annotationPlugin.fire('clickOutAnnotation');
		} else if (this.isVideoFile && this.annotationPlugin) {
			this.annotationPlugin.fire('closeAnnotationClicked');
		}
	}

	getFullname(username: any) {
		return _.get(this.userApi.usersList[username.toLowerCase()], 'fullName', username);
	}

	viewAllComments() {
		this.commentsToDisplay = this.comments;
		this.isAllShown = true;
	}

	copyLink(comment) {
		const tempEl = document.createElement('textarea');
		const params = [];
		params.push(`version=${this.hightLightedCommentAssetVersion}`);
		params.push(`fileId=${this.item.id}`);
		params.push(`type=COMMUNICATION`);
		params.push(`notificationCode=ANNOTATION_EDITED`);
		params.push(`commentId=${comment.id}`);
		if (this.privateCondition) {
			params.push(`privateConditionKey=${this.privateCondition}`);
		}
		params.push(`random=${moment().unix()}`);
		const paramUrl = params.join('&');

		tempEl.value = `http:${environment.web.baseUrl}/projects/${this.projectId}/${this.sectionId}/${this.subsectionId}/preview?${paramUrl}#${this.item.sourceId}`;
		document.body.appendChild(tempEl);
		tempEl.select();
		document.execCommand('copy');
		document.body.removeChild(tempEl);
	}

	getCommentLink(comment: any): string {
		const params = [];
		params.push(`version=${this.hightLightedCommentAssetVersion}`);
		params.push(`fileId=${this.item.id}`);
		params.push(`type=COMMUNICATION`);
		params.push(`notificationCode=ANNOTATION_EDITED`);
		params.push(`commentId=${comment.id}`);
		if (this.privateCondition) {
			params.push(`privateConditionKey=${this.privateCondition}`);
		}
		//params.push(`random=${moment().unix()}`);
		const paramUrl = params.join('&');
		return `${HelperService.getCurrentOrigin()}/projects/${this.projectId}/${this.sectionId}/${this.subsectionId}/preview?${paramUrl}#${this.item.sourceId}`;
	}

	uploadFileCallback(fileObject) {
		const { files } = fileObject;
		if (!files) {
			return;
		}
		if (this.editedComment) {
			commentFileAdd.emit({
				projectId: this.projectId,
				sectionId: this.sectionId,
				subSectionId: this.subsectionId,
				annotationId: this.editedComment.annotation.id,
				comment: this.editedComment,
				files: [...this.editedComment.files, ...files]
			})
		} else {
			this.newFiles.push(...files);
		}
	}

	deleteFileCallback(file, comment) {
		comment.files = comment.files.filter(f => f.id != file.id);
		this.annotationPlugin.fire('updateComment', {
			annotationId: comment.annotation.id,
			commentId: comment.id,
			body: comment.body,
			files: comment.files
		});
		wsAnnotationChange.emit();
		this.directoryService.deleteFile(this.projectId, file.id).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(
			(res) => {
				this.checkAndDeleteCommentIfEmpty(comment);
				projectKeyFileDeleted.emit(file);
			}
		);
	}

	onDeleteNewFile(e, file) {
		this.newFiles = this.newFiles.filter(a => a.fileLocalId != file.fileLocalId);
	}

	checkAndDeleteCommentIfEmpty(comment) {
		if (comment.files.length) {
			return;
		}
		let el = document.createElement('div');
		el.innerHTML = comment.body;
		if (!el.innerText.trim()) {
			this.deleteComment(_.omit(comment, ['replies']), true, comment.annotation);
		}
	}
}
