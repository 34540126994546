import { Injectable } from '@angular/core';
import { ApiService } from "app/shared/services/api.service";
import { AuthService } from "app/shared/services/auth.service";
import { Observable } from "rxjs";
import { GetVersionFilesParams, StoryboardUpdateParams } from '../interfaces';

@Injectable({
	providedIn: 'root'
})
export class StoryboardService {

	projectId: string;
	sectionId: string;
	subsectionId: string;
	public prefix_url: string;

	constructor(private api: ApiService, public auth: AuthService) { }


	/**
	 * Retrieve subsection's storyboards
	 */
	public getStoryboards(projectId: string, sectionId: string, subsectionId: string): Observable<any> {
		let endpoint = `/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-storyboards`;
		//return this.api.setProfileAndImagesProbForItems(endpoint);
		return this.api.httpGet(endpoint);
	}


	/**
	 * Create a new Storyboard
	 *
	 * @param storyboard
	 */
	public saveStoryboard(args: StoryboardUpdateParams): Observable<any> {
		let url = `/api/projects/${args.projectId}/sections/${args.sectionId}/subsections/${args.subsectionId}/v-storyboards`;
		const urlParams = [];
		if (args.topic) {
			urlParams.push('topic=' + encodeURIComponent(args.topic));
		}
		if (args.topicId) {
			urlParams.push('topicId=' + encodeURIComponent(args.topicId));
		}
		const queryString = urlParams.join('&');
		if (queryString) {
			url += '?' + queryString;
		}
		return this.api.httpPost(url, args.storyBoard);
	}

	/**
	 * Delete a Storyboard given by ID
	 *
	 * @param storyboardId
	 */
	public deleteStoryboard(projectId: string, sectionId: string, subsectionId: string, storyboardId: string): Observable<any> {
		return this.api.httpDelete(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-storyboards/${storyboardId}`);
	}

	public renameStoryboard(projectId: string, sectionId: string, subsectionId: string, storyboardId: string, newName: string): Observable<any> {
		return this.api.httpPut(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-storyboards/${storyboardId}?newName=${newName}`, {});
	}


	/**
	 * Retrieve storyboard by ID
	 *
	 * @param storyboardId
	 */
	public getStoryboard(projectId: string, sectionId: string, subsectionId: string, storyboardId: string): Observable<any> {
		return this.api.httpGet(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-storyboards/${storyboardId}`);
	}

	public getVersion(args: GetVersionFilesParams): Observable<any> {
		let url = `/api/projects/${args.projectId}/sections/${args.sectionId}/subsections/${args.subsectionId}/` +
			`v-storyboards/${args.itemId}/${args.versionNumber}`;
		return this.api.httpGet(url);
	}

	public getSimpleStoryboard(projectId: string, sectionId: string, subsectionId: string, storyboardId: string): Observable<any> {
		const url = `/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/v-storyboards-simple/${storyboardId}`;
		return this.api.httpGet(url);
	}
}
