import { HelperService } from "app/shared/services/helper.service";
import { Shape } from "./shape";

export class SelectableShape extends Shape {

	canvasFabric: any;
	colourPicker: any;
	objectIndex: number;
	redoIndex: number;
	objectStore: any[];
	redoStore: any[];
	undoClear: any;
	selectedObject: any;
	imageDragging: boolean = false;
	readonly strokeWidth = 4
	readonly drawingTools: any[];

	constructor(image: Object) {
		super(image);
		this.initAPI(this, 'SelectableShape');

		this.drawingTools = ["rectangle", "circle", "arrow"]

		this.initUndoRedo();
		this.bindEvents();
	}

	// UNDO/REDO Section

	initUndoRedo(init = false) {
		this.objectIndex = 0;
		this.redoIndex = 0;
		this.objectStore = [];
		this.redoStore = [];
		this.undoClear = false;
		this.canvasFabric = this.plugin.canvasFabric;
		let clearState = JSON.parse(JSON.stringify(this.canvasFabric));
		if (!init) {
			clearState.objects = [];
		}
		else {
			// this.plugin.firstTouch = true;
		}
		// this.canvasFabric.setBackgroundImage(this.plugin.options.item.signedURL, img => {
		// 	this.canvasFabric.renderAll();
		// 	this.plugin.centerImage(false);
		// }, {
		// 		id: "background",
		// 		// excludeFromExport: true,
		// 		selectable: false,
		// 		hoverCursor: 'cursor'
		// 	}
		// );
		this.objectStore.push(JSON.stringify(clearState));
		this.objectIndex += 1;
		this.checkUndoRedoDisable();
		this.checkClearDisable();
	}

	checkUndoRedoDisable() {
		// Disables undo/redo when they cannot be done
		if (this.objectIndex <= 1) {
			$("#undoIcon").addClass("disabledButtonChange");
		}
		else {
			$("#undoIcon").removeClass("disabledButtonChange");
		}

		if (this.redoIndex == 0) {
			$("#redoIcon").addClass("disabledButtonChange");
		}
		else {
			$("#redoIcon").removeClass("disabledButtonChange");
		}
	}

	undo() {
		let _this2 = this;
		if (_this2.objectIndex > 1) {
			_this2.objectIndex -= 1;
			_this2.renderState(_this2.objectStore[_this2.objectIndex - 1]);
			_this2.redoStore.push(_this2.objectStore[_this2.objectStore.length - 1]);
			_this2.redoIndex++;
			_this2.objectStore.pop();
			_this2.checkUndoRedoDisable();
			_this2.checkClearDisable();
		}
		//   else if (_this2.undoClear == true) {
		// 	_this2.objectStore.forEach(function(object) {
		// 	  canvasFabric.add(object);
		// 	});
		// 	_this2.undoClear = false;
		//   }
	}

	redo() {
		if (this.redoIndex > 0) {
			this.redoIndex -= 1;
			this.renderState(this.redoStore[this.redoIndex]);
			this.objectStore.push(this.redoStore[this.redoIndex]);
			this.objectIndex++;
			this.redoStore.pop();
			this.checkUndoRedoDisable();
			this.checkClearDisable();
		}
	}

	checkClearDisable() {
		let isObjectsExists = false;
		this.canvasFabric.getObjects().forEach((obj) => {
			if (!obj.excludeFromExport) {
				isObjectsExists = true;
			}
		});

		if (isObjectsExists) {
			$("#clearIcon").removeClass("disabledButtonChange");
		}
		else {
			$("#clearIcon").addClass("disabledButtonChange");
		}
	}

	enableSelectableObjects() {
		let _this2 = this;
		if (_this2.selectedObject === "cursor") {
			_this2.canvasFabric.getObjects().forEach((obj) => {
				if (obj.id !== "background" && !obj.excludeFromExport) {
					obj.set({ selectable: true });
					obj.hoverCursor = 'move';
					_this2.canvasFabric.renderAll();
				}
			});
			let currentState = JSON.stringify(_this2.canvasFabric);
			_this2.renderState(currentState);
		}
	}

	disableSelectableObjects() {
		let _this2 = this;
		if (_this2.selectedObject != 4) {
			_this2.canvasFabric.getObjects().forEach((obj) => {
				obj.selectable = false;
			});
			_this2.canvasFabric.discardActiveObject().renderAll();
		}
	}

	// State here is a stringified canvasFabric object
	renderState(state) {
		let _this2 = this
		// canvasFabric.remove(...canvasFabric.getObjects());
		_this2.canvasFabric.getObjects().forEach(object => {
			if (!object.excludeFromExport) {
				_this2.canvasFabric.remove(object);
			}
		});
		const imageURL = HelperService.isConvertibleImageFormat(_this2.plugin.options.item) ? _this2.plugin.options.item.signedURL + '?thumbnail=_IMG'
			: _this2.plugin.options.item.signedURL;
		_this2.canvasFabric.setBackgroundImage(imageURL, img => {
			_this2.canvasFabric.renderAll();
		}, {
			id: "background",
			// excludeFromExport: true,
			selectable: false,
			hoverCursor: 'cursor'
		}
		);
		// Setting isStateObject to avoid considering tho
		fabric.util.enlivenObjects(JSON.parse(state).objects, (objs) => {
			objs.forEach((o) => {
				o.set("isStateObject", true);
				_this2.canvasFabric.add(o)
			});
			_this2.canvasFabric.renderAll();

			_this2.canvasFabric.getObjects().forEach((obj) => {
				obj.set({ isStateObject: false });
			});
		});
	}

	changeActiveTool(tool) {
		let canvasFabric = this.canvasFabric;

		if (!this.plugin.firstTouch) this.startMouseEvents();
		if (tool === "pencil") {
			this.selectedObject = "pencil";
			this.freeDrawTemp();
			this.disableSelectableObjects();
		}
		else if (tool === "arrow") {
			this.selectedObject = "arrow";
			canvasFabric.isDrawingMode = false;
			this.disableSelectableObjects();
		}
		else if (tool === "rectangle") {
			this.selectedObject = "rectangle";
			canvasFabric.isDrawingMode = false;
			this.disableSelectableObjects();
		}
		else if (tool === "circle") {
			this.selectedObject = "circle";
			canvasFabric.isDrawingMode = false;
			this.disableSelectableObjects();
		}
		else if (tool === "cursor") {
			this.selectedObject = "cursor";
			canvasFabric.isDrawingMode = false;
			this.enableSelectableObjects();
		}
		else if (tool === "none") {
			this.selectedObject = "none"
			canvasFabric.isDrawingMode = false;
			this.disableSelectableObjects();
		}
		else if (tool === "delete") {
			let activeObject = canvasFabric.getActiveObject();
			canvasFabric.remove(activeObject);
			canvasFabric.fire("object:modified", { target: activeObject });
		}
		else if (tool === "undo") {
			this.undo();
		}
		else if (tool === "redo") {
			this.redo();
		}
		else if (tool === "clear") {
			canvasFabric.getObjects().forEach(object => {
				if (!object.excludeFromExport) {
					canvasFabric.remove(object);
				}
			});
			let currentState = JSON.stringify(this.canvasFabric);
			this.objectStore.push(currentState);
			this.objectIndex += 1;
			// _this2.initUndoRedo();
			this.checkUndoRedoDisable();
			this.checkClearDisable();
		}

	}

	bindEvents() {
		let _this2 = this;

		const canvasFabric = this.plugin.canvasFabric;

		// Clear cavas and update currentActive accordingly
		// this.plugin.annotationState.currentActive = new Set();

		const colourPicker = this.plugin.colourPicker;
		colourPicker.set("#A3F307", true);

		// $(".annotation-color-picker").on('click', function () {
		// 	if (!_this2.plugin.firstTouch) _this2.startMouseEvents();
		// });

		// $("#pencilIcon").on('click', function () {
		// 	if (!_this2.plugin.firstTouch) _this2.startMouseEvents();
		// 	_this2.selectedObject = 0;
		// 	_this2.freeDrawTemp();
		// 	disableSelectableObjects();
		// });
		// $("#lineIcon").on('click', function () {
		// 	if (!_this2.plugin.firstTouch) _this2.startMouseEvents();
		// 	_this2.selectedObject = 1;
		// 	canvasFabric.isDrawingMode = false;
		// 	disableSelectableObjects();
		// });
		// $("#rectangleIcon").on('click', function () {
		// 	if (!_this2.plugin.firstTouch) _this2.startMouseEvents();
		// 	_this2.selectedObject = 2;
		// 	canvasFabric.isDrawingMode = false;
		// 	disableSelectableObjects();
		// });
		// $("#circleIcon").on('click', function () {
		// 	if (!_this2.plugin.firstTouch) _this2.startMouseEvents();
		// 	_this2.selectedObject = 3;
		// 	canvasFabric.isDrawingMode = false;
		// 	disableSelectableObjects();
		// });
		// $("#cursorIcon").on('click', function () {
		// 	if (!_this2.plugin.firstTouch) _this2.startMouseEvents();
		// 	_this2.selectedObject = 4;
		// 	canvasFabric.isDrawingMode = false;
		// 	_this2.enableSelectableObjects();
		// });

		// $("#clearIcon").on('click', () => {
		// 	// if (!_this2.plugin.firstTouch) startMouseEvents();
		// 	// _this2.redoStore = [];
		// 	// _this2.redoIndex = 0;
		// 	// _this2.undoClear = true;
		// 	canvasFabric.getObjects().forEach(object => {
		// 		if (!object.excludeFromExport) {
		// 			canvasFabric.remove(object);
		// 		}
		// 	});
		// 	let currentState = JSON.stringify(this.canvasFabric);
		// 	this.objectStore.push(currentState);
		// 	this.objectIndex += 1;
		// 	// _this2.initUndoRedo();
		// 	_this2.checkUndoRedoDisable();
		// 	_this2.checkClearDisable();
		// });

		// $("#deleteIcon").on('click', () => {
		// 	let activeObject = canvasFabric.getActiveObject();
		// 	canvasFabric.remove(activeObject);
		// 	canvasFabric.fire("object:modified", { target: activeObject });
		// });

		// $("#undoIcon").on('click', _this2.undo);

		// $("#redoIcon").on('click', function () {
		// 	if (_this2.redoIndex > 0) {
		// 		_this2.redoIndex -= 1;
		// 		_this2.renderState(_this2.redoStore[_this2.redoIndex]);
		// 		_this2.objectStore.push(_this2.redoStore[_this2.redoIndex]);
		// 		_this2.objectIndex++;
		// 		_this2.redoStore.pop();
		// 		_this2.checkUndoRedoDisable();
		// 		_this2.checkClearDisable();
		// 	}
		// });

		//Initialising variables
		_this2.initUndoRedo();

		canvasFabric.on('object:added', (e) => {
			if (e.target.path) {
				canvasFabric.fire("object:modified", { target: e.target });
			}
		});

		canvasFabric.on('selection:created', (e) => {
			this.plugin.primordial.drawingSelected = true;
		});

		canvasFabric.on('selection:cleared', (e) => {
			this.plugin.primordial.drawingSelected = false;
		});

		canvasFabric.on('object:modified', (e) => {
			if (_this2.undoClear == false) {
				if (!e.target.excludeFromExport && !e.target.isStateObject) {
					_this2.objectStore.push(JSON.stringify(canvasFabric));
					_this2.objectIndex += 1;
					_this2.checkUndoRedoDisable();
					_this2.checkClearDisable();
				}
			}
		});

	}

	freeDrawTemp() {
		this.canvasFabric.isDrawingMode = true;
		this.canvasFabric.freeDrawingBrush.width = this.strokeWidth;
		this.canvasFabric.freeDrawingBrush.color = this.canvasFabric.drawColor;
	}

	startMouseEvents() {
		let _this2 = this;
		let shapeObject;
		let line;
		let triangle;
		let deltaX;
		let deltaY;
		let isDown;
		let origX;
		let origY;

		if (!_this2.plugin.firstTouch) {
			_this2.initUndoRedo(true);
			if (!_this2.plugin.controls.uiState.adding && !_this2.plugin.controls.uiState.editing) _this2.plugin.controls.startAddNew();
			_this2.plugin.firstTouch = true;

		}

		$(document).on('keydown', function (o) {
			if (o.key === "Alt" && !_this2.imageDragging) {
				_this2.canvasFabric.isDrawingMode = false;
				_this2.imageDragging = true;
			}
		});

		$(document).on('keyup', function (o) {
			if (o.key === "Alt" && _this2.imageDragging) {
				if (_this2.selectedObject === "pencil") {
					_this2.canvasFabric.isDrawingMode = true;
				}
				_this2.imageDragging = false;
			}
		});

		// Events for triggering toolbars
		_this2.canvasFabric.on('mouse:down', function (o) {
			if (o.e.altKey === true) {
				return;
			}
			_this2.redoIndex = 0;
			_this2.redoStore = [];
			// if (_this2.undoClear == true) {
			// 	_this2.initUndoRedo();
			// }
			if (_this2.drawingTools.includes(_this2.selectedObject)) {
				// Disabling hover and click on
				isDown = true;
				let pointer = _this2.canvasFabric.getPointer(o.e);
				pointer.x = Math.max(0, pointer.x);
				pointer.y = Math.max(0, pointer.y);
				pointer.x = Math.min(pointer.x, _this2.canvasFabric.backgroundImage.width);
				pointer.y = Math.min(pointer.y, _this2.canvasFabric.backgroundImage.height);
				origX = pointer.x;
				origY = pointer.y;
				pointer = _this2.canvasFabric.getPointer(o.e);
				pointer.x = Math.max(0, pointer.x);
				pointer.y = Math.max(0, pointer.y);
				pointer.x = Math.min(pointer.x, _this2.canvasFabric.backgroundImage.width);
				pointer.y = Math.min(pointer.y, _this2.canvasFabric.backgroundImage.height);

				// Event for line drawing
				if (_this2.selectedObject === "arrow") {
					var points = [pointer.x, pointer.y, pointer.x, pointer.y];
					line = new fabric.Line(points, {
						strokeWidth: _this2.strokeWidth,
						fill: _this2.canvasFabric.drawColor,
						stroke: _this2.canvasFabric.drawColor,
						hoverCursor: 'default',
						originX: 'center',
						originY: 'center',
					});
					var centerX = (line.x1 + line.x2) / 2;
					var centerY = (line.y1 + line.y2) / 2;
					deltaX = line.left - centerX;
					deltaY = line.top - centerY;

					triangle = new fabric.Triangle({
						left: line.get('x1') + deltaX,
						top: line.get('y1') + deltaY,
						originX: 'center',
						originY: 'center',
						selectable: false,
						angle: -45,
						width: 20,
						height: 20,
						fill: _this2.canvasFabric.drawColor,
						hoverCursor: 'default'
					});
					_this2.canvasFabric.add(line, triangle);


					// Event for rectangle drawing
				} else if (_this2.selectedObject === "rectangle") {
					shapeObject = new fabric.Rect({
						left: origX,
						top: origY,
						originX: 'left',
						originY: 'top',
						width: pointer.x - origX,
						height: pointer.y - origY,
						angle: 0,
						fill: 'rgba(0,0,0,0)',
						stroke: _this2.canvasFabric.drawColor,
						selectable: false,
						strokeWidth: _this2.strokeWidth,
						hoverCursor: 'default'
					});
					_this2.canvasFabric.add(shapeObject);

					// Event for circle drawing
				} else if (_this2.selectedObject === "circle") {
					shapeObject = new fabric.Circle({
						left: origX,
						top: origY,
						originX: 'center',
						originY: 'center',
						width: Math.pow((Math.pow((pointer.x - origX), 2) + Math.pow((pointer.y - origY), 2)), 0.5),
						angle: 0,
						selectable: false,
						fill: 'rgba(0,0,0,0)',
						stroke: _this2.canvasFabric.drawColor,
						strokeWidth: _this2.strokeWidth,
						hoverCursor: 'default'
					});
					_this2.canvasFabric.add(shapeObject);
				}

			}
			if (_this2.selectedObject === "pencil") {
				_this2.freeDrawTemp();
			}
		});

		var _FabricCalcArrowAngle = function (x1, y1, x2, y2) {
			var angle = 0, x, y;
			x = (x2 - x1);
			y = (y2 - y1);
			if (x === 0) {
				angle = (y === 0) ? 0 : (y > 0) ? Math.PI / 2 : Math.PI * 3 / 2;
			} else if (y === 0) {
				angle = (x > 0) ? 0 : Math.PI;
			} else {
				angle = (x < 0) ? Math.atan(y / x) + Math.PI :
					(y < 0) ? Math.atan(y / x) + (2 * Math.PI) : Math.atan(y / x);
			}
			return (angle * 180 / Math.PI + 90);
		};

		_this2.canvasFabric.on('mouse:move', function (o) {
			if (o.e.altKey === true) {
				return;
			}
			if (_this2.drawingTools.includes(_this2.selectedObject)) {
				if (!isDown) return;
				const pointer = _this2.canvasFabric.getPointer(o.e);
				pointer.x = Math.max(0, pointer.x);
				pointer.y = Math.max(0, pointer.y);
				pointer.x = Math.min(pointer.x, _this2.canvasFabric.backgroundImage.width);
				pointer.y = Math.min(pointer.y, _this2.canvasFabric.backgroundImage.height);
				if (_this2.selectedObject === "arrow") {

					line.set({
						x2: pointer.x,
						y2: pointer.y
					});
					triangle.set({
						'left': pointer.x + deltaX,
						'top': pointer.y + deltaY,
						'angle': _FabricCalcArrowAngle(line.x1,
							line.y1,
							line.x2,
							line.y2)
					});
				}
				if (_this2.selectedObject === "circle") {
					let r = Math.pow((Math.pow((origX - pointer.x), 2) + Math.pow((origY - pointer.y), 2)), 0.5);
					r = Math.min(r, shapeObject.left, _this2.canvasFabric.backgroundImage.width - shapeObject.left);
					r = Math.min(r, shapeObject.top, _this2.canvasFabric.backgroundImage.height - shapeObject.top);
					shapeObject.set({ 'radius': r });
				}
				if (_this2.selectedObject === "rectangle") {
					if (origX > pointer.x) {
						shapeObject.set({ left: Math.abs(pointer.x) });
					}
					if (origY > pointer.y) {
						shapeObject.set({ top: Math.abs(pointer.y) });
					}
					shapeObject.set({ width: Math.abs(origX - pointer.x) });
					shapeObject.set({ height: Math.abs(origY - pointer.y) });
				}
				_this2.canvasFabric.renderAll();
			}
		});

		_this2.canvasFabric.on('mouse:up', function (o) {
			if (o.e.altKey === true) {
				return;
			}
			if (_this2.drawingTools.includes(_this2.selectedObject)) {
				isDown = false;
			}
			if (_this2.selectedObject === "arrow") {
				// Grouping the line and triangle
				var group = new fabric.Group([line, triangle],
					{
						selectable: false,
						hoverCursor: 'default'
					}
				);
				// Calling undo twice to remove individual line triangle for arrow
				// undo();
				// undo();
				_this2.canvasFabric.remove(line);
				_this2.canvasFabric.remove(triangle);
				_this2.canvasFabric.add(group);
				_this2.canvasFabric.fire("object:modified", { target: group });
				$("#cursorIcon").trigger('click');
			}
			else if (_this2.selectedObject === "rectangle") {
				_this2.canvasFabric.fire('object:modified', { target: shapeObject });
				$("#cursorIcon").trigger('click');
			}
			else if (_this2.selectedObject === "circle") {
				_this2.canvasFabric.fire('object:modified', { target: shapeObject });
				$("#cursorIcon").trigger('click');
			}
		});

		_this2.canvasFabric.on('path:created', function (opt) {
			opt.path.selectable = false;
			shapeObject = opt.path;
			opt.path.set('hoverCursor', 'cursor');
		});
	}
}