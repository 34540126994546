<div class="video-recorder-modal pl-4 pr-4 pt-3 pb-3">
    <i class="fa fa-times pointer" (click)="closeModal()"></i>
    <div class="d-flex flex-column flex-lg-row align-items-center">
        <div class="d-block flex-fill">
            <audio *ngIf="!isAudioRecording && audioBlobUrl" class="w-100" controls>
                <source [src]="audioBlobUrl" type="audio/mp3">
            </audio>
            <div *ngIf="!(!isAudioRecording && audioBlobUrl)">
                <mtm-audio-mic-visualizer (onError)="onMediaError($event)"></mtm-audio-mic-visualizer>
            </div>
        </div>
        <div *ngIf="!(!isAudioRecording && audioBlobUrl)" class="d-flex justify-content-center _600"
            [style.font-weight]="'500'">
            <span [style.width]="'32px'" [style.text-align]="'right'">{{(minutes < 10 ? '0' : '' ) + minutes}}</span>
                    <span [style.padding]="'0 2px'">:</span>
                    <span [style.width]="'32px'" [style.text-align]="'left'">{{(seconds < 10 ? '0' : '' ) +
                            seconds}}</span>
        </div>
        <div class="d-block">
            <button [disabled]="!allowed" class="btn mtm-button b-mtm-black _600 pointer text-center c-full-white pt-2 pb-2 start-button"
                *ngIf="!isAudioRecording && !audioBlobUrl" (click)="startAudioRecording()">Record</button>
            <button [disabled]="!allowed" class="btn mtm-button b-mtm-black _600 pointer text-center c-full-white pt-2 pb-2 stop-button"
                *ngIf="isAudioRecording && !audioBlobUrl" (click)="stopAudioRecording()">Stop</button>
        </div>
    </div>
    <div *ngIf="!isAudioRecording && audioBlobUrl" class="d-flex mt-2">
        <button [disabled]="!allowed" class="mr-2 btn btn-block mtm-button mtm-grey-button _600 c-mtm-blue-grey pt-2 pb-2"
            *ngIf="!isAudioRecording && audioBlobUrl" (click)="clearAudioRecordedData()">Cancel</button>
        <button [disabled]="!allowed" class="ml-2 btn btn-block mtm-button mtm-black-button _600 c-full-white pt-2 pb-2"
            *ngIf="!isAudioRecording && audioBlobUrl" (click)="downloadAudioRecordedData()">Save</button>
    </div>
</div>