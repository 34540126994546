export const CommentEditFocusEvent = 'comment.editFocus';
export const CommentReplyFocusEvent = 'comment.replyFocus';

export const MaintenanceUpdatedEvent = 'maintenanceUpdated';

export const OptionCreatedEvent = 'optionCreated';
export const OptionDeletedEvent = 'optionDeleted';
export const OptionUpdatedEvent = 'optionUpdated';
export const TopicCreatedEvent = 'topicCreated';
export const VersionUpdatedEvent = 'versionUpdated';
export const FileUpdatedEvent = 'fileUpdated';
export const FileDeletedEvent = 'fileDeleted';
