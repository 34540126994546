import { ImageComp } from "../lib/image-component";

export class Shape extends ImageComp {

	shape: any;

	constructor(image: any, shape = null) {
		super(image);
		this.shape = shape;
	}

	openShape(id: string, resolution: any, excludeFromExport = true) {
		if (!this.shape) return;

		if (this.shape) {
			let canvasFabric = this.plugin.canvasFabric;
			let annotation_data = JSON.parse(this.shape);

			// Calculate scale factors for both width and height
			// const scaleX = canvasFabric.getWidth() / resolution.width;
			// const scaleY = canvasFabric.getHeight() / resolution.height;

			if (!excludeFromExport) {
				canvasFabric.remove(...canvasFabric.getObjects());
			}

			fabric.util.enlivenObjects(annotation_data.objects, function (objects) {
				objects.forEach(function (o) {
					// Apply scaling for both width and height
					// o.set('left', o.left * scaleX);
					// o.set('top', o.top * scaleY);
					// o.set('scaleX', o.scaleX * scaleX);
					// o.set('scaleY', o.scaleY * scaleY);
					// o.set('strokeWidth', o.strokeWidth / Math.min(scaleX, scaleY)); // Adjust stroke width based on the smaller scale
					o.set('id', id);
					o.set('selectable', false);
					o.set('hoverCursor', 'cursor');
					o.set('excludeFromExport', excludeFromExport);
					// o.setCoords();
					canvasFabric.add(o);
				});
				canvasFabric.renderAll();
			});
		};
	}

	closeShape(id: string) {
		const canvasFabric = this.plugin.canvasFabric;
		canvasFabric.getObjects().forEach(function (o) {
			if (o.id == id) {
				canvasFabric.remove(o);
			}
		});
		canvasFabric.renderAll();
	}
}
