import * as _ from 'lodash';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from "@angular/router";
import { onLoadProject, ProjectService } from "app/shared/services/project.service";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { NewUserProjectComponent } from "./new-user-project/new-user-project.component";
import { DeleteUserProjectComponent } from "./delete-user-project/delete-user-project.component";
import { BlockUserProjectComponent } from "./block-user-project/block-user-project.component";
import { UserService } from "app/shared/services/user.service";
import { AuthService } from "app/shared/services/auth.service";
import { NotificationService } from 'app/shared/services/notification.service';
import { ReinviteUserProjectComponent } from "./reinvite-user-project/reinvite-user-project.component";
import { PermissionsInfoModal } from '../../permissions-info-modal/permissions-info-modal.component';
import { OverlayService } from 'app/shared/services/overlayService';
import { Authority, PermissionService } from "app/shared/services/permissions.service";
import { takeUntil } from 'rxjs/operators';
import { Subject, forkJoin } from 'rxjs';
import { ColDef, RowClickedEvent } from 'ag-grid-community';
import { projectPermissionColumnsDef } from './table/project-permission-table.coldef';
import { projectTeamColumnsDef } from './team-table/project-team-table.coldef';
import { ProjectPermissionTableHeader } from './table/header/project-permission-table-header.component';
import { AgGridAngular } from 'ag-grid-angular';
import { TeamEditorComponent } from "app/shared/components/team-editor/team-editor.component";
import { WorkspaceService } from "app/shared/services/workspace.service";
import { TeamService } from 'app/shared/services/team.service';
import { EMPTY_ID } from 'app/shared/components/task/task-status.const';
import { Team } from 'app/shared/interfaces';
import { TranslatePipe } from "app/shared/pipes/translate.pipe";
import { HelperService } from 'app/shared/services/helper.service';
import { ProjectTeamTableHeader } from './team-table/header/project-team-table-header.component';
import { MtmBreadcrumbService } from 'app/shared/services/mtm-breadcrumb.service';
import { CryptoService } from 'app/shared/services/crypto.service';
import { environment } from 'environments/environment';
import { TimelineService } from "app/shared/services/timeline.service";
import { GoogleAnalyticsService } from "app/kanban/core/services/google-analytics.service"


@Component({
	selector: 'mtm-project-permissions',
	templateUrl: './project-permissions.component.html',
	styleUrls: ['./project-permissions.component.scss']
})
export class ProjectPermissionsComponent implements OnInit, OnDestroy {
	@ViewChild(AgGridAngular) projectPermissionTable!: AgGridAngular;
	@Input() isProposal: boolean = false;
	@Input() projectId;
	@Input() proposalId;

	users: any[] = [];
	roles: any[] = [];
	teams: Team[] = [];
	private participants: any[] = [];
	productionRoles: any[] = [];
	projectRoles: any[] = [];

	counterSelectedUser = 0;
	isAdmin: boolean = false;

	authUser: any = null;
	decision: boolean = true;
	isProductionSide: boolean = false;
	authUserHaveAValidationRight: boolean = false;
	isAuthUserIsClientSide: boolean = false;
	ngUnsubscribe = new Subject();

	tableView: string = 'userView';

	defaultColDef: ColDef = {
		sortable: false,
		resizable: true,
		filter: false
	};

	defaultTeamColDef: ColDef = {
		sortable: false,
		resizable: true,
		filter: false,
	};

	localFilterParams: { fullName: string, email: string, roles: any[], decisionMake: any } = { fullName: '', email: '', roles: [], decisionMake: '' };

	projectPermissionColumnsDef = projectPermissionColumnsDef;
	projectTeamColumnsDef = projectTeamColumnsDef;
	tableContext: any;
	teamTableContext: any;
	public components: { [p: string]: any } = { agColumnHeader: ProjectPermissionTableHeader, };
	public teamComponents: { [p: string]: any } = { agColumnHeader: ProjectTeamTableHeader };
	allRoles: any = [];
	brandId: string | null = null;
	project: any;
	private workspaceId: string = '';
	workspaceName: string;
	isCpd: boolean = false;
	driveCampaign: any;
	projectUsers: any[];
	constructor(public auth: AuthService,
		public userService: UserService,
		public service: ProjectService,
		private router: Router,
		private modalService: NgbModal,
		private notificationService: NotificationService,
		private workspaceService: WorkspaceService, private teamService: TeamService,
		private translatePipe: TranslatePipe,
		private breadcrumbService: MtmBreadcrumbService,
		private googleAnalyticsService: GoogleAnalyticsService,
		private cryptoService: CryptoService,
		private overlayService: OverlayService, public permissionService: PermissionService,
		private timelineService: TimelineService) {
		this.tableContext = {
			componentParent: this
		};
		this.teamTableContext = {
			componentParent: this
		};
	}
	ngOnDestroy(): void {
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}


	ngOnInit() {
		this.authUser = this.auth.getAuthUser();
		this.isCpd = this.authUser.companyId == environment.ltg.cpdCompanyId;
		if (this.permissionService.hasAuthority(Authority.Z, null)) {
			this.isAdmin = true;
		}

		if (this.service.projectOnloaded) {
			if (this.service.project) {
				this.authUserHaveAValidationRight = this.permissionService.hasAuthority(Authority.V, this.projectId);
				this.isProductionSide = this.auth.hasProductionRole();
				this.isAuthUserIsClientSide = this.auth.hasProjectRole();
				if (!this.permissionService.hasAuthority(Authority.U)) {
					this.router.navigate(['projects', this.service.project.id]);
				}
				this.loadUsers();
				this.project = this.service.project;
				if (this.project.assetList?.length) {
					this.workspaceId = this.project.assetList[0].workspaceId;
				} else {
					this.workspaceId = this.project.workspaceId;
				}
			}
		}
		else
			onLoadProject.pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe((project: any) => {
				if (project.id) {
					this.project = project;

					if (this.project.assetList?.length) {
						this.workspaceId = this.project.assetList[0].workspaceId;
					} else {
						this.workspaceId = this.project.workspaceId;
					}
					this.loadUsers();
				}
			});
		this.loadRoles();
		this.retrieveExistingWorkspaceName();
	}

	ngAfterViewInit(): void {
		this.retrieveExistingWorkspaceName();
	}

	retrieveExistingWorkspaceName() {
		if (this.project && this.project.company && this.project.workspaceId) {
			this.workspaceService.getWorkspaces(this.project.company.id)
				.subscribe(async workspaces => {
					const existing = workspaces.find(w => w.id == this.project.workspaceId);
					if (existing && existing.name) {
						this.workspaceName = existing.name;
					}
					await this.handleCampaignEvents();
					this.getTeams();
					this.setBreadcrumb();
				});
		}
	}

	private async handleCampaignEvents(): Promise<void> {
		if (this.project?.campaignId) {
			this.driveCampaign = await this.timelineService.getTimeLine(this.project.workspaceId, this.project.campaignId);
		}
	}

	onChange(data, user) {
		if (data.length == 0) {
			if (!this.isProductionSide && this.isAuthUserIsClientSide) {
				user.memberOf = null;
			}
		} else {
			if (!this.isProductionSide && this.isAuthUserIsClientSide) {
				user.memberOf = null;
			} else {
				let productions = data.filter(d => d.startsWith("PRODUCTION"));
				let clients = data.filter(d => d.startsWith("PROJECT"));
				if (productions.length > 0)
					user.memberOf = "PRODUCTION";
				if (clients.length > 0)
					user.memberOf = "PROJECT";
			}
		}
		let filtered_1 = user.selectedRoles.filter(k => k.indexOf('OWNER') >= 0);
		let filtered_2 = user.roles.filter(k => k.indexOf('OWNER') >= 0);
		filtered_2.map(k => {
			let index = filtered_1.findIndex(l => l == k);
			if (index >= 0)
				filtered_1.splice(index, 1);
		});
		if (this.decision == false && filtered_1.length > 0 && this.getDecisionLenght() >= 1) {
			filtered_2.map(k => {
				let index = user.selectedRoles.findIndex(l => l == k);
				if (index >= 0)
					user.selectedRoles.splice(index, 1);
			});
		} else {
			this.service.setRoles(this.service.project.id, user.username, data).pipe(
				takeUntil(this.ngUnsubscribe)
			).subscribe((res) => {
				this.service.project.users = res.users;
				this.service.project.validators = res.validators;
				let userFound = res.participants.find(u => u.username == user.username);
				this.odmSelect(userFound);
				this.service.prepareProjectInit(this.service.project.id);
			}, (err: any) => {
				console.log(err);
			});
		}
	}

	/**
	 * Load users
	 */
	private loadUsers(): void {
		this.authUserHaveAValidationRight = this.permissionService.hasAuthority(Authority.V, this.projectId);
		this.service.getProjectUsers(this.service.project.id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
			{
				next: (data) => {
					this.projectUsers = data;
				},
				error: (err) => {
					console.log(err);
				}

			}
		)

		forkJoin([
			this.service.getProjectParticipantWithUserActivationStatus(this.service.project.id),
			this.service.getProject(this.service.project.id),
		]).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (result: any[]) => {
				const [participantsResult, projectResult] = result;
				this.participants = participantsResult;
				this.service.project = {
					...this.service.project,
					...projectResult
				};

				// Ensure projectUsers is initialized as an array
				if (!Array.isArray(this.projectUsers)) {
					this.projectUsers = [];
				}

				// Process users and add `userPermissionType` if matched with `projectUsers`
				this.users = _.filter(projectResult.participants.map((user, index) => {

					// Find matching user in projectUsers, ensure projectUsers is defined and an array
					const matchingProjectUser = this.projectUsers.find(projUser => projUser.email === user.email);

					if (matchingProjectUser) {
						// Dynamically add userPermissionType from matchingProjectUser
						user.userPermissionType = matchingProjectUser.userPermissionType;
					}

					user.index = index;
					user.isSelected = false;
					user.selectedRoles = JSON.parse(JSON.stringify(user.roles));
					this.odmSelect(user);

					let productionRoles = user.selectedRoles.filter(s => s.startsWith("PRODUCTION"));
					let projectRoles = user.selectedRoles.filter(s => s.startsWith("PROJECT"));

					if (productionRoles.length > 0 && this.isProductionSide)
						user.memberOf = "PRODUCTION";
					if (projectRoles.length > 0 && this.isProductionSide)
						user.memberOf = "PROJECT";

					user.activationStatus = this.participants.find(value => value.email === user.email)?.userActivationStatus;

					return user;
				}), (user) => {
					return user.roles[0] !== 'COMPANY_OCCASIONAL';
				});

				this.initDecision();
			},
			error: (err) => {
				console.log(err);
			}
		});

	}

	getTeams() {
		const { project } = this.service;
		this.teamService.getTeamByProjectId(project.company.id, this.brandId || HelperService.getEmptyUUID(), project.workspaceId, project.id)
			.subscribe((res: any) => {
				res.forEach((team: any) => {
					team.overrideRoles = false;
					team.roles = [];
				});
				res.sort((a: any, b: any) => a.name.localeCompare(b.name));
				this.teams = res;
			});
	}

	private odmSelect(user: any) {
		user.showOdm = false;
		if (user.roles.includes("PROJECT_OWNER") || user.roles.includes("PROJECT_MANAGER")) {
			if (!this.service.project.validators) {
				user.selectOdm = true
			} else if (this.service.project.validators.includes(user.username))
				user.selectOdm = true;
			else
				user.selectOdm = false;
			user.showOdm = true;
		} else {
			if (this.service.project.validators.includes(user.username)) {
				user.selectOdm = true;
				user.showOdm = true;
			}
			else
				user.selectOdm = false;
		}

		if (this.users.length > 0) {
			let index = this.users.findIndex(u => u.username == user.username);
			if (index > -1) {
				this.users[index].showOdm = user.showOdm;
				this.users[index].selectOdm = user.selectOdm;
			}
		}
	}

	/**
	 * Load roles
	 */
	private loadRoles(): void {
		this.service.getRoles(true).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe((list: any) => {
			this.allRoles = list.filter(r => r.id !== 'ADMIN');
			this.roles = list.filter(l => !l.id.startsWith("ADMIN"));
			this.productionRoles = list.filter(l => l.id.startsWith("PRODUCTION"));
			this.projectRoles = list.filter(l => l.id.startsWith("PROJECT"));
		}, err => console.log(err));
	}

	/**
	 * Open New User Modal
	 */
	addNewUserModal(): NgbModalRef {
		let modal = this.modalService.open(NewUserProjectComponent, { size: 'lg', backdrop: 'static', keyboard: false });
		if (this.isProductionSide)
			modal.componentInstance.roles = this.productionRoles;
		else
			modal.componentInstance.roles = this.roles;
		modal.componentInstance.users = this.users;
		modal.componentInstance.project = this.service.project;

		modal.componentInstance.decision = (this.decision == false && this.getDecisionLenght() < 1) || this.decision == true;
		modal.result.then((result: any) => {
			if (result) {
				// It means user was found, so add to the list
				if (result.user) {
					let filtered = this.users.filter(u => u.username == result.user.username);

					if (filtered.length > 0) {
						this.notificationService.open({
							title: 'User Invitation',
							description: `This user is already in the list!`,
							confirmBtn: 'OK'
						});
					}
				}

				else if (result.invited) {
					this.notificationService.open({
						title: 'User Invitation',
						description: `We haven't found a user with ${result.email} email address. We've sent an invite to join MTM.`,
						confirmBtn: 'Accept'
					});
				}
				this.loadUsers();
			}
		}, (err: any) => {
			console.log('aasdsa')
		});
		return modal;
	}

	/**
	 * Assign role to user
	 * @param email
	 */
	private assignRole(user: any): void {
		this.service.setRoles(this.service.project.id, user.username, [user.selectedRole]).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe((res) => {
			this.service.project.users = res.users;
		}, (err: any) => {
			console.log(err);
		});
	}

	/*
	* if (selectedRoles of user) > 0 ... visible
	* else unvisible
	*/
	reInviteStyleUserControl(user): boolean {
		if (user.rsvp == "YES") {
			return false;
		} else if (Object.keys(user).length > 3 && user.selectedRoles != null) {
			return user.selectedRoles.length;
		}
		return false;
	}

	private reinviteUserModal(user: any): NgbModalRef {
		if (user.selectedRoles <= 0)
			return;
		let modal = this.modalService.open(ReinviteUserProjectComponent, { size: 'lg' });
		modal.componentInstance.user = user;
		modal.componentInstance.projeID = this.service.project.id;
		modal.result.then(v => {
			if (v)
				this.loadUsers();
		}, (err: any) => console.log(err));
		return modal;
	}


	/**
	 * Open Delete User Modal
	 * @param user
	 */
	private deleteUserModal(users): NgbModalRef {
		let modal = this.modalService.open(DeleteUserProjectComponent, { size: 'lg' });
		modal.componentInstance.users = Array.isArray(users) ? users : [users];
		modal.result.then(v => {
			if (v)
				this.loadUsers();
		}, (err: any) => console.log(err));
		return modal;
	}


	/**
	 * Open Block User Modal
	 * @param user
	 */
	private blockUserModel(user: any): NgbModalRef {
		let modal = this.modalService.open(BlockUserProjectComponent, { size: 'lg' });
		modal.componentInstance.user = user;
		modal.result.then(v => {
			if (v)
				this.loadUsers();
		}, (err: any) => console.log(err));

		return modal;
	}


	/**
	 * Continue to next step
	 */
	continue() {
		this.router.navigate(['projects', this.service.project.id, 'dashboard']);
	}

	goToTeamFiles() {
		if (this.isCpd && this.project.campaignId) {
			this.router.navigate(['projects', 'edit', this.service.project.id, 'asset-list']);
		} else {
			this.router.navigate(['projects', 'edit', this.service.project.id, 'assets']);
		}
	}

	/*
	 * Return to project
	 */
	private returnToProject() {
		this.router.navigate(['projects', this.service.project.id]);
	}

	private deleteUsers() {
		let deleteUsers = this.users.filter(f => f.isSelected == true);
		this.deleteUserModal(deleteUsers);
	}

	onSelectChangedForUser(user) {
		user.isSelected ? this.counterSelectedUser++ : this.counterSelectedUser--;
		setTimeout(() => {
			if (user.username != this.authUser.username)
				return;

			user.isSelected = false;
			this.counterSelectedUser--;
		});
	}

	onOpenPermissionInfo() {
		let modal = this.modalService.open(PermissionsInfoModal, { modalDialogClass: 'responsive-modal' });
		modal.componentInstance.CLIENTorPROD = 'CLIENT';
	}

	goToProjectStagesProposalSide() {
		this.router.navigate(['projects', this.projectId, 'proposal', this.proposalId, 'projectStages']);
	}

	private initDecision() {
		if (this.service.project.validationMode == "ALL") {
			this.decision = true;
		} else if (this.service.project.validationMode == "ONE") {
			this.decision = false;
		}
	}

	changeDecision(status: boolean, isProposal: boolean) {
		if (!this.authUserHaveAValidationRight) {
			return;
		}

		if (status == false) {
			this.service.project.validationMode = "ONE";
			this.service.project.validators = [];
			const projectOwner = this.service.project.manager ?? this.authUser.username;
			this.service.project.validators.push(projectOwner);
			this.users.forEach(user => {
				if (user.username != projectOwner)
					user.selectOdm = false;
				else
					user.selectOdm = true;
			});
			this.decision = status;
		} else {
			this.service.project.validationMode = "ALL";
			this.service.project.validators = [];
			this.users.forEach(user => {
				user.showOdm = false;
				user.selectedRoles.forEach(role => {
					if (role == "PROJECT_OWNER" || role == "PROJECT_MANAGER") {
						user.selectOdm = true;
						user.showOdm = true;
						this.service.project.validators.push(user.username);
					} else {
						user.selectOdm = false;
						user.showOdm = false;
					}
				});
			});
			this.decision = status;
		}
		this.service.saveProject(this.service.project);
	}

	private getDecisionLenght(): number {
		return this.users.filter(k => k.roles.includes('PROJECT_OWNER')).length;
	}

	private oneDecisionOption(user: any): boolean {
		if (!this.decision) {
			if (this.authUser.username == user.username) {
				this.service.project.validators.push(this.authUser.username);
				return true;
			}
			return false;
		} else {
			return user.selectedRoles.includes("PROJECT_OWNER") || user.selectedRoles.includes("PROJECT_MANAGER");
		}
	}

	changeOdmDecision(user: any, e) {
		if (!this.authUserHaveAValidationRight) {
			return;
		}

		if (this.service.project.validators.includes(user.username)) {
			if (this.service.project.validators.length <= 1) {
				user.selectOdm = true;
				e.target.checked = true;
				this.service.saveProject(this.service.project);
				return;
			}
			let index = this.service.project.validators.findIndex(k => k == user.username);
			if (index >= 0) {
				user.selectOdm = false;
				e.target.checked = false;
				this.service.project.validators.splice(index, 1);
			}
		} else {
			if (user.selectedRoles.includes("PROJECT_OWNER") || user.selectedRoles.includes("PROJECT_MANAGER")) {
				user.selectOdm = true;
				e.target.checked = true;
				this.service.project.validators.push(user.username);
			} else {
				user.selectOdm = false;
				e.target.checked = false;
			}
		}
		this.service.project.validationMode = this.service.project.validators.length <= 1 ? "ONE" : "ALL";
		this.initDecision();
		this.service.saveProject(this.service.project);
	}

	onGridReady(event) {

	}

	searchUser(e, key) { }

	autoSizeColumnWidth() {
		this.projectPermissionTable.columnApi.autoSizeAllColumns();
	}

	fitTableColumns(event) {
		event.api.sizeColumnsToFit();
	}

	createTeam() {
		const activeWorkspace = this.workspaceService.getActiveWorkspace();
		if (!activeWorkspace) {
			return;
		}

		const modalRef = this.modalService.open(TeamEditorComponent, { size: 'lg', backdrop: 'static', keyboard: false });
		modalRef.componentInstance.workspaceId = activeWorkspace.id;
		modalRef.componentInstance.projectId = this.service.project.id;
		modalRef.componentInstance.brandId = this.brandId || HelperService.getEmptyUUID();
		modalRef.result.then((result: any) => {
			this.getTeams();
		});
	}

	editTeam(event: RowClickedEvent<Team>) {
		const activeWorkspace = this.workspaceService.getActiveWorkspace();
		if (!activeWorkspace) {
			return;
		}

		const modalRef = this.modalService.open(TeamEditorComponent, { size: 'lg', backdrop: 'static', keyboard: false });
		modalRef.componentInstance.workspaceId = activeWorkspace.id;
		modalRef.componentInstance.projectId = this.service.project.id;
		modalRef.componentInstance.brandId = event.data.brandId || this.brandId || HelperService.getEmptyUUID();
		modalRef.componentInstance.teamId = event.data.id;
		modalRef.result.then((result: any) => {
			this.getTeams();
		});
	}

	deleteTeam(team: Team) {
		console.log('team to delete', team);
		this.notificationService.open({
			title: this.translatePipe.transform('team_deleteTeam'),
			description: this.translatePipe.transform('team_confirmDelete', { name: team.name }),
			confirmBtn: this.translatePipe.transform('yes'),
			cancelBtn: this.translatePipe.transform('no')
		}).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe((confirm: any) => {
			if (!confirm)
				return;

			this.teamService.deleteTeam({
				projectId: team.projectId,
				workspaceId: team.workspaceId,
				companyId: team.companyId,
				brandId: team.brandId,
				id: team.id
			}).pipe(takeUntil(this.ngUnsubscribe))
				.subscribe({
					next: () => {
						this.getTeams();
					},
					error: () => {
						this.overlayService.showError(this.translatePipe.transform('deleteFailed'));
					}
				});

		});
	}

	async setBreadcrumb() {
		let links = [];
		const hasLtgPermission = this.auth.checkLtgPermission();
		if (hasLtgPermission) {
			const brand = this.auth.getMyBrand();
			if (brand) {
				links.push({
					label: brand.name
				})
			}
			if (this.project?.workspaceId) {
				links.push({
					label: 'Workspaces',
					url: ['/workspaces']
				});
				links.push({
					label: this.workspaceName,
					url: ['/workspaces', 'campaign-drive'],
					queryParams: {
						wid: this.cryptoService.encryptString(this.workspaceId)
					}
				});
			} else {
				links.push({
					label: this.isCpd ? 'Campaigns & Projects' : 'Drive Campaigns & Projects',
					url: ['/workspaces', 'campaign-drive'],
					queryParams: {
						wid: this.cryptoService.encryptString(this.workspaceId)
					}
				});
			}

			if (this.project && this.project.campaignId && this.project.isDriveCampaign) {
				links.push({
					label: this.driveCampaign?.name,
					url: ['/workspaces', 'campaigndrive', 'dashboard'],
					queryParams: {
						wid: this.cryptoService.encryptString(this.workspaceId),
						dc: this.cryptoService.encryptString(this.project?.campaignId)
					}
				})
				if (this.driveCampaign?.name && this.workspaceName)
					this.googleAnalyticsService.sendUserAnalyticsConfig(this.driveCampaign?.name, this.workspaceName,this.project?.name);
			} else if (this.workspaceName) {
				this.googleAnalyticsService.sendUserAnalyticsConfig('', this.workspaceName,'');
			}
		} else {
			links.push({
				label: 'Workspaces',
				url: ['/workspaces']
			});
			links.push({
				label: this.workspaceName,
				url: ['/projects']
			});
			if (this.workspaceName)
				this.googleAnalyticsService.sendUserAnalyticsConfig('', this.workspaceName,this.project?.name);
		}

		links.push({
			label: !this.project?.id ? 'New Project' : this.project?.name
		})

		if (this.router.url.startsWith('/projects/edit')) {
			links.push({
				label: 'Setting',
			});
			links.push({
				label: 'Users & permissions',
			});
		}
		this.breadcrumbService.setLinks(links);
	}
}
