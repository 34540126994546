<!-- <div class="container setting-header" [style.max-width]="'1239px'" [style.min-width]="'1239px'">
    <h4 class="text-left mt-3 pl-3"> {{projectName}} </h4>
    <p class="text-left pl-3">
    </p>
    <ul class="list-inline steps" style="display: block;">
        <li class="list-inline-item" #rla="routerLinkActive" routerLinkActive="active">
            <a>
                <span class="text-uppercase setting-header-title">{{'notification_settings_project' | translate }}</span>
            </a>
        </li>
    </ul>
</div> -->

<div class="d-flex flex-wrap w-100 px-4 custom-scroller" [style.background]="'#fff'">
   <div class="d-flex row w-100">
    <div class="row mt-30 mb-30 ml-10 w-100">
        <div class="col-lg-8 col-md-9 col-sm-12 d-flex flex-wrap w-100">
            <div class="w-100 d-flex">
                <span class="fw-700 notification">{{'notification_settings_projectTitle' | translate}}: {{projectName}}</span>
            </div>
            <div class="w-100 d-flex">
                <span class="notificationDesc">{{'notification_settings_projectDescription' | translate }}</span>
            </div>
        </div>
        <div class="col-lg-4 col-md-3 col-sm-12 d-flex flex-wrap w-100 justify-content-lg-end justify-content-md-end justify-content-sm-center">
            <div class="d-flex align-items-center">
                <span class=" notification-text mr-2">{{'toggleAll' | translate }}</span>
                <div class="switch-btn pull-right">
                    <ui-switch defaultBgColor="black" id="projectnotificationsetting" size="small" switchColor="#efefef"
                        [(ngModel)]="isAllNotificationsSelected"
                        (click)="toggleNotifications()"></ui-switch>
                </div>
            </div>
        </div>
    </div>

    <div class="row mt-30 mb-30 ml-10 w-100">
        <div class="col-lg-8 col-md-9 col-sm-12 d-flex flex-wrap w-100">
            <div class="w-100">
                <span class="fw-700 notification">{{'notification_settings_selfNotifications' | translate}}</span>
            </div>
            <div class="w-100">
                <span class="notificationDesc mb-30">{{'notification_settings_selfNotificationsDescription' | translate}}</span>
            </div>
        </div>
        <div class="col-lg-4 col-md-3 col-sm-12 d-flex flex-wrap w-100 justify-content-lg-end justify-content-md-end justify-content-sm-center">
            <div class="d-flex align-items-center">
            <ui-switch defaultBgColor="black" size="small" id="projectnotificationsetting" switchColor="#efefef" switchColor="#efefef"
                        [(ngModel)]="userNotification.seeOwnActions"
                        (click)="changeNotificationsSettings(userNotification)"></ui-switch>
            </div>
        </div>
    </div>
    <span class="text-center f-18 _700 d-flex justify-content-center my-3 w-100 ">{{'notification_settings_periodicEmailSummaryNotifications' | translate }}</span>
    <div class="w-100 h-100">
        <div class="d-flex flex-wrap w-100">
            <div class="col-lg-8 col-md-12 col-sm-12 d-flex flex-wrap w-100">
                <div class="w-100 d-flex">
                    <span class="fw-700 notification">{{'notificationsSummaryTitle' | translate}}</span>
                </div>
                <div class="w-100 d-flex">
                    <span class="notificationDesc">{{'notificationsSummarySubTitle' | translate}}</span>
                </div>
            </div>
            <div class="align-items-center col-lg-4 col-md-12 col-sm-12 d-flex flex-lg-nowrap flex-md-nowrap flex-wrap flex-xl-nowrap pr-0" [style.gap]="'20px'">
                <div class="w-100 d-flex flex-wrap">
                    <select class="notification-time-select w-100" [(ngModel)]="userNotification.notificationSummaryDuration"
                        (ngModelChange)="changeNotificationsSettings(userNotification)">
                        <option *ngFor="let interval of notificationIntervals" [value]="interval.value">{{interval.text  | translate}}</option>
                    </select>
                </div>
                <div class="align-items-center d-flex flex-lg-nowrap flex-xl-nowrap w-100">
                    <div class="d-flex w-100 align-items-center justify-content-md-end ">
                        <img class="mr-1" width="22" height="22"
                        src="../../../assets/img/icons/mail-box.svg" alt=""> <span
                        class=" notification-text mr-2">Email</span>
                    </div>
                    <div class="switch-btn">
                        <ui-switch defaultBgColor="black" id="projectnotificationsetting" size="small" switchColor="#efefef"
                            [(ngModel)]="userNotification.notificationSummaryEmail"
                            (click)="changeNotificationsSettings(userNotification)"></ui-switch>
                    </div>
                </div>
            </div>
        </div>
        <span class="w-100 f-18 _700 d-flex justify-content-center my-3 text-center text-capitalize">{{'notification_settings_realTimeNotifications' | translate }}</span>
        <div class="w-100 h-100 mb-3">
            <div class="d-flex row project-noti-setting-section pt-2 pb-2 ">
                    <div class="col-lg-8 col-md-12 col-sm-12 d-flex flex-wrap p-0">
                        <div class="col-12 d-flex">
                            <span class="fw-700 f-12">{{'notification_settings_allBelow' | translate}}</span>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-12 col-sm-12 d-flex flex-wrap">
                        <div class="align-items-center col d-flex justify-content-between">
                            <div class="d-flex">
                                <img class="mr-1" src="../../../assets/img/icons/gloab.svg" alt="">
                                <span class="f-12 _400 c-mtm-full-black"> MTM Platform </span>
                            </div>
                            <div class="switch-btn">
                                <ui-switch id="projectnotificationsetting" defaultBgColor="black" size="small" switchColor="#efefef"
                                    switchColor="#efefef" [(ngModel)]="isAllPlatformNotificationsSelected"
                                    (click)="togglePlatformNotifications()"></ui-switch>
                            </div>
                        </div>
                        <div class="align-items-center col d-flex justify-content-between">
                            <div class="d-flex">
                                <img class="mr-1" src="../../../assets/img/icons/mail-box.svg" alt="">
                                <span class=" f-12 _400 c-mtm-full-black">Email</span>
                            </div>
                            <div class="switch-btn">
                                <ui-switch id="projectnotificationsetting" defaultBgColor="black" size="small" switchColor="#efefef"
                                    [(ngModel)]="isAllEmailNotificationsSelected"
                                    (click)="toggleEmailNotifications()"></ui-switch>
                            </div>
                        </div>
                        <div class="align-items-center col d-flex justify-content-between">
                            <div class="d-flex">
                                <img class="mr-1" src="../../../assets/img/icons/cell-phone.svg" alt="">
                                <span class="f-12 _400 c-mtm-full-black">SMS</span>
                            </div>
                            <div class="switch-btn">
                                <ui-switch id="projectnotificationsetting" defaultBgColor="black" size="small" switchColor="#efefef"
                                    [(ngModel)]="isAllSMSNotificationsSelected"
                                    (click)="toggleSMSNotifications()"></ui-switch>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex row project-noti-setting-section pt-2 pb-2 ">
                    <div class="col-lg-8 col-md-12 col-sm-12 d-flex flex-wrap p-0">
                        <div class="col-12 d-flex">
                            <span class="fw-700 f-12">{{'Validation' | translate}}</span>
                        </div>
                        <div class="col-12 d-flex">
                            <span class="notificationDesc">{{'notification_settings_validationDescription' |
                                translate}}..
                            </span>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-12 col-sm-12 d-flex flex-wrap">
                        <div class="align-items-center col d-flex justify-content-between">
                            <div class="d-flex">
                                <img class="mr-1" src="../../../assets/img/icons/gloab.svg" alt="">
                                <span class="f-12 _400 c-mtm-full-black"> MTM Platform </span>
                            </div>
                            <div class="switch-btn">
                                <ui-switch id="projectnotificationsetting" defaultBgColor="black" size="small" switchColor="#efefef"
                                    switchColor="#efefef" [(ngModel)]="userNotification.validationsWeb"
                                    (click)="changeNotificationsSettings(userNotification)"></ui-switch>
                            </div>
                        </div>
                        <div class="align-items-center col d-flex justify-content-between">
                            <div class="d-flex">
                                <img class="mr-1" src="../../../assets/img/icons/mail-box.svg" alt="">
                                <span class=" f-12 _400 c-mtm-full-black">Email</span>
                            </div>
                            <div class="switch-btn">
                                <ui-switch id="projectnotificationsetting" defaultBgColor="black" size="small" switchColor="#efefef"
                                [(ngModel)]="userNotification.validationsEmail"
                                (click)="changeNotificationsSettings(userNotification)"></ui-switch>
                            </div>
                        </div>
                        <div class="align-items-center col d-flex justify-content-between">
                            <div class="d-flex">
                                <img class="mr-1" src="../../../assets/img/icons/cell-phone.svg" alt="">
                                <span class="f-12 _400 c-mtm-full-black">SMS</span>
                            </div>
                            <div class="switch-btn">
                                <ui-switch id="projectnotificationsetting" defaultBgColor="black" size="small" switchColor="#efefef"
                                [(ngModel)]="userNotification.validationsMobile"
                                (click)="changeNotificationsSettings(userNotification)"></ui-switch>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="d-flex row project-noti-setting-section pt-2 pb-2">
                    <div class="col-lg-8 col-md-12 col-sm-12 d-flex flex-wrap p-0">
                        <div class="col-12 d-flex">
                            <span class="fw-700 f-12">{{'notificationEditing' | translate}}</span>
                        </div>
                        <div class="col-12 d-flex">
                            <span class="notificationDesc">{{'notification_settings_editingDescription' |
                                translate}}..
                            </span>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-12 col-sm-12 d-flex flex-wrap">
                        <div class="align-items-center col d-flex justify-content-between">
                            <div class="d-flex">
                                <img class="mr-1" src="../../../assets/img/icons/gloab.svg" alt="">
                                <span class="f-12 _400 c-mtm-full-black"> MTM Platform </span>
                            </div>
                            <div class="switch-btn">
                                <ui-switch id="projectnotificationsetting" defaultBgColor="black" size="small" switchColor="#efefef"
                                    switchColor="#efefef" [(ngModel)]="userNotification.editingWeb"
                                    (click)="changeNotificationsSettings(userNotification)"></ui-switch>
                            </div>
                        </div>
                        <div class="align-items-center col d-flex justify-content-between">
                            <div class="d-flex">
                                <img class="mr-1" src="../../../assets/img/icons/mail-box.svg" alt="">
                                <span class=" f-12 _400 c-mtm-full-black">Email</span>
                            </div>
                            <div class="switch-btn">
                                <ui-switch id="projectnotificationsetting" defaultBgColor="black" size="small" switchColor="#efefef"
                                    [(ngModel)]="userNotification.editingEmail"
                                    (click)="changeNotificationsSettings(userNotification)"></ui-switch>
                            </div>
                        </div>
                        <div class="align-items-center col d-flex justify-content-between">
                            <div class="d-flex">
                                <img class="mr-1" src="../../../assets/img/icons/cell-phone.svg" alt="">
                                <span class="f-12 _400 c-mtm-full-black">SMS</span>
                            </div>
                            <div class="switch-btn">
                                <ui-switch id="projectnotificationsetting" defaultBgColor="black" size="small" switchColor="#efefef"
                                    [(ngModel)]="userNotification.editingMobile"
                                    (click)="changeNotificationsSettings(userNotification)"></ui-switch>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="d-flex row  project-noti-setting-section pt-2 pb-2">
                    <div class="col-lg-8 col-md-12 col-sm-12 d-flex flex-wrap p-0">
                        <div class="col-12 d-flex">
                            <span class="fw-700 f-12">{{'projectCommunication' | translate}}</span>
                        </div>
                        <div class="col-12 d-flex">
                            <span
                                class="notificationDesc">{{'notification_settings_projectCommunicationDescription'
                                | translate}}..
                            </span>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-12 col-sm-12 d-flex flex-wrap">
                        <div class="align-items-center col d-flex justify-content-between">
                            <div class="d-flex">
                                <img class="mr-1" src="../../../assets/img/icons/gloab.svg" alt="">
                                <span class="f-12 _400 c-mtm-full-black"> MTM Platform </span>
                            </div>
                            <div class="switch-btn">
                                <ui-switch id="projectnotificationsetting" defaultBgColor="black" size="small" switchColor="#efefef"
                                    switchColor="#efefef" [(ngModel)]="userNotification.projectCommunicationWeb"
                                    (click)="changeNotificationsSettings(userNotification)"></ui-switch>
                            </div>
                        </div>
                        <div class="align-items-center col d-flex justify-content-between">
                            <div class="d-flex">
                                <img class="mr-1" src="../../../assets/img/icons/mail-box.svg" alt="">
                                <span class=" f-12 _400 c-mtm-full-black">Email</span>
                            </div>
                            <div class="switch-btn">
                                <ui-switch id="projectnotificationsetting" defaultBgColor="black" size="small" switchColor="#efefef"
                                    [(ngModel)]="userNotification.projectCommunicationEmail"
                                    (click)="changeNotificationsSettings(userNotification)"></ui-switch>
                            </div>
                        </div>
                        <div class="align-items-center col d-flex justify-content-between">
                            <div class="d-flex">
                                <img class="mr-1" src="../../../assets/img/icons/cell-phone.svg" alt="">
                                <span class="f-12 _400 c-mtm-full-black">SMS</span>
                            </div>
                            <div class="switch-btn">
                                <ui-switch id="projectnotificationsetting" defaultBgColor="black" size="small" switchColor="#efefef"
                                    [(ngModel)]="userNotification.projectCommunicationMobile"
                                    (click)="changeNotificationsSettings(userNotification)"></ui-switch>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="d-flex row project-noti-setting-section pt-2 pb-2">
                    <div class="col-lg-8 col-md-12 col-sm-12 d-flex flex-wrap p-0">
                        <div class="col-12 d-flex">
                            <span class="fw-700 f-12">{{'taskNotificationSetting' | translate}}</span>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-12 col-sm-12 d-flex flex-wrap">
                        <div class="align-items-center col d-flex justify-content-between">
                            <div class="d-flex">
                                <img class="mr-1" src="../../../assets/img/icons/gloab.svg" alt="">
                                <span class="f-12 _400 c-mtm-full-black"> MTM Platform </span>
                            </div>
                            <div class="switch-btn">
                                <ui-switch id="projectnotificationsetting" defaultBgColor="black" size="small" switchColor="#efefef"
                                    switchColor="#efefef" [(ngModel)]="userNotification.taskWeb"
                                    (click)="changeNotificationsSettings(userNotification)"></ui-switch>
                            </div>
                        </div>
                        <div class="align-items-center col d-flex justify-content-between">
                            <div class="d-flex">
                                <img class="mr-1" src="../../../assets/img/icons/mail-box.svg" alt="">
                                <span class=" f-12 _400 c-mtm-full-black">Email</span>
                            </div>
                            <div class="switch-btn">
                                <ui-switch id="projectnotificationsetting" defaultBgColor="black" size="small" switchColor="#efefef"
                                    [(ngModel)]="userNotification.taskEmail"
                                    (click)="changeNotificationsSettings(userNotification)"></ui-switch>
                            </div>
                        </div>
                        <div class="align-items-center col d-flex justify-content-between">
                            <div class="d-flex">
                                <img class="mr-1" src="../../../assets/img/icons/cell-phone.svg" alt="">
                                <span class="f-12 _400 c-mtm-full-black">SMS</span>
                            </div>
                            <div class="switch-btn">
                                <ui-switch id="projectnotificationsetting" defaultBgColor="black" size="small" switchColor="#efefef"
                                    [(ngModel)]="userNotification.taskMobile"
                                    (click)="changeNotificationsSettings(userNotification)"></ui-switch>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex row project-noti-setting-section pt-2 pb-2">
                    <div class="col-lg-8 col-md-12 col-sm-12 d-flex flex-wrap p-0">
                        <div class="col-12 d-flex">
                            <span class="fw-700 f-12">{{'projectSchedule' | translate}}</span>
                        </div>
                        <div class="col-12 d-flex">
                            <span
                                class="notificationDesc">{{'notification_settings_projectScheduleDescription'
                                | translate}}..
                            </span>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-12 col-sm-12 d-flex flex-wrap">
                        <div class="align-items-center col d-flex justify-content-between">
                            <div class="d-flex">
                                <img class="mr-1" src="../../../assets/img/icons/gloab.svg" alt="">
                                <span class="f-12 _400 c-mtm-full-black"> MTM Platform </span>
                            </div>
                            <div class="switch-btn">
                                <ui-switch id="projectnotificationsetting" defaultBgColor="black" size="small" switchColor="#efefef"
                                    switchColor="#efefef" [(ngModel)]="userNotification.projectScheduleWeb"
                            (click)="changeNotificationsSettings(userNotification)"></ui-switch>
                            </div>
                        </div>
                        <div class="align-items-center col d-flex justify-content-between">
                            <div class="d-flex">
                                <img class="mr-1" src="../../../assets/img/icons/mail-box.svg" alt="">
                                <span class=" f-12 _400 c-mtm-full-black">Email</span>
                            </div>
                            <div class="switch-btn">
                                <ui-switch id="projectnotificationsetting" defaultBgColor="black" size="small" switchColor="#efefef"
                                    [(ngModel)]="userNotification.projectScheduleEmail"
                                    (click)="changeNotificationsSettings(userNotification)"></ui-switch>
                            </div>
                        </div>
                        <div class="align-items-center col d-flex justify-content-between">
                            <div class="d-flex">
                                <img class="mr-1" src="../../../assets/img/icons/cell-phone.svg" alt="">
                                <span class="f-12 _400 c-mtm-full-black">SMS</span>
                            </div>
                            <div class="switch-btn">
                                <ui-switch id="projectnotificationsetting" defaultBgColor="black" size="small" switchColor="#efefef"
                                    [(ngModel)]="userNotification.projectScheduleMobile"
                                    (click)="changeNotificationsSettings(userNotification)"></ui-switch>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="d-flex row project-noti-setting-section pt-2 pb-2">
                    <div class="col-lg-8 col-md-12 col-sm-12 d-flex flex-wrap p-0">
                        <div class="col-12 d-flex">
                            <span class="fw-700 f-12">{{'teamBuilding' | translate}}</span>
                        </div>
                        <div class="col-12 d-flex">
                            <span class="notificationDesc">{{'notification_settings_teamScheduleDescription'
                                | translate}}..
                            </span>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-12 col-sm-12 d-flex flex-wrap">
                        <div class="align-items-center col d-flex justify-content-between">
                            <div class="d-flex">
                                <img class="mr-1" src="../../../assets/img/icons/gloab.svg" alt="">
                                <span class="f-12 _400 c-mtm-full-black"> MTM Platform </span>
                            </div>
                            <div class="switch-btn">
                                <ui-switch id="projectnotificationsetting" defaultBgColor="black" size="small" switchColor="#efefef"
                                    switchColor="#efefef" [(ngModel)]="userNotification.teamBuildingWeb"
                                    (click)="changeNotificationsSettings(userNotification)"></ui-switch>
                            </div>
                        </div>
                        <div class="align-items-center col d-flex justify-content-between">
                            <div class="d-flex">
                                <img class="mr-1" src="../../../assets/img/icons/mail-box.svg" alt="">
                                <span class=" f-12 _400 c-mtm-full-black">Email</span>
                            </div>
                            <div class="switch-btn">
                                <ui-switch id="projectnotificationsetting" defaultBgColor="black" size="small" switchColor="#efefef"
                                    [(ngModel)]="userNotification.teamBuildingEmail"
                                    (click)="changeNotificationsSettings(userNotification)"></ui-switch>
                            </div>
                        </div>
                        <div class="align-items-center col d-flex justify-content-between">
                            <div class="d-flex">
                                <img class="mr-1" src="../../../assets/img/icons/cell-phone.svg" alt="">
                                <span class="f-12 _400 c-mtm-full-black">SMS</span>
                            </div>
                            <div class="switch-btn">
                                <ui-switch id="projectnotificationsetting" defaultBgColor="black" size="small" switchColor="#efefef"
                                    [(ngModel)]="userNotification.teamBuildingMobile"
                                    (click)="changeNotificationsSettings(userNotification)"></ui-switch>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    </div>
</div>
</div>
<!-- <div class="row my-4 ml-4 justify-content-between custom-max-width-notification ml-auto mr-auto">
    <button class="btn btn-lg btn-danger mr-2" type="button" (click)="returnToProject()">
        <span>{{'returnProject' | translate}}</span>
    </button>
</div> -->
<ng-template #searchPanel>
    <div class="d-flex flex-column flex-sm-row align-items-center search-panel">
        <button class="btn btn-orange text-uppercase position-relative btn-new-workspace" (click)="returnToProject()">
            <span>{{'returnProject' | translate}}</span> </button>
    </div>
<ng-template>