import { AfterViewInit, Component, Inject, Input, OnDestroy, OnInit } from "@angular/core";
import { Form, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { CreateNewWorkspaceInfo, NewWorkspaceInfo } from "../../models/workspace.model";
import { AuthService } from "../../../shared/services/auth.service";
import { UserService } from "../../../shared/services/user.service";
import { Subject } from "rxjs";
import { takeUntil, take, toArray } from "rxjs/operators";
import { DomSanitizer } from "@angular/platform-browser";
import { SignedURL_FU_Base } from "../../../shared/bases/signed-url-file-upload.base";
import { NgbActiveModal, NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { UploadModalComponent } from "../../../on-boarding/profile-avatar/upload-modal/upload-modal.component";
import { UploadService } from "../../../shared/services/upload.service";
import { ApiService } from "../../../shared/services/api.service";
import { UUID } from "angular2-uuid";
import { zip } from "rxjs";
import { Router } from "@angular/router";
import { WorkspaceService } from "app/shared/services/workspace.service";
import { forkJoin } from "rxjs";
import { concat } from "rxjs";
import { EmitterService } from "app/shared/services/emitter.service";
import { WorkspaceAvatarUpdatedEvent, WorkspaceUpdatedEvent } from "app/shared/interfaces/workspace.interface";
// import { e } from "@angular/core/src/render3";
import { BreadcrumbService } from "ng5-breadcrumb";
import { DataTransferBetweenComponentsService } from "app/shared/services/data-transfer-between-components.service";
import { OverlayService } from "app/shared/services/overlayService";
import { DOCUMENT, TranslationWidth } from "@angular/common";
import { TranslatePipe } from "app/shared/pipes/translate.pipe";
import { AnalyticsService } from "app/shared/services/analytics.service";
import { onLeaveFromProject } from "app/shared/services/project.service";
import { MtmBreadcrumbService } from "app/shared/services/mtm-breadcrumb.service";
import { ThemeService } from "app/shared/services/theme.service";

@Component({
	selector: 'mtm-create-workspace',
	templateUrl: './create-workspace.component.html',
	styleUrls: ['./create-workspace.component.scss']
})
export class CreateWorkspaceComponent implements OnInit, OnDestroy, AfterViewInit {
	@Input() companyId: string;
	@Input() brand: any;
	@Input() asModal: boolean = false;

	workspaceForm: UntypedFormGroup;
	model: NewWorkspaceInfo = CreateNewWorkspaceInfo();
	isAdding: boolean = false;
	companyUsers: any[];
	uploading: boolean = false;
	private ngUnsubscribe = new Subject();
	private modal: NgbModalRef;
	authUser: any = null;
	isSaving: boolean = false;
	keyword = 'fullName';
	brandId: string;
	fromBandW: boolean = false;
	breadcrumbs: any;

	constructor(
		@Inject(DOCUMENT) private breadcrumbDoc: Document,
		private fb: UntypedFormBuilder,
		private authService: AuthService,
		private router: Router,
		private userService: UserService,
		private workspaceService: WorkspaceService,
		private sanitizer: DomSanitizer,
		private uploadService: UploadService,
		private apiService: ApiService,
		private emitterService: EmitterService,
		private modalService: NgbModal,
		private breadcrumbService: BreadcrumbService,
		private mtmBreadcrumbService: MtmBreadcrumbService,
		private themeService: ThemeService,
		private overlayService: OverlayService,
		private translatePipe: TranslatePipe,
		private activeModal: NgbActiveModal,
		private transferService: DataTransferBetweenComponentsService,
		private analyticService: AnalyticsService) {
	}
	initForm() {
		this.workspaceForm = this.fb.group({
			name: ['', Validators.required]
		});
	}
	
	ngOnInit() {
		// // update breadcrumb color
		// this.breadcrumbs = this.breadcrumbDoc.body.getElementsByTagName('breadcrumb');
		// if (this.breadcrumbs.length) {
		//   this.breadcrumbs[0].classList.add('campaign-drive-general');
		// }

		this.authUser = this.authService.getAuthUser();
		this.analyticService.selectedBrand.subscribe(brand => {
			if (brand) {
				this.brand = brand;
				this.fromBandW = true;
			}
		})
		this.initForm();
		this.mtmBreadcrumbService.clearLinks();
		this.themeService.updateContent({
			fullWidthContent: true,
		})
		this.configureBreadcrumb();
		const companyId = this.companyId || this.authUser.companyId;
		if (this.authUser.globalRole == 'COMPANY_PRINCIPAL' || 'ADMIN')  {
			this.model.owners = [{
				name: this.authUser.fullName,
				avatarUrl: this.authUser.avatarUrl,
				email: this.authUser.email,
				jobTitle: this.authUser.jobTitle,
				role: this.authUser.globalRole,
				user: this.authUser,
				status: this.authUser.activationStatus
			}];
		} else {
			console.log('user dont have permission to create workspace')
		}
		this.userService.getUsersByCompanyId(companyId)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(users => {
				this.companyUsers = users;
		
				// this.model.owners = users.filter(u => u.globalRole == 'COMPANY_PRINCIPAL')
				// 	.sort((a, b) => a.fullName.localeCompare(b.fullName))
				// 	.map((user: any) => {
				// 		return {
							// name: user.fullName,
							// avatarUrl: user.avatarUrl,
							// email: user.email,
							// jobTitle: user.jobTitle,
							// role: user.globalRole,
							// user: user,
							// status: user.activationStatus
				// 		}
				// 	});

			});
		this.brandId = this.brand?.id || localStorage.getItem('brandId');
		this.setBreadcrumb();

	}

	ngOnDestroy() {
		// update breadcrumb color
		this.breadcrumbs = this.breadcrumbDoc.body.getElementsByTagName('breadcrumb');
		if (this.breadcrumbs.length) {
			this.breadcrumbs[0].classList.add('campaign-drive-general');
		}
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
		this.mtmBreadcrumbService.clearLinks();
	}

	ngAfterViewInit(): void {
		// update breadcrumb color
		setTimeout(() => {
			let breadcrumbs = this.breadcrumbDoc.body.getElementsByTagName('breadcrumb');
			if (breadcrumbs.length) {
				breadcrumbs[0].classList.add('campaign-drive-general');
			}
		}, 500);
	}
     
	setBreadcrumb() {

		let links = [];
		const hasLtgPermission = this.authService.checkLtgPermission();
		if (hasLtgPermission) {
			const brand = this.authService.getMyBrand();

			if (brand){

				links.push({
					label: brand.name
				})
			}

				links.push({
					label: 'Workspaces',
					url: ['/workspaces']
				});

				links.push({
					label: 'New Workspaces'
				});

				
		this.mtmBreadcrumbService.setLinks(links);
	}
}

	showUserField() {
		this.isAdding = true;
	}

	configureBreadcrumb() {
		this.breadcrumbService.addCallbackForRoute('/workspace', () => this.translatePipe.transform('workspace'));
		this.breadcrumbService.addCallbackForRoute(
			'/workspace/new',
			() => {
				return this.translatePipe.transform('workspace_createNewWorkspace');
			}
		);

		this.transferService.ngOnChangesBreadcrumbComponent();
		this.breadcrumbs = this.breadcrumbDoc.body.getElementsByTagName('breadcrumb');
		if (this.breadcrumbs.length) {
			this.breadcrumbs[0].classList.add('campaign-drive-general');
		}
	}

	addUser(user) {
		if (this.model.owners.find(o => o.email == user.email)) {
			this.isAdding = false;
			return;
		}
		console.log(user);

		let role = user.company.companyType == 'ADVERTISER' ? 'COMPANY_PROJECT_OWNER' : 'COMPANY_PRODUCTION_OWNER';
		const exceptions = ['ADMIN', 'COMPANY_PRINCIPAL'];

		if (user.globalRole && exceptions.indexOf(user.globalRole) > -1)
			role = user.globalRole;

		this.model.owners.push({
			name: user.fullName ? user.fullName.trim() : '',
			avatarUrl: user.avatarUrl,
			email: user.email,
			jobTitle: user.jobTitle,
			role: role,
			user: user,
			status: user.activationStatus
		});
		console.log(this.model.owners);

		this.isAdding = false;
	}

	removeUser(owner) {
		if (!owner)
			return;

		const index = this.model.owners.indexOf(owner);
		if (index != -1)
			this.model.owners.splice(index, 1);
	}

	formatUserSuggest(data: any) {
		let html = `<span class="list-item" >
						<span>${data.fullName}</span>
						<span>( ${data.email} )</span>
					</span>`;

		return html;
	}


	//forces form controls to mark as touched when save button is triggered
	private validateFormFields(formGroup: UntypedFormGroup) {
		Object.keys(formGroup.controls).forEach(field => {
			const control = formGroup.get(field);
			if (control instanceof UntypedFormControl) {
				control.markAsTouched({ onlySelf: true });
			} else if (control instanceof UntypedFormGroup) {
				this.validateFormFields(control);
			}
		});
	}

	cancel() {
		if (this.asModal) {
			this.activeModal.close();
		} else {
			const isLTG = localStorage.getItem('hasLtgPermission');
			if (isLTG === "true") {
				this.router.navigate(['/workspaces']);
			} else {
				this.router.navigate(['/projects']);
			}
		}
	}

	submit() {
		this.validateFormFields(this.workspaceForm);
		if (!this.workspaceForm.valid || this.model.owners.length == 0) {
			return;
		}
		this.saveWorkspace();
	}

	saveWorkspace() {
		const authUser = this.authUser;
		const usernames = this.model.owners.map(o => o.email);
		const workspaceData = {
			companyId: this.companyId || authUser.companyId,
			name: this.workspaceForm.value.name,
			usernames
		};
		// if (this.brandId) {
		// 	workspaceData["brandId"] = this.brandId;
		// }
		this.isSaving = true;

		// Add brandId as a query string parameter
		// const queryParams = this.brandId ? { brandId: this.brandId } : {};

		this.workspaceService.createWorkspace(workspaceData, this.brandId).pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe((result: any) => {
			if (this.model.imageUrl) {
				const workspaceUrl = this.apiService.baseUrl + `/api/workspace/${this.companyId || authUser.company.id}/${result.id}/avatar`;
				this.uploadService.uploadImage(this.model.imageUrl, workspaceUrl)
					.subscribe((uploadResult) => {
						EmitterService.get(WorkspaceUpdatedEvent).emit(workspaceData);

						EmitterService.get(WorkspaceAvatarUpdatedEvent).emit({
							workspaceId: result.id,
							avatarUrl: uploadResult.avatarUrl
						})
						// this.saveUsers(this.companyId || authUser.company.id, result);
						if (this.brand && !this.fromBandW) {
							this.activeModal.close(workspaceData);
						}
						this.router.navigate(['/workspace/edit', result.id]);
					});
				}
			else {
				// this.saveUsers(this.companyId || authUser.company.id, result);
				this.router.navigate(['/workspace/edit', result.id]);
			}
			//this.router.navigate(['/workspace/edit', result.id]);

			this.overlayService.showSuccess('Workspace Created.', 'Success');
		}, (error) => {
			this.isSaving = false;
			let errorCode = error.errorCode;
			let errorMessage = errorCode == 'DUPLICATE_NAME' ? 'workspace_create_duplicateError' : 'workspace_create_error';
			this.overlayService.showError(
				this.translatePipe.transform(errorMessage, { name: workspaceData.name }), 'Error');
		});
	}

	private saveUsers(companyId: string, workspaceData: any) {
		let $obs = [];

		this.model.owners.forEach(owner => {
			$obs.push(this.workspaceService.addUserToWorkspace(companyId, workspaceData.id,
				owner.email, 'COMPANY_PROJECT_OWNER', ['PROJECT_OWNER']));
		});

		console.log('$obs.length', $obs.length)
		if ($obs.length) {
			concat(...$obs)
				.pipe(toArray())
				.subscribe(data => {
					this.isSaving = false;
					EmitterService.get(WorkspaceUpdatedEvent).emit(workspaceData);
					if (this.brand && !this.fromBandW) {
						return this.activeModal.close(workspaceData);
					}
					return this.router.navigate(['/workspace/edit', workspaceData.id]);
				});
		} else {
			this.isSaving = false;
			EmitterService.get(WorkspaceUpdatedEvent).emit(workspaceData);
			if (this.brand && !this.fromBandW) {
				this.activeModal.close(workspaceData);
			}
			this.router.navigate(['/workspace/edit', workspaceData.id]);
		}

	}

	private openModal() {
		this.modal = this.modalService.open(UploadModalComponent, { size: 'lg', windowClass: 'upload-modal-window' });
		this.modal.componentInstance.setCrop(false);
	}

	fileChangeListener($event): void {
		const files = $event.files[0];
		if (files.length == 0)
			return;

		this.openModal();
		let that = this;
		this.modal.componentInstance.imageFile = files;
		const file = files;
		const fileReader = new FileReader();
		fileReader.onloadend = (loadEvent: any) => {
			this.setImageOnModal(that, loadEvent.target.result);
		};
		fileReader.readAsDataURL(file);
	}

	private setImageOnModal(that: any, img64) {
		let image: any = new Image();
		image.src = img64;
		// let cropper = that.modal.componentInstance.cropper;
		// cropper.setImage(image);
		this.modal.componentInstance.uploadObserver.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe((result) => {
			if (result) {
				this.uploading = true;

				this.model.imageUrl = result;
			}
		});
	}
}
