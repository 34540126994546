import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from "ag-grid-community";

@Component({
    selector: 'role-cell',
    templateUrl: './role-cell.component.html',
    styleUrls: ['./role-cell.component.scss']
})
export class RoleCellComponent implements ICellRendererAngularComp, OnInit {
    @ViewChild('roleCell', { static: false }) roleCell: ElementRef;
    params: any;

    constructor() { }

    ngOnInit(): void { }

    agInit(params: ICellRendererParams): void {
        this.params = params;
    }

    refresh(params: ICellRendererParams): boolean {
        this.params = params;
        return true;
    }

    dropdownOpen(event) {
        if (event.isOpen) {
            let row = this.roleCell.nativeElement.closest('.ag-row');
            row.classList.add("row-open");
        } else {
            let row = this.roleCell.nativeElement.closest('.ag-row');
            row.classList.remove("row-open");
        }
    }

    getRoles(): any[] {
        const user = this.params.data; // Get the current user being rendered
    
        // Access projectUsers from the correct context
        const projectUsers = this.params.context.componentParent.projectUsers;
    
        // Check if projectUsers is defined and an array
        if (!Array.isArray(projectUsers)) {
            console.error('projectUsers is not an array:', projectUsers);
            return []; // Return an empty array or handle the case as needed
        }
    
        // Find matching project user
        const matchingProjectUser = projectUsers.find(projUser => projUser.email === user.email);
    
        if (matchingProjectUser) {
            // Dynamically add userPermissionType from matchingProjectUser
            user.userPermissionType = matchingProjectUser.userPermissionType;
        }
    
        // Return roles based on userPermissionType
        switch (user.userPermissionType) {
            case "BRAND":
                return this.params.context.componentParent.projectRoles; // Return production roles for BRAND users
            case "AGENCY":
                return this.params.context.componentParent.productionRoles; // Return project roles for AGENCY users
            default:
                return this.params.context.componentParent.roles; // Return default roles for other users
        }
    }
}

